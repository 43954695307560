import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import * as Yup from "yup";
import Dropzone from "react-dropzone";
import { withStyles } from "@mui/styles";
import {
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
} from "@mui/material";
import * as API from "../../api/API";
import store from "../../redux/reducers";
import { ADD_USER_DETAILS } from "../../redux/constants/ActionTypes";
import { Text } from "../../components/shared/Typography/Typo";
import { LoginIcon } from "../../components/shared/fields/Button/LoginIcon";
import { CustomButton } from "../../components/shared/fields/Button/CustomButton";
import Search from "../../components/shared/fields/Search/Search";
import image from "../../assets/images/text logo.png";
import {
  Image,
  ImageContainer,
  ImageWrapper,
} from "../../components/shared/fields/Search/SearchRow";
import Divider from "@mui/material/Divider";
import { useStateValue } from "../../context/StateProvider";
import {
  ContainerForm,
  DroppableContainer,
  InnerContainer,
  LoadingContainer,
} from "../Login/LoginForm";
import RequestVerificationActionConfirm from "../../components/shared/Profile/RequestVerificationActionConfirm";
import * as encryption from "../../utils/encryption";

const AddProfile = ({ files, loading, setShowAction }) => {
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useSelector((state) => state.currentUser);
  const [showActionConfirm, setShowActionConfirm] = useState(() =>
    localStorage.secretKey ? 1 : 0
  );
  const [secretKey, setSecretKey] = useState(null);
  const [authUser, setAuthUser] = useState(() => API.getUserSession());
  const [{ searchData }, dispatch] = useStateValue();
  const [data, setSearchData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [fileError] = useState(false);
  const [showSecret, setShowSecret] = useState(false);
  // Loading state
  const [searchLoading, setSearchLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  //Check Values
  const [searchError, setSearchError] = useState(false);
  const history = useHistory();

  // Search credential provider API
  const SearchMatch = async (value) => {
    setSearchLoading(true);
    try {
      const userData = await API.searchProfile({
        query: {
          key: value,
        },
      });
      setSearchLoading(false);
      if (userData) {
        setSearchError(false);
        setSearchData(userData.data.data);
      } else if (userData === undefined) {
        setSearchError(true);
      }
    } catch (error) {
      throw new Error();
    } finally {
      setSearchLoading(false);
    }
  };

  // Add product API
  const addProduct = async () => {
    setSubmitting(true);
    try {
      const encryptedSecretKey = encryption.symmetricEncryption(
        searchData.secretKey,
        secretKey ? secretKey : localStorage.secretKey
      );

      const addProfileResponse = await API.addProfile({
        path: { id: currentUser.publicKey },
        body: {
          profilePublicKey: searchData.publicKey,
          alias: searchData.alias,
          encryptedSecretKey,
        },
        token: localStorage.niftoken,
      });
      if (addProfileResponse.data.code === 202) {
        const userData = await API.me({
          token: localStorage.niftoken,
        });
        if (userData?.data?.code === 200) {
          store.dispatch({
            type: ADD_USER_DETAILS,
            payload: userData?.data?.data[0],
          });
        }
        enqueueSnackbar("Profile Added Successfully", {
          variant: "success",
        });
      } else if (addProfileResponse.data.code === 200) {
        enqueueSnackbar("Profile Already Added", {
          variant: "warning",
        });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setSubmitting(false);
      setSearchLoading(false);
      handleRemoveAuthority();
      history.push("/profiles");
    }
  };

  const handleInputChange = (value) => {
    setSearchInput(value);
    SearchMatch(value);
  };

  const handleClickShowSecretKey = () => {
    setShowSecret((value) => !value);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const handleRemoveAuthority = () => {
    if (!loading || !files.loading) {
      if (Object.keys(searchData).length > 0) {
        try {
          dispatch({
            type: "REMOVE_AUTHORITY",
          });
        } catch (error) {
          console.log(error);
        }
      }
      setShowAction(false);
    }
  };

  const handleSetSecretKey = (secretKey) => {
    setSecretKey(secretKey);
  };

  return (
    <Fragment>
      <OverLayContainer onClick={handleRemoveAuthority} />
      {(() => {
        // If current field contains validated data
        if (showActionConfirm === 0) {
          return (
            <RequestVerificationActionConfirm
              warningMessage={"Confirm your credentials to decrypt"}
              setShowAction={setShowActionConfirm}
              authTypeInput={authUser.authType}
              publicKey={authUser.publicKey}
              encryptedSecret={authUser.encryptedSecret}
              onSecretKey={handleSetSecretKey}
            />
          );
        } else if (showActionConfirm === 1) {
          return (
            <Container>
              <FlexContainer>
                <Text primary>Add Product</Text>
              </FlexContainer>
              <Divider
                style={{
                  background: "#36353511",
                  width: "100%",
                  margin: "0.5rem 0rem",
                }}
              />
              <FlexContainer addPadding>
                <ColumnContainer>
                  <Text primary lighter small>
                    Please select a product
                  </Text>
                </ColumnContainer>
                <LoginIcon large />
              </FlexContainer>
              <Divider
                style={{
                  width: "100%",
                  marginBottom: "0.5rem",
                }}
              />
              <Search
                loading={searchLoading}
                error={searchError}
                reducer="AUTHORITY"
                style={{ width: "100%" }}
                placeholder="Search for a product"
                searchValue={searchInput}
                onSearchChange={handleInputChange}
                data={data}
                withSelect
              />
              <FlexContainer
                style={{
                  width: "100%",
                  marginTop: "1rem",
                  // marginBottom: "2rem",
                }}
              >
                {searchData?.alias && (
                  <FlexContainerSearch>
                    <ImageContainer>
                      <ImageWrapper>
                        <Image src={image} alt="logo" />
                      </ImageWrapper>
                      <Text lighter small color="#394673">
                        {searchData?.alias}
                      </Text>
                    </ImageContainer>
                  </FlexContainerSearch>
                )}
              </FlexContainer>

              <FlexContainer
                style={{
                  width: "100%",
                  marginTop: "1rem",
                }}
              >
                {" "}
                {searchData?.alias && (
                  <Formik
                    initialValues={{
                      secretKey: "",
                      autosign: false,
                    }}
                    validationSchema={Yup.object().shape({
                      secretKey: Yup.string()
                        .matches(
                          /^[S,s][a-zA-Z0-9]{55}$/,
                          "SecretKey format is invalid"
                        )
                        .required("Secret Key is required"),
                    })}
                    onSubmit={async (values) => {
                      try {
                        if (searchData.secretKey !== values.secretKey) {
                          enqueueSnackbar("Credential Mismatch", {
                            variant: "warning",
                          });
                        } else {
                          addProduct();
                        }
                      } catch (e) {
                        enqueueSnackbar(e.message, { variant: "error" });
                        console.log(e.message);
                      }
                    }}
                  >
                    {({
                      errors,
                      isValid,
                      dirty,
                      isSubmitting,
                      setFieldValue,
                      handleBlur,
                      handleSubmit,
                      touched,
                      values,
                      handleChange,
                    }) => (
                      <ContainerForm onSubmit={handleSubmit}>
                        <TextField
                          fullWidth
                          size="small"
                          variant="standard"
                          disabled={isSubmitting}
                          name="secretKey"
                          label={`Secret key of the ${searchData?.alias}`}
                          id="secretKey"
                          onChange={handleChange}
                          value={values.secretKey}
                          helperText={touched.secretKey && errors.secretKey}
                          InputLabelProps={{
                            style: {
                              color: "#080036",
                              fontFamily: "Poppins",
                            },
                          }}
                          error={Boolean(touched.secretKey && errors.secretKey)}
                          onBlur={handleBlur}
                          InputProps={{
                            style: { color: "#0e0c2b", padding: ".2rem" },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  style={{ marginBottom: ".2rem" }}
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowSecretKey}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                  disabled={isSubmitting}
                                >
                                  {showSecret ? (
                                    <VisibilityIcon
                                      style={{ fill: "#080036" }}
                                    />
                                  ) : (
                                    <VisibilityOffIcon
                                      style={{ fill: "#080036" }}
                                    />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                            autoComplete: "new-password",
                            type: showSecret ? "text" : "password",
                          }}
                        />
                        {/*SecretKey*/}
                        <Dropzone
                          onDrop={(acceptedFiles) => {
                            const reader = new FileReader();
                            reader.addEventListener("load", async () => {
                              setFieldValue(
                                "secretKey",
                                JSON.parse(atob(reader.result)).secretKey
                              );
                            });
                            reader.readAsText(acceptedFiles[0]);
                          }}
                          multiple={false}
                          accept={[".niftron"]}
                        >
                          {({ getRootProps, getInputProps, isDragActive }) => (
                            <DroppableContainer>
                              <InnerContainer
                                {...getRootProps({
                                  className: fileError
                                    ? "dropzone2Error"
                                    : "dropzone2",
                                })}
                              >
                                <input {...getInputProps()} />
                                {isDragActive ? (
                                  <Text small>Drop the files here ...</Text>
                                ) : (
                                  <Text primary small lighter>
                                    {`Drop or select ${searchData?.alias} Niftron credential file`}
                                  </Text>
                                )}
                              </InnerContainer>
                            </DroppableContainer>
                          )}
                        </Dropzone>

                        <CustomButton
                          style={{ width: "100%" }}
                          login
                          wide
                          disabled={
                            Object.keys(searchData).length === 0 || submitting
                          }
                          onclick={handleSubmit}
                          text={submitting ? "Adding.." : "Add Product"}
                        />
                      </ContainerForm>
                    )}
                  </Formik>
                )}
              </FlexContainer>
            </Container>
          );
        }
      })()}
    </Fragment>
  );
};

export default AddProfile;

const FlexContainerSearch = styled.div`
  text-align: center;
  width: 100%;
  background: #eaeaf3;
  align-items: center;
  border-radius: 5px;
  display: flex;
  cursor: pointer;
  padding: 0.5rem 1rem;
  gap: 1rem;
`;

const OverLayContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 50%;
  z-index: 999;
  right: 50%;
  transform: translate(50%, -50%);
  background: #0c0b5584;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const FlexContainer = styled.div`
  width: 100%;
  padding: ${(props) => (props.addPadding ? "0.5rem 0rem" : "0rem")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem, 0rem;
`;

const Container = styled.div`
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  width: 40%;
  z-index: 999;
  height: auto;
  min-height: 30rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  @media (max-width: 768px) {
    width: 80%;
    padding: 1.5rem;
  }
  @media (max-width: 1368px) {
    width: 80%;
  }
`;
