import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Modal } from "@mui/material";
import Divider from "@mui/material/Divider";
import { CircularProgress } from "@mui/material";

import { Label } from "../FormComponents/TextField";
import VerifiedStatusContainer from "../../Profile/VerifiedStatusContainer";
import fileTypePng from "../../../../assets/images/fileTypePng.png";
import fileTypeJpeg from "../../../../assets/images/fileTypeJpeg.png";
import fileTypePdf from "../../../../assets/images/fileTypePdf.png";
import googleDoc from "../../../../assets/images/googleDocs.png";

const CustomDetailsCard = ({
  data,
  fieldName,
  viewPage,
  isPublic,
  setShowAction,
  setVerification,
  setShowDecryption,
  setShowEncryption,
  setCurrentField,
  profileData,
  id,
  setOpenEditedDetails,
  setEditedDetails,
  setImageData,
}) => {
  const [loading, setLoading] = useState(false);
  const [fileType, setFileType] = useState("");
  const [openImage, setImageOpen] = useState(false);
  const handleImageOpen = () => setImageOpen(true);
  const handleImageClose = () => setImageOpen(false);
  const [currentImage, setImage] = useState();

  const getFileType = async (fileURL) => {
    if (fileURL) {
      setLoading(true);
      let file = fileURL;
      var req = await fetch(file, { method: "HEAD" });
      setLoading(false);
      return setFileType(req.headers.get("content-type"));
    }
  };

  useEffect(() => {
    if (data && data.length > 0) getFileType(data[0]);
  }, []);
  return (
    <Container>
      <Modal
        open={openImage}
        onClose={handleImageClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ImageFlexContainer>
          <div style={{ position: "relative" }}>
            <LargeImage src={currentImage} alt="image" />
          </div>
        </ImageFlexContainer>
      </Modal>
      <InnerContainer>
        <ImageContainer>
          <InnerImageContainer>
            {data &&
              data.length > 0 &&
              data.map((item, key) => {
                return loading ? (
                  <CircularProgress size={20} />
                ) : fileType === "application/pdf" ? (
                  <img
                    style={{
                      width: "80px",
                      height: "80px",
                      color: "red",
                    }}
                    onClick={() => window.open(item)}
                    src={fileTypePdf}
                    // srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={"pdf"}
                    loading="lazy"
                  />
                ) : fileType === "image/png" ? (
                  <img
                    style={{
                      width: "80px",
                      height: "80px",
                      color: "red",
                    }}
                    onClick={() => {
                      setImage(item);
                      handleImageOpen();
                    }}
                    src={fileTypePng}
                    // srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={"png"}
                    loading="lazy"
                  />
                ) : fileType === "image/jpeg" ? (
                  <img
                    style={{
                      width: "80px",
                      height: "80px",
                      color: "red",
                    }}
                    onClick={() => {
                      setImage(item);
                      handleImageOpen();
                    }}
                    src={fileTypeJpeg}
                    // srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={"jpeg"}
                    loading="lazy"
                  />
                ) : (
                  <img
                    style={{
                      width: "80px",
                      height: "80px",
                      color: "red",
                    }}
                    onClick={() => {
                      setImage(item);
                      handleImageOpen();
                    }}
                    src={googleDoc}
                    // srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={"docs"}
                    loading="lazy"
                  />
                );
              })}
          </InnerImageContainer>
          <Label style={{ marginLeft: "30px" }}>
            {fieldName
              .toString()
              .replace(/([A-Z])/g, " $1")
              .substring(0, 1)
              .toUpperCase() +
              fieldName
                .toString()
                .replace(/([A-Z])/g, " $1")
                .substring(1)}
          </Label>
        </ImageContainer>
        <VerifiedStatusContainer
          status={0}
          isPublic={isPublic}
          fieldData={profileData}
          fieldId={{ id: id, name: "customData" }}
          fieldName={fieldName}
          setShowAction={setShowAction}
          setVerification={setVerification}
          setShowDecryption={setShowDecryption}
          setShowEncryption={setShowEncryption}
          isDecrypted={profileData.isPublic}
          setCurrentField={setCurrentField}
          encryptedType={profileData.data[fieldName].encryptionType}
          setOpenEditedDetails={setOpenEditedDetails}
          setEditedDetails={setEditedDetails}
        />
      </InnerContainer>
      <Divider />
      {/* <Divider variant="inset" /> */}
    </Container>
  );
};

export default CustomDetailsCard;

const Container = styled.div`
  display: flex;
  //   margin: 1rem 0rem;
  flex-direction: column;
  // gap: 1rem;
  //   margin: 1.5rem 0rem;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: start;
  }
`;

const InnerImageContainer = styled.div`
  cursor: pointer;
  @media (max-width: 768px) {
    margin: 2rem 0rem;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  margin: 1rem 0rem;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin: 1.5rem 0rem;
  justify-content: space-between !important;
`;

export const ImageFlexContainer = styled.div`
  display: grid;
  width: 40%;
  height: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  background: #ffffff;
  transform: translate(-50%, -50%);
  place-items: center;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  @media (max-width: 1368px) {
    width: 60%;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
`;

export const LargeImage = styled.img`
  text-align: center;
  width: 100%;
  height: auto;
`;
