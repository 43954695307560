import { useEffect, useState, Fragment } from "react";
import styled from "styled-components";
import moment from "moment";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import { SearchComponent } from "../../pages/JobListings/SearchComponent";
import ProviderVerifications from "./ProviderVerifications";
import * as API from "../../api/API";
import {
  TableOuterWrapper,
  TableWrapper,
} from "../../pages/CertificatesView/TokenCredentialsTableView";
import Table from "../../components/shared/Table/Table";
import { ButtonContainer } from "../../components/shared/DashboardComponents/ButtonContainer";
import * as encryption from "../../utils/encryption";
import ActionConfirm from "../../components/shared/Form/FormComponents/ActionConfirm";
import { CustomButton } from "../../components/shared/fields/Button/CustomButton";
import InnerLoadingView from "../../components/shared/loading/InnerLoadingView";
import EncryptionBG from "../../assets/images/encryption.gif";
import { Error } from "../../pages/Claim/ClaimView";

const ProviderVerificationsView = () => {
  const currentUser = useSelector((state) => state.currentUser);
  const [authUser, setAuthUser] = useState(() => API.getUserSession());
  const [showActionConform, setShowActionConfirm] = useState(false);
  const [secretKey, setSecretKey] = useState(localStorage?.secretKey);
  const [showAction, setShowAction] = useState(false);
  const [loading, setLoading] = useState(false);
  const [date, onDateChange] = useState();
  const [search, setSearch] = useState("");
  const [verificationsData, setVerificationsData] = useState(null);

  const fetchVerifications = async () => {
    if (secretKey) {
      setLoading(true);
      try {
        const response = await API.retrieveVerifications({
          path: {
            vId: currentUser.publicKey,
          },
          token: localStorage.niftoken,
          query: {
            type: "received",
          },
        });
        if (response?.data.code === 200) {
          const reversedArr = [...response?.data?.data].reverse();
          decryptData(reversedArr);
          setVerificationsData(reversedArr);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
  };

  // Asymmetric decryption
  const decryptData = async (data) => {
    var decryptedData = {};
    let decryptedObjectData = {};

    data.map((e) => {
      if (e?.data?.nonce) {
        decryptedData = encryption.asymmetricDecryption(e.data, secretKey);
        e.data = decryptedData;
      } else {
        for (let [key, value] of Object.entries(e.data)) {
          if (key !== "customStageData") {
            decryptedObjectData[key] = encryption.asymmetricDecryption(
              value,
              secretKey
            );
          }
          if (key === "customStageData") {
            decryptedObjectData.customStageData = value?.data;
          }
        }
        e.data = decryptedObjectData;
      }
    });

    setVerificationsData(data);
  };

  const handleShowData = (data) => {
    setShowActionConfirm(true);
  };

  useEffect(() => {
    fetchVerifications();
  }, [secretKey]);

  console.log(verificationsData, search);

  return (
    <Container>
      <ButtonContainer
        span
        biglink
        heading="Verifications"
        description="Select and Verify data of requested users"
        icon={"link"}
      />

      {showActionConform && !secretKey && (
        <ActionConfirm
          warningMessage={"Confirm your credentials to view data"}
          setShowAction={setShowActionConfirm}
          authTypeInput={authUser.authType}
          publicKey={authUser.publicKey}
          encryptedSecret={authUser.encryptedSecret}
          //use the prop below to get the secret key
          onSecretKey={setSecretKey}
        />
      )}

      {(() => {
        if (loading) {
          return <InnerLoadingView style={{ marginTop: "0rem" }} />;
        } else if (!secretKey) {
          return (
            <>
              <Error bg={EncryptionBG} width="470px" />
              <EncryptedDetailsContainer>
                <p>Oops! You cannot view the verifications since the data is encrypted. Please click below to add your SecretKey and decrypt the data.</p>
                <CustomButton
                  login
                  onclick={handleShowData}
                  text="View Verifications"
                />
              </EncryptedDetailsContainer>
            </>
          );
        } else {
          return (
            <Fragment>
              <SearchComponent
                searchValue={search}
                onSearchChange={setSearch}
                dateValue={date}
                placeholder="Search"
                onDateChange={(e) => {
                  onDateChange(e);
                  setSearch(moment(e).format("L"));
                }}
              />
              <TableOuterWrapper>
                <TableWrapper>
                  <Table
                    onChange={() => fetchVerifications()}
                    customRow
                    theadData={[
                      "No",
                      "Name",
                      "Status",
                      "Date",
                      "Data Type",
                      "Actions",
                    ]}
                    tbodyData={verificationsData?.filter((item) => {
                      if (search === "" && date === undefined) return item;
                      else if (
                        item?.senderAlias
                          .toLowerCase()
                          ?.includes(search?.toLowerCase())
                      )
                        return item;
                      else if (
                        item?.dataType?.name
                          ?.toLowerCase()
                          .includes(search.toLowerCase()) ||
                        item?.dataType?.id
                          ?.toString()
                          .toLowerCase()
                          .includes(search?.toLowerCase())
                      )
                        return item;
                      else if (
                        moment(item?.createdAt).format("L").includes(search)
                      )
                        return item;
                    })}
                  />
                </TableWrapper>
              </TableOuterWrapper>
            </Fragment>
          );
        }
      })()}
    </Container>
  );
};

export default ProviderVerificationsView;

const Container = styled.div`
  display: flex;
  border-radius: 10px;
  border: 0.75px solid #d3d3d3b0;
  gap: 2rem;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 1rem;
  min-height: 40rem;
  height: auto;
`;

const EncryptedDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > p {
    text-align: center;
  }

  > button {
    margin: 1rem 0;
    width: 14rem;
  }

  @media screen and (max-width: 768px) {
    > button {
      width: 100%;
    }
  }
`