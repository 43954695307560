import React from "react";
import styled from "styled-components";
import { ButtonContainer } from "./components/shared/DashboardComponents/ButtonContainer";
import ClaimView from "./pages/Claim/ClaimView";

function ClaimCertificate(id) {
  return (
    <Container>
      <ButtonContainer
        smallLink
        cursor
        link={`/claim-certificate/${id}`}
        heading="Claim Collectible"
        description="Select claim to claim the token"
        icon={"card_membership"}
      />
      <ClaimView publicView uid />
    </Container>
  );
}

export default ClaimCertificate;

const Container = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
  padding: 1rem 2rem;
`;
