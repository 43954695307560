import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import { ButtonContainer } from "../../components/shared/DashboardComponents/ButtonContainer";
import { SubHeading, Text } from "../../components/shared/Typography/Typo";
import { CustomButton } from "../../components/shared/fields/Button/CustomButton";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import * as API from "../../api/API";
import {
  Box,
  Button,
  Checkbox,
  FormHelperText,
  Tooltip,
  TextField,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  InputAdornment,
} from "@mui/material";
import CryptingText from "../../components/shared/ViewCredentials/CryptingText";
import InnerLoadingView from "../../components/shared/loading/InnerLoadingView";
// import CryptingText from "../../utils/CryptingText";

const ViewCredentials = () => {
  const currentUser = useSelector((state) => state.currentUser);
  const [authUser, setAuthUser] = useState(() => API.getUserSession());
  const [authCreator, setAuthCreator] = useState(() => API.getCreatorSession());
  const { enqueueSnackbar } = useSnackbar();
  const [encryptedSecret, setEncryptedSecret] = useState("");

  const fetchProviderDetails = async () => {
    try {
      const providerResponse = await API.retrieveCredentialProvider({
        path: {
          id: currentUser.creatorPublicKey,
        },
      });

      if (providerResponse?.status === 200) {
        providerResponse.data.data[0].profiles.map((e) => {
          if (e.publicKey === currentUser.publicKey) {
            setEncryptedSecret(e.encryptedSecretKey);
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    localStorage.getItem("providerToken")
      ? fetchProviderDetails()
      : setEncryptedSecret(authUser.encryptedSecret);
  });

  const copyMessage = (val) => {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
  };
  return (
    <Container>
      <ButtonContainer
        heading="View Credentials"
        description={"You will find your credentials here."}
        icon={"lock"}
      />
      <InnerContainer>
        {" "}
        {!encryptedSecret ? (
          <InnerLoadingView />
        ) : (
          <>
            <Box mb={3}>
              <Typography color="textSecondary" gutterBottom variant="body2">
                The following is the Public Key that you will be used in the
                DePro
              </Typography>
              <TextField
                fullWidth
                label="Public Key"
                margin="normal"
                name="publicKey"
                value={currentUser.publicKey}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Copy" placement="top">
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            copyMessage(currentUser.publicKey);
                            enqueueSnackbar(
                              "Public Key is copied to clipboard",
                              {
                                variant: "info",
                              }
                            );
                          }}
                        >
                          {" "}
                          <FileCopyIcon
                            sx={{
                              color: "#404b6e",
                            }}
                          />{" "}
                        </Button>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box mb={3}>
              <Typography color="textSecondary" gutterBottom variant="body2">
                The following is the DePro Secret Key.
              </Typography>
              {encryptedSecret && (
                <CryptingText
                  encryptedText={encryptedSecret}
                  label={"DePro Secret Key"}
                  name={"deproSecretKey"}
                />
              )}
            </Box>
            <Box mb={3}>
              <Typography color="textSecondary" gutterBottom variant="body2">
                The following is the EVM Public Key.
              </Typography>
              <TextField
                fullWidth
                label="EVM Public Key"
                margin="normal"
                name="secretKey"
                value={authUser.accounts[2].publicKey}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Copy" placement="top">
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            copyMessage(authUser.publicKey);
                            enqueueSnackbar(
                              "EVM Public Key is copied to clipboard",
                              { variant: "info" }
                            );
                          }}
                        >
                          <FileCopyIcon
                            sx={{
                              color: "#404b6e",
                            }}
                          />
                        </Button>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box mb={3}>
              <Typography color="textSecondary" gutterBottom variant="body2">
                The following is the EVM Secret Key.
              </Typography>
              {authUser.accounts.length > 2 && (
                <CryptingText
                  encryptedText={authUser.accounts[2].encryptedSecret}
                  label={"EVM Secret Key"}
                  name={"evmSecretKey"}
                />
              )}
            </Box>
          </>
        )}
      </InnerContainer>
    </Container>
  );
};

export default ViewCredentials;

export const InnerContainer = styled.div`
  display: flex;
  border-radius: 10px;
  border: 0.75px solid #d3d3d3b0;
  gap: 1rem;
  flex-direction: column;
  padding: 1.5rem;
  margin-bottom: 1rem;
  min-height: 55vh;
  background-color: #eff2f5;
`;

const Container = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
`;

export const Field = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 0.5px solid #9b9b9b46;
  margin: 0rem 1rem;
  justify-content: space-between;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentWithPadding = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 0rem;
`;
