import React, { useState } from "react";
import styled from "styled-components";
import { Formik } from "formik";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Select from "react-select";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
//MUI
import { InputAdornment, LinearProgress, TextField } from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { Text } from "../../components/shared/Typography/Typo";
import { CustomButton } from "../../components/shared/fields/Button/CustomButton";
import { checkAlias } from "../../services/UserManagement";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CircularProgress from "@mui/material/CircularProgress";
import { NIFTRON, UserType } from "niftron-client-sdk";
import * as API from "../../api/API";
import * as encryption from "../../utils/encryption";
import store from "../../redux/reducers";
import { ADD_USER_DETAILS } from "../../redux/constants/ActionTypes";

//Progress button styles
const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 4,
    borderRadius: 4,
    width: "100%",
  },
  colorPrimary: {
    backgroundColor: "transparent",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#80B7FF",
    animationDuration: "550ms",
  },
}))(LinearProgress);

const formStatusProps = {
  success: {
    message: "Signed up successfully.",
    type: "success",
  },
  error: {
    message: "Something went wrong. Please try again.",
    type: "error",
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFilledInput-root": {
      background: "rgb(255, 255, 255)",
    },
    "& .Mui-error": {
      color: "rgb(244, 67, 54)",
    },
    "& .MuiFormHelperText-root": {
      color: "rgb(244, 67, 54)",
    },
  },
}));

const options = [
  { value: 2, label: "Entity Profile" },
  { value: 3, label: "Product Profile" },
  { value: 4, label: "Documents" },
  { value: 5, label: "Individual Profile" },
];

export const RegisterForm = ({
  setSignUpStep,
  setUserData,
  userData,
  isWorkFlow,
  currentWorkflow,
  ...props
}) => {
  const classes = useStyles();
  const history = useHistory();
  const currentUser = useSelector((state) => state.currentUser);
  const { enqueueSnackbar } = useSnackbar();
  //Form Status
  //eslint-disable-next-line
  const [displayFormStatus, setDisplayFormStatus] = useState(false);
  //eslint-disable-next-line
  const [formStatus, setFormStatus] = useState({
    message: "",
    type: "",
  });
  const [type, setType] = useState(options[0]);

  // setAlias
  const [alias, setAlias] = useState("");
  // Loading state
  const [aliasLoading, setAliasLoading] = useState(false);
  //Check Values
  const [checkAliasError, setCheckAliasError] = useState(false);
  const [checkAliasSuccess, setCheckAliasSuccess] = useState(false);
  const [secretKey, setSecretKey] = useState();
  const [loginStatus, setLoginStatus] = useState(0);

  // reroute after signup
  function handleSignUp() {
    setSignUpStep(1);
    // createProductNextStep();
  }

  //Alias match check
  const AliasMatch = async (value) => {
    // eslint-disable-next-line
    if (value === alias) {
      setAliasLoading(true);
      const check = await checkAlias(value);
      if (check != null && !check.isAvailable) {
        setCheckAliasError(true);
        setCheckAliasSuccess(false);
      } else {
        setCheckAliasSuccess(true);
        setCheckAliasError(false);
      }
      setAliasLoading(false);
    }
  };

  //Api debouncer
  const aliasAPIDebounced = AwesomeDebouncePromise(AliasMatch, 500);

  // Custom form validation
  const customValidation = (values) => {
    let errors = {};

    var aliasValidation = /^[ A-Za-z0-9_@./#&+-]*$/;
    //alias validation
    if (!alias) {
      errors.alias = "Required";
    } else if (checkAliasError) {
      errors.alias = "Profile name already exists";
    } else if (!aliasValidation.test(alias)) {
      errors.alias =
        "Please input a valid profile name, should be between 8 to 30 characters without spaces";
    }

    return errors;
  };

  const handleAccess = async () => {
    setLoginStatus(2);
    const token = localStorage.getItem("niftoken");
    await NIFTRON.user.loginWithSecret(secretKey);
    window.localStorage.setItem("secretKey", secretKey);
    window.localStorage.setItem("providerToken", token);
    window.location.assign("/");
  };

  // Form status
  const createNewUser = async (data) => {
    try {
      // API call integration will be here. Handle success / error response accordingly.
      const options = {
        type: UserType.USER,
        alias: `${data.alias}`,
        password: props.secretKey,
      };

      const token = localStorage.getItem("niftoken");
      // eslint-disable-next-line
      const { status, publicKey, secretKey } =
        await NIFTRON.user.createMediumPrivacyUser(options);
      if (publicKey && secretKey) {
        setUserData({
          ...userData,
          alias: alias,
          publicKey: publicKey,
          secretKey: secretKey,
        });

        setSecretKey(secretKey);
      }

      window.localStorage.setItem("niftoken", token);
      window.localStorage.setItem("providerToken", token);
      window.localStorage.setItem("providerSecretKey", props.secretKey);

      try {
        const body = {
          alias: data.alias,
          publicKey: publicKey,
          secretKey: secretKey,
          status: 1,
          type: "PROFILE",
          email: data.email,
          profileType: isWorkFlow ? 0 : type.value,
          creatorPublicKey: currentUser.publicKey,
        };

        if (isWorkFlow) {
          body.workflowRefId = currentWorkflow.id;
        }
        const response = await API.register({
          body,
        });
        if (response?.data?.code === 201) {
          const encryptedSecretKey = encryption.symmetricEncryption(
            secretKey,
            props.secretKey
          );

          const addProfileResponse = await API.addProfile({
            path: { id: currentUser.publicKey },
            body: {
              profilePublicKey: publicKey,
              alias: data.alias,
              encryptedSecretKey,
            },
            token: localStorage.niftoken,
          });
          if (addProfileResponse.data.code === 202) {
            const userData = await API.me({
              token: localStorage.niftoken,
            });
            if (userData?.data?.code === 200) {
              store.dispatch({
                type: ADD_USER_DETAILS,
                payload: userData?.data?.data[0],
              });
            }
            enqueueSnackbar("Profile Created Successfully", {
              variant: "success",
            });
          } else if (addProfileResponse.data.code === 200) {
            enqueueSnackbar("Profile Already Created", {
              variant: "warning",
            });
          }
        }
      } catch (error) {
        console.log(error);
        throw new Error();
      }
      if (data) {
        setLoginStatus(1);
        setFormStatus(formStatusProps.success);
      }
    } catch (error) {
      const response = error.response;
      if (response.data === "user already exist" && response.status === 400) {
        setFormStatus(formStatusProps.duplicate);
      } else {
        setFormStatus(formStatusProps.error);
      }
    } finally {
      if (isWorkFlow) {
        history.push("/profiles");
      } else {
        setDisplayFormStatus(true);
      }
    }
  };

  return (
    <Formik
      initialValues={{
        alias: "",
        email: "",
        password: "",
        confirm_password: "",
        type: "",
      }}
      validate={customValidation}
      // ON submit do a API call
      onSubmit={async (values, actions) => {
        await createNewUser(values);
        handleSignUp();
      }}
    >
      {/* Formik Props */}
      {({
        values,
        touched,
        errors,
        isValid,
        dirty,
        isSubmitting,
        setFieldValue,
        handleBlur,
        handleSubmit,
      }) => (
        <ContainerForm>
          <FlexContainer>
            {/* Alias */}
            <TextField
              fullWidth
              size="small"
              variant="standard"
              name="alias"
              id="alias"
              className={classes.root}
              label="Profile Name (Batch Code) *"
              disabled={isSubmitting || loginStatus === 1 || loginStatus === 2}
              helperText={errors.alias && touched.alias ? errors.alias : null}
              error={errors.alias && touched.alias ? true : false}
              onBlur={handleBlur}
              InputLabelProps={{
                style: {
                  color: "#000000",
                  fontFamily: "Poppins",
                },
              }}
              InputProps={{
                style: { color: "#000000", padding: ".2rem" },
                type: "text",
                value: values.alias,
                endAdornment: (
                  <InputAdornment position="end">
                    {aliasLoading && <CircularProgress size={20} />}
                    {!aliasLoading && checkAliasSuccess && (
                      <CheckCircleOutlineIcon
                        size={20}
                        style={{ fill: "rgb(76, 175, 80)" }}
                      />
                    )}
                    {!aliasLoading && checkAliasError && (
                      <HighlightOffIcon
                        size={20}
                        style={{ fill: "rgb(244, 67, 54)" }}
                      />
                    )}
                  </InputAdornment>
                ),
                onChange: async function (e) {
                  setAlias(e.target.value);
                  setFieldValue("alias", e.target.value);
                  await aliasAPIDebounced(e.target.value);
                },
                onKeyUp: async function (e) {
                  await aliasAPIDebounced(e.target.value);
                },
              }}
            />
            {!isWorkFlow && (
              <FlexContainer>
                <Label style={{ fontWeight: "400" }}>
                  Select profile type *
                </Label>
                <Select
                  isDisabled={
                    isSubmitting || loginStatus === 1 || loginStatus === 2
                  }
                  required
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      outline: "none",
                      text: "orangered",
                      primary25: "#2f3a6039",
                      primary: " #2f3a60a7",
                      neutral0: "#c3d0e1",
                      boxShadow: "none",
                      zIndex: "5",
                    },
                  })}
                  name="country"
                  defaultValue={options[0]}
                  options={options}
                  value={type}
                  onChange={setType}
                />
              </FlexContainer>
            )}
          </FlexContainer>

          {(() => {
            if (isSubmitting) {
              return (
                <LoadingContainer>
                  <BorderLinearProgress />
                  <Text small lighter style={{ width: "100px" }}>
                    Creating <span className="loading"></span>
                  </Text>
                </LoadingContainer>
              );
            } else if (loginStatus === 0 && !isSubmitting) {
              return (
                <CustomButton
                  style={{ marginTop: "2rem" }}
                  disabled={!isValid || (isSubmitting && dirty)}
                  onclick={handleSubmit}
                  login
                  text=" Create"
                  type="submit"
                />
              );
            } else if (loginStatus === 1 && !isWorkFlow) {
              return (
                <CustomButton
                  login
                  text="Access"
                  type="button"
                  onclick={handleAccess}
                />
              );
            } else if (loginStatus === 2) {
              return (
                <LoadingContainer>
                  <BorderLinearProgress />
                  <Text small lighter style={{ width: "100px" }}>
                    Accessing <span className="loading"></span>
                  </Text>
                </LoadingContainer>
              );
            }
          })()}
        </ContainerForm>
      )}
    </Formik>
  );
};

export default RegisterForm;

const ContainerForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1.7rem;
  align-items: center;
  justify-content: space-evenly;
  @media (max-width: 768px) {
    margin-bottom: 5rem;
  }
`;

export const LoadingContainer = styled.div`
  height: 68px;
  width: 100%;
  margin-top: 0.5rem;
  gap: 0.3rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const Label = styled.label`
  color: #000000;
  font-weight: 500;
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;
