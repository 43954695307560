import React, { useState, lazy, Suspense } from "react";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import {
  ButtonWrapper,
  ContainerForm,
  OuterContainer,
  privacyOptions,
} from "../../Form/BlockForm/BlockForm";
import { Label, TextField } from "../../Form/FormComponents/TextField";
import { Column } from "../../Form/Web3ResumeContainer";
import Select from "react-select";
import { AddImageToIPFS } from "../../../../services/IpfsService";
import { useStateValue } from "../../../../context/StateProvider";
import { useSnackbar } from "notistack";
import { Button, CircularProgress, useMediaQuery } from "@mui/material";
import { SubHeading } from "../../Typography/Typo";
import { LoadingContainer } from "./MultiStageEditComponent";
import { imageFields } from "../../../../utils/customDataImageSplit";
import TextEditor from "../../TextEditor/TextEditor";
import { Preview } from "../../../../pages/JobListings/JobComponent";
import DOMPurify from "dompurify";
const CustomDataEditForm = lazy(() =>
  import("../CustomEditForm/CustomDataEditForm")
);

const SectionEditDocument = ({
  initialStageName,
  initialDescription,
  initialCustomData,
  isPublic,
  onDelete,
  initialData,
  id,
  disabled,
  stageData,
}) => {
  const [{ products }, dispatch] = useStateValue();
  var currentDateTime = Date.now();
  const [loading, setLoading] = useState(false);
  const [privacyStage, setPrivacyStage] = useState(
    isPublic ? privacyOptions[0] : privacyOptions[1]
  );
  const [description, setDescription] = useState(
    initialDescription ? initialDescription : ""
  );
  const [stageName, setStageName] = useState(
    initialStageName ? initialStageName : ""
  );
  const [customData, setCustomData] = useState(
    initialCustomData ? initialCustomData : []
  );

  const matches = useMediaQuery("(max-width:1368px)");
  const { enqueueSnackbar } = useSnackbar();

  const [editorState, onEditorStateChange] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(initialDescription))
    )
  );

  var html = description;
  var div = document.createElement("p");
  div.innerHTML = html;

  const handleStageFieldSubmit = async () => {
    setLoading(true);
    try {
      customData.map((e) => {
        e.isPublic = privacyStage.value;
      });

      const item = {
        id: id,
        stageName: stageName,
        customStageData: customData,
        stageDescription: description,
        isPublic: privacyStage.value,
        addedDate: currentDateTime,
      };

      // Delete isPublic object in both
      delete stageData.addedDate;
      delete item.addedDate;

      // Copy of the item and eduData
      const itemClone = JSON.parse(JSON.stringify(item));
      const stageDataClone = JSON.parse(JSON.stringify(stageData));
      // Delete isPublic object in both
      delete stageDataClone.isPublic;
      delete itemClone.isPublic;

      if (
        stageData.isPublic !== privacyStage.value &&
        JSON.stringify(itemClone) === JSON.stringify(stageDataClone)
      ) {
        initialData.isPublic = privacyStage.value;
        initialData.data.isPublic.data = privacyStage.value.toString();
      } else if (
        (stageData.isPublic !== item.isPublic &&
          JSON.stringify(itemClone) !== JSON.stringify(stageDataClone)) ||
        JSON.stringify(itemClone) !== JSON.stringify(stageDataClone)
      ) {
        initialData.data.id.data = id;
        initialData.data.isPublic.data = privacyStage.value.toString();
        initialData.data.stageDescription.data = description;
        initialData.data.customStageData.data = customData;
        initialData.data.stageName.data = stageName;
        initialData.verifiedBy = [];
        initialData.isPublic = privacyStage.value;
      }

      dispatch({
        type: "ADD_PRODUCTS",
        item: initialData,
      });

      enqueueSnackbar(`Successfully edited ${initialStageName}`, {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("Please input an image", {
        variant: "warning",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (object) => {
    const newCustomData = customData.filter(
      (item) => JSON.stringify(item) !== JSON.stringify(object)
    );
    setCustomData(newCustomData);
  };

  return (
    <OuterContainer style={{ width: "100%" }}>
      <ContainerForm>
        <TextField
          disabled={disabled}
          span={!matches ? true : false}
          label="Document Section Name *"
          placeholder="Document Section Name "
          id="stageName-stage"
          onChange={setStageName}
          value={stageName}
          name="stageName"
          type="text"
          maxLength="200"
        />
        <TextEditor
          disabled={disabled}
          placeholder="Document Stage Description *"
          fieldName="Document Stage Description"
          editorState={editorState}
          setDescription={setDescription}
          setEditorState={onEditorStateChange}
        />
        <Column span={!matches ? true : false}>
          <Label>Private / Public</Label>
          <Select
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                outline: "none",
                text: "orangered",
                primary25: "#2f3a6039",
                primary: " #2f3a60a7",
                neutral0: "#c3d0e1",
                boxShadow: "none",
              },
            })}
            name="isPublic"
            className="basic-multi-select"
            classNamePrefix="select"
            defaultValue={isPublic ? privacyOptions[0] : privacyOptions[1]}
            options={privacyOptions}
            value={privacyStage}
            onChange={setPrivacyStage}
          />
        </Column>
        {customData.length > 0 && (
          <>
            <SubHeading
              primary
              style={{ gridColumn: `${!matches ? "1/3" : "unset"}` }}
            >
              Edit Meta Data
            </SubHeading>
            <Suspense
              fallback={
                <LoadingContainer>
                  <CircularProgress size={40} />
                </LoadingContainer>
              }
            >
              <Column
                style={{
                  gridColumn: `${!matches ? "1/3" : "unset"}`,
                  gap: "1rem",
                }}
              >
                {customData &&
                  customData.length > 0 &&
                  Array.isArray(customData) &&
                  customData?.map((data, key) => {
                    const fieldName = Object.keys(data);
                    return (
                      <CustomDataEditForm
                        key={key}
                        id={key}
                        data={data}
                        disabled={disabled}
                        onDelete={handleDelete}
                        isPublic={Boolean(data.isPublic)}
                        initialData={data[fieldName[0]]}
                        initialFieldName={fieldName[0]}
                        initialFieldValue={data[fieldName[0]]}
                        initialCustomImages={imageFields(data[fieldName[0]])}
                        customData={customData}
                        setCustomData={setCustomData}
                        type={!data[fieldName[0]].toString()?.includes("ipfs")}
                      />
                    );
                  })}
              </Column>
            </Suspense>
          </>
        )}
        <ButtonWrapper
          style={{
            girdColumn: `${!matches ? "1/3" : "unset"}`,
            gap: "1rem",
          }}
        >
          <Button
            style={{
              background: "#ff623f7a",
              color: "#080036",
              textTransform: "inherit",
              fontWeight: "500",
              fontFamily: "Poppins",
              padding: ".3rem 1rem",
              float: "right",
            }}
            onClick={() => onDelete(id)}
            size="small"
          >
            Delete Stage
          </Button>
          <Button
            style={{
              background: "#1fc36157",
              color: "#080036",
              textTransform: "inherit",
              fontWeight: "500",
              fontFamily: "Poppins",
              padding: ".3rem 1rem",
              float: "right",
            }}
            endIcon={loading && <CircularProgress size={20} />}
            onClick={handleStageFieldSubmit}
            size="small"
          >
            Save Stage Entry
          </Button>
        </ButtonWrapper>
      </ContainerForm>
    </OuterContainer>
  );
};

export default SectionEditDocument;
