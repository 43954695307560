import { CustomButton } from "../fields/Button/CustomButton";
import WorkIcon from "@mui/icons-material/Work";
import PersonIcon from "@mui/icons-material/Person";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GradingIcon from "@mui/icons-material/Grading";
import VerifiedIcon from "@mui/icons-material/Verified";
import QrCodeIcon from "@mui/icons-material/QrCode";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import PolylineIcon from "@mui/icons-material/Polyline";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import FeedIcon from "@mui/icons-material/Feed";

export const StudentNav = (history) => {
  return (
    <div>
      <CustomButton
        text="Home"
        tab
        onclick={() => {
          history.push("/dashboard");
        }}
        icon={<DashboardIcon />}
      />
      <CustomButton
        text="My Profile"
        tab
        onclick={() => {
          history.push("/dashboard/my-profile");
        }}
        icon={<PersonIcon />}
      />

      {/* <CustomButton
        text="Job Listings"
        tab
        onclick={() => {
          history.push("/dashboard/job-listings");
        }}
        icon={<WorkIcon />}
      /> */}
      <CustomButton
        text="Collectibles"
        tab
        onclick={() => {
          history.push("/dashboard/credentials");
        }}
        icon={<GradingIcon />}
      />
      <CustomButton
        text="Products"
        tab
        onclick={() => {
          history.push("/products");
        }}
        icon={<QrCodeIcon />}
      />
      <CustomButton
        text="Scans"
        tab
        onclick={() => {
          history.push("/tokens");
        }}
        icon={<QrCode2Icon />}
      />
    </div>
  );
};

export const DocumentNav = (history) => {
  return (
    <div>
      <CustomButton
        text="Home"
        tab
        onclick={() => {
          history.push("/dashboard");
        }}
        icon={<DashboardIcon />}
      />
      <CustomButton
        text="Document Profile"
        tab
        onclick={() => {
          history.push("/dashboard/my-profile");
        }}
        icon={<PersonIcon />}
      />

      {/* <CustomButton
        text="Job Listings"
        tab
        onclick={() => {
          history.push("/dashboard/job-listings");
        }}
        icon={<WorkIcon />}
      /> */}
      {/* <CustomButton
        text="Collectibles"
        tab
        onclick={() => {
          history.push("/dashboard/credentials");
        }}
        icon={<GradingIcon />}
      />
      <CustomButton
        text="Products"
        tab
        onclick={() => {
          history.push("/products");
        }}
        icon={<QrCodeIcon />}
      />
      <CustomButton
        text="Scans"
        tab
        onclick={() => {
          history.push("/tokens");
        }}
        icon={<QrCode2Icon />}
      /> */}
    </div>
  );
};

export const ProductNav = (history) => {
  return (
    <div>
      <CustomButton
        text="Home"
        tab
        onclick={() => {
          history.push("/dashboard");
        }}
        icon={<DashboardIcon />}
      />
      <CustomButton
        text="My Profile"
        tab
        onclick={() => {
          history.push("/dashboard/my-profile");
        }}
        icon={<PersonIcon />}
      />

      {/* <CustomButton
        text="Collectibles"
        tab
        onclick={() => {
          history.push("/dashboard/credentials");
        }}
        icon={<GradingIcon />}
      />

      <CustomButton
        text="Scans"
        tab
        onclick={() => {
          history.push("/dashboard/scans");
        }}
        icon={<QrCode2Icon />}
      /> */}
    </div>
  );
};

export const CredentialProvider = (history) => {
  return (
    <div>
      <CustomButton
        text="Dashboard"
        tab
        onclick={() => {
          history.push("/dashboard");
        }}
        icon={<DashboardIcon />}
      />
      <CustomButton
        text="Creator"
        tab
        onclick={() => {
          history.push("/dashboard/profile");
        }}
        icon={<PersonIcon />}
      />
      <CustomButton
        text="Verifications"
        tab
        onclick={() => {
          history.push("/dashboard/verifications");
        }}
        icon={<VerifiedIcon />}
      />
      <CustomButton
        text="Collectibles"
        tab
        onclick={() => {
          history.push("/dashboard/issue-credentials");
        }}
        icon={<GradingIcon />}
      />{" "}
      {/* <CustomButton
        text="Job Postings"
        tab
        onclick={() => {
          history.push("/dashboard/job-postings");
        }}
        icon={<WorkIcon />}
      /> */}
      <CustomButton
        text="Profiles"
        tab
        onclick={() => {
          history.push("/profiles");
        }}
        icon={<PolylineIcon />}
      />
      <CustomButton
        text="Templates"
        tab
        onclick={() => {
          history.push("/dashboard/templates");
        }}
        icon={<ViewQuiltIcon />}
      />
      <CustomButton
        text="Workflows"
        tab
        onclick={() => {
          history.push("/dashboard/workflows");
        }}
        icon={<FeedIcon />}
      />
      <CustomButton
        text="Customers"
        tab
        onclick={() => {
          history.push("/dashboard/customers");
        }}
        icon={<VerifiedIcon />}
      />
    </div>
  );
};

export const DefaultNav = (history) => {
  return (
    <div>
      <CustomButton
        text="Home"
        tab
        onclick={() => {
          history.push("/dashboard");
        }}
        icon={<DashboardIcon />}
      />
      <CustomButton
        text="My Profile"
        tab
        onclick={() => {
          history.push("/dashboard/my-profile");
        }}
        icon={<PersonIcon />}
      />
      <CustomButton
        text="Collectibles"
        tab
        onclick={() => {
          history.push("/dashboard/credentials");
        }}
        icon={<GradingIcon />}
      />
      <CustomButton
        text="Products"
        tab
        onclick={() => {
          history.push("/products");
        }}
        icon={<QrCodeIcon />}
      />
    </div>
  );
};
