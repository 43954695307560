import React from "react";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import moment from "moment";
import QRCode from "qrcode";

export const TokenPDFFile = async (data) => {
  const doc = new jsPDF();

  //

  const rows = [];
  const qrBase64String = [];
  data.forEach(async (key, index) => {
    const base64String = await QRCode.toDataURL(
      `/dashboard/view-tokens/${key._id}`
    );
    qrBase64String.push(base64String);

    const data = [
      key.tokenName,
      key.tokenType,
      moment(Number(key.createdAt)).format("MM/DD/YYYY"),
    ];
    rows.push(data);
  });

  const base64String = await QRCode.toDataURL("haiii");

  autoTable(doc, {
    // startY: doc.autoTable() + 110,

    // margin: { horizontal: 10 },

    head: [["Token Name", "Token Type", "Created At", "QR View"]],
    body: rows,
    bodyStyles: { minCellHeight: 40, valign: "top" },

    showHead: "everyPage",
    // rowPageBreak: "auto",
    styles: { overflow: "hidden" },
    // startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 70,
    rowPageBreak: "avoid",

    didDrawPage: function (data) {
      // Header
      // doc.setFontSize(20);
      // doc.setTextColor(40);
      // doc.text("Report", data.settings.margin.left, 22);

      // Footer
      var str = "Page " + doc.internal.getNumberOfPages();

      doc.setFontSize(10);

      // jsPDF 1.4+ uses getWidth, <1.4 uses .width
      var pageSize = doc.internal.pageSize;
      var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
      doc.text(str, data.settings.margin.left, pageHeight - 10);
    },

    didDrawCell: (data) => {
      // console.log("data : ", data);
      if (data.section === "body" && data.column.index === 3) {
        doc.addImage(
          qrBase64String[data.row.index],
          "JPEG",
          data.cell.x + 2,
          data.cell.y + 2,
          25,
          25
        );
      }
    },
  });

  doc.save(`TokenDetails${Date.now()}.pdf`);
};
