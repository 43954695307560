import { Divider, IconButton } from "@mui/material";
import React, { Fragment, useState } from "react";
import { SubHeading } from "../Typography/Typo";
import ExperienceCard from "./ExperienceCard";
import {
  FlexSpaceBetweenContainer,
  SectionInnerContainer,
} from "./UserProfile";
import { IconWrapper, SectionContainer } from "./UserProfileHeader";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";

function EducationSection({
  isPublic,
  profileData,
  setShowAction,
  setShowDecryption,
  setShowEncryption,
  setVerification,
  setCurrentField,
  isDecrypted,
  decryptType,
  setEditType,
  setShowEduEdit,
}) {
  return (
    <SectionContainer>
      <SectionInnerContainer>
        <FlexSpaceBetweenContainer>
          <SubHeading primary>Education</SubHeading>{" "}
          <IconWrapper style={{ backgroundColor: "#c7c7c72f" }}>
            <IconButton
              onClick={() => {
                setEditType("");
                if (!isDecrypted) {
                  decryptType("all");
                  setShowDecryption(true);
                  setEditType("eduEdit");
                } else {
                  setShowEduEdit((value) => !value);
                }
              }}
            >
              {profileData?.educationData?.length === 0 ? (
                <AddIcon style={{ color: "#8a8a8a" }} sx={{ fontSize: 20 }} />
              ) : (
                <EditIcon style={{ color: "#8a8a8a" }} sx={{ fontSize: 20 }} />
              )}
            </IconButton>
          </IconWrapper>{" "}
        </FlexSpaceBetweenContainer>

        {profileData?.educationData &&
          profileData?.educationData?.map((item, key) => {
            return (
              <Fragment key={key}>
                <ExperienceCard
                  fieldId={{ id: key, name: "educationData" }}
                  fieldData={item}
                  title={item?.data?.courseName?.data}
                  logoImage={item?.data?.imageLogo?.data}
                  isPublic={isPublic}
                  setShowAction={setShowAction}
                  setVerification={setVerification}
                  startDate={Number(item?.data?.startDate?.data)}
                  institution={item?.data?.institutionName?.data}
                  endDate={Number(item?.data?.endDate?.data)}
                  description={item?.data?.description?.data}
                  setShowDecryption={setShowDecryption}
                  setShowEncryption={setShowEncryption}
                  setCurrentField={setCurrentField}
                  encryptedType={item?.data?.courseName?.encryptionType}
                />
                {profileData?.educationData?.length > 1 &&
                  profileData?.educationData?.length - 1 !== key && (
                    <Divider
                      style={{
                        background: "#5e5e5e11",
                        width: "100%",
                      }}
                    />
                  )}
              </Fragment>
            );
          })}
      </SectionInnerContainer>
    </SectionContainer>
  );
}

export default EducationSection;
