import React, { Fragment } from "react";
import { Editor } from "react-draft-wysiwyg";
import { Label } from "../Form/FormComponents/TextField";
import styled from "styled-components";
import { convertToHTML } from "draft-convert";

function TextEditor({
  fieldName,
  placeholder,
  editorState,
  setEditorState,
  setDescription,
  icon,
  disabled,
}) {
  const handleChange = (editorState) => {
    const contentState = convertToHTML(editorState.getCurrentContent());
    setDescription(contentState);
  };

  return (
    <EditorContainer span>
      <InputContainer>
        <Label>{fieldName}</Label>
        {icon}
      </InputContainer>
      <TextInputContainer>
        <Editor
          readOnly={disabled}
          onEditorStateChange={(editorState) => {
            setEditorState(editorState);
            handleChange(editorState);
          }}
          placeholder={placeholder}
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
        />
      </TextInputContainer>
    </EditorContainer>
  );
}

export default TextEditor;

const TextInputContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const EditorContainer = styled.div`
  gap: 0.3rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  grid-column: ${(props) => (props.span ? "1/3" : "unset")};
  @media (max-width: 768px) {
    grid-column: unset;
  }
  z-index: 0;
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${(props) => (props.custom ? "0.2rem" : "0")}; ;
`;
