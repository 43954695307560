import React, { useState } from "react";
import { Text } from "../Typography/Typo";
import { Accordion, AccordionSummary } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import VerificationCard from "./VerificationCard";
import { SmallText } from "../Profile/ShareConfirm";
import styled from "styled-components";
import VerificationEntityCard from "./VerificationEntityCard";
import CorrectGif from "../../../assets/images/correct-tick.gif";
import PendingGif from "../../../assets/images/pending-status.gif";
import RejectedGif from "../../../assets/images/rejected.gif";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";

const VerificationStepper = ({ item, verification }) => {
  let key = Object.keys(verification?.fieldData?.data);
  const [expanded, setExpanded] = useState("panel3");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : "panel3");
  };

  return (
    <FlexColumnContainer>
      <FlexContainer>
        <Text primary>Verification Status</Text>
        {item.verificationStatus === 0 ? (
          <FlexIconContainer>
            <CircleIcon
              style={{
                color: "#df642b",
              }}
            />
            <Text primary>Pending</Text>
          </FlexIconContainer>
        ) : item.verificationStatus === 1 || typeof item === "string" ? (
          <FlexIconContainer>
            <CheckCircleIcon
              style={{
                color: "#1ccc54",
              }}
            />
            <Text primary>Approved</Text>
          </FlexIconContainer>
        ) : (
          <FlexIconContainer>
            <CircleIcon
              style={{
                color: "#cf2020",
              }}
            />
            <Text primary>Rejected</Text>
          </FlexIconContainer>
        )}
      </FlexContainer>

      <Accordion
        style={{ boxShadow: "none", marginTop: "1rem" }}
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <FlexColumnContainer>
            <Text lighter primary>
              {item?.type === "PASSIVE"
                ? "Requested Details"
                : "Verified Details"}
            </Text>
            <SmallText lighter>
              View Your Requested Verification Details
            </SmallText>
          </FlexColumnContainer>
        </AccordionSummary>
        <AccordionDetails>
          <VerificationCard
            type={verification.type}
            title={
              verification?.fieldData?.data.courseName?.data ||
              verification?.fieldData?.data.position?.data ||
              verification?.fieldData?.data.itemName?.data ||
              verification?.fieldData?.data.data ||
              key[0]
            }
            logoImage={
              verification?.fieldData?.data?.imageLogo?.data ||
              verification?.fieldData?.data?.itemImage?.data ||
              verification?.fieldData?.data?.productImage?.data ||
              verification?.fieldData?.data?.documentImage?.data
            }
            isPublic={verification?.fieldData.isPublic}
            startDate={
              Number(verification?.fieldData?.data.startDate?.data) ||
              Number(verification?.fieldData?.data.issueDate?.data)
            }
            institution={
              verification?.fieldData?.data?.institutionName?.data
                ? verification?.fieldData?.data?.institutionName?.data
                : verification?.fieldData?.data?.company?.data
                ? verification?.fieldData?.data?.company?.data
                : verification?.fieldData?.data?.issuer?.data
            }
            endDate={Number(verification?.fieldData?.data?.endDate?.data)}
            description={
              verification?.fieldData?.data?.description?.data ||
              verification?.fieldData?.data[key[0]]?.data
            }
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{ boxShadow: "none" }}
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <FlexColumnContainer>
            <Text lighter primary>
              {item?.type === "PASSIVE"
                ? "Requested Entity"
                : "Verified Entity"}
            </Text>
            <SmallText lighter>View your requested entity details</SmallText>
          </FlexColumnContainer>
        </AccordionSummary>
        <AccordionDetails>
          <VerificationEntityCard
            status={typeof item === "string" ? 1 : item?.verificationStatus}
            receiverPublicKey={
              typeof item === "string"
                ? item
                : item?.type === "PASSIVE"
                ? item?.receiverPublicKey
                : item?.senderPublicKey
            }
            receiverImage={
              item?.type === "PASSIVE" ? item?.receiverImage : item?.senderImage
            }
            receiverAlias={
              typeof item === "string"
                ? "N/A"
                : item?.type === "PASSIVE"
                ? item?.receiverAlias
                : item?.senderAlias
            }
            createdAt={
              item?.type === "PASSIVE" ? item?.createdAt : item?.verifiedAt
            }
            type={item?.type}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
        style={{ boxShadow: "none" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <FlexColumnContainer>
            <Text lighter primary>
              Status
            </Text>
            <SmallText lighter>View your request Status</SmallText>
          </FlexColumnContainer>
        </AccordionSummary>
        <AccordionDetails>
          <FlexColumnCenterContainer>
            {(() => {
              if (item.verificationStatus === 0) {
                return (
                  <>
                    <Gif src={PendingGif} />
                    <Text lighter primary>
                      Pending
                    </Text>
                  </>
                );
              } else if (
                item.verificationStatus === 1 ||
                typeof item === "string"
              ) {
                return (
                  <>
                    <Gif src={CorrectGif} />
                    <Text lighter primary>
                      Approved
                    </Text>
                  </>
                );
              } else {
                return (
                  <>
                    <Gif src={RejectedGif} />
                    <Text lighter primary>
                      Rejected
                    </Text>
                  </>
                );
              }
            })()}
          </FlexColumnCenterContainer>
        </AccordionDetails>
      </Accordion>
    </FlexColumnContainer>
  );
};

export default VerificationStepper;

const FlexContainer = styled.div`
  width: 100%;
  padding: ${(props) => (props.addPadding ? "0.5rem 0rem" : "0rem")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem, 0rem;
`;

const FlexIconContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;
const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  &:first-child {
    margin-top: unset;
  }
`;

const FlexColumnCenterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-direction: column;
`;

export const Gif = styled.img`
  width: 8rem;
  height: auto;
`;
