import { Divider, CircularProgress } from "@mui/material";
import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import moment from "moment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { IconWrapper } from "../../App";
import { Text } from "../../components/shared/Typography/Typo";
import { FlexContainer } from "../../components/shared/Profile/ShareConfirm";
import * as API from "../../api/API";

const ScanModal = ({ setShowScanModal, scanData }) => {
  const currentUser = useSelector((state) => state.currentUser);
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(false);

  const fetchUserDetails = async () => {
    setLoading(true);
    try {
      const response = await API.getObject({
        aId: scanData.profilePublicKey,
      });
      console.log("res : ", response);
      if (response !== undefined) {
        setUserData(response?.data?.data[0]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, [scanData]);

  console.log("scanData : ", scanData);
  return (
    <Fragment>
      <OverLayContainer onClick={() => setShowScanModal(false)} />
      <ContactContainer>
        <FlexContainer>
          <Text primary>Token ID :</Text>
          <Text primary small>
            {scanData.uniqueId}
          </Text>
        </FlexContainer>
        <Divider
          style={{
            background: "#36353511",
            width: "100%",
            margin: "0.5rem 0rem",
          }}
        />

        <ContactInnerColumnFlex>
          <ContactFlexContainer addPadding>
            <Text primary>Token Validity : </Text>{" "}
            <CheckCircleIcon
              style={{
                color: "#1ccc54",
              }}
            />
          </ContactFlexContainer>
          <ContactFlexContainer addPadding>
            <Text primary>Owner Alias : </Text>

            {loading ? (
              <CircularProgress size={20} />
            ) : (
              <Text lighter primary>
                {userData?.alias}
              </Text>
            )}
          </ContactFlexContainer>{" "}
          <ContactFlexContainer addPadding>
            <Text primary>Scanned At : </Text>{" "}
            <Text lighter primary>
              {moment(scanData?.scannedAt).format("LLL")}
            </Text>
          </ContactFlexContainer>{" "}
        </ContactInnerColumnFlex>
      </ContactContainer>
    </Fragment>
  );
};

export default ScanModal;

export const IconOuter = styled.div`
  background: linear-gradient(180deg, #2f3a60 0%, #1d2439 100%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  height: 2.25rem;
  padding: 0.39rem 0.1rem 0.1rem 0.1rem;
  width: 2.5rem;
  min-width: 2.5rem;
`;

export const ContactContainer = styled.div`
  position: absolute;
  top: 50%;
  outline: none;
  right: 50%;
  transform: translate(50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  width: 25rem;
  z-index: 999;
  height: auto;
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const OverLayContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 50%;
  z-index: 999;
  right: 50%;
  transform: translate(50%, -50%);
  background: #0c0b5584;
`;

const ContactInnerColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  gap: 0.5rem;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderNameWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.2rem;
`;

const AddressWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const ContactFlexContainer = styled.div`
  width: 100%;
  padding: ${(props) => (props.addPadding ? "0.5rem 0rem" : "0rem")};
  display: flex;
  gap: 1rem;
  align-items: center;
  margin: 0.7rem, 0rem;
`;

const Link = styled.a`
  max-width: 20rem;
  cursor: pointer;
  word-break: break-all;
  text-decoration: underline;
  color: #1696ff;
  font-family: "Poppins", sans-serif;
  font-size: ${(props) => (props.small ? "0.875rem" : "16px")};
  font-weight: ${(props) => (props.lighter ? "400" : "500")};
  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;
