import { createEncryptionObject } from "./encryption";

export function createCVObject(
  productData,
  toPublicKey,
  fromPublicKey,
  fromSecretKey,
  encryptionType
) {
  let encryptedObject = {};

  let productObjects = {};

  for (const [key, value] of Object.entries(productData)) {
    let dataType;

    let privacyStatus = encryptionType.find((x) => x[key]);
    productObjects[key] = value;

    if (Array.isArray(value)) {
      let tempArray = [];
      value.forEach((arrayObject) => {
        if (typeof arrayObject === "object") {
          let obj = {};
          obj.verifiedBy = [];
          obj.editedBy = [];
          obj.isPublic = arrayObject.isPublic;
          obj.data = {};
          for (const [key, value] of Object.entries(arrayObject)) {
            if (key === "customStageData") {
              obj.data[key] = createEncryptionObject(
                "PUBLIC",
                value,
                toPublicKey,
                fromPublicKey,
                fromSecretKey
              );
            } else {
              // Set data type
              if (
                key === "startDate" ||
                key === "endDate" ||
                key === "issueDate"
              ) {
                dataType = "date";
              } else if (key === "imageLogo" || key === "itemImage") {
                dataType = "image";
              } else if (key === "imageLogo" || key === "itemImage") {
                dataType = "image";
              } else if (key === "Quantity") {
                dataType = "number";
              } else if (
                key === "company" ||
                key === "description" ||
                key === "id" ||
                key === "position" ||
                key === "courseName" ||
                key === "institutionName" ||
                key === "issuer" ||
                key === "itemName"
              ) {
                dataType = "string";
              } else {
                dataType = "string";
              }

              if (key !== "dataType") {
                obj.data[key] = createEncryptionObject(
                  // arrayObject.isPublic === true ? "PUBLIC" : "SYMMETRIC", // Did for json profile stage data
                  arrayObject.isPublic
                    ? arrayObject.isPublic === true
                      ? "PUBLIC"
                      : "SYMMETRIC"
                    : "PUBLIC",
                  typeof value === "number" || "string"
                    ? value.toString()
                    : value,
                  toPublicKey,
                  fromPublicKey,
                  fromSecretKey,
                  (dataType =
                    key === "isPublic"
                      ? "boolean"
                      : arrayObject["dataType"]
                      ? arrayObject["dataType"]
                      : dataType)
                );
              }
            }
          }
          tempArray.push(obj);
        } else {
          let obj = {};
          obj.verifiedBy = [];
          obj.editedBy = [];
          obj.isPublic = privacyStatus === undefined ? true : false;
          obj.data = arrayObject;
          obj.data = createEncryptionObject(
            privacyStatus === undefined ? "PUBLIC" : "SYMMETRIC",
            arrayObject,
            toPublicKey,
            fromPublicKey,
            fromSecretKey
          );
          tempArray.push(obj);
        }
      });
      encryptedObject[key] = tempArray;
    } else {
      // Set data type
      if (key === "age" || key === "phoneNumber" || key === "profileType") {
        dataType = "number";
      } else if (key === "addedDate") {
        dataType = "date";
      } else if (key === "profileImage") {
        dataType = "image";
      } else {
        dataType = "string";
      }

      encryptedObject[key] = {};
      encryptedObject[key].verifiedBy = [];
      encryptedObject[key].editedBy = [];
      encryptedObject[key].isPublic =
        privacyStatus === undefined ? true : false;
      encryptedObject[key].data = createEncryptionObject(
        privacyStatus === undefined ? "PUBLIC" : "SYMMETRIC",
        typeof value === "number" ? value.toString() : value,
        toPublicKey,
        fromPublicKey,
        fromSecretKey,
        dataType
      );
    }
  }
  console.log("PRODUCT OBJECT", productObjects);
  console.log("*****************************************");
  console.log("ENCRYPTED OBJECT", encryptedObject);
  return encryptedObject;
}

//change this to new one later
export function oldCreateCVObject(
  productData,
  toPublicKey,
  fromPublicKey,
  fromSecretKey,
  encryptionType = "PUBLIC"
) {
  let encryptedObject = {};
  let productObjects = {};
  for (const [key, value] of Object.entries(productData)) {
    productObjects[key] = value;
    if (Array.isArray(value)) {
      let tempArray = [];
      value.forEach((arrayObject) => {
        if (typeof arrayObject === "object") {
          let obj = {};
          obj.verifiedBy = [];
          obj.isPublic = true;
          obj.data = arrayObject;
          for (const [key, value] of Object.entries(arrayObject)) {
            obj.data[key] = createEncryptionObject(
              encryptionType,
              value,
              toPublicKey,
              fromPublicKey,
              fromSecretKey
            );
          }
          tempArray.push(obj);
        } else {
          let obj = {};
          obj.verifiedBy = [];
          obj.isPublic = true;
          obj.data = arrayObject;
          obj.data = createEncryptionObject(
            encryptionType,
            arrayObject,
            toPublicKey,
            fromPublicKey,
            fromSecretKey
          );
          tempArray.push(obj);
        }
      });
      encryptedObject[key] = tempArray;
    } else {
      encryptedObject[key] = {};
      encryptedObject[key].verifiedBy = [];
      encryptedObject[key].isPublic = true;
      encryptedObject[key].data = createEncryptionObject(
        encryptionType,
        value,
        toPublicKey,
        fromPublicKey,
        fromSecretKey
      );
    }
  }
  console.log("PRODUCT OBJECT", productObjects);
  console.log("*****************************************");
  console.log("ENCRYPTED OBJECT", encryptedObject);
  return encryptedObject;
}
