import Divider from "@mui/material/Divider";
import React from "react";
import styled from "styled-components";
import { Text } from "../Typography/Typo";
import { CustomButton } from "../fields/Button/CustomButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LinkedIn from "../../../assets/images/linkedin-icon.svg";
import { useSnackbar } from "notistack";
import QrCodeGen from "./QrCodeGen";
import { urlToHash } from "../../../utils/ipfs";

export const ShareQr = ({
  setShowAction,
  data,
  link,
  message,
  title,
  currentUser,
}) => {
  // eslint-disable-next-line
  const { enqueueSnackbar } = useSnackbar();

  const copyToClipBoard = async (val) => {
    try {
      await navigator.clipboard.writeText(val);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <OverLayContainer onClick={() => setShowAction(false)} />
      <Container>
        <ImageContainer>
          <Image
            src={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(
              data.itemImage ? data.itemImage : data?.profileImage?.data?.data
            )}`}
            alt="image"
          />
        </ImageContainer>
        <FlexContainer addPadding>
          <ColumnContainer>
            <Text primary>
              {data.itemName
                ? data.itemName
                : `${data?.firstName?.data?.data} ${data?.lastName?.data?.data} `}
            </Text>
            <Text small lighter primary>
              {data.description
                ? data.description
                : data?.bio?.data?.data.length > 150
                ? data?.bio?.data?.data.toString().substring(0, 100 - 3) + "..."
                : data?.bio?.data?.data}
            </Text>
          </ColumnContainer>
        </FlexContainer>

        <QrCodeGen
          name={
            data.itemName
              ? data.itemName
              : `${data?.firstName?.data?.data} ${data?.lastName?.data?.data} `
          }
          link={link}
        />
        <CustomButton
          wide
          login
          onclick={() => {
            copyToClipBoard(link);
            enqueueSnackbar("URL Copied", {
              variant: "info",
            });
          }}
          icon={<ContentCopyIcon />}
          text={message}
        />
      </Container>
    </>
  );
};

const Container = styled.div`
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  width: 25rem;
  z-index: 999;
  height: 35rem;
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const OverLayContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 50%;
  z-index: 999;
  right: 50%;
  transform: translate(50%, -50%);
  background: #0c0b5584;
`;

export const FlexContainer = styled.div`
  width: 100%;
  padding: ${(props) => (props.addPadding ? "0.5rem 0rem" : "0rem")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem, 0rem;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SmallText = styled.p`
  font-size: 0.7rem;
  color: ${(props) => (props.primary ? "#080036" : props.color)};
  font-weight: ${(props) => (props.lighter ? "400" : "bold")};
`;

export const ColumnLoadContainer = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: center;
  height: 21rem;
  align-items: center;
`;

export const Gif = styled.img`
  margin-top: 5rem;
  width: 16rem;
  height: auto;
`;

const ImageContainer = styled.div`
  overflow: hidden;
  width: 100%;
  height: 45%;
  overflow: hidden;
  border-radius: 5px;
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  position: absolute;
  height: auto;
  transform: translate(-50%, -50%);
  top: 100%;
  left: 50%;
`;

const IconContainer = styled.a`
  position: relative;
  width: 2rem;
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 0.5rem;
  height: 2rem;
  background: ${(props) => props.bg};
  border-radius: 50%;
`;

const Icon = styled.img`
  width: 100%;
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
  height: auto;
`;

const FlexContainerAlignCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;
  width: 100%;
  padding: 1rem 0rem 1.5rem 0rem;
`;
