import React, { useState } from "react";
import styled from "styled-components";
import { Text } from "../../components/shared/Typography/Typo";
import { Modal } from "@mui/material";
import {
  ColumnContainer,
  Image,
  ImageWrapper,
} from "../JobListings/JobComponent";
import moment from "moment";
import { ImageFlexContainer, LargeImage } from "./Field";
import { urlToHash } from "../../utils/ipfs";

function CredentialCard({ data }) {
  const [openImage, setImageOpen] = useState(false);
  const handleImageOpen = () => setImageOpen(true);


  const handleImageClose = () => {
    setImageOpen(false);
  };
  
  return (
    <>
      <Modal
        open={openImage}
        onClose={handleImageClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ImageFlexContainer>
          <div style={{ position: "relative" }}>
            <LargeImage src={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(data?.itemImage?.data)}`} alt="image" />
          </div>
        </ImageFlexContainer>
      </Modal>
      <Container>
        <ImageWrapper>
          <Image
            alt="Token"
            src={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(data?.itemImage?.data)}`}
            cursor
            onClick={() => {
              handleImageOpen();
            }}
          />
        </ImageWrapper>
        <ColumnContainer>
          <Text lighter primary>
            {data?.itemName?.data}
          </Text>
          <Text lighter color="#666666">
            {data?.issuer?.data}
          </Text>
          <Text lighter color="#666666" small>
            {moment(data?.issueDate?.data).format("MM/DD/YYYY")}
          </Text>{" "}
          <Text small primary lighter>
            {data?.description?.data}
          </Text>
        </ColumnContainer>
      </Container>
    </>
  );
}

export default CredentialCard;

const Container = styled.div`
  border-radius: 5px;
  gap: 1rem;
  padding: 0.5rem 0.5rem;
  display: flex;
  @media (max-width: 1368px) {
    flex-direction: column;
  }
`;
