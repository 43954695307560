import React, { Fragment, useState, useEffect, useStateValue } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import CreateIcon from "@mui/icons-material/Create";
import Tooltip from "@mui/material/Tooltip";

import { SubHeading, Text } from "../../components/shared/Typography/Typo";
import { getObject } from "../../api/API";
import { urlToHash } from "../../utils/ipfs";
import { GetIPFSData } from "./../../services/IpfsService";
import InnerLoadingView from "../../components/shared/loading/InnerLoadingView";
import { Error, ErrorContainer } from "../Claim/ClaimView";
import ErrorBG from "../../assets/images/not-found.gif";
import ViewPagination from "../../components/shared/Pagination/Pagination";
// import AssignedItemCard from "./AssignedItemCard";
import { SearchComponent } from "./../JobListings/SearchComponent";
import { FlexSpaceBetweenContainer } from "./../../components/shared/Profile/UserProfile";
import { IconWrapper } from "./../../university-pages/Certificates/IssueCertificateForm";
import { IconButton } from "@mui/material";
import { RequestVerification } from "../../components/shared/Profile/RequestVerification";
import ProfileListCard from "./ProfileListCard";
import AddProfile from "./../AddProfile/AddProfile";
import CreateProfile from "./CreateProfile";

const ViewProductList = () => {
  const { id } = useParams();
  const location = useLocation();

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [date, onDateChange] = useState();
  const currentUser = useSelector((state) => state.currentUser);
  const [latestIpfsData, setLatestIpfsData] = useState([]);
  const [mainPageLoading, setMainPageLoading] = useState(false);
  const [showAction, setShowAction] = useState(false);
  const [showCreateProduct, setShowCreateProduct] = useState(false);
  // const [{ searchData }, dispatch] = useStateValue();

  const itemsPerPage = 6;

  const paginate = (event, value) => {
    setCurrentPage(value);
    window.scroll(0, 0);
  };

  // TODO : this is want to happen through server side
  const fetchProducts = async (data) => {
    setMainPageLoading(true);
    let arr = [];

    for (let i = 0; i < data?.length; i++) {
      try {
        const response = await getObject({
          aId: data[i].publicKey,
        });
        if (response !== undefined) {
          const hash = urlToHash(
            response?.data?.data[0]?.latestIpfsUrl?.ipfsUrl
          );
          const ipfsData = await GetIPFSData(hash);
          ipfsData["publicKey"] = data[i]?.publicKey;
          arr.push(ipfsData);
        }
      } catch (e) {
        console.log(e);
      }
    }
    setLatestIpfsData(arr);
    setMainPageLoading(false);
  };

  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentProductList =
    latestIpfsData &&
    latestIpfsData.length > 0 &&
    latestIpfsData.slice(indexOfFirstPost, indexOfLastPost);

  useEffect(() => {
    currentUser?.profiles && fetchProducts(currentUser?.profiles);
  }, [currentUser?.profiles]);

  return (
    <Container>
      {showAction && (
        <AddProfile show={showAction} setShowAction={setShowAction} />
      )}
      {showCreateProduct && (
        <CreateProfile
          show={showCreateProduct}
          setShowAction={setShowCreateProduct}
        />
      )}
      <SearchComponent
        searchValue={search}
        onSearchChange={setSearch}
        dateValue={date}
        placeholder="Search products"
        onDateChange={(e) => {
          onDateChange(e);
          setSearch(moment(e).format("L"));
        }}
      />

      <IconContainer>
        <IconWrapper
          style={{ backgroundColor: "#c7c7c72f", marginRight: "0.5rem" }}
        >
          <Tooltip title="Create Profile">
            <IconButton
              onClick={() => {
                setShowCreateProduct(true);
              }}
            >
              <CreateIcon style={{ color: "#8a8a8a" }} sx={{ fontSize: 20 }} />
            </IconButton>
          </Tooltip>
        </IconWrapper>

        <IconWrapper style={{ backgroundColor: "#c7c7c72f" }}>
          <IconButton
            onClick={() => {
              setShowAction(true);
            }}
          >
            <AddIcon style={{ color: "#8a8a8a" }} sx={{ fontSize: 20 }} />
          </IconButton>
        </IconWrapper>
      </IconContainer>

      {(() => {
        if (mainPageLoading) {
          return <InnerLoadingView style={{ marginTop: "1rem" }} />;
        } else if (latestIpfsData <= 0) {
          return (
            <ErrorContainer>
              <Error bg={ErrorBG} width="470px" />
              <Text style={{ marginBottom: "1rem" }} primary>
                No Purchased Products Found!
              </Text>
            </ErrorContainer>
          );
        } else {
          return (
            <Fragment>
              <GridContainer>
                {currentProductList &&
                  currentProductList
                    .filter((item) => {
                      if (search === "" && date === undefined) {
                        return item;
                      } else if (
                        item.userAlias &&
                        item.userAlias
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      ) {
                        return item;
                      } else if (
                        item.createdAt &&
                        moment(item.createdAt).format("L").includes(search)
                      ) {
                        return item;
                      }
                      return item;
                    })
                    ?.map((item, key) => {
                      return <ProfileListCard data={item} key={key} token />;
                    })}
              </GridContainer>
              {search === "" && (
                <ViewPagination
                  itemsPerPage={itemsPerPage}
                  totalItems={currentProductList && currentProductList.length}
                  paginate={paginate}
                />
              )}
            </Fragment>
          );
        }
      })()}
    </Container>
  );
};

export default ViewProductList;

const Container = styled.div`
  display: flex;
  border-radius: 10px;
  border: 0.75px solid #d3d3d3b0;
  gap: 1rem;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 1rem;
  min-height: 55vh;
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const GridContainer = styled.div`
  width: 100%;
  gap: 1rem;
  margin: 1rem 0rem;
  display: flex;
  flex-direction: column;
`;
