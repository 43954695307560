import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useStateValue } from "../../../../context/StateProvider";
import { ToggleField } from "../../Form/FormComponents/TextField";
import { Text } from "../../Typography/Typo";
import styled from "styled-components";

const PrivacyToggle = ({ _id, fieldName, select, top, right, custom }) => {
  const [{ privacyData, customPrivacyData }, dispatch] = useStateValue();
  const [checked, setChecked] = useState(false);

  // Set the status of privacy
  const handlePrivacyDispatch = (fieldName, _id) => {
    if (privacyData.length > 0) {
      const checkId = (obj) => obj._id === _id;
      if (privacyData?.some(checkId) === true) {
        dispatch({
          type: "EDIT_PRIVACY",
          item: { [fieldName]: checked, _id: _id },
        });
      } else {
        dispatch({
          type: "ADD_PRIVACY",
          item: { [fieldName]: checked, _id: _id },
        });
      }
    } else {
      dispatch({
        type: "ADD_PRIVACY",
        item: { [fieldName]: checked, _id: _id },
      });
    }
    if (custom) {
      if (customPrivacyData.length > 0) {
        const checkId = (obj) => obj._id === _id;

        if (customPrivacyData?.some(checkId) === true) {
          dispatch({
            type: "EDIT_PRIVACY_CUSTOM",
            item: { [fieldName]: checked, _id: _id },
          });
        } else {
          dispatch({
            type: "ADD_PRIVACY_CUSTOM",
            item: { [fieldName]: checked, _id: _id },
          });
        }
      } else {
        dispatch({
          type: "ADD_PRIVACY_CUSTOM",
          item: { [fieldName]: checked, _id: _id },
        });
      }
    }
  };

  useEffect(() => {
    handlePrivacyDispatch(fieldName, _id);
  }, [checked]);

  if (select) {
    return (
      <AbsoluteContainer top={top} right={right}>
        <Stack direction="row" spacing={1} alignItems="center">
          <ToggleField
            onChange={(e) => {
              setChecked(e.target.checked);
            }}
          />
          <Text lighter small style={{ minWidth: "3rem" }}>
            {checked ? "Private" : "Public"}
          </Text>
        </Stack>
      </AbsoluteContainer>
    );
  } else {
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <ToggleField
          onChange={(e) => {
            setChecked(e.target.checked);
          }}
        />
        <Text lighter small style={{ minWidth: "3rem" }}>
          {checked ? "Private" : "Public"}
        </Text>
      </Stack>
    );
  }
};

export default PrivacyToggle;

const AbsoluteContainer = styled.div`
  position: absolute;
  top: ${(props) => props.top};
  z-index: 2;
  right: ${(props) => props.right};
`;
