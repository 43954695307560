import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Web3 from "web3";
import {
  InputContainer,
  Label,
  TextField,
} from "../Form/FormComponents/TextField";
import {
  Button,
  IconButton,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import CreatableSelect from "react-select/creatable";
import { useHistory } from "react-router-dom";
import { useStateValue } from "../../../context/StateProvider";
import { getUserSession } from "../../../api/API";
import { useSnackbar } from "notistack";
import { SubHeading } from "../Typography/Typo";
import { FlexSpaceBetweenContainer } from "../DocumentProfile/DocumentProfile";
import MultipleImageViewSlider from "../Form/StageForm/MultipleImageViewSlider";
import CustomView from "../CustomTextfieldModal/CustomView";
import CustomTextFieldModal from "../CustomTextfieldModal/CustomTextFieldModal";
import { CustomButton } from "../fields/Button/CustomButton";
import { createCVObject } from "../../../utils/cvObject";
import { jwtDecode } from "../../../utils/jwt";
import { AddImageToIPFS, GetIPFSData } from "../../../services/IpfsService";
import * as API from "../../../api/API";
import { urlToHash } from "../../../utils/ipfs";
import store from "../../../redux/reducers";
import { ADD_USER_DETAILS } from "../../../redux/constants/ActionTypes";
import ActionConfirm from "../Form/FormComponents/ActionConfirm";
import { symmetricDecryption } from "../../../utils/encryption";
import DropzoneComponent from "../fields/Dropzone/DropzoneComponent";
import { getSignature } from "../../../utils/getSingature";
import InnerLoadingView from "../loading/InnerLoadingView";
// import PrivacyToggle from "../fields/Button/PrivacyToggle";
// import { countryOptions } from "./IndividualFormContainer";
export var phoneNoValidation =
  /^(?:0|94|\+94|0094)?(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|912)(0|2|3|4|5|7|9)|7(0|1|2|4|5|6|7|8)\d)\d{6}$/;

export const CreateWorkFlow = ({ setView, profileType }) => {
  const currentUser = useSelector((state) => state.currentUser);
  const history = useHistory();
  //eslint-disable-next-line
  const [authUser, setAuthUser] = useState(() => getUserSession());
  const [openCustomModal, setCustomModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mainLoading, setMainLoading] = useState(false);
  const [showAction, setShowAction] = useState(false);
  const [showMainAction, setShowMainAction] = useState(false);
  const [stageName, setStageName] = useState("");
  const [sectionIndex, setSectionIndex] = useState(null);
  const [formDataState, setFormDataState] = useState([]);
  const [stageIndex, setStageIndex] = useState(null);
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState("");
  let [multiSelect, setMultiSelect] = useState([]);
  const [isFinalComplete, setIsFinalComplete] = useState(false);
  const [dataJson, setDataJson] = useState();
  // const [mainSectionIndex, setMainSectionIndex] = useState(0);
  // const [subSectionIndex, setSubSectionIndex] = useState(0);
  var [latestDraftData, setLatestDraftData] = useState();
  const [latestIpfsData, setLatestIpfsData] = useState();
  // const [mainLoading, setMainLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const matches = useMediaQuery("(max-width:1368px)");

  const fetchWorkflow = async () => {
    setMainLoading(true);
    try {
      const response = await API.retrieveWorkflow({
        path: {
          wId: currentUser.workflowRefId,
        },
      });

      if (response?.data?.code === 200) {
        setDataJson(JSON.parse(response?.data?.data[0].file));
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setMainLoading(false);
    }
  };

  useEffect(() => {
    fetchWorkflow();
  }, []);

  const fetchLatestDraftData = async () => {
    if (currentUser?.drafts) {
      const hash = urlToHash(
        currentUser?.drafts[currentUser.drafts.length - 1].ipfsUrl
      );
      try {
        const data = await GetIPFSData(hash);
        setLatestDraftData(data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const fetchLatestIpfsData = async () => {
    if (currentUser?.latestIpfsUrl) {
      const hash = urlToHash(currentUser.latestIpfsUrl.ipfsUrl);
      try {
        const data = await GetIPFSData(hash);
        setLatestIpfsData(data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchLatestDraftData();
    fetchLatestIpfsData();
  }, []);

  const onSave = async (e) => {
    e.preventDefault();
    setFormDataState(e.currentTarget);
    setShowAction(true);
  };

  const handleOnSave = async (secretKey) => {
    setLoading(true);
    const user = jwtDecode();

    var dataObject = {};
    var formattedObject = {};
    var privacyData = [];
    let previewImageUrl = "";

    const drafts = currentUser?.drafts ? currentUser?.drafts : [];
    const formData = new FormData(formDataState);

    if (files.length > 0) {
      const ipfsData = await AddImageToIPFS(files && files[0]);
      previewImageUrl = `https://niftron.infura-ipfs.io/ipfs/${ipfsData.ipfsHash}`;

      dataObject["Media Files"] = previewImageUrl;
    }

    if (sectionIndex === 0) {
      for (let [key, value] of formData.entries()) {
        if (value) {
          dataObject[key] = value;
        }
      }

      formattedObject = createCVObject(
        dataObject,
        user.publicKey,
        user.publicKey,
        secretKey,
        privacyData
      );

      latestDraftData = {
        basicData: formattedObject,
      };
    } else {
      for (let [key, value] of formData.entries()) {
        if (value) {
          let keyOnly = Object.keys(dataObject);
          let checkKey = keyOnly.includes(key);
          if (checkKey) {
            if (typeof dataObject[key] === "string") {
              dataObject[key] = [dataObject[key], value];
            } else {
              dataObject[key].push(value);
            }
          } else {
            dataObject[key] = value;
          }
        }
      }

      dataObject["stageName"] = stageName;
      const stageData = [];
      const finalStageObject = {};
      stageData.push(dataObject);

      finalStageObject.stageData = stageData;

      formattedObject = createCVObject(
        finalStageObject,
        user.publicKey,
        user.publicKey,
        secretKey,
        privacyData
      );
    }
    if (sectionIndex !== 0) {
      const stageArray = latestDraftData?.stageData
        ? latestDraftData?.stageData
        : [];

      stageArray[stageIndex] = formattedObject.stageData[0];
      latestDraftData.stageData = stageArray;
    }

    const productIpfsHash = await AddImageToIPFS(
      JSON.stringify(latestDraftData)
    );
    const ipfsUrl = `https://niftron.infura-ipfs.io/ipfs/${productIpfsHash.ipfsHash}`;

    const draftObject = {
      ipfsUrl: ipfsUrl,
      timestamp: Date.now(),
    };

    drafts.push(draftObject);

    try {
      const response = await API.updateProfile({
        path: { id: user.publicKey },
        token: localStorage.niftoken,
        body: { drafts },
      });
      if (response?.data?.code === 202) {
        const userData = await API.me({
          token: localStorage.niftoken,
        });
        if (userData?.data?.code === 200) {
          store.dispatch({
            type: ADD_USER_DETAILS,
            payload: userData?.data?.data[0],
          });
          enqueueSnackbar("Data Saved", {
            variant: "success",
          });
          window.location.reload();
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setShowAction(false);
    }
  };

  const handleComplete = async () => {
    setMainLoading(true);
    try {
      const { blockchain, signatureObj } = await getSignature(
        currentUser,
        authUser,
        localStorage.secretKey
      );

      const hash = urlToHash(
        currentUser?.drafts[currentUser.drafts.length - 1].ipfsUrl
      );

      const user = jwtDecode();

      const response = await API.addCv({
        path: { id: user.publicKey },
        token: localStorage.niftoken,
        body: {
          latestIpfsUrl: {
            ipfsUrl: currentUser?.drafts[currentUser.drafts.length - 1].ipfsUrl,
            timestamp: Date.now(),
          },
          profileType: 0,
          signature: signatureObj,
          network: blockchain,
          ipfsHash: hash,
          isProfileCreated: isFinalComplete ? true : false,
        },
      });
      if (response?.data?.code === 201) {
        const userData = await API.me({
          token: localStorage.niftoken,
        });
        if (userData?.data?.code === 200) {
          store.dispatch({
            type: ADD_USER_DETAILS,
            payload: userData?.data?.data[0],
          });
          enqueueSnackbar("Profile Created", {
            variant: "success",
          });
          !isFinalComplete && window.location.reload();
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setMainLoading(false);
      setShowMainAction(false);
      isFinalComplete && setView(2);
    }
  };

  const getCount = (mainSectionIndex, subSectionIndex) => {
    const count = dataJson.form.sections[mainSectionIndex]?.sections[
      subSectionIndex
    ]?.fields.filter((arr) => {
      if (arr.required === true) {
        return true;
      }

      return false;
    }).length;

    return count;
  };

  const basicDataValidity = (itemIndex, sectionIndex) => {
    if (itemIndex === 0 && latestDraftData) {
      // return (
      //   dataJson.form.sections[itemIndex]?.sections[sectionIndex]?.fields
      //     ?.length === Object.keys(latestDraftData?.basicData)?.length
      // );

      // Temp
      if (itemIndex === 0 && latestIpfsData) {
        return true;
      } else {
        return (
          dataJson.form.sections[itemIndex]?.sections[sectionIndex]?.fields
            ?.length === Object.keys(latestDraftData?.basicData)?.length
        );
      }
    } else if (itemIndex !== 0 && !latestIpfsData) {
      return true;
    }
  };

  const checkStage = (itemIndex, key) => {
    let value = false;

    if (itemIndex !== 0 && latestDraftData?.stageData) {
      dataJson.form.sections[1].sections[key].fields.map((dataJsonElement) => {
        latestDraftData?.stageData &&
          latestDraftData?.stageData.map((latestElement) => {
            if (
              dataJson.form.sections[1].sections[key].sectionTitle ===
              latestElement?.data?.stageName?.data
            ) {
              if (latestElement?.data[dataJsonElement.label]) {
                value = true;
              } else {
                value = false;
              }
            } else if (!latestIpfsData?.stageData) {
              value = true;
            }
          });
      });
    } else if (itemIndex !== 0 && key !== 0) {
      // return true;
      value = true;
    } else if (itemIndex !== 0 && key === 0 && latestIpfsData) {
      // return false;
      value = false;
    }

    return value;
  };

  // Temp
  const checkStageWithIpfs = (itemIndex, key) => {
    let value;

    if (itemIndex !== 0 && latestIpfsData?.stageData) {
      const checkCurrentSection = latestIpfsData?.stageData.find(
        (e) =>
          e &&
          e?.data?.stageName?.data ===
            dataJson.form.sections[itemIndex].sections[key].sectionTitle
      );

      if (checkCurrentSection) {
        value = true;
      }
    }

    return value;
  };

  const enableBasicComplete = (itemIndex, key) => {
    let value;

    if (itemIndex === 0 && latestDraftData) {
      dataJson.form.sections[itemIndex].sections[key].fields.map((e) => {
        if (e.required === true && latestDraftData.basicData[e.label]) {
          value = false;
        } else if (e.required === true && !latestDraftData.basicData[e.label]) {
          value = true;
        }
      });
    } else if (itemIndex === 0 && !latestDraftData) {
      value = true;
    }

    return value;
  };

  const enableStageComplete = (itemIndex, key) => {
    let value;

    if (itemIndex === 1 && latestDraftData?.stageData) {
      for (let fieldValue of dataJson.form.sections[itemIndex].sections[key]
        .fields) {
        if (fieldValue.required === true) {
          const found = latestDraftData.stageData.some(
            (el) => el && el.data[fieldValue.label]
          );

          if (found) {
            // console.log("value : ", fieldValue.label);
            value = false;
          } else {
            value = true;
          }
        }
      }
    }
    //  else {
    //   value = true;
    // }

    return value;
  };

  const checkLatestIpfsStageData = (itemIndex, key) => {
    let value;

    if (itemIndex !== 0 && latestIpfsData?.stageData) {
      const found = latestIpfsData.stageData.some(
        (el) =>
          el &&
          el.data?.stageName?.data ===
            dataJson.form.sections[itemIndex].sections[key].sectionTitle
      );

      if (found) {
        value = true;
      } else {
        value = false;
      }
    }

    return value;
  };

  const checkCurrentField = (itemIndex, key) => {
    let value;

    if (
      itemIndex !== 0 &&
      latestIpfsData?.stageData &&
      key !== 0 &&
      dataJson.form.sections[itemIndex].sections[key].required === true
    ) {
      let arr = [];

      for (let JsonValue of dataJson.form.sections[itemIndex].sections) {
        const found = latestIpfsData.stageData.some(
          (el) => el && el.data?.stageName?.data === JsonValue.sectionTitle
        );

        if (!found) {
          if (JsonValue.required === true) {
            arr.push(JsonValue);
          }
        }
      }

      if (arr[0]?.order === key) {
        value = false;
      } else if (arr[0]?.order !== key) {
        value = true;
      }
    }

    return value;
  };

  const getCurrentOptionalField = (itemIndex, key) => {
    let value;

    if (
      itemIndex !== 0 &&
      latestIpfsData?.stageData &&
      dataJson.form.sections[1].sections[key].required === false
    ) {
      const checkCurrentSection = latestIpfsData?.stageData.find(
        (e) =>
          e &&
          e?.data?.stageName?.data ===
            dataJson.form.sections[itemIndex].sections[key].sectionTitle
      );

      const checkPreviousSection = latestIpfsData?.stageData.find(
        (e) =>
          e &&
          e?.data?.stageName?.data ===
            dataJson.form.sections[itemIndex]?.sections[key - 1]?.sectionTitle
      );

      if (!checkCurrentSection && checkPreviousSection) {
        value = false;
      } else if (checkCurrentSection || !checkPreviousSection) {
        value = true;
      }
    }

    return value;
  };

  const enableFinalComplete = () => {
    let value;

    const isRequiredAvailable = dataJson?.form?.sections[1]?.sections.some(
      (el) => el && el.required === true
    );

    if (isRequiredAvailable) {
      if (latestIpfsData?.stageData) {
        dataJson.form.sections[1].sections.map((e) => {
          if (e.required === true) {
            const found = latestIpfsData?.stageData.some(
              (el) => el && el.data?.stageName?.data === e.sectionTitle
            );
            if (!found) {
              value = true;
            } else {
              value = false;
            }
          }
        });
      }
    } else if (!isRequiredAvailable && latestIpfsData) {
      value = false;
    } else {
      value = true;
    }

    return value;
  };

  return (
    <Container>
      {!dataJson ? (
        <InnerLoadingView />
      ) : (
        <>
          <CustomTextFieldModal
            title="Add Additional Stage Details"
            formId="custom-stage-data-form"
            setCustomModalOpen={setCustomModalOpen}
            openCustomModal={openCustomModal}
            stageType
          />

          {showAction && (
            <ActionConfirm
              successMessage={{
                message: "Adding data",
                width: "14rem",
              }}
              warningMessage={"Are you sure you want to submit?"}
              loading={loading}
              setShowAction={setShowAction}
              authTypeInput={authUser.authType}
              publicKey={authUser.publicKey}
              encryptedSecret={authUser.encryptedSecret}
              onSecretKey={handleOnSave}
            />
          )}

          {showMainAction && (
            <ActionConfirm
              successMessage={{
                message: "Adding data to Blockchain",
                width: "14rem",
              }}
              warningMessage={"Are you sure you want to submit?"}
              loading={mainLoading}
              setShowAction={setShowAction}
              authTypeInput={authUser.authType}
              publicKey={authUser.publicKey}
              encryptedSecret={authUser.encryptedSecret}
              onSecretKey={handleComplete}
            />
          )}

          {(latestDraftData || !currentUser?.drafts) && (
            <>
              <form
                onSubmit={onSave}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                {dataJson &&
                  dataJson.form.sections.map((item, itemIndex) => {
                    return (
                      <GridContainer id="main-form">
                        <FlexSpaceBetweenContainer
                          style={{
                            gridColumn: `${!matches ? "1/3" : "unset"}`,
                            margin: "1rem 0rem",
                          }}
                        >
                          <SubHeading primary>{item.sectionTitle}</SubHeading>
                        </FlexSpaceBetweenContainer>
                        {item.sections.map((section, key) => {
                          return (
                            <FieldContainer>
                              {section.sectionTitle && (
                                <FlexSpaceBetweenContainer
                                  style={{
                                    gridColumn: `${!matches ? "1/3" : "unset"}`,
                                    margin: "1rem 0rem",
                                  }}
                                >
                                  <SubHeading primary>
                                    {`${section.sectionTitle}  ${
                                      section?.required === false ? "" : "*"
                                    }`}
                                  </SubHeading>
                                </FlexSpaceBetweenContainer>
                              )}
                              {section.fields.map((field) => {
                                if (field.inputType === "text") {
                                  return (
                                    <TextField
                                      label={`${field.label}  ${
                                        field?.required === false ? "" : "*"
                                      }`}
                                      // required={field.required}
                                      placeholder={field.placeHolder}
                                      id={field.label}
                                      name={field.label}
                                      type={
                                        field.dataType === "number"
                                          ? "number"
                                          : "text"
                                      }
                                      disabled={
                                        (item.order === 0 &&
                                          latestIpfsData &&
                                          true) ||
                                        (item.order !== 0 &&
                                          latestIpfsData?.stageData &&
                                          latestIpfsData?.stageData[
                                            section.order
                                          ])
                                      }
                                      value={
                                        item.order === 0
                                          ? latestDraftData?.basicData[
                                              field.label
                                            ]?.data?.data
                                          : latestDraftData?.stageData &&
                                            latestDraftData?.stageData[
                                              section.order
                                            ]?.data[field.label]?.data
                                      }
                                    />
                                  );
                                } else if (field.inputType === "selection") {
                                  return (
                                    <DropContainer
                                      style={{ gridRow: "span 2" }}
                                    >
                                      <InputContainer>
                                        <Label>
                                          {" "}
                                          {`${field.label}  ${
                                            field?.required === false ? "" : "*"
                                          }`}
                                        </Label>
                                      </InputContainer>
                                      <DropzoneComponent
                                        disabled={
                                          (item.order === 0
                                            ? latestDraftData?.basicData[
                                                "Media Files"
                                              ]?.data?.data
                                              ? [
                                                  {
                                                    preview:
                                                      latestDraftData
                                                        ?.basicData[
                                                        "Media Files"
                                                      ]?.data?.data,
                                                  },
                                                ]
                                              : false
                                            : latestDraftData?.stageData &&
                                              latestDraftData?.stageData[
                                                section.order
                                              ]?.data["Media Files"]?.data
                                            ? [
                                                {
                                                  preview:
                                                    latestDraftData?.stageData[
                                                      section.order
                                                    ]?.data["Media Files"]
                                                      ?.data,
                                                },
                                              ]
                                            : false) ||
                                          (item.order === 0 &&
                                            latestIpfsData) ||
                                          (item.order !== 0 &&
                                            latestIpfsData?.stageData &&
                                            section.order ===
                                              latestIpfsData?.stageData
                                                ?.length -
                                                1)
                                        }
                                        files={
                                          item.order === 0
                                            ? latestDraftData?.basicData[
                                                "Media Files"
                                              ]?.data?.data
                                              ? [
                                                  {
                                                    preview:
                                                      latestDraftData
                                                        ?.basicData[
                                                        "Media Files"
                                                      ]?.data?.data,
                                                  },
                                                ]
                                              : files
                                            : latestDraftData?.stageData &&
                                              latestDraftData?.stageData[
                                                section.order
                                              ]?.data["Media Files"]?.data
                                            ? [
                                                {
                                                  // preview:
                                                  //   latestDraftData?.stageData[
                                                  //     section.order
                                                  //   ]?.data[field.label]?.data,
                                                  preview:
                                                    latestDraftData?.stageData[
                                                      section.order
                                                    ]?.data["Media Files"]
                                                      ?.data,
                                                },
                                              ]
                                            : files
                                        }
                                        id="dropzoneTwo"
                                        amount="1"
                                        form="none"
                                        multiple={false}
                                        errors={errors}
                                        setErrors={setErrors}
                                        maxSize="1000000"
                                        setFiles={setFiles}
                                        type="image/jpeg, image/png"
                                        description="Drop or select image (Max file size 10MB)"
                                      />
                                    </DropContainer>
                                  );
                                } else if (
                                  field.inputType === "multiSelection"
                                ) {
                                  // Create structure for added data
                                  let draftIpfsArray =
                                    latestDraftData?.stageData &&
                                    latestDraftData?.stageData[
                                      section.order
                                    ]?.data[field.label]?.data.split(",");

                                  let latestIpfsArray =
                                    latestIpfsData?.stageData &&
                                    latestIpfsData?.stageData[
                                      section.order
                                    ]?.data[field.label]?.data.split(",");

                                  let obj = {};

                                  if (
                                    draftIpfsArray !== undefined &&
                                    latestIpfsData
                                  ) {
                                    const myArray = [];
                                    draftIpfsArray?.map((item) => {
                                      if (latestIpfsArray) {
                                        obj = {
                                          label: item,
                                        };
                                      } else {
                                        obj = {
                                          value: item,
                                          label: item,
                                        };
                                      }

                                      myArray.push(obj);
                                    });

                                    multiSelect = myArray;
                                  }

                                  return (
                                    <Column span={!matches ? true : false}>
                                      <Label>{`${field.label}  ${
                                        field?.required === false ? "" : "*"
                                      }`}</Label>
                                      <CreatableSelect
                                        theme={(theme) => ({
                                          ...theme,
                                          colors: {
                                            ...theme.colors,
                                            outline: "none",
                                            text: "orangered",
                                            primary25: "#2f3a6039",
                                            primary: " #2f3a60a7",
                                            neutral0: "#c3d0e1",
                                            boxShadow: "none",
                                          },
                                        })}
                                        styles={{
                                          menu: (provided) => ({
                                            ...provided,
                                            zIndex: 3,
                                          }),
                                        }}
                                        isMulti
                                        name={field.label}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        defaultValue={field.options[0]}
                                        options={field.options}
                                        value={multiSelect}
                                        onChange={setMultiSelect}
                                      />
                                    </Column>
                                  );
                                }
                              })}

                              <ButtonContainer
                                style={{
                                  gridColumn: `${!matches ? "1/3" : "unset"}`,
                                }}
                              >
                                <Button
                                  type="onSubmit"
                                  sx={{
                                    "&.Mui-disabled": {
                                      background: "#cccccc",
                                      color: "#666666",
                                    },
                                    "&:hover": {
                                      background: "#1fc36157",
                                      color: "#080036",
                                    },
                                    background: "#1fc36157",
                                    color: "#080036",
                                    textTransform: "inherit",
                                    fontWeight: "500",
                                    fontFamily: "Poppins",
                                    padding: "0.3rem 1rem",
                                    float: "right",
                                  }}
                                  // disabled={
                                  //   (itemIndex === 0 && latestDraftData
                                  //     ? dataJson.form.sections[0]?.sections[0]
                                  //         ?.fields?.length ===
                                  //       Object.keys(latestDraftData?.basicData)
                                  //         ?.length
                                  //     : false) ||
                                  //   (itemIndex === 0 && latestIpfsData && true) ||
                                  //   (itemIndex !== 0 && latestDraftData?.stageData
                                  //     ? dataJson.form.sections[1]?.sections[key]
                                  //         ?.fields?.length ===
                                  //       (latestDraftData?.stageData[key] &&
                                  //         Object.keys(
                                  //           latestDraftData?.stageData[key]?.data
                                  //         )?.length - 1)
                                  //     : false) ||
                                  //   (itemIndex === 1 &&
                                  //   key !== 0 &&
                                  //   key === latestIpfsData?.stageData?.length
                                  //     ? false
                                  //     : itemIndex !== 0 && key !== 0 && true) ||
                                  //   (itemIndex === 1 &&
                                  //     key !== 0 &&
                                  //     !latestDraftData?.stageData &&
                                  //     true) ||
                                  //   (itemIndex === 1 &&
                                  //     key === 0 &&
                                  //     latestIpfsData &&
                                  //     false) ||
                                  //   (itemIndex === 1 &&
                                  //     key === 0 &&
                                  //     !latestIpfsData &&
                                  //     true) ||
                                  //   (itemIndex !== 0 &&
                                  //     key === 0 &&
                                  //     latestIpfsData?.stageData &&
                                  //     true)
                                  // }

                                  disabled={
                                    (basicDataValidity(itemIndex, key) !==
                                      undefined &&
                                      basicDataValidity(itemIndex, key)) ||
                                    (checkStage(itemIndex, key) !== undefined &&
                                      checkStage(itemIndex, key)) ||
                                    (checkCurrentField(itemIndex, key) !==
                                      undefined &&
                                      checkCurrentField(itemIndex, key)) ||
                                    (getCurrentOptionalField(itemIndex, key) !==
                                      undefined &&
                                      getCurrentOptionalField(
                                        itemIndex,
                                        key
                                      )) ||
                                    (checkStageWithIpfs(itemIndex, key) !==
                                      undefined &&
                                      checkStageWithIpfs(itemIndex, key))
                                  }
                                  onClick={(e) => {
                                    setSectionIndex(itemIndex);
                                    setStageIndex(key);
                                    if (item.order) {
                                      setStageName(section.sectionTitle);
                                    }
                                  }}
                                  size="small"
                                >
                                  Save
                                </Button>
                                <Button
                                  sx={{
                                    "&.Mui-disabled": {
                                      background: "#cccccc",
                                      color: "#666666",
                                    },
                                    "&:hover": {
                                      background: "#1fc36157",
                                      color: "#080036",
                                    },
                                    background: "#1fc36157",
                                    color: "#080036",
                                    textTransform: "inherit",
                                    fontWeight: "500",
                                    fontFamily: "Poppins",
                                    padding: "0.3rem 1rem",
                                    float: "right",
                                  }}
                                  disabled={
                                    (itemIndex === 0 &&
                                    latestDraftData &&
                                    getCount(0, 0) <=
                                      Object.keys(latestDraftData?.basicData)
                                        ?.length
                                      ? false
                                      : itemIndex === 0 && true) ||
                                    // enableBasicComplete(itemIndex, key) ||
                                    (itemIndex === 0 &&
                                      latestIpfsData &&
                                      true) ||
                                    // checkLatestIpfsStageData(itemIndex,key) ||
                                    (itemIndex !== 0 &&
                                    latestDraftData?.stageData &&
                                    getCount(1, key) <=
                                      (latestDraftData?.stageData[key] &&
                                        Object.keys(
                                          latestDraftData?.stageData[key]?.data
                                        )?.length - 1)
                                      ? false
                                      : itemIndex !== 0 && true) ||
                                    // enableStageComplete(itemIndex, key) ||
                                    (itemIndex !== 0 &&
                                      latestIpfsData?.stageData &&
                                      latestIpfsData?.stageData[key] &&
                                      true) ||
                                    (itemIndex === 1 &&
                                      key !== 0 &&
                                      !latestIpfsData?.stageData &&
                                      true)
                                  }
                                  onClick={() => {
                                    setShowMainAction(true);
                                  }}
                                  size="small"
                                  //   endIcon={loading && <CircularProgress size={20} />}
                                >
                                  Complete
                                </Button>
                              </ButtonContainer>
                            </FieldContainer>
                          );
                        })}
                      </GridContainer>
                    );
                  })}
              </form>
              <ButtonContainer
                style={{
                  gridColumn: `${!matches ? "1/3" : "unset"}`,
                }}
              >
                <CustomButton
                  // disabled={
                  //   dataJson?.form?.sections[1].sections?.length !==
                  //   latestIpfsData?.stageData?.length
                  // }
                  disabled={enableFinalComplete()}
                  login
                  text="Complete"
                  onclick={() => {
                    setIsFinalComplete(true);
                    setShowMainAction(true);
                  }}
                />
              </ButtonContainer>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default CreateWorkFlow;

const Container = styled.div`
  // display: flex;
  // flex-direction: column;
  gap: ${(props) => (props.noGap ? "0" : "1rem")};
`;

const DropContainer = styled.div`
  gap: 0.3rem;
  outline: none;
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  grid-column: ${(props) => (props.span ? "1/3" : "unset")};
  @media (max-width: 768px) {
    grid-column: unset;
  }
`;

const ButtonContainer = styled.div`
  margin: 1rem 0rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`;

const FieldContainer = styled.div`
  display: grid;
  row-gap: 1rem;
  column-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  border: 0.75px solid #d3d3d36e;
  margin-top: 1rem;
  background-color: #c3d0e181;
  border-radius: 10px;
  padding: 1.5rem;
  @media (max-width: 1368px) {
    grid-template-columns: 1fr;
  }
`;

const GridContainer = styled.div`
  background-color: #eff2f5;
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  padding: 1.5rem;
`;

// const GridContainer = styled.form`
//   display: grid;
//   row-gap: 1rem;
//   column-gap: 2rem;
//   grid-template-columns: 1fr 1fr;
//   @media (max-width: 1368px) {
//     grid-template-columns: 1fr;
//   }

//   background-color: #eff2f5;
//   border: 0.75px solid #d3d3d36e;

//   border-radius: 10px;
//   padding: 1.5rem;
// `;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: ${(props) => (props.span ? "1/3" : "unset")};
`;

const ButtonWrapper = styled.div`
  padding-top: 1rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`;

const Column = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  grid-column: ${(props) => (props.span ? "1/3" : "unset")};
  @media (max-width: 768px) {
    grid-column: unset;
  }
`;
