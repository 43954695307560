import styled from "styled-components";
import React, { Fragment, useState, useEffect } from "react";
import { Modal } from "@mui/material";
import { Link } from "react-router-dom";
import { ClaimCardHeader } from "./ClaimCardHeader";
import { Text } from "../../components/shared/Typography/Typo";
import TimeAgo from "react-time-ago";

const ClaimCard = ({ preview, data, key, token }) => {
  const [openImage, setImageOpen] = useState(false);
  const [urlName, setUrlName] = useState("polygonscan");
  //eslint-disable-next-line
  const [blockchain, setBlockchain] = useState("");
  const handleImageOpen = () => setImageOpen(true);
  const handleImageClose = () => {
    setImageOpen(false);
  };

  useEffect(() => {
    switch (data?.networkId) {
      case 1:
        setUrlName("etherscan");
        setBlockchain("ETHEREUM");
        break;
      case 4:
        setUrlName("rinkeby.etherscan");
        setBlockchain("RINKEBY");
        break;
      case 56:
        setUrlName("bscscan");
        setBlockchain("BSC");
        break;
      case 97:
        setUrlName("testnet.bscscan");
        setBlockchain("BSCTESTNET");
        break;
      case 137:
        setUrlName("polygonscan");
        setBlockchain("MATIC");
        break;
      case 80001:
        setUrlName("mumbai.polygonscan");
        setBlockchain("MUMBAI");
        break;
      default:
        setUrlName("polygonscan");
        setBlockchain("MATIC");
    }
  }, [data?.networkId]);

  if (preview) {
    return (
      <Fragment>
        <Modal
          open={openImage}
          onClose={handleImageClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ImageFlexContainer>
            <LargeImage src={data?.certificateImage} alt="image" />
          </ImageFlexContainer>
        </Modal>
        <Container key={key}>
          <ClaimCardHeader
            certificateName={data?.certificateName}
            description={data?.description}
          />
          <FieldWrapper>
            <Text primary lighter small style={{ justifySelf: "start" }}>
              {token ? "Image" : "Token Image"}
            </Text>
            <Image
              loading="lazy"
              cursor
              onClick={() => {
                handleImageOpen();
              }}
              src={data?.certificateImage}
              alt={"Token Image"}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Text primary lighter small style={{ justifySelf: "start" }}>
              Reciever Email
            </Text>
            <Text primary lighter small style={{ justifySelf: "end" }}>
              {data?.recieverEmail}
            </Text>
          </FieldWrapper>
        </Container>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Modal
          open={openImage}
          onClose={handleImageClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ImageFlexContainer>
            <LargeImage src={data.itemImage} alt="image" />
          </ImageFlexContainer>
        </Modal>
        <Container>
          <ClaimCardHeader
            addPadding
            certificateName={data.itemName}
            id={data.uniqueId}
            description={data.description}
          />
          <FieldWrapper addPadding>
            <Text primary lighter small style={{ justifySelf: "start" }}>
              {data.type === "education" ? "Token Image" : "Token Image"}
            </Text>
            <Image
              loading="lazy"
              cursor
              onClick={() => {
                handleImageOpen();
              }}
              src={data.itemImage}
              alt={"Image"}
            />
          </FieldWrapper>
          <ButtonContainer>
            <InnerButtonContainer>
              <ViewButton
                to={{
                  pathname: `/dashboard/credentials/${data.uniqueId}`,
                  state: { data: data },
                }}
              >
                {data.type === "education" ? "View Token" : "View Token"}
              </ViewButton>
              {/* {data?.txnHashes?.claimedTxnHash && (
                <ViewButton
                  to={{
                    pathname: `/dashboard/credentials/${data.uniqueId}`,
                    state: { data: data },
                  }}
                >
                  {data.type === "education" ? "View Token" : "View Token"}
                </ViewButton>)} */}
              {data?.txnHashes?.claimedTxnHash && (
                <ViewButton
                  to={{
                    pathname: `https://${urlName}.com/tx/${data?.txnHashes?.claimedTxnHash}`,
                    // state: { data: data },
                  }}
                  target={"_blank"}
                >
                  {"View OnChain"}
                </ViewButton>
              )}
            </InnerButtonContainer>

            <Text primary lighter small>
              <TimeAgo date={new Date(data?.date)} locale="en-US" />
            </Text>
          </ButtonContainer>
        </Container>
      </Fragment>
    );
  }
};

export default ClaimCard;

export const Container = styled.div`
  flex-direction: column;
  display: flex;
  position: relative;
  justify-content: space-between;
  background-color: #eff2f5;
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  gap: 0.5rem;
  padding: ${(props) => (props.addPadding ? "1.5rem" : "1rem")};
  // width: 450px;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const FieldWrapper = styled.div`
  padding: ${(props) => (props.addPadding ? "1rem 1rem" : "0.2rem 1rem")};
  background-color: #c3d0e1;
  display: grid;
  border-radius: 5px;
  grid-template-columns: 1fr 1fr;
`;

const ViewButton = styled(Link)`
  text-decoration: none;
  padding: 0.3rem 1rem;
  background: #1fc36157;
  font-size: 14px;
  border-radius: 5px;
  font-weight: 500;
  color: #080036;
  font-family: "Poppins";
`;

const ButtonContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InnerButtonContainer = styled.div`
  gap: 2rem;
  display: flex;
  align-items: center;
`;

const Image = styled.img`
  width: 4rem;
  height: auto;
  cursor: ${(props) => (props.cursor ? "pointer" : "unset")};
  justify-self: end;
  border-radius: 5px;
  text-align: center;
`;

const LargeImage = styled.img`
  text-align: center;
  width: 100%;
  height: auto;
`;

const ImageFlexContainer = styled.div`
  display: grid;
  width: 40%;
  position: absolute;
  top: 50%;
  left: 50%;
  background: #ffffff;
  transform: translate(-50%, -50%);
  place-items: center;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  @media (max-width: 1368px) {
    width: 60%;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
`;
