import styled from "styled-components";
import { NIFTRON, UserType } from "niftron-client-sdk";
import React, { Fragment, useState, useEffect } from "react";
import { Box, Card, CardContent, CardMedia, Modal } from "@mui/material";
import Typography from "@mui/material/Typography";
// import AspectRatio from "@mui/material/AspectRatio";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import CardActions from "@mui/material/CardActions";

// import { ClaimCardHeader } from "./ClaimCardHeader";
import { Text } from "../../components/shared/Typography/Typo";
import { urlToHash } from "../../utils/ipfs";
import { ClaimCardHeader } from "../Claim/ClaimCardHeader";
import * as API from "../../api/API";
import * as encryption from "../../utils/encryption";
import ActionConfirm from "../../components/shared/Form/FormComponents/ActionConfirm";
import boxIcon from "../../assets/images/box.png";
import docIcon from "../../assets/images/document.png";

const ProfileListCard = ({ data, type, setMainPageLoading }) => {
  const [openImage, setImageOpen] = useState(false);
  const [showActionConfirm, setShowActionConfirm] = useState(false);
  const [authUser, setAuthUser] = useState(() => API.getUserSession());
  const [secretKey, setSecretKey] = useState("");
  const [accessProfileLoading, setAccessProfileLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageType, setImageType] = useState(
    type == 1
      ? "profileImage"
      : type == 2
      ? "profileImage"
      : type == 3
      ? "productImage"
      : type == 4
      ? "documentImage"
      : type == 5
      ? "profileImage"
      : ""
  );
  const [name, setName] = useState(
    type == 1
      ? "firstName"
      : type == 2
      ? "firstName"
      : type == 3
      ? "productName"
      : type == 4
      ? "documentName"
      : type == 5
      ? "firstName"
      : ""
  );
  const [description, setDescription] = useState(
    type == 1
      ? "bio"
      : type == 2
      ? "bio"
      : type == 3
      ? "description"
      : type == 4
      ? "description"
      : type == 5
      ? "bio"
      : ""
  );

  const handleSecretKey = (secretKey) => {
    window.localStorage.setItem("secretKey", secretKey);

    setSecretKey(secretKey);
    handleAccessProfile();
  };

  const handleImageOpen = () => setImageOpen(true);
  const handleImageClose = () => {
    setImageOpen(false);
  };

  const handleAccessProfile = async () => {
    setMainPageLoading(true);
    if (data?.secretKey) {
      const token = localStorage.getItem("niftoken");
      await NIFTRON.user.loginWithSecret(data?.secretKey);
      window.localStorage.setItem("secretKey", data?.secretKey);
      window.localStorage.setItem("providerToken", token);
      window.location.assign("/");
    } else {
      localStorage.secretKey
        ? setShowActionConfirm(false)
        : setShowActionConfirm(true);
      if (localStorage.secretKey) {
        const decryptedSecretKey = encryption.symmetricDecryption(
          data.encryptedSecretKey,
          localStorage.secretKey
        );
        const token = localStorage.getItem("niftoken");
        await NIFTRON.user.loginWithSecret(decryptedSecretKey);
        window.localStorage.setItem("secretKey", decryptedSecretKey);
        window.localStorage.setItem("providerToken", token);
        window.location.assign("/");
      }
    }
    setMainPageLoading(false);
  };

  // console.log("type : ", type);

  return (
    // <StyledCard>
    //   {type !== 5 && (
    //     <>
    //       <Box
    //         sx={{ padding: "1rem", display: "flex", justifyContent: "center" }}
    //       >
    //         <Text primary lighter small>
    //           {type == 1
    //             ? "Profile Image"
    //             : type == 2
    //             ? "Profile Image"
    //             : type == 3
    //             ? "Product Image"
    //             : type == 4
    //             ? "Document Image"
    //             : type == 5
    //             ? "Individual Image"
    //             : ""}
    //         </Text>
    //       </Box>
    //       <Box
    //         sx={{
    //           paddingBottom: "1rem",
    //           display: "flex",
    //           justifyContent: "center",
    //         }}
    //       >
    //         <CardMedia
    //           component="img"
    //           // image={
    //           //   data[imageType]?.data?.data === "undefined" ||
    //           //   !data[imageType]?.data?.data?.toString()?.includes("ipfs")
    //           //     ? "https://aui.atlassian.com/aui/8.8/docs/images/avatar-person.svg"
    //           //     : `https://niftron.infura-ipfs.io/ipfs/${urlToHash(
    //           //         data[imageType]?.data?.data
    //           //       )}`
    //           // }
    //           image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSezRDjNPTFaU5ANO6O8eQxbujy6MGJTdv4KA&usqp=CAU"
    //           alt="image"
    //           onClick={() => {
    //             handleImageOpen();
    //           }}
    //           sx={{ width: "230px", cursor: "pointer" }}
    //         />
    //       </Box>
    //     </>
    //   )}

    //   {showActionConfirm && (
    //     <ActionConfirm
    //       successMessage={{
    //         message: "Accessing Profile",
    //         width: "16rem",
    //       }}
    //       warningMessage={"Are you sure you want to access profile?"}
    //       loading={loading}
    //       setShowAction={setShowActionConfirm}
    //       authTypeInput={authUser.authType}
    //       publicKey={authUser.publicKey}
    //       encryptedSecret={authUser.encryptedSecret}
    //       onSecretKey={handleSecretKey}
    //     />
    //   )}
    //   {!data?.alias ? (
    //     <>
    //       <Modal
    //         open={openImage}
    //         onClose={handleImageClose}
    //         aria-labelledby="modal-modal-title"
    //         aria-describedby="modal-modal-description"
    //       >
    //         {/* <ImageFlexContainer>
    //           <LargeImage
    //             alt="image"
    //             cursor
    //             src={
    //               data[imageType]?.data?.data === "undefined" ||
    //               !data[imageType]?.data?.data?.toString()?.includes("ipfs")
    //                 ? "https://aui.atlassian.com/aui/8.8/docs/images/avatar-person.svg"
    //                 : `https://niftron.infura-ipfs.io/ipfs/${urlToHash(
    //                     data[imageType]?.data?.data
    //                   )}`
    //             }
    //             onClick={() => {
    //               handleImageOpen();
    //             }}
    //           />
    //         </ImageFlexContainer> */}

    //         <ImageFlexContainer>
    //           <LargeImage
    //             alt="image"
    //             cursor
    //             src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSezRDjNPTFaU5ANO6O8eQxbujy6MGJTdv4KA&usqp=CAU"
    //             onClick={() => {
    //               handleImageOpen();
    //             }}
    //           />
    //         </ImageFlexContainer>
    //       </Modal>

    //       <Container>
    //         <ClaimCardHeader
    //           removeBackground
    //           certificateName={
    //             data[name]?.isPublic ? data[name].data?.data : "**************"
    //           }
    //           description={
    //             data[description]?.isPublic
    //               ? data[description]?.data?.data
    //               : "**************"
    //           }
    //         />

    //         <ButtonContainer>
    //           {type != 5 && (
    //             <ViewButton
    //               to={{
    //                 pathname: `/profiles/${data?.publicKey}`,
    //               }}
    //             >
    //               {" "}
    //               View Profile
    //             </ViewButton>
    //           )}
    //           <AccessButton
    //             onClick={handleAccessProfile}
    //             style={{ width: "100%" }}
    //           >
    //             {" "}
    //             Access Profile
    //           </AccessButton>
    //         </ButtonContainer>
    //       </Container>
    //     </>
    //   ) : (
    //     <Container>
    //       <ClaimCardHeader removeBackground certificateName={data.alias} />

    //       <ButtonContainer style={{ marginTop: "6.7rem" }}>
    //         <AccessButton
    //           onClick={handleAccessProfile}
    //           style={{ width: "100%" }}
    //         >
    //           {" "}
    //           Access Profile
    //         </AccessButton>
    //       </ButtonContainer>
    //     </Container>
    //   )}
    // </StyledCard>
    <>
      {showActionConfirm && (
        <ActionConfirm
          successMessage={{
            message: "Accessing Profile",
            width: "16rem",
          }}
          warningMessage={"Are you sure you want to access profile?"}
          loading={loading}
          setShowAction={setShowActionConfirm}
          authTypeInput={authUser.authType}
          publicKey={authUser.publicKey}
          encryptedSecret={authUser.encryptedSecret}
          onSecretKey={handleSecretKey}
        />
      )}

      <Card sx={{ maxWidth: 345 }}>
        <CardMedia
          component="img"
          alt="image"
          height="140"
          // image={
          //   !data?.alias
          //     ? data[imageType]?.data?.data === "undefined" ||
          //       !data[imageType]?.data?.data?.toString()?.includes("ipfs")
          //       ? "https://aui.atlassian.com/aui/8.8/docs/images/avatar-person.svg"
          //       : `https://niftron.infura-ipfs.io/ipfs/${urlToHash(
          //           data[imageType]?.data?.data
          //         )}`
          //     : data?.profileType == 3
          //     ? boxIcon
          //     : docIcon
          // }
          // image={
          //   data?.alias
          //     ? data?.profileType == 3
          //       ? boxIcon
          //       : data?.profileType == 4
          //       ? docIcon
          //       : data[imageType]?.data?.data === "undefined" ||
          //         !data[imageType]?.data?.data?.toString()?.includes("ipfs")
          //       ? "https://aui.atlassian.com/aui/8.8/docs/images/avatar-person.svg"
          //       : `https://niftron.infura-ipfs.io/ipfs/${urlToHash(
          //           data[imageType]?.data?.data
          //         )}`
          //     : ""
          // }
          image={docIcon}
          sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {data?.alias
              ? data.alias
              : data[name]?.isPublic
              ? data[name].data?.data
              : "**************"}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" onClick={handleAccessProfile}>
            Access Profile
          </Button>
          {/* <Button size="small">View Profile</Button> */}
        </CardActions>
      </Card>
    </>
  );
};

export default ProfileListCard;

export const Container = styled.div`
  flex-direction: column;
  display: flex;
  position: relative;
  justify-content: space-between;
  background-color: #eff2f5;
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  gap: 0.5rem;
  padding: ${(props) => (props.addPadding ? "1.5rem" : "1rem")};
  /* height: 88%; */
`;

export const FieldWrapper = styled.div`
  padding: ${(props) => (props.addPadding ? "1rem 1rem" : "0.2rem 1rem")};
  background-color: #c3d0e1;
  display: grid;
  border-radius: 5px;
  grid-template-columns: 1fr 1fr;
`;

const ViewButton = styled(Link)`
  text-decoration: none;
  padding: 0.3rem 0rem;
  background: #1fc36157;
  font-size: 14px;
  border-radius: 5px;
  font-weight: 500;
  color: #080036;
  font-family: "Poppins";
  width: 100%;
  text-align: center;
`;
const OverLayContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 50%;
  z-index: 999;
  right: 50%;
  transform: translate(50%, -50%);
  background: #0c0b5584;
`;

export const AccessButton = styled.button`
  text-decoration: none;
  display: inline-block;
  border: none;
  padding: 0.3rem 1rem;
  background: #1fc36157;
  font-size: 14px;
  border-radius: 5px;
  font-weight: 500;
  color: #080036;
  font-family: "Poppins";
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
`;

const Image = styled.img`
  width: 4rem;
  height: auto;
  cursor: ${(props) => (props.cursor ? "pointer" : "unset")};
  justify-self: end;
  border-radius: 5px;
  text-align: center;
`;

const LargeImage = styled.img`
  text-align: center;
  width: 100%;
  height: auto;
`;

const ImageFlexContainer = styled.div`
  display: grid;
  width: 40%;
  position: absolute;
  top: 50%;
  left: 50%;
  background: #ffffff;
  transform: translate(-50%, -50%);
  place-items: center;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  @media (max-width: 1368px) {
    width: 60%;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const StyledCard = styled(Card)`
  width: 31%;

  @media (max-width: 1200px) {
    width: 49%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;
