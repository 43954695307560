import React from "react";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Keyboard, Pagination, Navigation, Lazy } from "swiper";
import styled from "styled-components";
import PDFViewer from "../../../PDFViewer";

// install Swiper modules
SwiperCore.use([Pagination]);
SwiperCore.use([Keyboard, Pagination, Navigation, Lazy]);

const PDFImageSlider = ({ data }) => {
  return (
    <div>
      <Swiper
        slidesPerView={1}
        keyboard={{
          enabled: true,
        }}
        autoHeight={true}
        spaceBetween={20}
        lazy={true}
        direction="horizontal"
        navigation={{
          prevEl: ".prev",
          nextEl: ".next",
        }}
        className="mySwiper "
      >
        {data?.length > 0 &&
          data?.map((data, key) => {
            return (
              <SwiperSlide key={key}>
                <Container>
                  {data?.path.includes(".pdf") ? (
                    <PDFViewer
                      url={data?.preview}
                      isShowHeader="true"
                      isShowFooter="true"
                    />
                  ) : (
                    <FieldWrapper>
                      <OverFlowWrap src={data?.preview} />
                    </FieldWrapper>
                  )}
                </Container>
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
};

export default PDFImageSlider;

const Container = styled.div`
  padding: 1rem;
  flex-direction: column;
  display: flex;
  z-index: 30;
  justify-content: space-between;
  background-color: #eff2f5;
  border: 0.75px solid #d3d3d36e;
  min-height: 30rem;

  border-radius: 10px;
  gap: 0.5rem;
`;

const OverFlowWrap = styled.img`
  max-width: 550px;
  max-height: 550px;
  min-width: 20px;
  min-height: 20px;
  border-radius: 10px;
  object-fit: contain;
  object-position: center;
  &:before {
    content: "";
    background: url("https://c.tenor.com/I6kN-6X7nhAAAAAi/loading-buffering.gif");
    min-width: 350px;
    min-height: 350px;
    object-fit: contain;
    object-position: center;
    display: block;
    background: #ffffff;
  }
  @media (max-width: 768px) {
    max-width: 250px;
    max-height: 250px;
    min-width: 250px;
    min-height: 250px;
  }
`;

export const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
`;
