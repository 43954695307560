import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
} from "@mui/material";
import React from "react";
import styled from "styled-components";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircleIcon from "@mui/icons-material/Circle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import moment from "moment";

import VerificationStepper from "../VerificationViews/VerificationStepper";
import { SubHeading, Text } from "../Typography/Typo";
import IpfsDataStepper from "./IpfsDataStepper";

const CustomDataAccodion = ({
  item,
  itemName,
  handleChange,
  expanded,
  index,
  setCvHistoryIpfsUrl,
  setShowAction,
}) => {
  return (
    <Accordion
      style={{ boxShadow: "none", margin: "0.2rem 0rem" }}
      expanded={expanded === `panel${index}`}
      onChange={handleChange(`panel${index}`)}
    >
      <AccordionSummary
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        expandIcon={<ExpandMoreIcon />}
      >
        <GridContainer>
          <FlexContainer>
            {/* <Avatar sx={{ width: 32, height: 32 }} src={item?.receiverImage} /> */}
            <Text lighter primary style={{ textTransform: "capitalize" }}>
              {itemName}
            </Text>
          </FlexContainer>
        </GridContainer>
      </AccordionSummary>
      <AccordionDetails>
        {item?.editedBy &&
          item?.editedBy.map((data, index) => {
            return (
              <IpfsDataStepper
                itemName={itemName}
                item={data}
                index={index}
                setCvHistoryIpfsUrl={setCvHistoryIpfsUrl}
                setShowAction={setShowAction}
              />
            );
          })}
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomDataAccodion;

const GridContainer = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 4fr 0.5fr;
  place-content: center;
  place-items: center;
`;

const FlexContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;
