import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Web3 from "web3";
import ShareIcon from "@mui/icons-material/Share";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import ErrorBG from "../../assets/images/not-found.gif";
import ErrorBG2 from "../../assets/images/error.gif";
import { CustomButton } from "../../components/shared/fields/Button/CustomButton";
import { DisabledView } from "../../university-pages/Certificates/IssueCertificateForm";
import { retrieveItem, claimItem, getUserSession } from "../../api/API";
import store from "../../redux/reducers";
import { ADD_IPFS_DATA } from "../../redux/constants/ActionTypes";
import { createEncryptionObject } from "../../utils/encryption";
import { AddToIPFS, GetIPFSData } from "../../services/IpfsService";
import { urlToHash } from "../../utils/ipfs";
import InnerLoadingView from "../../components/shared/loading/InnerLoadingView";
import { SubHeading, Text } from "../../components/shared/Typography/Typo";
import ActionConfirm from "../../components/shared/Form/FormComponents/ActionConfirm";
import { symmetricDecryption } from "../../services/encryptionService";
import { ShareConfirm } from "../../components/shared/Profile/ShareConfirm";
import SlideView from "../../university-pages/Certificates/SlideView";
import MultipleImageViewSlider from "../../components/shared/Form/StageForm/MultipleImageViewSlider";
import * as API from "../../api/API";
import ScanModal from "./ScanModal";

const ScannedSingleItem = ({ publicView }) => {
  //eslint-disable-next-line
  const [authUser, setAuthUser] = useState(() => getUserSession());
  const [shareOpen, setShareOpen] = useState(false);
  const location = useLocation();
  const { id } = useParams();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useSelector((state) => state.currentUser);
  const [tokenData, setTokenData] = useState();
  const [loading, setLoading] = useState(false);
  const [claimSubmitLoading, setClaimSubmitLoading] = useState(false);
  const [latestIpfsData, setLatestIpfsData] = useState();
  const [showAction, setShowAction] = useState(false);
  const [urlName, setUrlName] = useState("polygonscan");
  const [blockchain, setBlockchain] = useState("");
  const [isMismatch, setIsMismatch] = useState(false);
  const [scanLoading, setScanLoading] = useState(false);
  const [scanDetails, setScanDetails] = useState("");
  const [isFirstScan, setIsFirstScan] = useState(false);
  const [showScanModal, setShowScanModal] = useState(true);

  // Get the latestIpfsData
  const fetchLatestProfileData = async () => {
    if (currentUser?.latestIpfsUrl?.ipfsUrl) {
      const hash = urlToHash(currentUser?.latestIpfsUrl?.ipfsUrl);
      try {
        const data = await GetIPFSData(hash);
        setLatestIpfsData(data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  // Retrieving item API
  const retrieveItems = async () => {
    setLoading(true);
    fetchLatestProfileData();
    try {
      const response = await retrieveItem({ uniqueId: id });
      if (response.data.code === 200) {
        console.log(response.data.data[0]);
        setTokenData(response.data.data[0]);
        await fetchScannedDetails(response.data.data[0]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchScannedDetails = async (data) => {
    setScanLoading(true);
    try {
      const response = await API.scanItem({
        body: {
          uniqueId: data.uniqueId,
          scannerPublicKey: currentUser.publicKey,
          profilePublicKey: data.profilePublicKey,
        },
      });
      if (response?.data?.code === 201) {
        setScanDetails(response?.data?.data[0]);
        setIsFirstScan(true);
      } else if (response?.data?.code === 200) {
        setScanDetails(response?.data?.data[0]);
        setIsFirstScan(false);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setScanLoading(false);
    }
  };

  useEffect(() => {
    location?.state?.data ? setTokenData(location.state.data) : retrieveItems();
    //eslint-disable-next-line
  }, [location?.state?.data]);

  useEffect(() => {
    if (latestIpfsData && tokenData) {
      if (
        latestIpfsData &&
        latestIpfsData.fullName &&
        tokenData &&
        tokenData.fullName &&
        latestIpfsData.fullName.data?.data === tokenData.fullName
      ) {
        setIsMismatch(false);
      }

      if (
        latestIpfsData &&
        latestIpfsData.fullName &&
        tokenData &&
        tokenData.fullName &&
        latestIpfsData.fullName.data?.data !== tokenData.fullName
      ) {
        setIsMismatch(true);
      }
    }
  }, [latestIpfsData, tokenData]);

  useEffect(() => {
    switch (tokenData?.networkId) {
      case 1:
        setUrlName("etherscan");
        setBlockchain("ETHEREUM");
        break;
      case 4:
        setUrlName("rinkeby.etherscan");
        setBlockchain("RINKEBY");
        break;
      case 56:
        setUrlName("bscscan");
        setBlockchain("BSC");
        break;
      case 97:
        setUrlName("testnet.bscscan");
        setBlockchain("BSCTESTNET");
        break;
      case 137:
        setUrlName("polygonscan");
        setBlockchain("MATIC");
        break;
      case 80001:
        setUrlName("mumbai.polygonscan");
        setBlockchain("MUMBAI");
        break;
      default:
        setUrlName("polygonscan");
        setBlockchain("MATIC");
    }
  }, [tokenData?.networkId]);

  const handleClose = () => setShowScanModal(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Container>
      {(() => {
        if (loading) {
          return <InnerLoadingView style={{ marginTop: "1.5rem" }} />;
        } else if (tokenData === undefined) {
          return (
            <ErrorContainer>
              <Error bg={ErrorBG} width="500px">
                <SubHeading
                  primary
                >{`Token Not Found!`}</SubHeading>
              </Error>
              <Text primary>Looks like you're lost</Text>
            </ErrorContainer>
          );
        } else if (isMismatch) {
          return (
            <ErrorContainer>
              <Error bg={ErrorBG2} width="500px">
                <SubHeading primary>{`Profile Mismatch!`}</SubHeading>
              </Error>
              <Text primary>
                Oops! Looks like your fullname does not match, please contact
                your issuer and get it right.
              </Text>
            </ErrorContainer>
          );
        } else {
          return (
            <Fragment>
              <Modal
                open={showScanModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <ScanModal
                  scanData={scanDetails}
                  setShowScanModal={setShowScanModal}
                />
              </Modal>
              <GridContainer style={{ marginTop: "2rem" }}>
                <ColumnContainer style={{ gap: "1rem" }}>
                  <ColumnContainer>
                    <Label style={{ marginBottom: "0.3rem" }}>
                      {tokenData?.type === "education"
                        ? "Token Name"
                        : "Token Name"}
                    </Label>
                    <DisabledView style={{ textTransform: "capitalize" }}>
                      {tokenData?.itemName}
                    </DisabledView>
                  </ColumnContainer>
                  <ColumnContainer>
                    <Label style={{ marginBottom: "0.3rem" }}>
                      Description
                    </Label>
                    <DisabledView>{tokenData?.description}</DisabledView>
                  </ColumnContainer>
                  <ColumnContainer>
                    <Label style={{ marginBottom: "0.3rem" }}>Blockchain</Label>
                    <DisabledView style={{ textTransform: "uppercase" }}>
                      {tokenData?.blockchain}
                    </DisabledView>
                  </ColumnContainer>
                  {tokenData?.email && (
                    <ColumnContainer>
                      <Label style={{ marginBottom: "0.3rem" }}>
                        Awarded to
                      </Label>
                      <DisabledView>{tokenData?.email}</DisabledView>
                    </ColumnContainer>
                  )}

                  {tokenData?.fullName && (
                    <ColumnContainer>
                      <Label style={{ marginBottom: "0.3rem" }}>
                        Receiver Full Name
                      </Label>
                      <DisabledView>{tokenData?.fullName}</DisabledView>
                    </ColumnContainer>
                  )}
                  {tokenData?.name && (
                    <ColumnContainer>
                      <Label style={{ marginBottom: "0.3rem" }}>
                        Receiver Name
                      </Label>
                      <DisabledView>{tokenData?.name}</DisabledView>
                    </ColumnContainer>
                  )}
                  {tokenData?.txnHashes?.mintedTxnHash && (
                    <ColumnContainer>
                      <Label style={{ marginBottom: "0.3rem" }}>
                        Mint Txn Hash
                      </Label>
                      <DisabledView>
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={`https://${urlName}.com/tx/${tokenData?.txnHashes?.mintedTxnHash}`}
                        >
                          {tokenData?.txnHashes?.mintedTxnHash}
                        </a>
                      </DisabledView>
                    </ColumnContainer>
                  )}
                  {tokenData?.txnHashes?.claimedTxnHash && (
                    <ColumnContainer>
                      <Label style={{ marginBottom: "0.3rem" }}>
                        Claim Txn Hash
                      </Label>
                      <DisabledView>
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={`https://${urlName}.com/tx/${tokenData?.txnHashes?.claimedTxnHash}`}
                        >
                          {tokenData?.txnHashes?.claimedTxnHash}
                        </a>
                      </DisabledView>
                    </ColumnContainer>
                  )}
                </ColumnContainer>

                <ImageWrapper>
                  <Image
                    alt="Token"
                    src={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(
                      tokenData?.itemImage
                    )}`}
                  />
                </ImageWrapper>
              </GridContainer>

              <ButtonWrapper>
                <Fragment>
                  <CustomButton
                    login
                    text="Back"
                    onclick={() => history.push("/tokens")}
                  />
                </Fragment>
              </ButtonWrapper>
            </Fragment>
          );
        }
      })()}
    </Container>
  );
};

export default ScannedSingleItem;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.noGap ? "0" : "1rem")};
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  padding: 1.5rem;
  min-height: 24rem;
  margin-bottom: 2rem;
`;

const GridContainer = styled.div`
  width: 100%;
  display: grid;
  row-gap: 1rem;
  justify-content: center;
  column-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  color: #080036;
  font-weight: 500;
`;

const ButtonWrapper = styled.div`
  padding-top: 1rem;
  gap: 2rem;
  display: flex;
  justify-content: flex-end;
`;

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  background-color: #fff;
  overflow: hidden;
  border-radius: 5px;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  cursor: ${(props) => (props.cursor ? "pointer" : "unset")};
  justify-self: end;
  border-radius: 5px;
  text-align: center;
  display: block;
`;

export const Error = styled.div`
  background-image: url(${(props) => props.bg});
  height: ${(props) => props.width};
  background-position: center;
  width: 100%;
  background-repeat: no-repeat;
`;

export const ErrorContainer = styled.div`
  display: flex;
  height: 100%;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
