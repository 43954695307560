import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import { IconButton, Tooltip } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import Web3ResumeContainer from "../../components/shared/Form/Web3ResumeContainer";
import IndividualFormContainer from "../../components/shared/Form/IndividualFormContainer";
import { fetchLatestProfileData } from "../../utils/FetchLatestData";
import { ButtonContainer } from "../../components/shared/DashboardComponents/ButtonContainer";
import TemplateCard from "../../components/shared/Templates/TemplateCard";
import CreateWorkFlow from "../../components/shared/Workflow/CreateWorkFlow";
import WorkflowCard from "../../components/shared/Workflow/WorkflowCard";
import CreateProfile from "../CredentialProviderProduct/CreateProfile";
import * as API from "../../api/API";
import InnerLoadingView from "../../components/shared/loading/InnerLoadingView";

const WorkFlow = (props) => {
  const history = useHistory();
  const currentUser = useSelector((state) => state.currentUser);
  const [templateType, setTemplateType] = useState();
  const [currentWorkflow, setCurrentWorkflow] = useState({});
  const [openUseWorkflow, setOpenUseWorkFlow] = useState(false);
  const [workflows, setWorkFlows] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchWorkflows = async () => {
    setLoading(true);
    try {
      const response = await API.retrieveWorkflows({
        path: {
          wId: currentUser.publicKey,
        },
        token: localStorage.niftoken,
      });

      if (response?.data?.code === 200) {
        setWorkFlows(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWorkflows();
  }, []);
  return (
    <Container>
      {openUseWorkflow && (
        <CreateProfile
          show={openUseWorkflow}
          setShowAction={setOpenUseWorkFlow}
          currentWorkflow={currentWorkflow}
          isWorkFlow
        />
      )}
      {(() => {
        if (!templateType) {
          return (
            <>
              <ButtonContainer
                span
                biglink
                heading="Workflows"
                description="Select a workflow and create a profile"
                icon={"view_quilt"}
              />

              <InnerContainer>
                {loading ? (
                  <InnerLoadingView style={{ margin: "auto" }} />
                ) : workflows.length > 0 ? (
                  <Box sx={{ width: "100%" }}>
                    <Grid
                      container
                      spacing={{ xs: 2, md: 3 }}
                      columns={{ xs: 4, sm: 8, md: 12 }}
                    >
                      {workflows.map((item) => {
                        return (
                          <Grid item xs={4}>
                            <WorkflowCard
                              image="https://res.cloudinary.com/monday-blogs/fl_lossy,f_auto,q_auto/wp-blog/2020/12/workflow-diagram-2-1.jpg"
                              name={item.alias}
                              id={item?._id}
                              description={item?.description}
                              setTemplateType={setTemplateType}
                              setCurrentWorkflow={setCurrentWorkflow}
                              setOpenUseWorkFlow={setOpenUseWorkFlow}
                            />
                          </Grid>
                        );
                      })}
                      <Grid
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingLeft: "150px",
                        }}
                      >
                        <Tooltip title="Click to add template">
                          <IconButton>
                            <AddCircleIcon
                              sx={{ fontSize: 35 }}
                              style={{
                                color: "#8080808f",
                              }}
                              onClick={() => {
                                history.push("/dashboard/templates");
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Box>
                ) : (
                  <Grid
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "auto",
                    }}
                  >
                    <Tooltip title="Click to add template">
                      <IconButton>
                        <AddCircleIcon
                          sx={{ fontSize: 35 }}
                          style={{
                            color: "#8080808f",
                          }}
                          onClick={() => {
                            history.push("/dashboard/templates");
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
              </InnerContainer>
            </>
          );
        } else {
          return (
            <Fragment>
              {(() => {
                switch (templateType) {
                  case 1:
                    return (
                      <Fragment>
                        <CreateWorkFlow />
                      </Fragment>
                    );
                  default:
                    return null;
                }
              })()}
            </Fragment>
          );
        }
      })()}
    </Container>
  );
};

export default WorkFlow;

const InnerContainer = styled.div`
  display: flex;
  border-radius: 10px;
  border: 0.75px solid #d3d3d3b0;
  gap: 1rem;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 1rem;
  min-height: 30vh;
`;

export const Container = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
`;
