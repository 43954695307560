import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { urlToHash } from "../../../utils/ipfs";
import { GetIPFSData } from "../../../services/IpfsService";
import ActionConfirm from "../Form/FormComponents/ActionConfirm";
import { getUserSession } from "../../../api/API";
import {
  symmetricDecryption,
  symmetricEncryption,
} from "../../../utils/encryption";
import store from "../../../redux/reducers";
import { ADD_IPFS_DATA } from "../../../redux/constants/ActionTypes";
import UserProfileEdit from "../EditForms/UserProfileEdit";
import EditForm from "../EditForms/EditForm";
import { Modal } from "@mui/material";
import { RequestVerification } from "../Profile/RequestVerification";
import { ShareConfirm } from "../Profile/ShareConfirm";
import ContactInfo from "../Profile/ContactInfo";
import UserProfileHeader, {
  SectionContainer,
} from "../Profile/UserProfileHeader";
import { SubHeading, Text } from "../Typography/Typo";
import VerifiedStatusContainer from "../Profile/VerifiedStatusContainer";
import EncryptedData from "../Profile/EncryptedData";
import AchievementSection from "../Profile/AchievementSection";
import AdditionalDetails from "../Profile/AdditionalDetails";
import { ConfigureTokenContainer, ViewButton } from "./../Profile/UserProfile";
import { fetchTokensData } from "../../../utils/FetchTokens";

const EntityProfile = () => {
  const currentUser = useSelector((state) => state.currentUser);
  const [latestIpfsData, setLatestIpfsData] = useState();
  const [showAction, setShowAction] = useState(false);
  const [authUser, setAuthUser] = useState(() => getUserSession());
  const [verification, setVerification] = useState({
    type: null,
    fieldData: null,
  });

  const [decryptType, setDecryptType] = useState();
  const [isDecrypted, setIsDecrypted] = useState(false);
  const [showDecryption, setShowDecryption] = useState(false);
  const [secretKey, setSecretKey] = useState();
  const [currentField, setCurrentField] = useState({});
  const [isPublic, setIsPublic] = useState(false);
  const [editType, setEditType] = useState("");
  const [editOpen, setEditOpen] = useState(false);
  const [showAchEdit, setShowAchEdit] = useState(false);
  const [showCustomEdit, setShowCustomEdit] = useState(false);
  const [openContact, setContactOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [uniqueNFTsTotalToken, setUniqueNFTsTotalToken] = useState(0);
  const [limitedSFTsTotalToken, setLimitedSFTsTotalToken] = useState(0);

  // TODO: Want to do a alternative way without getting the latestIpfsData
  // Get the latestIpfsData
  const fetchLatestProfileData = async () => {
    if (currentUser?.latestIpfsUrl?.ipfsUrl) {
      const hash = urlToHash(currentUser?.latestIpfsUrl?.ipfsUrl);
      try {
        const data = await GetIPFSData(hash);
        setLatestIpfsData(data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchTokensData(
      currentUser,
      setUniqueNFTsTotalToken,
      setLimitedSFTsTotalToken
    );
    fetchLatestProfileData();
  }, []);

  // TODO: Can be generalized into a util function
  // Decrypting data
  const decryptData = async (secretKey) => {
    setSecretKey(secretKey);
    // Decrypting all the data
    if (decryptType === "all") {
      Object.entries(latestIpfsData).forEach((e) => {
        const [key, value] = e;
        if (value.isPublic === false) {
          value.data.data = symmetricDecryption(value.data.data, secretKey);
          value.data.encryptionType = "PUBLIC";
          value.isPublic = true;
        }
        if (value.isPublic === true) {
          value.data.encryptionType = "PUBLIC";
        }

        if (key === "techStack") {
          for (let i = 0; i < value.length; i++) {
            if (value[i].isPublic === false) {
              value[i].isPublic = true;
              value[i].data.data = symmetricDecryption(
                value[i].data.data,
                secretKey
              );
              value[i].data.encryptionType = "PUBLIC";
            }
          }
        }

        if (key !== "techStack") {
          for (let i = 0; i < value.length; i++) {
            if (value[i].isPublic === false) {
              value[i].isPublic = true;
              Object.entries(value[i].data).forEach((e) => {
                const [key, value] = e;
                value.data = symmetricDecryption(value.data, secretKey);
                value.encryptionType = "PUBLIC";
              });
            }
            if (value[i].isPublic === true) {
              value[i].isPublic = true;
              Object.entries(value[i].data).forEach((e) => {
                const [key, value] = e;
                value.encryptionType = "PUBLIC";
              });
            }
          }
        }
      });
      store.dispatch({
        type: ADD_IPFS_DATA,
        payload: latestIpfsData,
      });
      setIsDecrypted(true);
    } else {
      // Decrypting single data
      if (typeof currentField?.id === "string") {
        latestIpfsData[currentField.id].data.data = symmetricDecryption(
          latestIpfsData[currentField.id].data.data,
          secretKey
        );
        latestIpfsData[currentField.id].isPublic = true;
      }
      // Decrypting data of arrays
      if (typeof currentField?.id === "number") {
        Object.entries(
          latestIpfsData[currentField.name][currentField.id].data
        ).forEach((e) => {
          const [key, value] = e;
          value.data = symmetricDecryption(value.data, secretKey);
        });
        latestIpfsData[currentField.name][currentField.id].isPublic = true;
      }
    }
    setShowDecryption(false);
    switch (editType) {
      case "basicEdit":
        return setEditOpen(true);
      case "achEdit":
        return setShowAchEdit(true);
      case "customEdit":
        return setShowCustomEdit(true);
      case "contact":
        return setContactOpen(true);
      case "shareOpen":
        return setShareOpen(true);
      default:
        return null;
    }
  };

  // TODO: Can be generalized into a util function
  // Encrypting data
  const encryptData = async (data) => {
    // Encrypting all the data
    if (decryptType === "all") {
      setDecryptType();
      setIsDecrypted(false);
      fetchLatestProfileData();
      store.dispatch({
        type: ADD_IPFS_DATA,
        payload: latestIpfsData,
      });
    } else {
      // Encrypting single data
      if (typeof data?.id === "string") {
        latestIpfsData[data.id].data.data = symmetricEncryption(
          latestIpfsData[data.id].data.data,
          secretKey
        );
        latestIpfsData[data.id].isPublic = false;
      }
      // Encrypting data of arrays
      if (typeof data?.id === "number") {
        Object.entries(latestIpfsData[data.name][data.id].data).forEach((e) => {
          const [key, value] = e;
          value.data = symmetricEncryption(value.data, secretKey);
        });
        latestIpfsData[data.name][data.id].isPublic = false;
      }
    }
  };

  const handleContactClose = () => {
    setContactOpen(false);
  };

  return (
    <Fragment>
      {showAction && (
        <RequestVerification
          verification={verification}
          currentField={currentField}
          show={showAction}
          setShowAction={setShowAction}
        />
      )}
      {showDecryption && (
        <ActionConfirm
          warningMessage={"Confirm your credentials to decrypt"}
          setShowAction={setShowDecryption}
          authTypeInput={authUser.authType}
          publicKey={authUser.publicKey}
          encryptedSecret={authUser.encryptedSecret}
          //use the prop below to get the secret key
          onSecretKey={decryptData}
        />
      )}
      {editOpen && (
        <UserProfileEdit
          profileData={latestIpfsData}
          setEditOpen={setEditOpen}
          secretKey={secretKey}
        />
      )}

      {showAchEdit && <EditForm setOverLay={setShowAchEdit} type={3} />}
      {showCustomEdit && <EditForm setOverLay={setShowCustomEdit} type={4} />}
      {shareOpen && (
        <ShareConfirm
          data={latestIpfsData}
          message={"Copy Profile Link"}
          setShowAction={setShareOpen}
          currentUser={currentUser}
          title={"Share your profile"}
          link={`http://explorer.deprofile.io/profile/${currentUser.publicKey}`}
        />
      )}
      <Modal
        open={openContact}
        onClose={handleContactClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ContactInfo
          profileData={latestIpfsData}
          setShowAction={setShowAction}
          setVerification={setVerification}
          setShowDecryption={setShowDecryption}
          setShowEncryption={encryptData}
          setCurrentField={setCurrentField}
        />
      </Modal>
      <Container>
        <ConfigureTokenContainer>
          <ViewButton
            to={{
              pathname: `/dashboard/view-tokens`,
            }}
          >
            {" "}
            Configure Profile Token
          </ViewButton>
        </ConfigureTokenContainer>
        <UserProfileHeader
          profileData={latestIpfsData}
          setVerification={setVerification}
          setShowAction={setShowAction}
          isPublic={isPublic}
          setIsPublic={setIsPublic}
          setShowDecryption={setShowDecryption}
          setCurrentField={setCurrentField}
          setShowEncryption={encryptData}
          decryptType={setDecryptType}
          isDecrypted={isDecrypted}
          setEditOpen={setEditOpen}
          setEditType={setEditType}
          setShareOpen={setShareOpen}
          setContactOpen={setContactOpen}
          uniqueNFTsTotalToken={uniqueNFTsTotalToken}
          limitedSFTsTotalToken={limitedSFTsTotalToken}
        />
        <SectionContainer>
          <SectionInnerContainer>
            <FlexSpaceBetweenContainer>
              <SubHeading primary>About</SubHeading>{" "}
              <VerifiedStatusContainer
                isPublic={isPublic}
                fieldData={latestIpfsData?.bio}
                fieldName="Bio"
                fieldId={{ id: "bio" }}
                setShowAction={setShowAction}
                setVerification={setVerification}
                setShowDecryption={setShowDecryption}
                setShowEncryption={encryptData}
                isDecrypted={latestIpfsData?.bio?.isPublic}
                encryptedType={latestIpfsData?.bio?.data?.encryptionType}
                setCurrentField={setCurrentField}
              />
            </FlexSpaceBetweenContainer>
            {latestIpfsData?.bio?.isPublic ? (
              <Text primary lighter>
                {latestIpfsData?.bio?.data?.data}
              </Text>
            ) : (
              <EncryptedData
                fieldData={latestIpfsData?.bio?.data?.data}
                setShowDecryption={setShowDecryption}
              />
            )}
          </SectionInnerContainer>
        </SectionContainer>

        {/*AchievementSection */}
        <AchievementSection
          isDecrypted={isDecrypted}
          decryptType={setDecryptType}
          isPublic={isPublic}
          profileData={latestIpfsData}
          setShowAction={setShowAction}
          setVerification={setVerification}
          setShowDecryption={setShowDecryption}
          setShowEncryption={encryptData}
          setCurrentField={setCurrentField}
          setShowAchEdit={setShowAchEdit}
          setEditType={setEditType}
        />
        <AdditionalDetails
          isDecrypted={isDecrypted}
          decryptType={setDecryptType}
          isPublic={isPublic}
          profileData={latestIpfsData}
          setShowAction={setShowAction}
          setVerification={setVerification}
          setShowDecryption={setShowDecryption}
          setShowEncryption={encryptData}
          setCurrentField={setCurrentField}
          setShowCustomEdit={setShowCustomEdit}
          setEditType={setEditType}
        />
      </Container>
    </Fragment>
  );
};

export default EntityProfile;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.noGap ? "0" : "1rem")};
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  padding: 1rem;
  @media (max-width: 1368px) {
    padding: 0.4rem;
  }
`;

export const SectionInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
`;

export const FlexSpaceBetweenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Box = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 2px;
  background-color: ${(props) => props.color};
`;
export const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
