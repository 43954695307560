import React, { useState, useEffect, Fragment } from "react";
import { Box, Dialog } from "@mui/material";
import styled from "styled-components";
import RegisterComponent from "../CreateProfileSignUp/RegisterComponent";
import { CustomButton } from "../../components/shared/fields/Button/CustomButton";
import * as API from "../../api/API";
import { SubHeading } from "../../components/shared/Typography/Typo";
import RequestVerificationActionConfirm from "../../components/shared/Profile/RequestVerificationActionConfirm";

const steps = ["Niftron Register", "Choose Profile Type", "Create Profile"];

const CreateProfile = ({
  show,
  setShowAction,
  isWorkFlow,
  currentWorkflow,
}) => {
  const [authUser, setAuthUser] = useState(() => API.getUserSession());
  const [activeStep, setActiveStep] = useState(0);
  const [showActionConfirm, setShowActionConfirm] = useState(() =>
    localStorage.secretKey ? 1 : 0
  );
  const [secretKey, setSecretKey] = useState(null);
  const [userData, setUserData] = useState({
    alias: "",
    publicKey: "",
    secretKey: "",
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleCloseOverlay = () => {
    setShowAction(false);
  };

  return (
    <Fragment>
      <OverLayContainer onClick={handleCloseOverlay} />
      {(() => {
        // If current field contains validated data
        if (showActionConfirm === 0) {
          return (
            <RequestVerificationActionConfirm
              warningMessage={"Confirm your credentials to decrypt"}
              setShowAction={setShowActionConfirm}
              authTypeInput={authUser.authType}
              publicKey={authUser.publicKey}
              encryptedSecret={authUser.encryptedSecret}
              onSecretKey={setSecretKey}
            />
          );
        } else if (showActionConfirm === 1) {
          return (
            <Dialog
              open={show}
              onClose={handleCloseOverlay}
              scroll={"paper"}
              maxWidth="sm"
              fullWidth
            >
              <RegisterComponent
                createProductNextStep={handleNext}
                setUserData={setUserData}
                userData={userData}
                isWorkFlow={isWorkFlow}
                currentWorkflow={currentWorkflow}
                secretKey={
                  localStorage.secretKey ? localStorage.secretKey : secretKey
                }
              />
            </Dialog>
          );
        }
      })()}
    </Fragment>
  );
};

export default CreateProfile;

const OverLayContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 50%;
  z-index: 999;
  right: 50%;
  transform: translate(50%, -50%);
  background: #0c0b5584;
`;
