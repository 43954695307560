import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { useHistory } from "react-router-dom";
import { TextField } from '@mui/material';
import { ImageFlexContainer, LargeImage } from '../ViewCV/Field';
import { urlToHash } from '../../utils/ipfs';
import { ImageWrapper } from '../JobListings/JobComponent';
import styled from 'styled-components';
import { Text } from '../../components/shared/Typography/Typo';

function calculate_age(dob) {
    var diff_ms = Date.now() - dob.getTime();
    var age_dt = new Date(diff_ms);

    return Math.abs(age_dt.getUTCFullYear() - 1970);
}

function CustomerDialog(props) {
    const { onClose, open, data, productsList, handleClickOpen } = props;
    const history = useHistory();

    const handleClose = () => {
        onClose();
    };
    const [products, setProducts] = React.useState([]);

    const handleListItemClick = (value) => {
        onClose(value);
    };

    React.useEffect(() => {

        let array = data.productPublicKey.split(",");
        // console.log(array)
        setProducts(array)

        // console.log()
    }, []);
    return (
        <>
            <OverLayContainer onClick={handleClose} />

            {/* <Dialog onClose={handleClose} open={open}> */}
            <Container>
                <Text primary>
                    {data?.userAlias}
                </Text>
                <ImageContainer>
                    <Image
                        src={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(
                            data?.userImage
                        )}`}
                        alt="image"
                    />
                </ImageContainer>
                <Text primary>
                    Products
                </Text>
                {/* <Text small lighter primary>
                    {data?.userAlias}

                </Text> */}
                {products.map(pro => (
                    <Chip label={productsList[pro]?.label} onClick={() => {
                        history.push({
                            pathname: `/products/${pro}`,
                        });
                    }} />
                ))}
                <TextField></TextField>
                <Button
                    style={{
                        background: "#1fc36157",
                        color: "#080036",
                        textTransform: "inherit",
                        fontWeight: "500",
                        fontFamily: "Poppins",
                    }}
                    // disabled
                    onClick={handleClose}
                    size="large"
                >
                    Send
                </Button>

            </Container>
            {/* </Dialog> */}
        </>

    );
}

CustomerDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired
};

export default CustomerDialog;


const Container = styled.div`
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  width: 25rem;
  z-index: 999;
  height: 35rem;
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const OverLayContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 50%;
  z-index: 999;
  right: 50%;
  transform: translate(50%, -50%);
  background: #0c0b5584;
`;

export const FlexContainer = styled.div`
  width: 100%;
  padding: ${(props) => (props.addPadding ? "0.5rem 0rem" : "0rem")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem, 0rem;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SmallText = styled.p`
  font-size: 0.7rem;
  color: ${(props) => (props.primary ? "#080036" : props.color)};
  font-weight: ${(props) => (props.lighter ? "400" : "bold")};
`;

export const ColumnLoadContainer = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: center;
  height: 21rem;
  align-items: center;
`;

export const Gif = styled.img`
  margin-top: 5rem;
  width: 16rem;
  height: auto;
`;

const ImageContainer = styled.div`
  overflow: hidden;
  width: 100%;
  height: 45%;
  overflow: hidden;
  border-radius: 5px;
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  position: absolute;
  height: auto;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
`;

const IconContainer = styled.a`
  position: relative;
  width: 2rem;
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 0.5rem;
  height: 2rem;
  background: ${(props) => props.bg};
  border-radius: 50%;
`;

const Icon = styled.img`
  width: 100%;
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
  height: auto;
`;

const FlexContainerAlignCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;
  width: 100%;
  padding: 1rem 0rem 1.5rem 0rem;
`;



                // {/* <DialogTitle>{data?.name}</DialogTitle> */}
                // <Box sx={{ width: '100%', maxWidth: "700px", minWidth: "300px", bgcolor: 'background.paper' }}>
                //     <Box sx={{ my: 3, mx: 2 }}>
                //         <Grid container alignItems="center">
                //             <Grid item xs>
                //                 <Typography gutterBottom variant="h4" component="div">
                //                     {data?.userAlias}
                //                 </Typography>
                //             </Grid>
                //             {/* <Grid item style={{ "margin-left": "50px" }}>
                //             <Typography gutterBottom variant="h6" component="div">
                //                 {calculate_age(new Date(data?.dob))}
                //             </Typography>
                //         </Grid> */}
                //         </Grid>
                //         {/* <Typography color="text.secondary" variant="body2">
                //         NIC: {data?.profileDetails?.nic}
                //     </Typography> */}

                //         {/* <img
                //             src={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(
                //                 data?.userImage
                //             )}`} width={"100px"}
                //         // onClick={() => {
                //         //     handleImageOpen();
                //         // }}
                //         /> */}
                //         {/* <ImageFlexContainer>
                //         <div style={{ position: "relative" }}>
                //             <LargeImage
                //                 src={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(
                //                     data?.userImage
                //                 )}`}
                //                 alt="image"
                //             />
                //         </div>
                //     </ImageFlexContainer> */}
                //     </Box>

                //     <Divider variant="middle" />
                //     <Box sx={{ m: 2 }}>
                //         <Typography gutterBottom variant="body1">
                //             Products
                //         </Typography>
                //         <Stack direction="row" spacing={1}>
                //             {products.map(pro => (
                //                 <Chip label={productsList[pro]?.label} onClick={() => {
                //                     history.push({
                //                         pathname: `/products/${pro}`,
                //                     });
                //                 }} />
                //             ))}
                //             {/* <Chip color="primary" label="Soft" />
                //         <Chip label="Medium" />
                //         <Chip label="Hard" /> */}
                //         </Stack>
                //     </Box>
                //     <Box sx={{ mt: 3, ml: 1, mb: 1 }}>
                //         <TextField></TextField>
                //         <Button
                //             style={{
                //                 background: "#1fc36157",
                //                 color: "#080036",
                //                 textTransform: "inherit",
                //                 fontWeight: "500",
                //                 fontFamily: "Poppins",
                //             }}
                //             // disabled
                //             onClick={handleClose}
                //             size="large"
                //         >
                //             Chat
                //         </Button>
                //         {/* <Button>Send Promotion</Button> */}
                //     </Box>
                // </Box>