import styled from "styled-components";
import Logo from "../../assets/images/DePro.png";
import { Heading, Text } from "../../components/shared/Typography/Typo";
import useMediaQuery from "@mui/material/useMediaQuery";
import { LoginCover } from "./LoginCover";

export const LoginBanner = () => {
  const matches = useMediaQuery("(max-width:1024px)");
  return (
    <Container>
      <TextContainer>
        <LogoContainer src={Logo} alt="Logo" />
        {!matches && <div style={{ paddingLeft: "1.5rem" }}>
          <Heading primary lighter>
            Build Organic Trust Instantly
          </Heading>
          <Text primary lighter>
          DePro allows users to profile individuals, entities, and products using collective authentic information through blockchain to build verified profiles in a decentralized form.



          </Text>
        </div>}

      </TextContainer>
      {!matches && <LoginCover />}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 94vh;
  @media (max-width: 1024px) {
    height: auto;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LogoContainer = styled.img`
  width: 13.5rem;
  margin: 1.5rem 1rem 0rem 0.5rem;
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
  height: auto;
`;
