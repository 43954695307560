import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import Web3ResumeContainer from "../../components/shared/Form/Web3ResumeContainer";
import IndividualFormContainer from "../../components/shared/Form/IndividualFormContainer";

//Icons
import ShoppingIcon from "../../assets/images/shopping-cart.png";
import { ButtonContainer } from "../../components/shared/DashboardComponents/ButtonContainer";
import { useSelector } from "react-redux";
import { fetchLatestProfileData } from "../../utils/FetchLatestData";
import InnerLoadingView from "../../components/shared/loading/InnerLoadingView";
import UserProfile from "../../components/shared/Profile/UserProfile";
import { Content } from "../Settings/Settings";
import { SubHeading, Text } from "../../components/shared/Typography/Typo";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import UserProfileSelect from "../../components/shared/Profile/UserProfileSelect";
import { IconButton } from "@mui/material";
import ProductFormContainer from "../../components/shared/Form/ProductFormContainer";
import ProductProfile from "../../components/shared/ProductProfile/ProductProfile";
import EntityProfileForm from "../../components/shared/Form/EntityProfileForm";
import EntityProfile from "../../components/shared/EntityProfile/EntityProfile";
import DocumentFormContainer from "../../components/shared/Form/DocumentForm";
import DocumentProfile from "../../components/shared/DocumentProfile/DocumentProfile";
import IndividualProfile from "../../components/shared/IndividualProfile/IndividualProfile";
import CreateWorkFlow from "../../components/shared/Workflow/CreateWorkFlow";
import CustomProfile from "../../components/shared/CustomProfile/CustomProfile";

const AddProfile = (props) => {
  const [view, setView] = useState(0);
  const [loading, setLoading] = useState();
  const [profileType, setProfileType] = useState(null);
  const currentUser = useSelector((state) => state.currentUser);
  const credentialProviderToken = localStorage.getItem("providerToken");
  useEffect(() => {
    // !props.isCreateProduct &&
    if (currentUser.profileType === 0) {
      setProfileType(currentUser.profileType);
      currentUser?.isProfileCreated === true ? setView(2) : setView(1);
    } else {
      fetchLatestProfileData(setLoading, setView, currentUser);
    }
  }, [currentUser, view]);

  useEffect(() => {
    if (profileType !== null && currentUser.profileType !== 0) {
      setView(1);
    }
  }, [profileType]);

  // useEffect(() => {
  //   if (!credentialProviderToken) {
  //     setProfileType(1);
  //   }
  // }, [credentialProviderToken]);
  return (
    <Container>
      {(() => {
        if (loading) {
          return <InnerLoadingView />;
        } else if (view === 2) {
          return (
            <Fragment>
              {(() => {
                switch (currentUser?.profileType) {
                  case 0:
                    return <CustomProfile />;
                  case 1:
                    return <UserProfile />;
                  case 2:
                    return <EntityProfile />;
                  case 3:
                    return <ProductProfile />;
                  case 4:
                    return <DocumentProfile />;
                  case 5:
                    return <IndividualProfile />;
                  default:
                    return null;
                }
              })()}
            </Fragment>
          );
        } else if (view === 0) {
          if (currentUser.profileType) {
            setProfileType(currentUser.profileType);
            setView(1);
          } else {
            setProfileType(1);
            setView(1);
            // return (
            //   <>
            //     <ButtonContainer
            //       heading="Chose a Profile Type"
            //       description="Select a profile type"
            //       icon={"3p"}
            //     />
            //     <InnerContainer>
            //       <Field>
            //         <Content>
            //           <SubHeading primary>
            //             Please select Profile type
            //           </SubHeading>
            //         </Content>
            //         <IconWrapper disabled={profileType === null}>
            //           <IconButton
            //             disabled={profileType === null}
            //             onClick={() => {
            //               setView(1);
            //             }}
            //           >
            //             <ArrowForwardIosIcon
            //               style={{ color: "#1e1b3b" }}
            //               sx={{ fontSize: 20 }}
            //             />
            //           </IconButton>
            //         </IconWrapper>
            //       </Field>
            //       <UserProfileSelect
            //         setProfileType={setProfileType}
            //         profileType={profileType}
            //         {...props}
            //       />
            //     </InnerContainer>
            //   </>
            // );
          }
        } else {
          return (
            <Fragment>
              {(() => {
                switch (profileType) {
                  case 0:
                    return (
                      <Fragment>
                        <CreateWorkFlow
                          view={view}
                          setView={setView}
                          profileType={profileType}
                        />
                      </Fragment>
                    );
                  case 1:
                    return (
                      <Fragment>
                        <ButtonContainer
                          heading="User Profile"
                          description="Add, remove or edit your personal details here"
                          icon="badge"
                        />
                        <Web3ResumeContainer
                          view={view}
                          setView={setView}
                          profileType={profileType}
                          {...props}
                        />
                      </Fragment>
                    );
                  case 2:
                    return (
                      <Fragment>
                        <ButtonContainer
                          heading="Entity Profile"
                          description="Add, remove or edit your entity details here"
                          icon="portrait"
                        />
                        <EntityProfileForm
                          view={view}
                          setView={setView}
                          profileType={profileType}
                          {...props}
                        />
                      </Fragment>
                    );
                  case 3:
                    return (
                      <Fragment>
                        <ButtonContainer
                          heading="Product Profile"
                          description="Add, remove or edit your product details here"
                          icon="inventory_2"
                        />
                        <ProductFormContainer
                          view={view}
                          setView={setView}
                          profileType={profileType}
                          {...props}
                        />
                      </Fragment>
                    );
                  case 4:
                    return (
                      <Fragment>
                        <ButtonContainer
                          heading="Documents"
                          description="Add, remove or edit your Document details here"
                          icon="article"
                        />
                        <DocumentFormContainer
                          view={view}
                          setView={setView}
                          profileType={profileType}
                          {...props}
                        />
                      </Fragment>
                    );
                  case 5:
                    return (
                      <Fragment>
                        <ButtonContainer
                          heading="Individual Profile"
                          description="Add, remove or edit your personal details here"
                          icon="badge"
                        />
                        <IndividualFormContainer
                          view={view}
                          setView={setView}
                          profileType={profileType}
                          {...props}
                        />
                      </Fragment>
                    );
                  default:
                    return null;
                }
              })()}
            </Fragment>
          );
        }
      })()}
    </Container>
  );
};

export default AddProfile;

export const Container = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.5rem 1.5rem 1rem 1.5rem;
  gap: ${(props) => (props.noGap ? "0" : "1rem")};
  background-color: #eff2f5;
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
`;

export const IconWrapper = styled.div`
  background-color: ${(props) => (props.disabled ? "#cecece" : "#cecece")};
  border-radius: 50%;
`;

export const Field = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  background-color: #eff2f5;
  justify-content: space-between;
`;
