import { Button, Modal } from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { ImageWrapper, Td, Tr } from "./TableRow";
import { ImageFlexContainer, LargeImage } from "../../../pages/ViewCV/Field";
import { urlToHash } from "../../../utils/ipfs";
import { format } from 'timeago.js';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomerDialog from "../../../pages/Customers/CustomerDialog";

function calculate_age(dob) {
  var diff_ms = Date.now() - dob.getTime();
  var age_dt = new Date(diff_ms);

  return Math.abs(age_dt.getUTCFullYear() - 1970);
}
const CustomerRow = ({ item, productsList }) => {
  const history = useHistory();
  const [openImage, setImageOpen] = useState(false);
  const handleImageOpen = () => setImageOpen(true);

  const handleImageClose = () => {
    setImageOpen(false);
  };

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  console.log("new push");

  return (
    <>

      <Tr
        customerRow
      // onClick={() => {
      //   history.push({
      //     pathname: `/dashboard/issue-credentials/${item.userPublicKey}`,
      //     state: { item: item },
      //   });
      // }}
      >
        <Td>{item?.userAlias}</Td>
        {/* <Td>{item?.userPublicKey}</Td> */}
        {/* <Td>{item?.userPublicKey}</Td> */}

        <Td>{item?.country}</Td>
        {/* <Td>
          <Modal
            open={openImage}
            onClose={handleImageClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <ImageFlexContainer>
              <div style={{ position: "relative" }}>
                <LargeImage
                  src={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(
                    item?.userImage
                  )}`}
                  alt="image"
                />
              </div>
            </ImageFlexContainer>
          </Modal>
          <ImageWrapper
            image={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(
              item?.userImage
            )}`}
            onClick={() => {
              handleImageOpen();
            }}
          />
        </Td> */}
        <Td>{productsList[item.productPublicKey]?.label}</Td>

        {/* <Td>{calculate_age(new Date(item?.dob))}</Td> */}
        <Td>{format(item?.createdAt)}</Td>
        <Td>  
          <Button
          style={{
            background: "#1fc36157",
            color: "#080036",
            textTransform: "inherit",
            fontWeight: "500",
            fontFamily: "Poppins",
          }}
          // disabled
          size="small" onClick={handleClickOpen}
        >
          View Details
        </Button>

          {/* <Button variant="outlined" onClick={handleClickOpen}>
            Open simple dialog
          </Button> */}
          {open &&
            <CustomerDialog
              data={item}
              open={open}
              onClose={handleClose}
              productsList={productsList}
              handleClickOpen={handleClickOpen}
            />
          }


          {/* 
          <Button
            style={{
              background: "#1fc36157",
              color: "#080036",
              textTransform: "inherit",
              fontWeight: "500",
              fontFamily: "Poppins",
            }}
            // disabled
            size="small"
          >
            Send Promo
          </Button> */}
          {/* {item?.isClaimed ? (
          <Button
            style={{
              background: "#1fc36157",
              color: "#080036",
              textTransform: "inherit",
              fontWeight: "500",
              fontFamily: "Poppins",
            }}
            disabled
            size="small"
          >
            True
          </Button>
        ) : (
          <Button
            style={{
              background: "#ff623f7a",
              color: "#080036",
              textTransform: "inherit",
              fontWeight: "500",
              fontFamily: "Poppins",
            }}
            size="small"
            disabled
          >
            False
          </Button>
        )} */}
        </Td>

      </Tr>

    </>
  );
};

export default CustomerRow;
