import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { urlToHash } from "../../../utils/ipfs";
import { GetIPFSData } from "../../../services/IpfsService";
import { RequestVerification } from "../Profile/RequestVerification";
import { SubHeading, Text } from "../Typography/Typo";
import WorkSection from "../Profile/WorkSection";
import IndividualProfileHeader, {
  SectionContainer,
} from "./IndividualProfileHeader";
import VerifiedStatusContainer from "../Profile/VerifiedStatusContainer";
import EducationSection from "../Profile/EducationSection";
import AchievementSection from "../Profile/AchievementSection";
import AdditionalDetails from "../Profile/AdditionalDetails";
import EncryptedData from "../Profile/EncryptedData";
import ActionConfirm from "../Form/FormComponents/ActionConfirm";
import { getUserSession } from "../../../api/API";
import {
  symmetricDecryption,
  symmetricEncryption,
} from "../../../utils/encryption";
import store from "../../../redux/reducers";
import { ADD_IPFS_DATA } from "../../../redux/constants/ActionTypes";
import UserProfileEdit from "../EditForms/UserProfileEdit";
import EditForm from "../EditForms/EditForm";
import { ShareConfirm } from "../Profile/ShareConfirm";
import { Modal } from "@mui/material";
import ContactInfo from "../Profile/ContactInfo";
import { TextField } from "../Form/FormComponents/TextField";
import { fetchTokensData } from "../../../utils/FetchTokens";
const countryListAlpha2 = {
  AF: "Afghanistan",
  AL: "Albania",
  DZ: "Algeria",
  AS: "American Samoa",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarctica",
  AG: "Antigua and Barbuda",
  AR: "Argentina",
  AM: "Armenia",
  AW: "Aruba",
  AU: "Australia",
  AT: "Austria",
  AZ: "Azerbaijan",
  BS: "Bahamas (the)",
  BH: "Bahrain",
  BD: "Bangladesh",
  BB: "Barbados",
  BY: "Belarus",
  BE: "Belgium",
  BZ: "Belize",
  BJ: "Benin",
  BM: "Bermuda",
  BT: "Bhutan",
  BO: "Bolivia (Plurinational State of)",
  BQ: "Bonaire, Sint Eustatius and Saba",
  BA: "Bosnia and Herzegovina",
  BW: "Botswana",
  BV: "Bouvet Island",
  BR: "Brazil",
  IO: "British Indian Ocean Territory (the)",
  BN: "Brunei Darussalam",
  BG: "Bulgaria",
  BF: "Burkina Faso",
  BI: "Burundi",
  CV: "Cabo Verde",
  KH: "Cambodia",
  CM: "Cameroon",
  CA: "Canada",
  KY: "Cayman Islands (the)",
  CF: "Central African Republic (the)",
  TD: "Chad",
  CL: "Chile",
  CN: "China",
  CX: "Christmas Island",
  CC: "Cocos (Keeling) Islands (the)",
  CO: "Colombia",
  KM: "Comoros (the)",
  CD: "Congo (the Democratic Republic of the)",
  CG: "Congo (the)",
  CK: "Cook Islands (the)",
  CR: "Costa Rica",
  HR: "Croatia",
  CU: "Cuba",
  CW: "Curaçao",
  CY: "Cyprus",
  CZ: "Czechia",
  CI: "Côte d'Ivoire",
  DK: "Denmark",
  DJ: "Djibouti",
  DM: "Dominica",
  DO: "Dominican Republic (the)",
  EC: "Ecuador",
  EG: "Egypt",
  SV: "El Salvador",
  GQ: "Equatorial Guinea",
  ER: "Eritrea",
  EE: "Estonia",
  SZ: "Eswatini",
  ET: "Ethiopia",
  FK: "Falkland Islands (the) [Malvinas]",
  FO: "Faroe Islands (the)",
  FJ: "Fiji",
  FI: "Finland",
  FR: "France",
  GF: "French Guiana",
  PF: "French Polynesia",
  TF: "French Southern Territories (the)",
  GA: "Gabon",
  GM: "Gambia (the)",
  GE: "Georgia",
  DE: "Germany",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Greece",
  GL: "Greenland",
  GD: "Grenada",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernsey",
  GN: "Guinea",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HT: "Haiti",
  HM: "Heard Island and McDonald Islands",
  VA: "Holy See (the)",
  HN: "Honduras",
  HK: "Hong Kong",
  HU: "Hungary",
  IS: "Iceland",
  IN: "India",
  ID: "Indonesia",
  IR: "Iran (Islamic Republic of)",
  IQ: "Iraq",
  IE: "Ireland",
  IM: "Isle of Man",
  IL: "Israel",
  IT: "Italy",
  JM: "Jamaica",
  JP: "Japan",
  JE: "Jersey",
  JO: "Jordan",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KI: "Kiribati",
  KP: "Korea (the Democratic People's Republic of)",
  KR: "Korea (the Republic of)",
  KW: "Kuwait",
  KG: "Kyrgyzstan",
  LA: "Lao People's Democratic Republic (the)",
  LV: "Latvia",
  LB: "Lebanon",
  LS: "Lesotho",
  LR: "Liberia",
  LY: "Libya",
  LI: "Liechtenstein",
  LT: "Lithuania",
  LU: "Luxembourg",
  MO: "Macao",
  MG: "Madagascar",
  MW: "Malawi",
  MY: "Malaysia",
  MV: "Maldives",
  ML: "Mali",
  MT: "Malta",
  MH: "Marshall Islands (the)",
  MQ: "Martinique",
  MR: "Mauritania",
  MU: "Mauritius",
  YT: "Mayotte",
  MX: "Mexico",
  FM: "Micronesia (Federated States of)",
  MD: "Moldova (the Republic of)",
  MC: "Monaco",
  MN: "Mongolia",
  ME: "Montenegro",
  MS: "Montserrat",
  MA: "Morocco",
  MZ: "Mozambique",
  MM: "Myanmar",
  NA: "Namibia",
  NR: "Nauru",
  NP: "Nepal",
  NL: "Netherlands (the)",
  NC: "New Caledonia",
  NZ: "New Zealand",
  NI: "Nicaragua",
  NE: "Niger (the)",
  NG: "Nigeria",
  NU: "Niue",
  NF: "Norfolk Island",
  MP: "Northern Mariana Islands (the)",
  NO: "Norway",
  OM: "Oman",
  PK: "Pakistan",
  PW: "Palau",
  PS: "Palestine, State of",
  PA: "Panama",
  PG: "Papua New Guinea",
  PY: "Paraguay",
  PE: "Peru",
  PH: "Philippines (the)",
  PN: "Pitcairn",
  PL: "Poland",
  PT: "Portugal",
  PR: "Puerto Rico",
  QA: "Qatar",
  MK: "Republic of North Macedonia",
  RO: "Romania",
  RU: "Russian Federation (the)",
  RW: "Rwanda",
  RE: "Réunion",
  BL: "Saint Barthélemy",
  SH: "Saint Helena, Ascension and Tristan da Cunha",
  KN: "Saint Kitts and Nevis",
  LC: "Saint Lucia",
  MF: "Saint Martin (French part)",
  PM: "Saint Pierre and Miquelon",
  VC: "Saint Vincent and the Grenadines",
  WS: "Samoa",
  SM: "San Marino",
  ST: "Sao Tome and Principe",
  SA: "Saudi Arabia",
  SN: "Senegal",
  RS: "Serbia",
  SC: "Seychelles",
  SL: "Sierra Leone",
  SG: "Singapore",
  SX: "Sint Maarten (Dutch part)",
  SK: "Slovakia",
  SI: "Slovenia",
  SB: "Solomon Islands",
  SO: "Somalia",
  ZA: "South Africa",
  GS: "South Georgia and the South Sandwich Islands",
  SS: "South Sudan",
  ES: "Spain",
  LK: "Sri Lanka",
  SD: "Sudan (the)",
  SR: "Suriname",
  SJ: "Svalbard and Jan Mayen",
  SE: "Sweden",
  CH: "Switzerland",
  SY: "Syrian Arab Republic",
  TW: "Taiwan",
  TJ: "Tajikistan",
  TZ: "Tanzania, United Republic of",
  TH: "Thailand",
  TL: "Timor-Leste",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad and Tobago",
  TN: "Tunisia",
  TR: "Turkey",
  TM: "Turkmenistan",
  TC: "Turks and Caicos Islands (the)",
  TV: "Tuvalu",
  UG: "Uganda",
  UA: "Ukraine",
  AE: "United Arab Emirates (the)",
  GB: "United Kingdom of Great Britain and Northern Ireland (the)",
  UM: "United States Minor Outlying Islands (the)",
  US: "United States of America (the)",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  VU: "Vanuatu",
  VE: "Venezuela (Bolivarian Republic of)",
  VN: "Viet Nam",
  VG: "Virgin Islands (British)",
  VI: "Virgin Islands (U.S.)",
  WF: "Wallis and Futuna",
  EH: "Western Sahara",
  YE: "Yemen",
  ZM: "Zambia",
  ZW: "Zimbabwe",
  AX: "Åland Islands",
};
const UserProfile = () => {
  const currentUser = useSelector((state) => state.currentUser);
  const [latestIpfsData, setLatestIpfsData] = useState();
  const [showAction, setShowAction] = useState(false);
  const [authUser, setAuthUser] = useState(() => getUserSession());
  const [verification, setVerification] = useState({
    type: null,
    fieldData: null,
  });

  const [decryptType, setDecryptType] = useState();
  const [isDecrypted, setIsDecrypted] = useState(false);
  const [showDecryption, setShowDecryption] = useState(false);
  const [secretKey, setSecretKey] = useState();
  const [currentField, setCurrentField] = useState({});
  const [isPublic, setIsPublic] = useState(false);
  const [editType, setEditType] = useState("");

  const [editOpen, setEditOpen] = useState(false);
  const [showWorkEdit, setShowWorkEdit] = useState(false);
  const [showEduEdit, setShowEduEdit] = useState(false);
  const [showAchEdit, setShowAchEdit] = useState(false);
  const [showCustomEdit, setShowCustomEdit] = useState(false);
  const [openContact, setContactOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [uniqueNFTsTotalToken, setUniqueNFTsTotalToken] = useState(0);
  const [limitedSFTsTotalToken, setLimitedSFTsTotalToken] = useState(0);

  // TODO: Want to do a alternative way without getting the latestIpfsData
  // Get the latestIpfsData
  const fetchLatestProfileData = async () => {
    if (currentUser?.latestIpfsUrl?.ipfsUrl) {
      const hash = urlToHash(currentUser?.latestIpfsUrl?.ipfsUrl);
      try {
        const data = await GetIPFSData(hash);
        setLatestIpfsData(data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchTokensData(
      currentUser,
      setUniqueNFTsTotalToken,
      setLimitedSFTsTotalToken
    );
    fetchLatestProfileData();
  }, []);

  // TODO: Can be generalized into a util function
  // Decrypting data
  const decryptData = async (secretKey) => {
    setSecretKey(secretKey);
    // Decrypting all the data
    if (decryptType === "all") {
      Object.entries(latestIpfsData).forEach((e) => {
        const [key, value] = e;
        if (value.isPublic === false) {
          value.data.data = symmetricDecryption(value.data.data, secretKey);
          value.data.encryptionType = "PUBLIC";
          value.isPublic = true;
        }
        if (value.isPublic === true) {
          value.data.encryptionType = "PUBLIC";
        }

        if (key === "techStack") {
          for (let i = 0; i < value.length; i++) {
            if (value[i].isPublic === false) {
              value[i].isPublic = true;
              value[i].data.data = symmetricDecryption(
                value[i].data.data,
                secretKey
              );
              value[i].data.encryptionType = "PUBLIC";
            }
          }
        }

        if (key !== "techStack") {
          for (let i = 0; i < value.length; i++) {
            if (value[i].isPublic === false) {
              value[i].isPublic = true;
              Object.entries(value[i].data).forEach((e) => {
                const [key, value] = e;
                value.data = symmetricDecryption(value.data, secretKey);
                value.encryptionType = "PUBLIC";
              });
            }
            if (value[i].isPublic === true) {
              value[i].isPublic = true;
              Object.entries(value[i].data).forEach((e) => {
                const [key, value] = e;
                value.encryptionType = "PUBLIC";
              });
            }
          }
        }
      });
      store.dispatch({
        type: ADD_IPFS_DATA,
        payload: latestIpfsData,
      });
      setIsDecrypted(true);
    } else {
      // Decrypting single data
      if (typeof currentField?.id === "string") {
        latestIpfsData[currentField.id].data.data = symmetricDecryption(
          latestIpfsData[currentField.id].data.data,
          secretKey
        );
        latestIpfsData[currentField.id].isPublic = true;
      }
      // Decrypting data of arrays
      if (typeof currentField?.id === "number") {
        Object.entries(
          latestIpfsData[currentField.name][currentField.id].data
        ).forEach((e) => {
          const [key, value] = e;
          value.data = symmetricDecryption(value.data, secretKey);
        });
        latestIpfsData[currentField.name][currentField.id].isPublic = true;
      }
    }
    setShowDecryption(false);
    switch (editType) {
      case "basicEdit":
        return setEditOpen(true);
      case "workEdit":
        return setShowWorkEdit(true);
      case "eduEdit":
        return setShowEduEdit(true);
      case "achEdit":
        return setShowAchEdit(true);
      case "customEdit":
        return setShowCustomEdit(true);
      case "contact":
        return setContactOpen(true);
      case "shareOpen":
        return setShareOpen(true);
      default:
        return null;
    }
  };

  // TODO: Can be generalized into a util function
  // Encrypting data
  const encryptData = async (data) => {
    // Encrypting all the data
    if (decryptType === "all") {
      setDecryptType();
      setIsDecrypted(false);
      fetchLatestProfileData();
      store.dispatch({
        type: ADD_IPFS_DATA,
        payload: latestIpfsData,
      });
    } else {
      // Encrypting single data
      if (typeof data?.id === "string") {
        latestIpfsData[data.id].data.data = symmetricEncryption(
          latestIpfsData[data.id].data.data,
          secretKey
        );
        latestIpfsData[data.id].isPublic = false;
      }
      // Encrypting data of arrays
      if (typeof data?.id === "number") {
        Object.entries(latestIpfsData[data.name][data.id].data).forEach((e) => {
          const [key, value] = e;
          value.data = symmetricEncryption(value.data, secretKey);
        });
        latestIpfsData[data.name][data.id].isPublic = false;
      }
    }
  };

  const handleContactClose = () => {
    setContactOpen(false);
  };

  return (
    <Fragment>
      {showAction && (
        <RequestVerification
          verification={verification}
          currentField={currentField}
          show={showAction}
          setShowAction={setShowAction}
        />
      )}
      {showDecryption && (
        <ActionConfirm
          warningMessage={"Confirm your credentials to decrypt"}
          setShowAction={setShowDecryption}
          authTypeInput={authUser.authType}
          publicKey={authUser.publicKey}
          encryptedSecret={authUser.encryptedSecret}
          //use the prop below to get the secret key
          onSecretKey={decryptData}
        />
      )}
      {editOpen && (
        <UserProfileEdit
          profileData={latestIpfsData}
          setEditOpen={setEditOpen}
          secretKey={secretKey}
        />
      )}
      {showWorkEdit && <EditForm setOverLay={setShowWorkEdit} type={2} />}
      {showEduEdit && <EditForm setOverLay={setShowEduEdit} type={1} />}
      {showAchEdit && <EditForm setOverLay={setShowAchEdit} type={3} />}
      {showCustomEdit && <EditForm setOverLay={setShowCustomEdit} type={4} />}
      {shareOpen && (
        <ShareConfirm
          data={latestIpfsData}
          message={"Copy Profile Link"}
          setShowAction={setShareOpen}
          currentUser={currentUser}
          title={"Share your profile"}
          link={`http://explorer.deprofile.io/profile/${currentUser.publicKey}`}
        />
      )}
      <Modal
        open={openContact}
        onClose={handleContactClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ContactInfo
          profileData={latestIpfsData}
          setShowAction={setShowAction}
          setVerification={setVerification}
          setShowDecryption={setShowDecryption}
          setShowEncryption={encryptData}
          setCurrentField={setCurrentField}
        />
      </Modal>
      <Container>
        <IndividualProfileHeader
          countryListAlpha2={countryListAlpha2}
          profileData={latestIpfsData}
          setVerification={setVerification}
          setShowAction={setShowAction}
          isPublic={isPublic}
          setIsPublic={setIsPublic}
          setShowDecryption={setShowDecryption}
          setCurrentField={setCurrentField}
          setShowEncryption={encryptData}
          decryptType={setDecryptType}
          isDecrypted={isDecrypted}
          setEditOpen={setEditOpen}
          setEditType={setEditType}
          setShareOpen={setShareOpen}
          setContactOpen={setContactOpen}
          uniqueNFTsTotalToken={uniqueNFTsTotalToken}
          limitedSFTsTotalToken={limitedSFTsTotalToken}
        />
        <SectionContainer>
          <SectionInnerContainer>
            <FlexSpaceBetweenContainer>
              <TextField
                disabled
                label={"First Name"}
                value={latestIpfsData?.firstName?.data?.data}
              />
            </FlexSpaceBetweenContainer>
            <FlexSpaceBetweenContainer>
              <TextField
                disabled
                label={"Last Name"}
                value={latestIpfsData?.lastName?.data?.data}
              />
            </FlexSpaceBetweenContainer>
            <FlexSpaceBetweenContainer>
              <TextField
                disabled
                label={"Passport/ID"}
                value={latestIpfsData?.id?.data?.data}
              />
            </FlexSpaceBetweenContainer>
            <FlexSpaceBetweenContainer>
              <TextField
                disabled
                label={"Date of Birth"}
                value={latestIpfsData?.dob?.data?.data}
              />
            </FlexSpaceBetweenContainer>
            <FlexSpaceBetweenContainer>
              <TextField
                disabled
                label={"Country"}
                value={countryListAlpha2[latestIpfsData?.country?.data?.data]}
              />
            </FlexSpaceBetweenContainer>
          </SectionInnerContainer>
        </SectionContainer>
        {/* <WorkSection
          isPublic={isPublic}
          isDecrypted={isDecrypted}
          decryptType={setDecryptType}
          profileData={latestIpfsData}
          setShowAction={setShowAction}
          setVerification={setVerification}
          setShowDecryption={setShowDecryption}
          setShowEncryption={encryptData}
          setCurrentField={setCurrentField}
          setShowWorkEdit={setShowWorkEdit}
          setEditType={setEditType}
        />
        <EducationSection
          isPublic={isPublic}
          isDecrypted={isDecrypted}
          decryptType={setDecryptType}
          profileData={latestIpfsData}
          setShowAction={setShowAction}
          setVerification={setVerification}
          setShowDecryption={setShowDecryption}
          setShowEncryption={encryptData}
          setCurrentField={setCurrentField}
          setShowEduEdit={setShowEduEdit}
          setEditType={setEditType}
        />
        <AchievementSection
          isDecrypted={isDecrypted}
          decryptType={setDecryptType}
          isPublic={isPublic}
          profileData={latestIpfsData}
          setShowAction={setShowAction}
          setVerification={setVerification}
          setShowDecryption={setShowDecryption}
          setShowEncryption={encryptData}
          setCurrentField={setCurrentField}
          setShowAchEdit={setShowAchEdit}
          setEditType={setEditType}
        /> */}
        <AdditionalDetails
          isDecrypted={isDecrypted}
          decryptType={setDecryptType}
          isPublic={isPublic}
          profileData={latestIpfsData}
          setShowAction={setShowAction}
          setVerification={setVerification}
          setShowDecryption={setShowDecryption}
          setShowEncryption={encryptData}
          setCurrentField={setCurrentField}
          setShowCustomEdit={setShowCustomEdit}
          setEditType={setEditType}
        />
      </Container>
    </Fragment>
  );
};

export default UserProfile;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.noGap ? "0" : "1rem")};
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  padding: 1rem;
  @media (max-width: 1368px) {
    padding: 0.4rem;
  }
`;

export const SectionInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
`;

export const FlexSpaceBetweenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Box = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 2px;
  background-color: ${(props) => props.color};
`;
export const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
