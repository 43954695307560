import React, { Fragment, useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { getObject, retrieveAssignedProducts } from "../../api/API";
import { urlToHash } from "../../utils/ipfs";
import { GetIPFSData } from "../../services/IpfsService";
import InnerLoadingView from "../../components/shared/loading/InnerLoadingView";
import ProviderViewProfile from "./../CreateProfileSignUp/ProviderViewProfile";
import ProviderViewProduct from "../CreateProfileSignUp/ProviderViewProduct";
import ProviderViewEntity from "./../CreateProfileSignUp/ProviderViewEntity";
import ProviderViewDocument from "./../CreateProfileSignUp/ProviderViewDocument";
import ProviderViewIndividual from "../CreateProfileSignUp/ProviderViewIndividual";

const ViewProfile = () => {
  const { id } = useParams();

  const currentUser = useSelector((state) => state.currentUser);
  const [latestIpfsData, setLatestIpfsData] = useState();
  const [isPublic, setIsPublic] = useState(false);
  const [isDecrypted, setIsDecrypted] = useState(false);
  const [showDecryption, setShowDecryption] = useState(false);
  const [ownerShipDetails, setOwnerShipDetails] = useState("");
  const [productPageLoading, setProductPageLoading] = useState(false);
  const [ownershipLoading, setOwnerShipLoading] = useState(false);
  const [verification, setVerification] = useState({
    type: null,
    fieldData: null,
  });

  const fetchIpfsData = async (ipfsUrl) => {
    const hash = urlToHash(ipfsUrl);
    try {
      const data = await GetIPFSData(hash);
      setLatestIpfsData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProduct = async () => {
    setProductPageLoading(true);
    try {
      const response = await getObject({
        aId: id,
      });
      if (response !== undefined) {
        fetchIpfsData(response?.data?.data[0].latestIpfsUrl.ipfsUrl);
        fetchOwnerShipDetails();
      }
    } catch (e) {
      console.log(e);
    } finally {
      setProductPageLoading(false);
    }
  };

  const fetchOwnerShipDetails = async () => {
    setOwnerShipLoading(true);
    try {
      const response = await retrieveAssignedProducts({
        path: {
          vId: id,
        },
        query: {
          type: "assigned",
        },
      });

      if (response?.data?.code === 200) {
        setOwnerShipDetails(response?.data?.data[0]);
      } else if (response === undefined) {
        setOwnerShipDetails("No one");
      }
    } catch (e) {
      console.log(e);
    } finally {
      setOwnerShipLoading(false);
    }
  };

  useEffect(() => {
    fetchProduct();
  }, []);

  return (
    <>
      {(() => {
        if (productPageLoading) {
          return <InnerLoadingView />;
        } else if (latestIpfsData && !productPageLoading) {
          switch (latestIpfsData && latestIpfsData?.profileType?.data?.data) {
            case "1":
              return (
                <ProviderViewProfile
                  latestIpfsData={latestIpfsData}
                  ownerShipDetails={ownerShipDetails}
                />
              );
            case "2":
              return (
                <ProviderViewEntity
                  latestIpfsData={latestIpfsData}
                  ownerShipDetails={ownerShipDetails}
                />
              );
            case "3":
              return (
                <ProviderViewProduct
                  latestIpfsData={latestIpfsData}
                  ownerShipDetails={ownerShipDetails}
                />
              );
            case "4":
              return (
                <ProviderViewDocument
                  latestIpfsData={latestIpfsData}
                  ownerShipDetails={ownerShipDetails}
                />
              );
            case "5":
              return (
                <ProviderViewIndividual
                  latestIpfsData={latestIpfsData}
                  ownerShipDetails={ownerShipDetails}
                />
              );
            default:
              return null;
          }
        }
      })()}
    </>
  );
};

export default ViewProfile;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.noGap ? "0" : "1rem")};
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  padding: 1rem;
  @media (max-width: 1368px) {
    padding: 0.4rem;
  }
`;

export const SectionInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
`;

export const FlexSpaceBetweenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Box = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 2px;
  background-color: ${(props) => props.color};
`;
export const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 1.5rem 1.5rem 3rem 1.5rem;
`;

export const Link = styled.a`
  color: #1e1b3b;
  max-width: 600px;
  word-break: break-word;
  font-weight: 500;
  @media (max-width: 1368px) {
    max-width: 500px;
  }
  @media (max-width: 768px) {
    max-width: 200px;
  }
`;

const GridContainer = styled.div`
  width: 100%;
  gap: 1rem;
  margin: 1rem 0rem;
  display: flex;
  flex-direction: column;
`;
