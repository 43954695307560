import Divider from "@mui/material/Divider";
import React, { useState } from "react";
import styled from "styled-components";
import {
  LinearProgress,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import { withStyles } from "@mui/styles";

import { Text } from "../../components/shared/Typography/Typo";
import { LoginIcon } from "../../components/shared/fields/Button/LoginIcon";
import { LoadingContainer } from "../Login/LoginForm";
import { CustomButton } from "../../components/shared/fields/Button/CustomButton";
import Search from "../../components/shared/fields/Search/Search";
import image from "../../assets/images/text logo.png";
import {
  Image,
  ImageContainer,
  ImageWrapper,
} from "../../components/shared/fields/Search/SearchRow";
import { useStateValue } from "../../context/StateProvider";
import * as API from "../../api/API";
import { TextField } from "../../components/shared/Form/FormComponents/TextField";

//Progress button styles
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 4,
    borderRadius: 4,
    width: "100%",
  },
  colorPrimary: {
    backgroundColor: "transparent",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#80B7FF",
    animationDuration: "550ms",
  },
}))(LinearProgress);

export var evmValidation = /^0x[a-fA-F0-9]{40}$/;

export const IssueTokenModal = ({
  setShowIssueModel,
  tokenData,
  setShowAction,
  currentBalance,
  setStateTokenLimit,
  setStateEvmAddress,
}) => {
  //eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  //eslint-disable-next-line
  const [submitting, setSubmitting] = useState(false);
  const [{ searchData }, dispatch] = useStateValue();
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchError, setSearchError] = useState(false);
  const [data, setSearchData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [tokenLimit, setTokenLimit] = useState(1);
  const [radioValue, setRadioValue] = useState("search");
  const [evmAddress, setEvmAddress] = useState("");
  const [evmAddressError, setEvmAddressError] = useState(false);
  const [currentBalanceError, setCurrentBalanceError] = useState(false);

  const max = 20;

  const handleInputChange = (value) => {
    setSearchInput(value);
    SearchMatch(value);
  };

  // Search credential provider
  const SearchMatch = async (value) => {
    setSearchLoading(true);
    try {
      const userData = await API.searchUser({
        query: {
          key: value,
          type: "PROFILE",
        },
      });
      setSearchLoading(false);
      if (userData) {
        setSearchError(false);
        setSearchData(userData.data.data);
      } else if (userData === undefined) {
        setSearchError(true);
      }
    } catch (error) {
      throw new Error();
    } finally {
      setSearchLoading(false);
    }
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      let receiverEvmAddress;
      if (radioValue === "search") {
        try {
          const res = await API.getNiftronUser({
            path: {
              id: searchData.publicKey,
            },
          });
          if (res?.data?.code === 200) {
            if (res.data.data.accounts.length > 2) {
              receiverEvmAddress = res.data.data.accounts[2].publicKey;
            }
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        receiverEvmAddress = evmAddress;
      }

      setStateEvmAddress(receiverEvmAddress);
      setStateTokenLimit(tokenLimit);

      deleteAuthority();
    } catch (error) {
      console.log(error);
    } finally {
      setShowAction(true);
      setShowIssueModel(false);
      setSubmitting(false);
    }
  };

  const deleteAuthority = async () => {
    if (Object.keys(searchData).length > 0) {
      try {
        dispatch({
          type: "REMOVE_AUTHORITY",
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <OverLayContainer
        onClick={() => {
          deleteAuthority();
          setShowIssueModel(false);
        }}
      />
      <Container>
        <FlexContainer>
          <Text primary>{`Token Name : ${tokenData.tokenName}`}</Text>
        </FlexContainer>
        <Divider
          style={{
            background: "#36353511",
            width: "100%",
            margin: "0.5rem 0rem",
          }}
        />
        <FlexContainer addPadding>
          <ColumnContainer>
            <Text primary lighter small>
              Please Select an Receiver
            </Text>
          </ColumnContainer>
          <LoginIcon large />
        </FlexContainer>
        <Divider
          style={{
            width: "100%",
            marginBottom: "0.5rem",
          }}
        />
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="search"
            name="radio-buttons-group"
          >
            <FormControlLabel
              value="search"
              control={<Radio />}
              label="Search User"
              onClick={() => {
                setRadioValue("search");
                setEvmAddress("");
              }}
            />
            <Search
              loading={searchLoading}
              error={searchError}
              reducer="AUTHORITY"
              style={{ width: "100%" }}
              placeholder="Search for an receiver"
              searchValue={searchInput}
              onSearchChange={handleInputChange}
              data={data}
              withSelect
              disabled={radioValue === "input"}
            />
            {searchData?.alias && (
              <FlexContainer style={{ width: "100%", marginTop: "1rem" }}>
                <FlexContainerSearch>
                  <ImageContainer>
                    <ImageWrapper>
                      <Image src={image} alt="logo" />
                    </ImageWrapper>
                    <Text lighter small color="#394673">
                      {searchData?.alias}
                    </Text>
                  </ImageContainer>
                </FlexContainerSearch>
              </FlexContainer>
            )}
            &nbsp;
            <FormControlLabel
              value="input"
              control={<Radio />}
              label="Enter EVM Address"
              onClick={() => {
                deleteAuthority();
                setRadioValue("input");
              }}
            />
            <TextField
              placeholder="Enter receiver EVM address"
              value={evmAddress}
              // onChange={setEvmAddress}
              id="evmAddress"
              name="evmAddress"
              type="text"
              onChange={(e) => {
                if (e !== "" && !evmValidation.test(e)) {
                  setEvmAddressError(true);
                } else {
                  setEvmAddressError(false);
                }
                setEvmAddress(e);
              }}
              color="#FF613F"
              helper={evmAddressError && "Invalid EVM Address"}
            />
          </RadioGroup>
        </FormControl>

        <TextField
          label="Token Limit"
          placeholder="Token Limit"
          id="token-limit"
          name="token-limit"
          required
          value={tokenLimit}
          // onChange={currentBalance && setTokenLimit}
          type="number"
          onChange={(e) => {
            if (currentBalance) {
              if (e <= 0 || e > currentBalance) {
                setCurrentBalanceError(true);
              } else {
                setCurrentBalanceError(false);
              }
              setTokenLimit(e);
            }
          }}
          color="#FF613F"
          helper={currentBalanceError && "Can't exceed the current balance"}
          style={{ marginTop: "2rem", marginBottom: "2rem" }}
        />
        {submitting ? (
          <LoadingContainer>
            <BorderLinearProgress />
            <Text primary small lighter style={{ width: "80px" }}>
              Verifying <span className="loading"></span>
            </Text>
          </LoadingContainer>
        ) : (
          <CustomButton
            wide
            login
            disabled={
              (Object.keys(searchData).length === 0 && evmAddress === "") ||
              evmAddressError ||
              currentBalanceError
            }
            onclick={handleSubmit}
            text="Issue Token"
          />
        )}
      </Container>
    </>
  );
};

const Container = styled.div`
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  width: 25rem;
  z-index: 999;
  min-height: 32rem;
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  // align-items: center;
  box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const OverLayContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 50%;
  z-index: 999;
  right: 50%;
  transform: translate(50%, -50%);
  background: #0c0b5584;
`;

const FlexContainer = styled.div`
  width: 100%;
  padding: ${(props) => (props.addPadding ? "0.5rem 0rem" : "0rem")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem, 0rem;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SmallText = styled.p`
  font-size: 0.7rem;
  color: ${(props) => (props.primary ? "#080036" : props.color)};
  font-weight: ${(props) => (props.lighter ? "400" : "bold")};
`;

export const ColumnLoadContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  height: 20rem;
  align-items: center;
`;

export const Gif = styled.img`
  margin-top: 5rem;
  width: 16rem;
  height: auto;
`;

const FlexContainerSearch = styled.div`
  text-align: center;
  width: 100%;
  background: #eaeaf3;
  align-items: center;
  border-radius: 5px;
  display: flex;
  cursor: pointer;
  padding: 0.5rem 1rem;
  gap: 1rem;
`;
