import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import { IconButton, Tooltip } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import Web3ResumeContainer from "../../components/shared/Form/Web3ResumeContainer";
import IndividualFormContainer from "../../components/shared/Form/IndividualFormContainer";
import { fetchLatestProfileData } from "../../utils/FetchLatestData";
import { ButtonContainer } from "../../components/shared/DashboardComponents/ButtonContainer";
import TemplateCard from "../../components/shared/Templates/TemplateCard";
import * as API from "../../api/API";
import InnerLoadingView from "../../components/shared/loading/InnerLoadingView";
import CreateTemplate from "../../components/shared/Templates/CreateTemplate";

const Template = (props) => {
  const [templateType, setTemplateType] = useState();
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [templateData, setTemplateData] = useState();

  const fetchTemplates = async () => {
    setLoading(true);
    try {
      const response = await API.retrieveTemplates();
      if (response?.data?.code === 200) {
        setTemplates(response?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchTemplates();
  }, []);
  return (
    <Container>
      {(() => {
        if (!templateData) {
          return (
            <>
              <ButtonContainer
                span
                biglink
                heading="Templates"
                description="Select a template and create a workflow"
                icon={"view_quilt"}
              />

              <InnerContainer>
                {loading ? (
                  <InnerLoadingView style={{ margin: "auto" }} />
                ) : (
                  <Box sx={{ width: "100%" }}>
                    <Grid
                      container
                      spacing={{ xs: 2, md: 3 }}
                      columns={{ xs: 4, sm: 8, md: 12 }}
                    >
                      {templates.map((item) => {
                        return (
                          <Grid item xs={4}>
                            <TemplateCard
                              image="https://res.cloudinary.com/monday-blogs/fl_lossy,f_auto,q_auto/wp-blog/2020/12/workflow-diagram-2-1.jpg"
                              name={item.templateName}
                              id={item?._id}
                              file={item?.file}
                              setTemplateData={setTemplateData}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                )}
              </InnerContainer>
            </>
          );
        } else {
          return (
            <Fragment>
              {/* {(() => {
                switch (templateType) {
                  case 1:
                    return <Fragment>{ <CreateWorkFlow /> }</Fragment>;
                  default:
                    return null;
                }
              })()} */}
              <CreateTemplate templateData={templateData} />
            </Fragment>
          );
        }
      })()}
    </Container>
  );
};

export default Template;

const InnerContainer = styled.div`
  display: flex;
  border-radius: 10px;
  border: 0.75px solid #d3d3d3b0;
  gap: 1rem;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 1rem;
  min-height: 30vh;
`;

export const Container = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
`;
