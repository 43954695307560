import React, { useState, Fragment, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { NIFTRON } from "niftron-client-sdk";
import { IconButton } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { ButtonContainer } from "../../components/shared/DashboardComponents/ButtonContainer";
import { SubHeading, Text } from "../../components/shared/Typography/Typo";
import { CustomButton } from "../../components/shared/fields/Button/CustomButton";
import { IconWrapper } from "../AddProfile/AddProfile";
import InnerLoadingView from "../../components/shared/loading/InnerLoadingView";
// import UniqueNFTs from "../../components/shared/Form/TokensForm/UniqueNFTs";
import ViewProductList from "./../CredentialProviderProduct/ViewProductList";
import ViewTokenList from "./ViewTokenList";

const Tokens = () => {
  return (
    <Container>
      <ButtonContainer
        heading="View All Your Tokens"
        description={"View your tokens here"}
        icon={"token"}
      />
      <ViewTokenList />
    </Container>
  );
};

export default Tokens;

export const InnerContainer = styled.div`
  display: flex;
  border-radius: 10px;
  border: 0.75px solid #d3d3d3b0;
  gap: 1rem;
  flex-direction: column;
  padding: 1.5rem;
  margin-bottom: 1rem;
  min-height: 55vh;
`;

const Container = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
`;

export const Field = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  // background-color: #eff2f5;
  justify-content: space-between;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentWithPadding = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 0rem;
`;
