import React, { useEffect, useState } from "react";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { Button, Tooltip, TextField, InputAdornment } from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { symmetricDecryption } from "../../../utils/encryption";
import * as API from "../../../api/API";
import ViewCredentialsAction from "./ViewCredentialsAction";
import ViewCredentialUsingSecret from "./ViewCredentialUsingSecret";

const CryptingText = ({ encryptedText, label, name }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [text, setText] = useState(encryptedText);
  const [isEncrypted, setIsEncrypted] = useState(true);
  const [authUser, setAuthUser] = useState(() => API.getUserSession());
  const [authCreator, setAuthCreator] = useState(() => API.getCreatorSession());
  const [open, setOpen] = React.useState(false);
  const [decryptedText, setDecryptedText] = useState("");
  const [loading, setLoading] = useState(false);

  const decrypt = (secret) => {
    setLoading(true);
    let decryptedValue;
    if (!localStorage.getItem("providerToken") && name === "deproSecretKey") {
      decryptedValue = secret;
    } else {
      decryptedValue = symmetricDecryption(encryptedText, secret);
    }
    setText(decryptedValue);
    setDecryptedText(decryptedValue);
    setIsEncrypted(false);
    setLoading(false);
    setOpen((value) => !value);
  };

  const copyMessage = (val) => {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
  };
  return (
    <>
      {open && name === "deproSecretKey" && (
        <ViewCredentialsAction
          successMessage={{
            message: "Decrypting Data",
            width: "16rem",
          }}
          warningMessage={"Add creator secret Key to decrypt key"}
          loading={loading}
          setShowAction={setOpen}
          authTypeInput={
            localStorage.getItem("providerToken")
              ? authCreator.authType
              : authUser.authType
          }
          publicKey={
            localStorage.getItem("providerToken")
              ? authCreator.publicKey
              : authUser.publicKey
          }
          encryptedSecret={
            localStorage.getItem("providerToken")
              ? authCreator.encryptedSecret
              : authUser.encryptedSecret
          }
          onSecretKey={decrypt}
        />
      )}

      {open && name === "evmSecretKey" && (
        <ViewCredentialUsingSecret
          successMessage={{
            message: "Decrypting Data",
            width: "16rem",
          }}
          warningMessage={"Add dePro profile secret Key to decrypt key"}
          loading={loading}
          setShowAction={setOpen}
          authTypeInput={authUser.authType}
          publicKey={authUser.publicKey}
          encryptedSecret={authUser.encryptedSecret}
          onSecretKey={decrypt}
        />
      )}

      <TextField
        fullWidth
        label={label + ` (${isEncrypted ? "ENCRYPTED" : "DECRYPTED"})`}
        margin="normal"
        name={name}
        value={isEncrypted ? encryptedText : decryptedText}
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {isEncrypted && (
                <Tooltip title="Decrypt" placement="top">
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      setOpen(true);
                    }}
                  >
                    {" "}
                    <VpnKeyIcon
                      sx={{
                        color: "#404b6e",
                      }}
                    />
                  </Button>
                </Tooltip>
              )}
              {!isEncrypted && (
                <Tooltip title="Copy" placement="top">
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      copyMessage(decryptedText);
                      enqueueSnackbar(`${label} is copied to clipboard`, {
                        variant: "info",
                      });
                    }}
                  >
                    {" "}
                    <FileCopyIcon
                      sx={{
                        color: "#404b6e",
                      }}
                    />
                  </Button>
                </Tooltip>
              )}
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};
CryptingText.propTypes = {
  // children: PropTypes.node.isRequired,
  encryptedText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
};
export default CryptingText;
