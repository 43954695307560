import React from "react";
import styled from "styled-components";
import CircleIcon from "@mui/icons-material/Circle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Text } from "../Typography/Typo";
import moment from "moment";
import { useSnackbar } from "notistack";
import { FlexSpaceBetweenContainer } from "../ProductProfile/ProductProfile";
import { IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { AccessButton } from "../../../pages/CredentialProviderProduct/ProfileListCard";

const IpfsEntityCard = ({
  status,
  setCvHistoryIpfsUrl,
  item,
  index,
  setShowAction,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const copyToClipBoard = async (val) => {
    try {
      await navigator.clipboard.writeText(val);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Container>
      <Content>
        <Text primary>{index === 0 ? "Creator Alias" : "Editor Alias"}</Text>
        <Text primary>{item?.editorAlias}</Text>
        <Text primary>
          {index === 0 ? "Creator Public Key" : "Editor Public Key"}
        </Text>

        <FlexSpaceBetweenContainer>
          <Span primary>
            {item?.editorPublicKey?.toString()?.substring(0, 20 - 3) + "..."}
          </Span>
        </FlexSpaceBetweenContainer>
        <Text primary>Switch Data</Text>
        <AccessButton
          onClick={() => {
            setCvHistoryIpfsUrl(item.ipfsHash);
            enqueueSnackbar(
              `Switched to ${moment(item?.createdAt).format("LLL")}`,
              {
                variant: "success",
              }
            );
            setShowAction(false);
          }}
        >
          {" "}
          View Profile
        </AccessButton>
      </Content>
    </Container>
  );
};

export default IpfsEntityCard;

const Container = styled.div`
  display: flex;
  gap: 1rem;
  // margin-left: 2rem;
  margin: 0.7rem 2rem;
`;

const Content = styled.div`
  display: grid;
  gap: 0.5rem;
  width: 100%;
  grid-template-columns: 2fr 3fr;
`;

const SmallImageWrapper = styled.div`
  overflow: hidden;
  width: 3.5rem;
  height: 3.5rem;
  min-height: 3.5rem;
  min-width: 3.5rem;
  background-color: #fff;
  position: relative;
  border-radius: 2px;
`;

const ProfileImage = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
`;

export const FlexContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const FlexIconContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const Span = styled.span`
  font-weight: 500;
  max-width: 400px;
  word-break: break-word;
  color: #6d6d6d;
  cursor: ${(props) => (props.cursor ? "pointer" : "unset")};

  @media (max-width: 1368px) {
    max-width: 500px;
  }
  @media (max-width: 768px) {
    max-width: 200px;
    font-size: 14px;
  }
`;
