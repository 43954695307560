import React, { useRef, useState } from "react";
import styled from "styled-components";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Tooltip from "@mui/material/Tooltip";
import { AnchorTag, SubHeading, Text } from "../../Typography/Typo";
import { Button, CircularProgress, IconButton } from "@mui/material";
import { Gif } from "../FormComponents/ActionConfirm";
import DecryptGif from "../../../../assets/images/decrypt.gif";
import { SectionContainer } from "../ProviderProfile/ProviderProfileHeader";
import DOMPurify from "dompurify";
import { Preview } from "../../../../pages/JobListings/JobComponent";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FlexSpaceBetweenContainer } from "./../../DocumentProfile/DocumentProfile";
import { Box } from "./SliderComponent";
import MultipleDocumentSlider from "./MultipleDocumentSlider";

const DocumentComponent = ({
  first,
  item,
  setCurrentField,
  data,
  fieldId,
  setShowDecryption,
  setShowEncryption,
  isDecrypted,
  encryptedType,
  isPublic,
  style,
  setShowAction,
  setVerification,
  fieldName,
  status,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  const positionRef = useRef({
    x: 0,
    y: 0,
  });

  const popperRef = useRef(null);
  const areaRef = useRef(null);

  const handleActionOpen = () => {
    setShowAction(true);
  };

  const handleMouseMove = (event) => {
    positionRef.current = { x: event.clientX, y: event.clientY };

    if (popperRef.current != null) {
      popperRef.current.update();
    }
  };

  var html = item?.stageDescription?.data || item?.stageDescription;
  var div = document.createElement("p");
  div.innerHTML = html;

  var dataListfieldKeys = item?.customStageData?.data
    ? item?.customStageData?.data?.map((data) => {
        const keyData = Object.keys(data);
        return keyData;
      })
    : item?.customStageData?.map((data) => {
        const keyData = Object.keys(data);
        return keyData;
      });

  var customData = item?.customStageData?.data
    ? item?.customStageData?.data?.map((data) => {
        return data;
      })
    : item?.customStageData?.map((data) => {
        return data;
      });

  if (!data?.isPublic && item?.isPublic) {
    return (
      <Container>
        <CardContainer
          style={{
            alignItems: "center",
            background: "#fff",
            overflow: "hidden",
            position: "relative",
            minHeight: "16rem",
          }}
        >
          <TextContainer>
            <Text primary>Data Encrypted!</Text>
          </TextContainer>
          <Gif
            src={DecryptGif}
            style={{
              width: "12rem",
              marginTop: "0",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-40%,-40%)",
            }}
          />
          <Button
            style={{
              background: "#1fc36157",
              color: "#080036",
              textTransform: "inherit",
              fontWeight: "500",
              fontFamily: "Poppins",
              padding: "0.3rem 1rem",
              float: "right",
            }}
            onClick={() => {
              var foo = setCurrentField;
              setCurrentField && setCurrentField(fieldId);
              setShowDecryption && setShowDecryption(true);
            }}
            size="small"
          >
            Decrypt
          </Button>
        </CardContainer>
      </Container>
    );
  } else {
    if (!first) {
      return (
        <Container>
          <CardContainer>
            <FlexSpaceBetweenContainer>
              <SubHeading primary>
                {item?.stageName?.data
                  ? item?.stageName?.data
                  : item?.stageName}
              </SubHeading>

              {/* Encryption icon */}
              {isDecrypted && encryptedType === "SYMMETRIC" ? (
                <IconButton>
                  <LockOpenIcon
                    onClick={() => {
                      var foo = setCurrentField;
                      if (typeof foo === "function") {
                        setCurrentField && setCurrentField(fieldId);
                        setShowEncryption && setShowEncryption(fieldId);
                      }
                    }}
                  />
                </IconButton>
              ) : (
                ""
              )}

              {/* Verification icon */}
              <InnerContainer>
                {(() => {
                  if (
                    data?.verifiedBy &&
                    data?.verifiedBy.find(
                      (item) => item?.verificationStatus === 0
                    ) &&
                    isPublic
                  ) {
                    return (
                      <Tooltip
                        placement="top"
                        arrow
                        title="Verification Pending"
                        PopperProps={{
                          popperRef,
                        }}
                      >
                        <StatusContainer
                          style={style}
                          status={0}
                          ref={areaRef}
                          onClick={() => {
                            handleActionOpen();
                            if (setVerification) {
                              setCurrentField && setCurrentField(fieldId);
                              setVerification({
                                type: fieldName,
                                fieldData: data,
                                isPublic: isDecrypted,
                              });
                            }
                          }}
                        >
                          <Box color="#fa7d25fc" />
                        </StatusContainer>
                      </Tooltip>
                    );
                  } else if (isPublic && data?.verifiedBy?.length === 0) {
                    return (
                      <Tooltip
                        arrow
                        placement="top"
                        title="Unverified"
                        PopperProps={{
                          popperRef,
                        }}
                      >
                        <StatusContainer
                          style={style}
                          onClick={() => {
                            handleActionOpen();
                            if (setVerification) {
                              setCurrentField && setCurrentField(fieldId);
                              setVerification({
                                type: fieldName,
                                fieldData: data,
                                isPublic: isDecrypted,
                              });
                            }
                          }}
                          status={status}
                          ref={areaRef}
                          onMouseMove={handleMouseMove}
                        >
                          <Box color="#9b9b9b" />
                        </StatusContainer>
                      </Tooltip>
                    );
                  } else if (
                    data?.verifiedBy &&
                    (data?.verifiedBy.find(
                      (item) => item?.verificationStatus === 1
                    ) ||
                      data?.verifiedBy.length !== 0) &&
                    isPublic
                  ) {
                    return (
                      <Tooltip
                        arrow
                        placement="top"
                        title="Verified"
                        PopperProps={{
                          popperRef,
                        }}
                      >
                        <StatusContainer
                          style={style}
                          onClick={() => {
                            handleActionOpen();
                            if (setVerification) {
                              setCurrentField && setCurrentField(fieldId);
                              setVerification({
                                type: fieldName,
                                fieldData: data,
                                isPublic: isDecrypted,
                              });
                            }
                          }}
                          status={status}
                          ref={areaRef}
                          onMouseMove={handleMouseMove}
                        >
                          <Box color="#59D88C" />
                        </StatusContainer>
                      </Tooltip>
                    );
                  } else if (
                    data?.verifiedBy &&
                    data?.verifiedBy.find(
                      (item) => item?.verificationStatus === 2
                    ) &&
                    isPublic
                  ) {
                    return (
                      <Tooltip
                        arrow
                        placement="top"
                        title="Unverified"
                        PopperProps={{
                          popperRef,
                        }}
                      >
                        <StatusContainer
                          style={style}
                          onClick={() => {
                            handleActionOpen();
                            if (setVerification) {
                              setCurrentField && setCurrentField(fieldId);
                              setVerification({
                                type: fieldName,
                                fieldData: data,
                                isPublic: isDecrypted,
                              });
                            }
                          }}
                          status={status}
                          ref={areaRef}
                          onMouseMove={handleMouseMove}
                        >
                          <Box color="#9b9b9b" />
                        </StatusContainer>
                      </Tooltip>
                    );
                  }
                })()}
              </InnerContainer>
            </FlexSpaceBetweenContainer>

            <SectionContainer>
              <Preview
                className="preview"
                dangerouslySetInnerHTML={createMarkup(
                  item?.stageDescription?.data || item?.stageDescription
                )}
              />
            </SectionContainer>

            <Accordion
              style={{ boxShadow: "none", margin: "0.2rem 0rem" }}
              expanded={expanded === "metaData"}
              onChange={handleChange("metaData")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="metaData-content"
                id="metaData-header"
              >
                <Text style={{ width: "33%", flexShrink: 0 }}>
                  Document Meta Data
                </Text>
              </AccordionSummary>
              <AccordionDetails>
                {dataListfieldKeys &&
                  dataListfieldKeys.length > 0 &&
                  dataListfieldKeys.map((fieldName, key) => {
                    if (fieldName[key][1] != "isPublic") {
                      return (
                        <FieldWrapper style={{ marginTop: "10px" }} key={key}>
                          <Text
                            primary
                            lighter
                            small
                            style={{
                              justifySelf: "start",
                              margin: "0px 0px 15px",
                            }}
                          >
                            {fieldName[0]
                              .toString()
                              .replace(/([A-Z])/g, " $1")
                              .substring(0, 1)
                              .toUpperCase() +
                              fieldName[0]
                                .toString()
                                .replace(/([A-Z])/g, " $1")
                                .substring(1)}
                          </Text>
                          {customData[key][fieldName[0]]
                            .toString()
                            ?.includes("https") ? (
                            <>
                              <MultipleDocumentSlider
                                data={customData[key][fieldName[0]]}
                              />
                            </>
                          ) : (
                            <Text
                              primary
                              lighter
                              small
                              style={{
                                justifySelf: "end",
                                wordBreak: "break-word",
                              }}
                            >
                              {customData[key][fieldName[0]]}
                            </Text>
                          )}
                        </FieldWrapper>
                      );
                    }
                  })}
              </AccordionDetails>
            </Accordion>
          </CardContainer>
        </Container>
      );
    } else {
      return (
        <Container>
          <CardContainer>
            <SubHeading primary>
              {item?.stageName?.data ? item?.stageName?.data : item?.stageName}
            </SubHeading>
            <SectionContainer>
              <Preview
                className="preview"
                dangerouslySetInnerHTML={createMarkup(
                  item?.stageDescription?.data || item?.stageDescription
                )}
              />
            </SectionContainer>

            <Accordion
              style={{ boxShadow: "none", margin: "0.2rem 0rem" }}
              expanded={expanded === "metaData"}
              onChange={handleChange("metaData")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="metaData-content"
                id="metaData-header"
              >
                <Text style={{ width: "33%", flexShrink: 0 }}>
                  Document Meta Data
                </Text>
              </AccordionSummary>
              <AccordionDetails>
                {dataListfieldKeys &&
                  dataListfieldKeys.length > 0 &&
                  dataListfieldKeys[0].map((fieldName, key) => {
                    if (fieldName != "isPublic") {
                      return (
                        <FieldWrapper key={key}>
                          {customData[0][fieldName]
                            .toString()
                            ?.includes("https") ? (
                            <AnchorTag
                              primary
                              lighter
                              small
                              style={{
                                justifySelf: "end",
                                wordBreak: "break-word",
                              }}
                              target="_blank"
                              href={customData[0][fieldName]}
                            >
                              View Document
                            </AnchorTag>
                          ) : (
                            <Text
                              primary
                              lighter
                              small
                              style={{
                                justifySelf: "end",
                                wordBreak: "break-word",
                              }}
                            >
                              {customData[0][fieldName]}
                            </Text>
                          )}
                        </FieldWrapper>
                      );
                    }
                  })}
              </AccordionDetails>
            </Accordion>
          </CardContainer>
        </Container>
      );
    }
  }
};

export default DocumentComponent;

const Container = styled.div`
  flex-direction: column;
  position: relative;
  display: flex;
  width: 100%;
`;

const CardContainer = styled.div`
  flex-direction: column;
  display: flex;
  z-index: 30;
  justify-content: space-between;
  background-color: #eff2f5;
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  gap: 0.5rem;
  padding: ${(props) => (props.addPadding ? "1.5rem" : "1rem")};
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.2rem;
  cursor: ${(props) => props.status !== 1 && "pointer"};
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 1rem;
`;

export const HyperLink = styled.a`
  font-size: ${(props) => (props.small ? "0.875rem" : "1.125rem")};
  color: ${(props) => (props.primary ? "#080036" : props.color)};
  font-weight: ${(props) => (props.lighter ? "400" : "bold")};
  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

export const FieldWrapper = styled.div`
  padding: ${(props) => (props.addPadding ? "1rem 1rem" : "0.2rem 1rem")};
  background-color: #c3d0e1;
  border-radius: 5px;
  grid-template-columns: 1fr 1fr;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: ${(props) => (props.span ? "1/3" : "unset")};
`;

const InnerContainer = styled.div`
  position: relative;
  display: flex;
  gap: 0.7rem;
  justify-content: center;
  align-items: center;
`;
