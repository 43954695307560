import React, { Fragment, useState } from "react";
import styled from "styled-components";
import Select from "react-select";
import { Dialog } from "@mui/material";
import { Button } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import {
  Container as DropContainer,
  Label,
  TextField,
  InputContainer,
} from "../../Form/FormComponents/TextField";
import DropzoneComponent from "../../fields/Dropzone/DropzoneComponent";
import { Text } from "../../Typography/Typo";
import { AddImageToIPFS } from "../../../../services/IpfsService";
import {
  DEPROERC20_CONTRACT_ID,
  DEPROERC1155_CONTRACT_ID,
  DEPROERC721_CONTRACT_ID,
  DEPROERC1155_CONTRACT_ID_ALFAJORES,
  DEPROERC20_CONTRACT_ID_ALFAJORES,
  DEPROERC721_CONTRACT_ID_ALFAJORES,
} from "../../../../utils/variables";
import { TextArea } from "../FormComponents/TextArea";

const customStyles = {
  control: (base) => ({
    ...base,
    border: 0,
    // This line disable the blue border
    boxShadow: "none",
    padding: "0.1rem 0.3rem",
    background: "#c3d0e1",
  }),
};

const options = [
  { value: "UNIQUENFTS", label: "Unique NFTs" },
  { value: "LIMITEDSFTS", label: "Limited SFTs" },
  { value: "NONMETADATASFTS", label: "Non-MetaData SFTs" },
];

const contractOptions = [
  { value: "DEPRO", label: "DePro Contract" },
  { value: "CUSTOM", label: "Custom Contract" },
];

const blockchainSelectOptions = [
  { value: "MUMBAI", label: "MUMBAI" },
  // { value: "STELLAR", label: "STELLAR" },
  // { value: "ETHEREUM", label: "ETHEREUM" },
  // { value: "BSC", label: "BSC" },
  //{ value: "MATIC", label: "MATIC" },
  // { value: "RINKEBY", label: "RINKEBY" },
  //{ value: "BSCTESTNET", label: "BSCTESTNET" },
  { value: "ALFAJORES", label: "CELO (TESTNET)" },
];

const DEPROERC721_ABI = require("../../../../utils/abi/DEPROERC721.json");
const DEPROERC1155_ABI = require("../../../../utils/abi/DEPROERC1155.json");
const DEPROERC20_ABI = require("../../../../utils/abi/DEPROERC20.json");

const AddTokenModal = ({
  show,
  handleClose,
  handleAddToken,
  addTokenLoading,
}) => {
  let previewImageUrl;
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState("");
  const [symbol, setSymbol] = useState("");
  const [decimal, setDecimal] = useState("");
  const [contractABI, setContractABI] = useState("");
  // const [network, setNetwork] = useState("");
  const [tokenLimit, setTokenLimit] = useState();
  const [contractAddress, setContractAddress] = useState();
  const [selectTokenType, setSelectTokenType] = useState(options[0]);
  const [selectContractType, setSelectContractType] = useState(
    contractOptions[0]
  );
  const [blockchainType, setBlockchainType] = useState(
    blockchainSelectOptions[0]
  );

  const onClose = () => {
    handleClose(false);
  };

  const handleFieldSubmit = async (e) => {
    e.preventDefault();
    if (files) {
      const ipfsData = await AddImageToIPFS(files && files[0]);
      previewImageUrl = `https://niftron.infura-ipfs.io/ipfs/${ipfsData.ipfsHash}`;
    }

    let DeproContractABI;
    let DeproContractAddress;

    if (
      selectTokenType.value === "UNIQUENFTS" &&
      selectContractType.value === "DEPRO"
    ) {
      DeproContractABI = DEPROERC721_ABI;
      if (blockchainType.value === "MUMBAI") {
        DeproContractAddress = DEPROERC721_CONTRACT_ID;
      } else if (blockchainType.value === "ALFAJORES") {
        DeproContractAddress = DEPROERC721_CONTRACT_ID_ALFAJORES;
      }
    } else if (
      selectTokenType.value === "LIMITEDSFTS" &&
      selectContractType.value === "DEPRO"
    ) {
      DeproContractABI = DEPROERC1155_ABI;
      if (blockchainType.value === "MUMBAI") {
        DeproContractAddress = DEPROERC1155_CONTRACT_ID;
      } else if (blockchainType.value === "ALFAJORES") {
        DeproContractAddress = DEPROERC1155_CONTRACT_ID_ALFAJORES;
      }
    } else if (
      selectTokenType.value === "NONMETADATASFTS" &&
      selectContractType.value === "DEPRO"
    ) {
      DeproContractABI = DEPROERC20_ABI;
      if (blockchainType.value === "MUMBAI") {
        DeproContractAddress = DEPROERC20_CONTRACT_ID;
      } else if (blockchainType.value === "ALFAJORES") {
        DeproContractAddress = DEPROERC20_CONTRACT_ID_ALFAJORES;
      }
    } else {
      DeproContractABI = JSON.parse(contractABI);
      DeproContractAddress = contractAddress;
    }

    const data =
      selectTokenType.value !== "NONMETADATASFTS"
        ? {
            description: description,
            image: previewImageUrl,
          }
        : {
            symbol: symbol,
            decimal: decimal,
          };

    data.tokenType = selectTokenType.value;
    data.tokenName = name;
    data.network = blockchainType.value;
    data.tokenLimit = selectTokenType.value === "UNIQUENFTS" ? 1 : tokenLimit;
    data.contractABI = DeproContractABI; //selectContractType.value === "DEPRO" ? DEPROERC721_ABI : contractABI;
    data.contractId = DeproContractAddress; //selectContractType.value === "DEPRO" ? DEPROERC721_CONTRACT_ID : contractAddress;
    handleAddToken(data);
    //console.log("ABI: ", contractABI);
  };

  return (
    <Dialog open={show} onClose={onClose}>
      <DialogTitle>
        <Text style={{ fontSize: "20px", fontWeight: "600" }}>
          Create Token
        </Text>
      </DialogTitle>
      <DialogContent>
        <Container
          id="addToken-form"
          onSubmit={handleFieldSubmit}
          style={{ minWidth: "400px" }}
        >
          <ColumnContainer>
            <Label style={{ marginBottom: "0.3rem" }}>Select Token Type</Label>
            <Select
              options={options}
              styles={customStyles}
              value={selectTokenType}
              onChange={setSelectTokenType}
              required
              defaultValue={options[0]}
            />
          </ColumnContainer>
          <TextField
            label="Name *"
            placeholder="Token Name"
            id="token-name"
            name="token-name"
            required
            value={name}
            onChange={setName}
            type="text"
            color="#21ca54"
            // maxLength="100"
          />
          <ColumnContainer>
            <Label style={{ marginBottom: "0.3rem" }}>Select Blockchain</Label>
            <Select
              disabled
              options={blockchainSelectOptions}
              styles={customStyles}
              value={blockchainType}
              onChange={blockchainSelectOptions[0]}
              required
              defaultValue={blockchainSelectOptions[0]}
            />
          </ColumnContainer>
          {selectTokenType.value !== "UNIQUENFTS" && (
            <TextField
              label="Token Limit"
              placeholder="Token Limit"
              id="token-limit"
              name="token-limit"
              required
              value={tokenLimit}
              onChange={setTokenLimit}
              type="number"
              color="#21ca54"
              // maxLength="100"
              // min="0"
            />
          )}
          <ColumnContainer>
            <Label style={{ marginBottom: "0.3rem" }}>
              Select Contract Type
            </Label>
            <Select
              options={contractOptions}
              styles={customStyles}
              value={selectContractType}
              onChange={setSelectContractType}
              required
              defaultValue={contractOptions[0]}
            />
          </ColumnContainer>
          {selectContractType.value === "CUSTOM" && (
            <>
              <TextField
                label="Contract Address"
                placeholder="Contract Address"
                id="token-limit"
                name="token-limit"
                required
                value={contractAddress}
                onChange={setContractAddress}
                type="text"
                color="#21ca54"
                // maxLength="100"
              />
              {/* <TextField
                label="Contract ABI *"
                placeholder="Contract ABI"
                id="token-contractId"
                name="token-contractId"
                required
                value={contractABI}
                onChange={setContractABI}
                // type="text"
                type="textarea"
                color="#21ca54"
                // maxLength="100"
              /> */}
              <TextArea
                label="Contract Address"
                placeholder="Contract Address"
                id="token-limit"
                name="token-limit"
                required
                value={contractAddress}
                onChange={setContractAddress}
                type="text"
                color="#21ca54"
              />
            </>
          )}

          {selectTokenType.value === "UNIQUENFTS" ||
          selectTokenType.value === "LIMITEDSFTS" ? (
            <>
              <TextField
                label="Description *"
                placeholder="Token Description"
                id="token-description"
                name="token-description"
                required
                value={description}
                onChange={setDescription}
                type="text"
                color="#21ca54"
                // maxLength="50"
              />
              <DropContainer style={{ gridRow: "span 2" }}>
                <InputContainer>
                  <Label>Token Image *</Label>
                </InputContainer>
                <DropzoneComponent
                  files={files}
                  id="dropzoneTwo"
                  amount="1"
                  form="none"
                  multiple={false}
                  errors={errors}
                  setErrors={setErrors}
                  maxSize="1000000"
                  setFiles={setFiles}
                  type="image/jpeg, image/png"
                  description="Drop or select your token image (Max file size 10MB)"
                />
              </DropContainer>
            </>
          ) : (
            <>
              <TextField
                label="Symbol *"
                placeholder="Token Symbol"
                id="token-symbol"
                name="token-symbol"
                required
                value={symbol}
                onChange={setSymbol}
                type="text"
                color="#21ca54"
                maxLength="10"
              />
              <TextField
                label="Decimal *"
                placeholder="Token Decimal"
                id="token-decimal"
                name="token-decimal"
                required
                value={decimal}
                onChange={setDecimal}
                type="text"
                color="#21ca54"
                maxLength="50"
              />
            </>
          )}

          <ButtonWrapper>
            <Button
              style={{
                background: "#1fc36157",
                color: "#080036",
                textTransform: "inherit",
                fontWeight: "500",
                fontFamily: "Poppins",
                padding: ".3rem 1rem",
                float: "right",
              }}
              type="submit"
              size="small"
              form="addToken-form"
              disabled={addTokenLoading}
            >
              {addTokenLoading ? "Adding Token.." : " Add Token"}
            </Button>
          </ButtonWrapper>
        </Container>
      </DialogContent>
    </Dialog>
  );
};

export default AddTokenModal;

const ButtonWrapper = styled.div`
  width: auto;
`;
const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled.form`
  background-color: #c3d0e181;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
`;
