import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Web3 from "web3";

import { useStateValue } from "../../../context/StateProvider";
import * as API from "../../../api/API";
import * as encryption from "../../../utils/encryption";
import RequestVerificationActionConfirm from "./RequestVerificationActionConfirm";
import VerificationStatusView from "../VerificationViews/VerificationStatusView";
import SubmitVerification from "../VerificationViews/SubmitVerification";
import store from "../../../redux/reducers";
import { ADD_USER_DETAILS } from "../../../redux/constants/ActionTypes";
import { urlToHash } from "../../../utils/ipfs";
import { getSignature } from "../../../utils/getSingature";

export const RequestVerification = ({
  setShowAction,
  verification,
  currentField,
  showRequestButton,
}) => {
  const history = useHistory();
  const currentUser = useSelector((state) => state.currentUser);
  const [authUser, setAuthUser] = useState(() => API.getUserSession());
  const [authCreator, setAuthCreator] = useState(() => API.getCreatorSession());
  const [creatorData, setCreatorData] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [showDecryption, setShowDecryption] = useState(() =>
    localStorage.secretKey ? 1 : 0
  );
  const [actionConfirm, setActionConfirm] = useState(0);

  const [secretKey, setSecretKey] = useState(null);
  //eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  // const [search, setSearch] = useState("");
  const [{ searchData }, dispatch] = useStateValue();
  const [data, setSearchData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  // Loading state
  const [searchLoading, setSearchLoading] = useState(false);
  const [files, setFiles] = useState({
    fileArr: null,
    loading: false,
    errors: false,
    upload: null,
  });
  //Check Values
  const [searchError, setSearchError] = useState(false);

  // Search credential provider
  const SearchMatch = async (value) => {
    setSearchLoading(true);
    try {
      const userData = await API.searchCredentialProvider({
        query: {
          key: value,
        },
      });
      setSearchLoading(false);
      if (userData) {
        setSearchError(false);
        setSearchData(userData.data.data);
      } else if (userData === undefined) {
        setSearchError(true);
      }
    } catch (error) {
      throw new Error();
    } finally {
      setSearchLoading(false);
    }
  };

  const handleInputChange = (value) => {
    setSearchInput(value);
    SearchMatch(value);
  };

  // TODO: Can be generalized into a util function
  const prepareEncryptedData = async () => {
    let dataType;
    let asymmetricEncryptedData = {};

    // Asymmetrically encrypting the data for basic details
    if (typeof currentField?.id === "string") {
      asymmetricEncryptedData = encryption.asymmetricEncryption(
        verification.fieldData.data.data,
        searchData.publicKey,
        secretKey ? secretKey : localStorage.secretKey
      );

      // setDataType(verification.fieldData.data.dataType);
      dataType = verification.fieldData.data.dataType;
    }

    // Asymmetrically encrypting the data for array
    if (typeof currentField?.id === "number") {
      if (verification.fieldData.data[verification.type]) {
        asymmetricEncryptedData = encryption.asymmetricEncryption(
          verification.fieldData.data[verification.type].data,
          searchData.publicKey,
          secretKey ? secretKey : localStorage.secretKey
        );
        // setDataType(verification.fieldData.data[verification.type].dataType);
        dataType = verification.fieldData.data[verification.type].dataType;
      } else {
        for (let [key, value] of Object.entries(verification.fieldData.data)) {
          if (key !== "customStageData") {
            asymmetricEncryptedData[key] = encryption.asymmetricEncryption(
              value.data,
              searchData.publicKey,
              secretKey ? secretKey : localStorage.secretKey
            );
          }
          if (key === "customStageData") {
            asymmetricEncryptedData.customStageData = value;
          }
        }
      }
    }

    return { asymmetricEncryptedData, dataType };
  };

  const getCreatorData = async () => {
    try {
      const response = await API.retrieveCredentialProvider({
        path: {
          id: authCreator.publicKey,
        },
      });
      if (response) {
        setCreatorData(response?.data?.data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    localStorage.getItem("providerToken") && getCreatorData();
  }, []);

  // Requesting a verification
  const handleSubmit = async () => {
    // if (files?.fileArr?.length > 0) {
    setLoading(true);
    try {
      const { blockchain, signatureObj } = await getSignature(
        currentUser,
        authUser,
        secretKey
      );

      const data = await prepareEncryptedData();
      // Requesting verification API
      const body = {
        receiverAlias: searchData.alias,
        receiverPublicKey: searchData.publicKey,
        receiverImage: searchData.image,
        profilePublicKey: currentUser.publicKey,
        senderAlias: localStorage.getItem("providerToken")
          ? creatorData.alias
          : currentUser.alias,
        senderPublicKey: localStorage.getItem("providerToken")
          ? creatorData.publicKey
          : currentUser.publicKey,
        senderImage: localStorage.getItem("providerToken")
          ? creatorData.image
          : currentUser.image,
        dataType: currentField,
        data: data.asymmetricEncryptedData,
        file: files.fileArr,
        verificationType: data?.dataType && data.dataType,
        signature: signatureObj,
        network: blockchain,
      };
      const sendVerificationResponse = await API.requestVerification({
        body,
        token: localStorage.niftoken,
        path: {
          vId: currentUser.publicKey,
        },
      });
      if (sendVerificationResponse.data.code === 201) {
        const userData = await API.me({
          token: localStorage.niftoken,
        });
        if (userData?.data?.code === 200) {
          store.dispatch({
            type: ADD_USER_DETAILS,
            payload: userData?.data?.data[0],
          });
          enqueueSnackbar("Verification has been submitted successfully!", {
            variant: "success",
          });
        }
      }
    } catch (error) {
      enqueueSnackbar("Couldn't Submit data", {
        variant: "error",
      });
    } finally {
      dispatch({
        type: "REMOVE_AUTHORITY",
      });
      setLoading(false);
      setShowAction(false);
      history.push("/dashboard/my-profile");
    }
    // }
    // else {
    //   enqueueSnackbar("Please Upload a Document", {
    //     variant: "warning",
    //   });
    // }
  };

  const decryptData = (secretKey) => {
    setSecretKey(secretKey);
  };

  //add API
  const handleCancelRequest = (secretkey) => {};

  const handleRemoveAuthority = () => {
    if (!loading || !files.loading) {
      if (Object.keys(searchData).length > 0) {
        try {
          dispatch({
            type: "REMOVE_AUTHORITY",
          });
        } catch (error) {
          console.log(error);
        }
      }
      setShowAction(false);
    }
  };

  const handleRequestVerification = () => {
    setActionConfirm(1);
  };

  return (
    <Fragment>
      <OverLayContainer onClick={handleRemoveAuthority} />
      {(() => {
        // If current field contains validated data
        if (
          (actionConfirm === 0 &&
            verification?.fieldData?.verifiedBy !== undefined &&
            verification?.fieldData?.verifiedBy.length !== 0) ||
          showRequestButton
        ) {
          return (
            <VerificationStatusView
              verification={verification}
              handleRequestVerification={handleRequestVerification}
              setActionConfirm={setActionConfirm}
              showRequestButton={showRequestButton}
            />
          );
        }
        // If there is no validated data in current field
        else if (showDecryption === 0) {
          return (
            <RequestVerificationActionConfirm
              warningMessage={"Confirm your credentials to decrypt"}
              setShowAction={setShowDecryption}
              authTypeInput={authUser.authType}
              publicKey={authUser.publicKey}
              encryptedSecret={authUser.encryptedSecret}
              //use the prop below to get the secret key
              onSecretKey={decryptData}
            />
          );
        } else if (actionConfirm === 2) {
          return (
            <RequestVerificationActionConfirm
              warningMessage={"Are you sure you want to cancel request?"}
              setShowAction={setShowAction}
              authTypeInput={authUser.authType}
              publicKey={authUser.publicKey}
              encryptedSecret={authUser.encryptedSecret}
              //use the prop below to get the secret key
              onSecretKey={handleCancelRequest}
            />
          );
        } else if (showDecryption !== 0 || actionConfirm === 1) {
          return (
            <SubmitVerification
              verification={verification}
              handleSubmit={handleSubmit}
              searchData={searchData}
              setFiles={setFiles}
              files={files}
              data={data}
              searchInput={searchInput}
              searchError={searchError}
              setShowAction={setShowAction}
              handleInputChange={handleInputChange}
              searchLoading={searchLoading}
              successMessage={{
                message: `Sending verification data to ${searchData.alias} `,
                width: "20rem",
              }}
              warningMessage={"Are you sure you want to submit?"}
              loading={loading}
            />
          );
        }
      })()}
    </Fragment>
  );
};

export const Container = styled.div`
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  width: 40%;
  z-index: 999;
  height: auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  @media (max-width: 768px) {
    width: 80%;
    padding: 1.5rem;
  }
  @media (max-width: 1368px) {
    width: 80%;
  }
`;

const OverLayContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 50%;
  z-index: 999;
  right: 50%;
  transform: translate(50%, -50%);
  background: #0c0b5584;
`;

export const SmallText = styled.p`
  font-size: 0.7rem;
  color: ${(props) => (props.primary ? "#080036" : props.color)};
  font-weight: ${(props) => (props.lighter ? "400" : "bold")};
`;

export const ColumnLoadContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  height: 20rem;
  align-items: center;
`;

export const Gif = styled.img`
  margin-top: 5rem;
  width: 16rem;
  height: auto;
`;
