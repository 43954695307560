import React, { useState } from "react";
import styled from "styled-components";
import { IconButton, useMediaQuery } from "@mui/material";
import Select from "react-select";
import { SubHeading, Text } from "../../components/shared/Typography/Typo";
import { useStateValue } from "../../context/StateProvider";
import {
  InputContainer,
  Label,
  Container as DropContainer,
  TextField,
} from "../../components/shared/Form/FormComponents/TextField";
import { CustomButton } from "../../components/shared/fields/Button/CustomButton";
import DropzoneComponent from "../../components/shared/fields/Dropzone/DropzoneComponent";
import ActionConfirm from "../../components/shared/Form/FormComponents/ActionConfirm";
import { useSelector } from "react-redux";
import { getUserSession, issueItem, checkUser } from "../../api/API";
import { useSnackbar } from "notistack";
import { AddImageToIPFS } from "../../services/IpfsService";
import { symmetricDecryption } from "../../services/encryptionService";
import Web3 from "web3";
import { FlexSpaceBetweenContainer } from "../../components/shared/Profile/UserProfile";
import CustomTextFieldModal from "../../components/shared/CustomTextfieldModal/CustomTextFieldModal";
import CustomView from "../../components/shared/CustomTextfieldModal/CustomView";
import MultipleImageViewSlider from "../../components/shared/Form/StageForm/MultipleImageViewSlider";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

var emailValidation =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// blockchain select options
const blockchainSelectOptions = [
  { value: "MUMBAI", label: "MUMBAI" },
  // { value: "STELLAR", label: "STELLAR" },
  // { value: "ETHEREUM", label: "ETHEREUM" },
  // { value: "BSC", label: "BSC" },
  { value: "MATIC", label: "MATIC" },
  // { value: "RINKEBY", label: "RINKEBY" },
  { value: "BSCTESTNET", label: "BSCTESTNET" },
];

const receieverTypeOptionsForClaimable = [{ value: "RESUME", label: "RESUME" }];

const receieverTypeOptionsForNonClaimable = [
  { value: "PRODUCT", label: "PRODUCT" },
];
export const IssueCertificateForm = ({ setMainView }) => {
  //eslint-disable-next-line
  const [authUser, setAuthUser] = useState(() => getUserSession());
  const { enqueueSnackbar } = useSnackbar();
  const matches = useMediaQuery("(max-width:1368px)");
  const currentUser = useSelector((state) => state.currentUser);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [recipientError, setRecipientError] = useState(false);
  const [recipient, setRecipient] = useState("");
  const [receiverType, setReceiverType] = useState("RESUME");

  //eslint-disable-next-line

  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(0);
  const [certificateName, setCertificateName] = useState("");
  const [description, setDescription] = useState("");
  const [showAction, setShowAction] = useState(false);
  const [{ customData }, dispatch] = useStateValue();
  const [imageFile, setImageFile] = useState([]);
  const [blockchain, setBlockchain] = useState(blockchainSelectOptions[0]);
  // const [receieverType, setReceieverType] = useState(receieverTypeOptions[0]);
  const [errors, setErrors] = useState("");
  const latestIpfsData = useSelector((state) => state.latestIpfsData);
  const [isClaimable, setIsClaimable] = useState(true);
  const [checedkUser, setChecedkUser] = useState("");
  const [openCustomModal, setCustomModalOpen] = useState(false);

  var dataListfieldKeys =
    customData.length > 0 &&
    customData.map((data) => {
      const keyData = Object.keys(data);
      return keyData;
    });

  var customDataListToMap =
    customData.length > 0 &&
    customData.map((data) => {
      return data;
    });

  function handleSubmit(e) {
    e.preventDefault();

    setView(1);
  }

  // Issuing single Certificate
  const issueCertificate = async (secretKey) => {
    if (currentUser?.latestIpfsUrl) {
      const evmPrivateKey = authUser.accounts[2].encryptedSecret;
      const privateKey = symmetricDecryption(evmPrivateKey, secretKey);
      let provider = "";
      if (blockchain.value === "MATIC") {
        provider = new Web3.providers.HttpProvider("https://polygon-rpc.com/");
      } else if (blockchain.value === "MUMBAI") {
        provider = new Web3.providers.HttpProvider(
          // "https://rpc-mumbai.matic.today"
          "https://matic-mumbai.chainstacklabs.com/"
        );
      } else if (blockchain.value === "BSCTESTNET") {
        provider = new Web3.providers.HttpProvider(
          "https://data-seed-prebsc-1-s1.binance.org:8545/"
        );
      }
      const web3 = new Web3(provider);
      let signerAccount = web3.eth.accounts.privateKeyToAccount(privateKey);
      let contractAddress = "";
      if (blockchain.value === "MATIC") {
        contractAddress = "0x2173ad61D4eC8a4b1F288B0631b7b29972D23B0C"; //"0x44de733c9d0c3517906c9E8520DC6E0c78088cf9";
      } else if (blockchain.value === "MUMBAI") {
        contractAddress = "0x1322D0894dF4355193625F7cdeFCC957BDD65ade";
      } else if (blockchain.value === "BSCTESTNET") {
        contractAddress = "0x5eec26b6af244afd2e4906763aa2c280a7eddd0d";
      }
      let DeproABI = require("../../utils/abi/DeproNFT.json");
      const chainId = await web3.eth.getChainId();
      let domainData = {
        name: "MetaTransaction",
        version: "1",
        chainId: chainId, // Matic Testnet
        verifyingContract: contractAddress,
      };
      const domainType = [
        { name: "name", type: "string" },
        { name: "version", type: "string" },
        { name: "chainId", type: "uint256" },
        { name: "verifyingContract", type: "address" },
      ];
      const metaTransactionType = [
        { name: "nonce", type: "uint256" },
        { name: "from", type: "address" },
      ];
      let DeproNFT = new web3.eth.Contract(DeproABI, contractAddress);
      const nonce = await DeproNFT.methods.nonces(signerAccount.address).call();
      let message = {};
      message.nonce = parseInt(nonce);
      message.from = signerAccount.address;
      const dataToSign = JSON.stringify({
        types: {
          EIP712Domain: domainType,
          MetaTransaction: metaTransactionType,
        },
        domain: domainData,
        primaryType: "MetaTransaction",
        message: message,
      });
      const hashedData = web3.utils.sha3(dataToSign);
      const signature = web3.eth.accounts.sign(
        hashedData,
        signerAccount.privateKey
      );
      let creatorSignature = {
        signR: signature.r,
        signS: signature.s,
        signV: signature.v,
        messageHash: signature.message,
        evmAddress: signerAccount.address,
      };
      setLoading(true);
      const ipfsData = await AddImageToIPFS(
        imageFile && imageFile.length > 0 && imageFile[0]
      );
      var previewImageUrl = `https://niftron.infura-ipfs.io/ipfs/${ipfsData.ipfsHash}`;

      const certificateData = {
        email: email,
        itemName: certificateName,
        blockchain: blockchain.value,
        itemImage: previewImageUrl,
        description: description,
        alias: latestIpfsData?.name?.data?.data,
        type: "education",
        senderPublicKey: currentUser.publicKey,
        creatorSignature: creatorSignature,
        isMinted: false,
        isClaimable: isClaimable,
        receiverType: receiverType,
      };
      // Set receiver public key if non claimable
      if (!isClaimable) {
        certificateData.profilePublicKey = checedkUser.publicKey;
        certificateData.alias = checedkUser.alias;
        certificateData.stageName = checedkUser.stageName;
      }
      certificateData.customData = customData;

      try {
        // Issuing Certificate API
        const response = await issueItem({
          body: certificateData,
          token: localStorage.niftoken,
        });
        if (response.status === 200) {
          enqueueSnackbar("Token Quota Exceeded!", {
            variant: "warning",
          });
        }
        if (response.status === 201) {
          enqueueSnackbar("Requesting relayer to mint succeeded!", {
            variant: "success",
          });
        }
      } catch (error) {
        enqueueSnackbar("Requesting relayer to mint failed!", {
          variant: "error",
        });
      } finally {
        setLoading(false);
        setEmail("");
        setCertificateName("");
        setDescription("");
        setShowAction(false);
        setView(0);
        setImageFile([]);
        setRecipient("");
        dispatch({
          type: "CLEAR_FIELDS",
        });
        dispatch({
          type: "CLEAR_CUSTOM_DATA_FIELDS",
        });
      }
    } else {
      enqueueSnackbar(`Please fill Creator data to proceed`, {
        variant: "error",
      });
      setShowAction(false);
    }
  };

  const checkUsers = async (e) => {
    try {
      const response = await checkUser({
        aId: e,
        query: {
          profileType: "PRODUCT",
        },
      });
      if (response === undefined) {
        setRecipientError(true);
      } else {
        setRecipientError(false);
        setEmail(response.data.data[0].email);
        setChecedkUser(response.data.data[0]);
      }
    } catch (e) {}
  };

  if (view === 1) {
    return (
      <Container>
        {showAction && (
          <ActionConfirm
            successMessage={{
              message: "Requesting relayer to mint token",
              width: "18rem",
            }}
            warningMessage={"Are you sure you want to submit?"}
            loading={loading}
            setShowAction={setShowAction}
            authTypeInput={authUser.authType}
            publicKey={authUser.publicKey}
            encryptedSecret={authUser.encryptedSecret}
            onSecretKey={issueCertificate}
          />
        )}
        <GridContainer style={{ marginTop: "2rem" }}>
          <ColumnContainer style={{ gap: "1rem" }}>
            <TextField
              disabled
              label={"Token Name *"}
              value={certificateName}
            />
            <TextField disabled label={"Description *"} value={description} />
            <TextField
              disabled
              label={"Blockchain *"}
              value={blockchain.label}
            />

            <ColumnContainer>
              {dataListfieldKeys &&
                dataListfieldKeys.length > 0 &&
                dataListfieldKeys.map((fieldName, key) => {
                  if (Array.isArray(customDataListToMap[key][fieldName[0]])) {
                    return null;
                  } else {
                    return (
                      <TextField
                        key={key}
                        disabled
                        label={
                          fieldName[0]
                            .toString()
                            .replace(/([A-Z])/g, " $1")
                            .substring(0, 1)
                            .toUpperCase() +
                          fieldName[0]
                            .toString()
                            .replace(/([A-Z])/g, " $1")
                            .substring(1)
                        }
                        value={customDataListToMap[key][fieldName[0]]}
                      />
                    );
                  }
                })}
            </ColumnContainer>
          </ColumnContainer>
          <ImageContainer>
            <Image src={imageFile[0].preview} alt="Token" />
          </ImageContainer>
        </GridContainer>
        <ColumnContainer>
          {dataListfieldKeys &&
            dataListfieldKeys.length > 0 &&
            dataListfieldKeys.map((fieldName, key) => {
              if (Array.isArray(customDataListToMap[key][fieldName[0]])) {
                return (
                  <ColumnContainer span={"true"}>
                    <MultipleImageViewSlider
                      fieldName={fieldName[0]}
                      data={customDataListToMap[key][fieldName[0]]}
                    />
                  </ColumnContainer>
                );
              }
            })}
        </ColumnContainer>
        <ButtonWrapper>
          <CustomButton login text="Back" onclick={() => setView(0)} />{" "}
          <CustomButton
            login
            disabled={
              certificateName === "" ||
              emailError ||
              (email === "" && recipient === "") ||
              imageFile.length === 0
            }
            onclick={() => {
              setShowAction(true);
            }}
            text="Issue Token"
          />
        </ButtonWrapper>
      </Container>
    );
  } else {
    return (
      <>
        <CustomTextFieldModal
          title="Add Additional Details"
          formId="custom-data-form-certificate"
          setCustomModalOpen={setCustomModalOpen}
          openCustomModal={openCustomModal}
        />
        <Container>
          <GridContainerForm
            id="certificateForm"
            name="certificateForm"
            onSubmit={handleSubmit}
          >
            <Column style={{ gridColumn: `${!matches ? "1/3" : "unset"}` }}>
              <FlexSpaceBetweenContainer
                style={{ marginBottom: "2rem", marginTop: "1rem" }}
              >
                <SubHeading primary>Token Details</SubHeading>
                <IconWrapper>
                  <IconButton
                    onClick={() => {
                      setMainView(0);
                    }}
                  >
                    <ArrowBackIosNewIcon
                      style={{ color: "#1e1b3b" }}
                      sx={{ fontSize: 20 }}
                    />
                  </IconButton>
                </IconWrapper>
              </FlexSpaceBetweenContainer>

              <FlexSpaceBetweenContainer
                style={{
                  margin: "0rem 0rem 1rem 0rem",
                  flexDirection: `${!matches ? "row" : "column"}`,
                  gap: "3rem",
                }}
              >
                <ColumnContainer>
                  <Text primary>Choose a issuing type</Text>
                  <Text small lighter color="#6b6b6b">
                    Claimable is for directly sending tokens, also non-claimable
                    will send the tokens through the email
                  </Text>
                </ColumnContainer>

                <ToggleContainer>
                  <ToggleButton
                    style={{
                      background: isClaimable
                        ? "linear-gradient(180deg, #293149 0%, #1b2236 100%)"
                        : "#e4e4e4",
                      borderTopLeftRadius: "8px",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "500",
                      borderBottomLeftRadius: "8px",
                      color: isClaimable ? "#ffffff" : "#1E1B3B",
                    }}
                    onClick={() => {
                      setIsClaimable(true);
                      setReceiverType("RESUME");
                    }}
                  >
                    Claimable
                  </ToggleButton>
                  <ToggleButton
                    style={{
                      background: !isClaimable
                        ? "linear-gradient(180deg, #293149 0%, #1b2236 100%)"
                        : "#e4e4e4",
                      fontWeight: "500",
                      borderTopRightRadius: "8px",
                      borderBottomRightRadius: "8px",
                      fontFamily: "Poppins, sans-serif",
                      color: !isClaimable ? "#ffffff" : "#1E1B3B",
                    }}
                    onClick={() => {
                      setIsClaimable(false);
                      setReceiverType("PRODUCT");
                    }}
                  >
                    Non Claimable
                  </ToggleButton>
                </ToggleContainer>
              </FlexSpaceBetweenContainer>
            </Column>
            <Column>
              <Label style={{ paddingBottom: "5px", paddingTop: "5px" }}>
                Receiver Type
              </Label>
              <Select
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    outline: "none",
                    text: "orangered",
                    primary25: "#2f3a6039",
                    primary: " #2f3a60a7",
                    neutral0: "#c3d0e1",
                    boxShadow: "none",
                  },
                })}
                options={
                  isClaimable
                    ? receieverTypeOptionsForClaimable
                    : receieverTypeOptionsForNonClaimable
                }
                defaultValue={
                  isClaimable
                    ? receieverTypeOptionsForClaimable[0]
                    : receieverTypeOptionsForNonClaimable[0]
                }
                value={
                  isClaimable
                    ? receieverTypeOptionsForClaimable[0]
                    : receieverTypeOptionsForNonClaimable[0]
                }
                onChange={(data) => {
                  setReceiverType(data.value);
                }}
              />
              <Label style={{ paddingBottom: "5px", paddingTop: "10px" }}>
                Blockchain *
              </Label>
              <Select
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    outline: "none",
                    text: "orangered",
                    primary25: "#2f3a6039",
                    primary: " #2f3a60a7",
                    neutral0: "#c3d0e1",
                    boxShadow: "none",
                  },
                })}
                defaultValue={blockchainSelectOptions[0]}
                options={blockchainSelectOptions}
                value={blockchain}
                onChange={setBlockchain}
              />
            </Column>
            <DropContainer style={{ gridRow: "span 1" }}>
              <InputContainer>
                <Label>
                  {receiverType == "RESUME" ? "Token Image *" : "Stage Image *"}
                </Label>
              </InputContainer>
              <DroppableContainer>
                <DropzoneComponent
                  files={imageFile}
                  id="dropzone"
                  amount="1"
                  multiple={false}
                  setErrors={setErrors}
                  errors={errors}
                  maxSize="1000000"
                  setFiles={setImageFile}
                  type="image/jpeg, image/png"
                  description="Drop or select your item image (Max file size 10MB)"
                />
              </DroppableContainer>
            </DropContainer>{" "}
            <>
              <TextField
                value={certificateName}
                label={
                  receiverType == "RESUME" ? "Token Name *" : "Stage Name *"
                }
                required
                form="noName"
                onChange={setCertificateName}
                placeholder={
                  receiverType == "RESUME" ? "Token Name" : "Stage Name"
                }
                id="certificateName"
                name="certificateName"
                type="text"
                maxLength="50"
              />

              {isClaimable ? (
                <TextField
                  label="Receiver Email *"
                  placeholder="Email"
                  id="email"
                  required
                  name="email"
                  type="text"
                  maxLength="50"
                  value={email}
                  onChange={(e) => {
                    if (!emailValidation.test(e)) {
                      setEmailError(true);
                    } else {
                      setEmailError(false);
                    }
                    setEmail(e);
                  }}
                  color="#FF613F"
                  helper={emailError && "Invalid Email"}
                />
              ) : !isClaimable ? (
                <>
                  <TextField
                    label="Recipient *"
                    placeholder="Email/Public Key/Alias"
                    id="email"
                    required
                    name="email"
                    type="text"
                    maxLength="100"
                    value={recipient}
                    onChange={async (e) => {
                      setRecipient(e);
                      await checkUsers(e);
                    }}
                    color="#FF613F"
                    helper={recipientError && "Couldn't find a product"}
                  />
                  {/* <TextField
           label="Stage Name *"
           placeholder="Stage name"
           id="stage"
           required
           name="stage"
           type="text"
           maxLength="50"
           value={stageName}
           onChange={async (e) => {
             setStageName(e);
           }}
           color="#FF613F"
         /> */}
                </>
              ) : null}
            </>
            <TextField
              span
              value={description}
              form="noName"
              label={
                receiverType == "RESUME"
                  ? "Token Description *"
                  : "Stage Description *"
              }
              required
              onChange={setDescription}
              placeholder={
                receiverType == "RESUME"
                  ? "Token Description"
                  : "Stage Description"
              }
              id="certificateDescription"
              name="certificateDescription"
              type="text"
            />
            <SubHeading
              primary
              style={{ gridColumn: `${!matches ? "1/3" : "unset"}` }}
            >
              Additional Details
            </SubHeading>
            {dataListfieldKeys &&
              dataListfieldKeys.length > 0 &&
              dataListfieldKeys.map((fieldName, key) => {
                if (Array.isArray(customDataListToMap[key][fieldName[0]])) {
                  return (
                    <ColumnContainer span={"true"}>
                      <MultipleImageViewSlider
                        fieldName={fieldName[0]}
                        data={customDataListToMap[key][fieldName[0]]}
                      />
                    </ColumnContainer>
                  );
                } else {
                  return (
                    <TextField
                      key={key}
                      disabled
                      label={
                        fieldName[0]
                          .toString()
                          .replace(/([A-Z])/g, " $1")
                          .substring(0, 1)
                          .toUpperCase() +
                        fieldName[0]
                          .toString()
                          .replace(/([A-Z])/g, " $1")
                          .substring(1)
                      }
                      value={customDataListToMap[key][fieldName[0]]}
                    />
                  );
                }
              })}
          </GridContainerForm>

          <ColumnContainer>
            <CustomView
              style={{ gridColumn: `${!matches ? "1/3" : "unset"}` }}
              setCustomModalOpen={setCustomModalOpen}
            />
            <ButtonWrapper>
              <CustomButton
                disabled={
                  imageFile.length === 0 ||
                  certificateName === "" ||
                  emailError ||
                  recipientError ||
                  (email === "" && recipient === "")
                }
                form="certificateForm"
                type="submit"
                login
                text="Preview"
              />
            </ButtonWrapper>
          </ColumnContainer>
        </Container>
      </>
    );
  }
};

export default IssueCertificateForm;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.noGap ? "0" : "1rem")};
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 2rem;
`;

export const GridContainerForm = styled.form`
  display: grid;
  row-gap: 1rem;
  column-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 1368px) {
    grid-template-columns: 1fr;
  }
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: ${(props) => (props.span ? "1/3" : "unset")};
`;

export const ButtonWrapper = styled.div`
  padding-top: 1rem;
  gap: 2rem;
  display: flex;
  justify-content: flex-end;
`;

export const DroppableContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: center;
  justify-content: space-evenly;
`;
export const InnerContainer = styled.div`
  height: 8rem;
  text-align: center;
  word-wrap: break-word;
  border: dashed 0.75px #ae9bf0a3;
  width: 100%;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #c3d0e147;
`;

export const Column = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  grid-column: ${(props) => (props.span ? "1/3" : "unset")};
  @media (max-width: 768px) {
    grid-column: unset;
  }
`;

export const GridContainer = styled.div`
  width: 100%;
  display: grid;
  row-gap: 1rem;
  justify-content: center;
  column-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
export const DisabledView = styled.div`
  display: flex;
  color: #2c2858;
  padding: 0.5rem;
  background-color: #eff2f5;
  border-radius: 5px;
  align-items: center;
  word-break: break-all;
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: auto;
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
`;

export const ToggleButton = styled.button`
  text-decoration: none;
  display: inline-block;
  border: none;
  text-align: center;
  padding: 10px 27px;
  cursor: pointer;
`;

const ToggleContainer = styled.div`
  max-height: 3rem;
  min-width: 17.5rem;
  display: flex;
`;

export const IconWrapper = styled.div`
  background-color: #dcdcdc;
  border-radius: 50%;
`;
