import React, { useState, useEffect } from "react";
import { Text } from "../../Typography/Typo";
import styled from "styled-components"; // import required modules
import { Lazy, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Carousel } from "react-responsive-carousel";
import { CircularProgress } from "@mui/material";

import { Modal } from "@mui/material";
import { urlToHash } from "../../../../utils/ipfs";
import fileTypePdf from "../../../../assets/images/fileTypePdf.png";

const MultipleSliderView = ({ data, key, fieldName }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileType, setFileType] = useState("");

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getFileType = async (fileURL) => {
    if (fileURL) {
      setLoading(true);
      let file = fileURL;
      var req = await fetch(file, { method: "HEAD" });
      console.log(req.headers.get("content-type"));
      setLoading(false);
      return setFileType(req.headers.get("content-type"));
    }
  };

  useEffect(() => {
    if (data && data.length > 0) getFileType(data[0]);
  }, []);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <CustomModalContainer>
          <ImageContainer>
            <Carousel>
              {data.length > 0 &&
                data.map((item) => {
                  return (
                    <img
                      style={{ width: "70%", height: "auto" }}
                      src={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(
                        item
                      )}`}
                    />
                  );
                })}
            </Carousel>
          </ImageContainer>
        </CustomModalContainer>
      </Modal>
      <FieldWrapper>
        <Text primary lighter small style={{ justifySelf: "start" }}>
          {/* {fieldName} */}
        </Text>
        <FlexContainer>
          {loading ? (
            <CircularProgress size={20} />
          ) : fileType === "application/pdf" ? (
            <img
              style={{
                width: "80px",
                height: "80px",
                color: "red",
              }}
              onClick={() => window.open(data[0])}
              src={fileTypePdf}
              // srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={"pdf"}
              loading="lazy"
            />
          ) : (
            <OverFlowWrap src={data[0]} onClick={() => handleOpen()} />
          )}
        </FlexContainer>
      </FieldWrapper>
    </>
  );
};

export default MultipleSliderView;

const FieldWrapper = styled.div`
  padding: ${(props) => (props.addPadding ? "1rem 1rem" : "0.2rem 1rem")};
  background-color: #c3d0e1;
  display: flex;
  gap: 1rem;
  border-radius: 5px;
  flex-direction: column;
`;

const OverFlowWrap = styled.img`
  max-width: 150px;
  max-height: 150px;
  min-width: 20px;
  min-height: 20px;
  border-radius: 10px;
  object-fit: contain;
  object-position: center;
  &:before {
    content: "";
    background: url("https://c.tenor.com/I6kN-6X7nhAAAAAi/loading-buffering.gif");
    min-width: 350px;
    min-height: 350px;
    object-fit: contain;
    object-position: center;
    display: block;
    background: #ffffff;
  }
  @media (max-width: 768px) {
    max-width: 150px;
    max-height: 150px;
    min-width: 150px;
    min-height: 150px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
`;

const CustomModalContainer = styled.div`
  display: flex;
  position: relative;
  width: 60%;
  padding: 2rem;
  position: absolute;
  flex-direction: column;
  top: 50%;
  left: 50%;
  max-height: 80vh;
  background: #ffffff;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  @media (max-width: 1368px) {
    width: 80%;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;
