import Divider from "@mui/material/Divider";
import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { Text } from "../Typography/Typo";
import { LoginIcon } from "../fields/Button/LoginIcon";
import { useSnackbar } from "notistack";
import { Formik } from "formik";
import Dropzone from "react-dropzone";
import LoadingGif from "../../../assets/images/loading.gif";
import {
  Button,
  Checkbox,
  FormHelperText,
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  ContainerForm,
  DroppableContainer,
  InnerContainer,
  LoadingContainer,
} from "../../../pages/Login/LoginForm";
import { CustomButton } from "../fields/Button/CustomButton";
import { Keypair } from "stellar-sdk";
import * as Yup from "yup";
import sha256 from "sha256";
import PropTypes from "prop-types";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { symmetricDecryption } from "../../../utils/encryption";

//Progress button styles
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 4,
    borderRadius: 4,
    width: "100%",
  },
  colorPrimary: {
    backgroundColor: "transparent",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#80B7FF",
    animationDuration: "550ms",
  },
}))(LinearProgress);

function ViewCredentialUsingSecret({
  loading,
  authTypeInput,
  successMessage,
  encryptedSecret,
  status,
  publicKey,
  warningMessage,
  onSecretKey,
  setShowAction,
}) {
  const [authType, setAuthType] = useState(authTypeInput);
  const { enqueueSnackbar } = useSnackbar();
  //eslint-disable-next-line
  const [check, setChecked] = useState(true);
  // states
  const [fileError] = useState(false);
  // setSecretKey
  const [showSecret, setShowSecret] = useState(false);
  const [hover, setHover] = useState(false);

  // setPassword
  const [showPassword, setShowPassword] = useState(false);

  //functions
  const handleClickShowSecretKey = () => {
    setShowSecret((value) => !value);
  };

  const handleClickShowPassword = () => {
    setShowPassword((value) => !value);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const switchToSecret = (e) => {
    e.preventDefault();
    setAuthType("2");
  };
  const switchToPassword = (e) => {
    e.preventDefault();
    setAuthType("0");
  };

  return (
    <Fragment>
      <OverLayContainer
        onClick={() => {
          if (!loading && !hover) {
            setShowAction(false);
          }
        }}
      >
        <Container
          onMouseOver={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          {(() => {
            if (loading) {
              return (
                <ColumnLoadContainer>
                  <Gif src={LoadingGif} style={{ width: "23rem" }} />
                  <LoadingContainer>
                    <BorderLinearProgress />
                    <Text
                      primary
                      small
                      lighter
                      style={{ width: successMessage.width }}
                    >
                      {successMessage.message}
                      <span className="loading"></span>
                    </Text>
                    <Text primary>{status}</Text>
                  </LoadingContainer>
                </ColumnLoadContainer>
              );
            } else {
              return (
                <Fragment>
                  <FlexContainer addPadding>
                    <ColumnContainer>
                      <Text primary lighter small>
                        Please Confirm action
                      </Text>
                      <SmallText color="#7D7979" lighter small>
                        {warningMessage}
                      </SmallText>
                    </ColumnContainer>
                    <LoginIcon large />
                  </FlexContainer>
                  <Divider
                    style={{
                      background: "#36353511",
                      width: "100%",
                      marginBottom: "0.5rem",
                    }}
                  />
                  <Formik
                    initialValues={{
                      secretKey: "",
                      autosign: false,
                    }}
                    validationSchema={Yup.object().shape({
                      secretKey: Yup.string()
                        .matches(
                          /^[S,s][a-zA-Z0-9]{55}$/,
                          "SecretKey format is invalid"
                        )
                        .required("SecretKey is required"),
                    })}
                    onSubmit={async (values) => {
                      try {
                        if (
                          publicKey !==
                          Keypair.fromSecret(values.secretKey).publicKey()
                        ) {
                          enqueueSnackbar("Credential Mismatch", {
                            variant: "warning",
                          });
                        } else {
                          enqueueSnackbar("Credential Validated Successfully", {
                            variant: "success",
                          });
                          if (values.autosign) {
                            localStorage.setItem("secretKey", values.secretKey);
                          }
                          onSecretKey(values.secretKey);
                        }
                      } catch (e) {
                        enqueueSnackbar(e.message, { variant: "error" });
                        console.log(e.message);
                      }
                    }}
                  >
                    {/* Formik props */}
                    {({
                      errors,
                      isValid,
                      dirty,
                      isSubmitting,
                      setFieldValue,
                      handleBlur,
                      handleSubmit,
                      touched,
                      values,
                      handleChange,
                    }) => (
                      <ContainerForm onSubmit={handleSubmit}>
                        <TextField
                          fullWidth
                          size="small"
                          variant="standard"
                          disabled={isSubmitting}
                          name="secretKey"
                          label="Secret Key"
                          id="secretKey"
                          onChange={handleChange}
                          value={values.secretKey}
                          helperText={touched.secretKey && errors.secretKey}
                          InputLabelProps={{
                            style: {
                              color: "#080036",
                              fontFamily: "Poppins",
                            },
                          }}
                          error={Boolean(touched.secretKey && errors.secretKey)}
                          onBlur={handleBlur}
                          InputProps={{
                            style: { color: "#0e0c2b", padding: ".2rem" },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  style={{ marginBottom: ".2rem" }}
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowSecretKey}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                  disabled={isSubmitting}
                                >
                                  {showSecret ? (
                                    <VisibilityIcon
                                      style={{ fill: "#080036" }}
                                    />
                                  ) : (
                                    <VisibilityOffIcon
                                      style={{ fill: "#080036" }}
                                    />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                            autoComplete: "new-password",
                            type: showSecret ? "text" : "password",
                          }}
                        />
                        {/*SecretKey*/}
                        <Dropzone
                          onDrop={(acceptedFiles) => {
                            const reader = new FileReader();
                            reader.addEventListener("load", async () => {
                              setFieldValue(
                                "secretKey",
                                JSON.parse(atob(reader.result)).secretKey
                              );
                            });
                            reader.readAsText(acceptedFiles[0]);
                          }}
                          multiple={false}
                          accept={[".niftron"]}
                        >
                          {({ getRootProps, getInputProps, isDragActive }) => (
                            <DroppableContainer>
                              <InnerContainer
                                {...getRootProps({
                                  className: fileError
                                    ? "dropzone2Error"
                                    : "dropzone2",
                                })}
                              >
                                <input {...getInputProps()} />
                                {isDragActive ? (
                                  <Text small>Drop the files here ...</Text>
                                ) : (
                                  <Text primary small lighter>
                                    Drop or select your Niftron credential file
                                  </Text>
                                )}
                              </InnerContainer>
                            </DroppableContainer>
                          )}
                        </Dropzone>

                        {isSubmitting ? (
                          <LoadingContainer>
                            <BorderLinearProgress />
                            <Text
                              primary
                              small
                              lighter
                              style={{ width: "80px" }}
                            >
                              Verifying <span className="loading"></span>
                            </Text>
                          </LoadingContainer>
                        ) : (
                          <CustomButton
                            wide
                            login
                            disabled={!isValid || (isSubmitting && dirty)}
                            onclick={handleSubmit}
                            text="Confirm Action"
                          />
                        )}
                      </ContainerForm>
                    )}
                  </Formik>
                </Fragment>
              );
            }
          })()}
        </Container>
      </OverLayContainer>
    </Fragment>
  );
}

ViewCredentialUsingSecret.propTypes = {
  status: PropTypes.number,
  successMessage: PropTypes.object,
  warningMessage: PropTypes.string,
  authTypeInput: PropTypes.string,
  onSecretKey: PropTypes.func,
  encryptedSecret: PropTypes.string,
  publicKey: PropTypes.string,
  loading: PropTypes.bool,
  setShowAction: PropTypes.func,
  isCredentialProvider: PropTypes.bool,
};

export default ViewCredentialUsingSecret;

const Container = styled.div`
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  width: 25rem;
  z-index: 999;
  height: 30rem;
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin: 1rem 0rem;
  gap: 1.5rem;
`;

const OverLayContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 50%;
  z-index: 999;
  right: 50%;
  transform: translate(50%, -50%);
  background: #0c0b5584;
`;

const FlexContainer = styled.div`
  width: 100%;
  padding: ${(props) => (props.addPadding ? "0.5rem 0rem" : "0rem")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem, 0rem;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SmallText = styled.p`
  font-size: 0.7rem;
  color: ${(props) => (props.primary ? "#080036" : props.color)};
  font-weight: ${(props) => (props.lighter ? "400" : "bold")};
`;

export const ColumnLoadContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  height: 20rem;
  align-items: center;
`;

export const Gif = styled.img`
  margin-top: 5rem;
  width: 16rem;
  height: auto;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
`;
