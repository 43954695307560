import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Label, TextField } from "../FormComponents/TextField";
import { Column } from "../Web3ResumeContainer";
import DatePicker from "react-date-picker";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import "./DatePicker.css";
import { useSnackbar } from "notistack";
import { useStateValue } from "../../../../context/StateProvider";
import { Button, useMediaQuery } from "@mui/material";
import uuid from "react-uuid";
import { Text } from "../../Typography/Typo";
import DropzoneComponent from "../../fields/Dropzone/DropzoneComponent";
import { AddImageToIPFS } from "../../../../services/IpfsService";
import Select from "react-select";
import { Checkbox } from "@mui/material";

export const privacyOptions = [
  { value: true, label: "Public" },
  { value: false, label: "Private" },
];

const BlockForm = ({ type }) => {
  const [startEduDate, setStartEduDate] = useState();
  const [description, setDescription] = useState("");
  const [imageFile, setImageFile] = useState([]);
  const [endEduDate, setEndEduDate] = useState();
  const [startWorkDate, setStartWorkDate] = useState();
  const [endWorkDate, setEndWorkDate] = useState();
  const [issueDate, setIssueDate] = useState();
  const [eduDateError, setEduDateError] = useState("");
  const [workDateError, setWorkDateError] = useState("");
  const [name, setName] = useState("");
  //eslint-disable-next-line
  const [{ fields }, dispatch] = useStateValue();
  const [institution, setInstitution] = useState("");
  const [issuer, setIssuer] = useState("");
  const [position, setPosition] = useState("");
  const [company, setCompany] = useState("");
  const matches = useMediaQuery("(max-width:1368px)");
  const { enqueueSnackbar } = useSnackbar();
  const [errors, setErrors] = useState("");
  const [privacyWork, setPrivacyWork] = useState(privacyOptions[0]);
  const [privacyEdu, setPrivacyEdu] = useState(privacyOptions[0]);
  const [privacyAch, setPrivacyAch] = useState(privacyOptions[0]);
  const [eduChecked, setEduChecked] = useState(false);
  const [workChecked, setWorkChecked] = useState(false);

  const handleCheckedEduChange = (event) => {
    setEduChecked(event.target.checked);
  };

  const handleCheckedWorkChange = (event) => {
    setWorkChecked(event.target.checked);
  };

  const handleEduFieldSubmit = async (e) => {
    e.preventDefault();
    const ipfsData = await AddImageToIPFS(
      imageFile && imageFile.length > 0 && imageFile[0]
    );
    var previewImageUrl = `https://niftron.infura-ipfs.io/ipfs/${ipfsData.ipfsHash}`;
    try {
      dispatch({
        type: "ADD_EDUCATION_FIELDS",
        item: {
          id: uuid(),
          courseName: name,
          institutionName: institution,
          startDate: new Date(startEduDate).valueOf(),
          endDate: !eduChecked
            ? new Date(endEduDate).valueOf().toString()
            : new Date(2050, 2, 3, 10, 30, 50, 800).valueOf(), // Mon Feb 03 2050 10:30:50,
          imageLogo: previewImageUrl,
          description: description,
          isPublic: privacyEdu.value,
        },
      });
      enqueueSnackbar("Education details added", {
        variant: "success",
      });
    } catch (error) {
      console.log(error);
    } finally {
      setName("");
      setInstitution("");
      setStartEduDate();
      setEndEduDate();
      setImageFile([]);
      setDescription("");
      setPrivacyEdu(privacyOptions[0]);
      setEduChecked(false);
    }
  };

  const handleWorkFieldSubmit = async (e) => {
    const ipfsData = await AddImageToIPFS(
      imageFile && imageFile.length > 0 && imageFile[0]
    );
    var previewImageUrl = `https://niftron.infura-ipfs.io/ipfs/${ipfsData.ipfsHash}`;
    e.preventDefault();
    try {
      dispatch({
        type: "ADD_WORK_FIELDS",
        item: {
          id: uuid(),
          position: position,
          company: company,
          startDate: new Date(startWorkDate).valueOf(),
          endDate: !workChecked
            ? new Date(endWorkDate).valueOf().toString()
            : new Date(2050, 2, 3, 10, 30, 50, 800).valueOf(), // Mon Feb 03 2050 10:30:50,
          imageLogo: previewImageUrl,
          description: description,
          isPublic: privacyWork.value,
        },
      });
      enqueueSnackbar("Work experience added", {
        variant: "success",
      });
    } catch (error) {
      console.log(error);
    } finally {
      setPosition("");
      setCompany("");
      setStartWorkDate();
      setEndWorkDate();
      setImageFile([]);
      setDescription("");
      setPrivacyWork(privacyOptions[0]);
      setWorkChecked(false);
    }
  };

  const handleAchievementFieldSubmit = async (e) => {
    e.preventDefault();
    // setFieldData(getFormData(e.currentTarget));
    const ipfsData = await AddImageToIPFS(
      imageFile && imageFile.length > 0 && imageFile[0]
    );
    var previewImageUrl = `https://niftron.infura-ipfs.io/ipfs/${ipfsData.ipfsHash}`;
    try {
      dispatch({
        type: "ADD_ACHIEVEMENT_FIELDS",
        item: {
          id: uuid(),
          itemName: name,
          description: description,
          issueDate: new Date(issueDate).valueOf(),
          issuer: issuer,
          itemImage: previewImageUrl,
          // customData: fieldData,
          isPublic: privacyAch.value,
        },
      });
      enqueueSnackbar("Achievement added", {
        variant: "success",
      });
    } catch (error) {
      console.log(error);
    } finally {
      setName("");
      setDescription("");
      setIssueDate("");
      setIssuer("");
      setImageFile([]);
      setPrivacyAch(privacyOptions[0]);
    }
  };

  const checkDateRange = () => {
    //edu
    const startEdu = new Date(startEduDate).getTime();
    const endEdu = new Date(endEduDate).getTime();
    //work
    const startWork = new Date(startWorkDate).getTime();
    const endWork = new Date(endWorkDate).getTime();
    //checkRange

    if (startEdu > endEdu) {
      setEduDateError("Your date is invalid");
    } else if (startWork > endWork) {
      setWorkDateError("Your date is invalid");
    } else {
      setEduDateError("");
      setWorkDateError("");
    }
  };

  useEffect(() => {
    if (!eduChecked) {
      if (startEduDate && endEduDate) {
        checkDateRange();
      }
    } else {
      setEduDateError("");
    }
    if (!workChecked) {
      if (startWorkDate && endWorkDate) {
        checkDateRange();
      }
    } else {
      setWorkDateError("");
    }

    //eslint-disable-next-line
  }, [
    startEduDate,
    endEduDate,
    startWorkDate,
    endWorkDate,
    eduChecked,
    workChecked,
  ]);

  return (
    <>
      {(() => {
        switch (type) {
          case 1:
            return (
              <OuterContainer>
                <ContainerForm id="educationForm" name="educationForm">
                  <TextField
                    label="Course Name *"
                    placeholder="Course Name"
                    id="courseName"
                    onChange={setName}
                    value={name}
                    form="none"
                    name="courseName"
                    type="text"
                    maxLength="100"
                  />
                  <TextField
                    label="Institution Name *"
                    placeholder="Institution Name"
                    id="institution"
                    onChange={setInstitution}
                    value={institution}
                    name="institution"
                    form="none"
                    type="text"
                    maxLength="100"
                  />
                  <Column style={{ gridRow: "span 2" }}>
                    <Label>Institution Logo *</Label>
                    <DropzoneComponent
                      files={imageFile}
                      id="dropzone"
                      amount="1"
                      form="none"
                      multiple={false}
                      maxSize="1000000"
                      setFiles={setImageFile}
                      setErrors={setErrors}
                      errors={errors}
                      type="image/jpeg, image/png"
                      description="Drop or select your Logo image (Max file size 10MB)"
                    />
                  </Column>{" "}
                  <Column>
                    <Label>Start Date *</Label>
                    <TextInputContainer>
                      <DatePicker
                        monthPlaceholder="MM"
                        yearPlaceholder="YY"
                        form="none"
                        dayPlaceholder="DD"
                        onChange={setStartEduDate}
                        value={startEduDate}
                        clearIcon={false}
                        calendarIcon={
                          <CalendarTodayIcon style={{ color: "#080036" }} />
                        }
                        className="custom-date"
                      />
                    </TextInputContainer>
                  </Column>
                  <Column>
                    {!eduChecked && (
                      <>
                        <Label>End Date *</Label>
                        <TextInputContainer>
                          <DatePicker
                            monthPlaceholder="MM"
                            yearPlaceholder="YY"
                            form="none"
                            dayPlaceholder="DD"
                            onChange={setEndEduDate}
                            value={endEduDate}
                            clearIcon={false}
                            calendarIcon={
                              <CalendarTodayIcon style={{ color: "#080036" }} />
                            }
                            className="custom-date"
                          />
                        </TextInputContainer>
                      </>
                    )}
                    <FlexContainer>
                      <Checkbox
                        checked={eduChecked}
                        onChange={handleCheckedEduChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <Label>Currently working on this role</Label>
                    </FlexContainer>
                  </Column>
                  <Column>
                    <Text lighter smaller style={{ color: "#FF613F" }}>
                      {eduDateError}
                    </Text>
                  </Column>
                  <TextField
                    span={!matches ? true : false}
                    label="Description"
                    placeholder="Description"
                    id="description"
                    onChange={setDescription}
                    value={description}
                    name="description"
                    form="none"
                    type="text"
                  />{" "}
                  <Column span={!matches ? true : false}>
                    <Label>Private / Public</Label>
                    <Select
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          outline: "none",
                          text: "orangered",
                          primary25: "#2f3a6039",
                          primary: " #2f3a60a7",
                          neutral0: "#c3d0e1",
                          boxShadow: "none",
                        },
                      })}
                      name="isPublic"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      defaultValue={privacyOptions[0]}
                      options={privacyOptions}
                      value={privacyEdu}
                      onChange={setPrivacyEdu}
                    />
                  </Column>
                  <ButtonWrapper
                    style={{ girdColumn: `${!matches ? "1/3" : "unset"}` }}
                  >
                    <Button
                      style={{
                        background: "#1fc36157",
                        color: "#080036",
                        textTransform: "inherit",
                        fontWeight: "500",
                        fontFamily: "Poppins",
                        padding: "0.3rem 1rem",
                        float: "right",
                      }}
                      form="educationForm"
                      onClick={handleEduFieldSubmit}
                      size="small"
                      disabled={
                        eduDateError !== "" ||
                        name === "" ||
                        institution === "" ||
                        imageFile?.length === 0 ||
                        startEduDate === undefined
                      }
                    >
                      Add Entry
                    </Button>
                  </ButtonWrapper>
                </ContainerForm>
              </OuterContainer>
            );
          case 2:
            return (
              <OuterContainer>
                <ContainerForm id="workForm" name="workForm">
                  <TextField
                    label="Position *"
                    placeholder="Position"
                    id="position"
                    value={position}
                    form="none"
                    onChange={setPosition}
                    name="position"
                    type="text"
                    maxLength="100"
                  />
                  <TextField
                    label="Company Name *"
                    placeholder="Company Name"
                    id="name"
                    value={company}
                    onChange={setCompany}
                    name="name"
                    form="none"
                    type="text"
                    maxLength="100"
                  />
                  <Column style={{ gridRow: "span 2" }}>
                    <Label>Company Logo *</Label>
                    <DropzoneComponent
                      files={imageFile}
                      id="dropzone"
                      amount="1"
                      form="none"
                      multiple={false}
                      setErrors={setErrors}
                      errors={errors}
                      maxSize="1000000"
                      setFiles={setImageFile}
                      type="image/jpeg, image/png"
                      description="Drop or select your Logo image (Max file size 10MB)"
                    />
                  </Column>
                  <Column>
                    <Label>Start Date *</Label>
                    <TextInputContainer>
                      <DatePicker
                        monthPlaceholder="MM"
                        yearPlaceholder="YY"
                        dayPlaceholder="DD"
                        form="none"
                        onChange={setStartWorkDate}
                        value={startWorkDate}
                        clearIcon={false}
                        calendarIcon={
                          <CalendarTodayIcon style={{ color: "#080036" }} />
                        }
                        className="custom-date"
                      />
                    </TextInputContainer>
                  </Column>
                  <Column>
                    {!workChecked && (
                      <>
                        <Label>End Date *</Label>
                        <TextInputContainer>
                          <DatePicker
                            monthPlaceholder="MM"
                            yearPlaceholder="YY"
                            form="none"
                            dayPlaceholder="DD"
                            onChange={setEndWorkDate}
                            value={endWorkDate}
                            clearIcon={false}
                            calendarIcon={
                              <CalendarTodayIcon style={{ color: "#080036" }} />
                            }
                            className="custom-date"
                          />
                        </TextInputContainer>
                      </>
                    )}
                    <FlexContainer>
                      <Checkbox
                        checked={workChecked}
                        onChange={handleCheckedWorkChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <Label>Currently working on this role</Label>
                    </FlexContainer>
                  </Column>
                  <Column>
                    <Text lighter smaller style={{ color: "#FF613F" }}>
                      {workDateError}
                    </Text>
                  </Column>
                  <TextField
                    span={!matches ? true : false}
                    label="Description"
                    placeholder="Description"
                    id="description"
                    onChange={setDescription}
                    value={description}
                    name="description"
                    form="none"
                    type="text"
                  />{" "}
                  <Column span={!matches ? true : false}>
                    <Label>Private / Public</Label>
                    <Select
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          outline: "none",
                          text: "orangered",
                          primary25: "#2f3a6039",
                          primary: " #2f3a60a7",
                          neutral0: "#c3d0e1",
                          boxShadow: "none",
                        },
                      })}
                      name="isPublic"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      defaultValue={privacyOptions[0]}
                      options={privacyOptions}
                      value={privacyWork}
                      onChange={setPrivacyWork}
                    />
                  </Column>
                  <ButtonWrapper
                    style={{ girdColumn: `${!matches ? "1/3" : "unset"}` }}
                  >
                    <Button
                      style={{
                        background: "#1fc36157",
                        color: "#080036",
                        textTransform: "inherit",
                        fontWeight: "500",
                        fontFamily: "Poppins",
                        padding: ".3rem 1rem",
                        float: "right",
                      }}
                      form="workForm"
                      onClick={handleWorkFieldSubmit}
                      disabled={
                        workDateError !== "" ||
                        position === "" ||
                        company === "" ||
                        imageFile?.length === 0 ||
                        startWorkDate === undefined
                      }
                      size="small"
                    >
                      Add Entry
                    </Button>
                  </ButtonWrapper>
                </ContainerForm>
              </OuterContainer>
            );
          case 3:
            return (
              <OuterContainer>
                <ContainerForm id="achievementForm" name="achievementForm">
                  <TextField
                    label="Token Name *"
                    placeholder="Token Name"
                    id="certificateName"
                    value={name}
                    form="none"
                    onChange={setName}
                    name="certificateName"
                    type="text"
                    maxLength="100"
                  />
                  <TextField
                    label="Description *"
                    placeholder="Description"
                    id="description"
                    value={description}
                    form="none"
                    onChange={setDescription}
                    name="description"
                    type="text"
                  />{" "}
                  <Column style={{ gridRow: "span 2" }}>
                    <Label>Token Image *</Label>
                    <DropzoneComponent
                      files={imageFile}
                      id="dropzone"
                      amount="1"
                      form="none"
                      setErrors={setErrors}
                      errors={errors}
                      multiple={false}
                      maxSize="1000000"
                      setFiles={setImageFile}
                      type="image/jpeg, image/png"
                      description="Drop or select your token image (Max file size 10MB)"
                    />
                  </Column>{" "}
                  <TextField
                    label="Issuer *"
                    placeholder="issuer Name"
                    id="issuer"
                    value={issuer}
                    form="none"
                    onChange={setIssuer}
                    name="issuer"
                    type="text"
                    maxLength="300"
                  />
                  <Column>
                    <Label>Issued Date *</Label>
                    <TextInputContainer>
                      <DatePicker
                        monthPlaceholder="MM"
                        yearPlaceholder="YY"
                        dayPlaceholder="DD"
                        form="none"
                        onChange={setIssueDate}
                        value={issueDate}
                        clearIcon={false}
                        calendarIcon={
                          <CalendarTodayIcon style={{ color: "#080036" }} />
                        }
                        className="custom-date"
                      />
                    </TextInputContainer>
                  </Column>{" "}
                  <Column span={!matches ? true : false}>
                    <Label>Private / Public</Label>
                    <Select
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          outline: "none",
                          text: "orangered",
                          primary25: "#2f3a6039",
                          primary: " #2f3a60a7",
                          neutral0: "#c3d0e1",
                          boxShadow: "none",
                        },
                      })}
                      name="isPublic"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      defaultValue={privacyOptions[0]}
                      options={privacyOptions}
                      value={privacyAch}
                      onChange={setPrivacyAch}
                    />
                  </Column>
                  <ButtonWrapper
                    style={{ girdColumn: `${!matches ? "1/3" : "unset"}` }}
                  >
                    <Button
                      style={{
                        background: "#1fc36157",
                        color: "#080036",
                        textTransform: "inherit",
                        fontWeight: "500",
                        fontFamily: "Poppins",
                        padding: ".3rem 1rem",
                        float: "right",
                      }}
                      form="achievementForm"
                      // type="submit"
                      onClick={handleAchievementFieldSubmit}
                      disabled={
                        name === "" ||
                        description === "" ||
                        issuer === "" ||
                        imageFile?.length === 0 ||
                        issueDate === undefined
                      }
                      size="small"
                    >
                      Add Entry
                    </Button>
                  </ButtonWrapper>
                </ContainerForm>
              </OuterContainer>
            );
          default:
            return null;
        }
      })()}
    </>
  );
};

export default BlockForm;

export const OuterContainer = styled.div`
  border-radius: 10px;
  background-color: #c3d0e181;
  margin: 4.5rem 0rem;
`;

export const ContainerForm = styled.form`
  display: grid;
  row-gap: 1rem;
  padding: 1.2rem;
  column-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

export const ButtonWrapper = styled.div`
  padding-top: 1rem;
  display: flex;
  grid-column: 1/3;
  justify-content: flex-end;
`;

export const TextInputContainer = styled.div`
  display: flex;
  padding: 0.3rem 0.5rem;
  background-color: #c3d0e1;
  border-radius: 5px;
  align-items: center;
`;

const FlexContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;
