import { useState } from "react";
import styled from "styled-components";
import { ButtonContainer } from "../../components/shared/DashboardComponents/ButtonContainer";
import IssueCertificateForm from "./IssueCertificateForm";
import IssueCertificateFormBulk from "./IssueCertificateFormBulk";
import ViewIssuedCredentials from "./ViewIssuedCredentials";

const IssueCertificate = () => {
  const [view, setView] = useState(0);
  return (
    <Container>
      <ButtonContainer
        onClick={() => setView(2)}
        cursor
        biglink
        heading="Issue a Collectible"
        description="Select and Issue collectible here"
        icon={"link"}
        active={view === 2}
      />

      <ButtonContainer
        onClick={() => setView(1)}
        cursor
        biglink
        heading="Issue Collectibles in Bulk"
        description="Issue collectibles in bulk here"
        icon={"cloud_upload"}
        active={view === 1}
      />
      <FlexContainer>
        {(() => {
          switch (view) {
            case 0:
              return <ViewIssuedCredentials />;
            case 1:
              return <IssueCertificateFormBulk setMainView={setView} />;
            case 2:
              return <IssueCertificateForm setMainView={setView} />;
            default:
              return <ViewIssuedCredentials />;
          }
        })()}
      </FlexContainer>
    </Container>
  );
};

export default IssueCertificate;

export const Container = styled.div`
  display: grid;
  align-content: flex-start;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 1068px) {
    display: flex;
    flex-direction: column;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1/3;
  gap: 1rem;
  width: 100%;
`;
