import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import RegisterForm from "./RegisterForm";

export const RegisterComponent = (props) => {
  const [signUpStep, setSignUpStep] = useState(0);
  return (
    <Container isWorkFlow={props.isWorkFlow}>
      <RegisterForm
        setSignUpStep={setSignUpStep}
        setUserData={props.setUserData}
        userData={props.userData}
        createProductNextStep={props.createProductNextStep}
        isWorkFlow={props.isWorkFlow}
        currentWorkflow={props.currentWorkflow}
        secretKey={props.secretKey}
      />
    </Container>
  );
};

export default RegisterComponent;

RegisterComponent.propTypes = {
  isCreateProduct: PropTypes.bool,
};

const Container = styled.div`
  color: #000000;
  flex-direction: column;
  border-radius: 10px;
  padding: 2rem 2rem;
  display: flex;
  gap: 3rem;
  align-items: center;
  margin: 1.5rem;
  @media (max-width: 1368px) {
    margin: 2rem;
  }
  height: ${(props) => !props.isWorkFlow && "300px"};
`;

const HeadingWrapper = styled.div`
  text-align: center;
`;
