import React from "react";
import { ContainerForm, OuterContainer } from "../../Form/BlockForm/BlockForm";
import { TextField } from "../../Form/FormComponents/TextField";
import MultipleImageUpload from "../../Form/FormComponents/MultipleImageUpload";
import { useStateValue } from "../../../../context/StateProvider";
import PDFUploaderDropzone from "../../VerificationViews/PDFUploaderDropzone";

function AddCustomDataForm({ formId, setCustomImageArr, customImageArr }) {
  const [{ fields }, dispatch] = useStateValue();

  return (
    <>
      <OuterContainer style={{ width: "100%", marginBottom: "4rem" }}>
        <ContainerForm id={formId} name={formId}>
          {fields &&
            fields.length > 0 &&
            fields.map((item, key) => {
              if (item.select.value === "pdf") {
                return (
                  <MultipleImageUpload
                    span
                    key={key}
                    name={item.name}
                    setCustomImageArr={setCustomImageArr}
                    customImageArr={customImageArr}
                    type="application/pdf"
                    description="Drop or select File (Max file size 5MB)"
                  />
                );
              }
              if (item.select.value === "image") {
                return (
                  <MultipleImageUpload
                    span
                    key={key}
                    name={item.name}
                    setCustomImageArr={setCustomImageArr}
                    customImageArr={customImageArr}
                    type="image/*, image/jpeg, image/png"
                    description="Drop or select your item image (Max file size 10MB)"
                  />
                );
              } else {
                return (
                  <TextField
                    custom
                    form={formId}
                    key={key}
                    label={item.name}
                    placeholder={item.name}
                    id={item.name}
                    required
                    name={item.name.replace(/ /g, "")}
                    pattern={item.select.value === "number" && "[0-9]{0,5}"}
                    type={item.select.value}
                    maxLength="100"
                  />
                );
              }
            })}
        </ContainerForm>
      </OuterContainer>
    </>
  );
}

export default AddCustomDataForm;
