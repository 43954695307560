import React, { Fragment } from "react";
import styled from "styled-components";
import { ButtonContainer } from "../../components/shared/DashboardComponents/ButtonContainer";
import { useParams, useLocation } from "react-router-dom";
import ViewProfileList from "./ViewProfileList";
import ViewProfile from "./ViewProfile";

const PrvoiderProductComponent = () => {
  const { id } = useParams();
  const location = useLocation();

  return (
    <Container>
      {id ? (
        <Fragment>
          {" "}
          <ViewProfile />{" "}
        </Fragment>
      ) : (
        <>
          <ButtonContainer
            heading="View All Your Profiles"
            description="View your profiles here"
            icon={"qr_code"}
          />
          <ViewProfileList />
        </>
      )}
    </Container>
  );
};

export default PrvoiderProductComponent;

export const Container = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
`;
