export const DEPROERC721_CONTRACT_ID =
    "0x9fdF45cBeb504828C5526dAb329843eeE742c63C";
export const DEPROERC1155_CONTRACT_ID =
    "0x778f534A821A88270e58AAA459dA6bC3F55317BD";
export const DEPROERC20_CONTRACT_ID =
    "0xb6F0D7e039bBD193611BC90941cD0B81047BAd13";

// Add the contract for CELO (ALFAJORES) TEST NETWORK
export const DEPROERC721_CONTRACT_ID_ALFAJORES =
    "0x5c31d74e5228A4deF1eC2D6190e92781A7EA07fd";
export const DEPROERC1155_CONTRACT_ID_ALFAJORES =
    "0x507a298ef023dAB92F2FD9F04E5564ec9d3Cb5e0";
export const DEPROERC20_CONTRACT_ID_ALFAJORES =
    "0x0C4bb44757e507E0025e86146B60cf4a15cBB36C";