import styled from "styled-components";
import React from "react";
import DocumentComponent from "./DocumentComponent";

const DocumentContainer = ({
  productData,
  isPublic,
  setShowAction,
  setVerification,
  setViewVerifiedData,
  setShowEncryption,
  setShowDecryption,
  setCurrentField,
}) => {
  return (
    <Container>
      {productData &&
        productData?.length > 0 &&
        productData?.map((data, key) => {
          return (
            <DocumentComponent
              key={key}
              item={data?.data ? data?.data : data}
              data={data}
              addPadding
              fieldId={{ id: key, name: "stageData" }}
              fieldName="stageData"
              setShowAction={setShowAction}
              setShowDecryption={setShowDecryption}
              setVerification={setVerification}
              setViewVerifiedData={setViewVerifiedData}
              setCurrentField={setCurrentField}
              isPublic={isPublic}
              isDecrypted={data?.isPublic}
              encryptedType={data?.data?.addedDate?.encryptionType}
              setShowEncryption={setShowEncryption}
            />
          );
        })}
    </Container>
  );
};

export default DocumentContainer;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
