import React, { Fragment, useState } from "react";
import styled from "styled-components";
import Select from "react-select";
import { CircularProgress, Dialog } from "@mui/material";
import { Button } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { Text } from "../Typography/Typo";
import {
  Container as DropContainer,
  TextField,
} from "../Form/FormComponents/TextField";
import * as API from "../../../api/API";

const CreateWorkflowModal = ({ show, handleClose, dataJson, templateId }) => {
  const currentUser = useSelector((state) => state.currentUser);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  const onClose = () => {
    handleClose(false);
  };

  const handleCreateWorkflow = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const response = await API.createWorkflow({
        body: {
          alias: name,
          description: description,
          file: JSON.stringify(dataJson),
          templateRefId: templateId,
        },
        path: {
          wId: currentUser.publicKey,
        },
        token: localStorage.niftoken,
      });
      if (response?.data?.code === 201) {
        enqueueSnackbar("Workflow created", {
          variant: "success",
        });

        history.push("/dashboard/workflows");
      }
    } catch (error) {
      enqueueSnackbar("Couldn't Create Workflow", {
        variant: "error",
      });
    } finally {
      handleClose(false);
      setLoading(false);
    }
  };

  return (
    <Dialog open={show} onClose={onClose} style={{ cursor: "pointer" }}>
      <DialogTitle>
        <Text style={{ fontSize: "20px", fontWeight: "600" }}>
          Create Workflow
        </Text>
      </DialogTitle>
      <DialogContent>
        <Container
          id="createWorkflow-form"
          //   onSubmit={handleFieldSubmit}
          style={{ minWidth: "400px" }}
          onSubmit={handleCreateWorkflow}
        >
          <TextField
            label="Workflow Name *"
            placeholder="Workflow Name"
            id="workflow-name"
            name="workflow-name"
            required
            value={name}
            onChange={setName}
            type="text"
            color="#21ca54"
          />

          <TextField
            label="Description *"
            placeholder="Workflow Description"
            id="workflow-description"
            name="workflow-description"
            required
            value={description}
            onChange={setDescription}
            type="text"
            color="#21ca54"
          />

          <ButtonWrapper>
            <Button
              style={{
                background: "#1fc36157",
                color: "#080036",
                textTransform: "inherit",
                fontWeight: "500",
                fontFamily: "Poppins",
                padding: ".3rem 1rem",
                float: "right",
              }}
              endIcon={loading && <CircularProgress size={20} />}
              disabled={loading}
              size="small"
              type="submit"
              // size="small"
              form="createWorkflow-form"
              // disabled={loading}
            >
              Create Workflow
            </Button>
          </ButtonWrapper>
        </Container>
      </DialogContent>
    </Dialog>
  );
};

export default CreateWorkflowModal;

const ButtonWrapper = styled.div`
  width: auto;
`;
const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled.form`
  background-color: #c3d0e181;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
`;
