import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { TextField } from "./FormComponents/TextField";
import { IconButton, useMediaQuery } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useSnackbar } from "notistack";

import { SubHeading } from "../Typography/Typo";
import {
  Container as DropContainer,
  InputContainer,
  Label,
} from "./FormComponents/TextField";
import * as API from "../../../api/API";
import ActionConfirm from "./FormComponents/ActionConfirm";
import { useStateValue } from "../../../context/StateProvider";
import { CustomButton } from "../fields/Button/CustomButton";
import { getUserSession, getCreatorSession } from "../../../api/API";
import { AddImageToIPFS, AddToIPFS } from "../../../services/IpfsService";
import { createCVObject } from "../../../utils/cvObject";
import DropzoneComponent from "../fields/Dropzone/DropzoneComponent";
import { FlexSpaceBetweenContainer } from "../Profile/UserProfile";
import { IconWrapper } from "../../../pages/AddProfile/AddProfile";
import store from "../../../redux/reducers";
import { ADD_USER_DETAILS } from "../../../redux/constants/ActionTypes";
import CustomView from "../CustomTextfieldModal/CustomView";
import CustomTextFieldModal from "../CustomTextfieldModal/CustomTextFieldModal";
import MultipleImageViewSlider from "./StageForm/MultipleImageViewSlider";
import PrivacyToggle from "../fields/Button/PrivacyToggle";
import { jwtDecode } from "../../../utils/jwt";
import DocumentStageForm from "./StageForm/DocumentStageForm";
import DocumentContainer from "./StageForm/DocumentContainer";
import TextEditor from "../TextEditor/TextEditor";
import { symmetricDecryption } from "../../../utils/encryption";
import Web3 from "web3";
import { getSignature } from "../../../utils/getSingature";

var phoneNoValidation =
  /^(?:0|94|\+94|0094)?(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|912)(0|2|3|4|5|7|9)|7(0|1|2|4|5|6|7|8)\d)\d{6}$/;

export const DocumentFormContainer = ({ setView, profileType }) => {
  //eslint-disable-next-line
  const currentUser = useSelector((state) => state.currentUser);
  const [authUser, setAuthUser] = useState(() => getUserSession());
  const [authCreator, setAuthCreator] = useState(() => getCreatorSession());
  const [loading, setLoading] = useState(false);
  const [showAction, setShowAction] = useState(false);
  const [{ customData, privacyData, products }, dispatch] = useStateValue();
  const matches = useMediaQuery("(max-width:1368px)");
  const [editorState, onEditorStateChange] = useState();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [openCustomModal, setCustomModalOpen] = useState(false);

  var dataListfieldKeys =
    customData.length > 0 &&
    customData.map((data) => {
      const keyData = Object.keys(data);
      return keyData;
    });

  var customDataListToMap =
    customData.length > 0 &&
    customData.map((data) => {
      return data;
    });

  function handleDocumentSubmit(e) {
    e.preventDefault();
    setShowAction(true);
  }

  const dispatchProduct = async (secretKey) => {
    setLoading(true);
    try {
      const { blockchain, signatureObj } = await getSignature(
        currentUser,
        authUser,
        secretKey
      );

      const productData = {
        description: description,
        documentName: name,
        profileType: profileType,
        customData: customData,
        // stageData: products,
      };

      const editedByData = {
        editorPublicKey: localStorage.getItem("providerToken")
          ? authCreator.publicKey
          : currentUser.publicKey,
        editorAlias: localStorage.getItem("providerToken")
          ? authCreator.alias
          : currentUser.alias,
        ipfsHash: "",
        createdAt: Date.now(),
      };

      // Get logged in user
      const user = jwtDecode();

      // Format into CV Object
      const formattedObject = createCVObject(
        productData,
        user.publicKey,
        user.publicKey,
        secretKey,
        privacyData
      );

      Object.keys(formattedObject).forEach((key, value) => {
        if (formattedObject[key]?.data) {
          formattedObject[key].editedBy.push(editedByData);
        } else if (formattedObject[key].length > 0) {
          formattedObject[key].forEach((i) => {
            i.editedBy.push(editedByData);
          });
        }
      });

      formattedObject.previousIpfsHash = "";

      // Add document to ipfs
      const productIpfsHash = await AddToIPFS(JSON.stringify(formattedObject));
      productData.latestIpfsUrl = {
        ipfsUrl: `https://niftron.infura-ipfs.io/ipfs/${productIpfsHash.ipfsHash}`,
        timestamp: Date.now(),
      };

      const response = await API.addCv({
        path: { id: user.publicKey },
        token: localStorage.niftoken,
        body: {
          latestIpfsUrl: {
            ipfsUrl: `https://niftron.infura-ipfs.io/ipfs/${productIpfsHash.ipfsHash}`,
            timestamp: Date.now(),
          },
          profileType: profileType,
          signature: signatureObj,
          network: blockchain,
          ipfsHash: productIpfsHash.ipfsHash,
        },
      });

      if (response?.data?.code === 201) {
        const userData = await API.me({
          token: localStorage.niftoken,
        });
        if (userData?.data?.code === 200) {
          store.dispatch({
            type: ADD_USER_DETAILS,
            payload: userData?.data?.data[0],
          });
          enqueueSnackbar("Profile details added", {
            variant: "success",
          });
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      document.getElementById("document-form").reset();
      setName("");
      setDescription("");
      dispatch({
        type: "CLEAR_FIELDS",
      });
      dispatch({
        type: "CLEAR_CUSTOM_DATA_FIELDS",
      });
      setLoading(false);
      setShowAction(false);
    }
    setView(2);
  };

  return (
    <Container>
      <CustomTextFieldModal
        title="Add Additional Details"
        formId="document-custom-data-form"
        setCustomModalOpen={setCustomModalOpen}
        openCustomModal={openCustomModal}
      />
      {showAction && (
        <ActionConfirm
          successMessage={{
            message: "Adding Document details into IPFS",
            width: "17rem",
          }}
          warningMessage={"Are you sure you want to submit?"}
          loading={loading}
          setShowAction={setShowAction}
          authTypeInput={authUser.authType}
          publicKey={authUser.publicKey}
          encryptedSecret={authUser.encryptedSecret}
          onSecretKey={dispatchProduct}
        />
      )}
      <GridContainer id="document-form" onSubmit={handleDocumentSubmit}>
        <FlexSpaceBetweenContainer
          style={{
            gridColumn: `${!matches ? "1/3" : "unset"}`,
            margin: "1rem 0rem",
          }}
        >
          <SubHeading primary>Basic Document Details</SubHeading>
          <IconWrapper>
            <IconButton
              onClick={() => {
                setView(0);
              }}
            >
              <ArrowBackIosNewIcon
                style={{ color: "#1e1b3b" }}
                sx={{ fontSize: 20 }}
              />
            </IconButton>
          </IconWrapper>
        </FlexSpaceBetweenContainer>

        <TextField
          label="Document Name *"
          required
          value={name}
          onChange={setName}
          placeholder="Document Name "
          id="documentName"
          form="unProductForm"
          name="documentName"
          type="text"
          maxLength="400"
          icon={<PrivacyToggle fieldName="documentName" _id="1523" />}
        />

        <TextEditor
          placeholder="Document Description *"
          fieldName="Document Description*"
          editorState={editorState}
          setDescription={setDescription}
          setEditorState={onEditorStateChange}
          icon={<PrivacyToggle fieldName="description" _id="519" />}
        />
      </GridContainer>
      <ColumnContainer>
        <SubHeading primary>Add Meta Data</SubHeading>
        <GridContainerDiv style={{ margin: "1rem 0rem 2rem 0rem" }}>
          {dataListfieldKeys &&
            dataListfieldKeys.length > 0 &&
            dataListfieldKeys.map((fieldName, key) => {
              if (Array.isArray(customDataListToMap[key][fieldName[0]])) {
                return (
                  <ColumnContainer span={"true"}>
                    <MultipleImageViewSlider
                      fieldName={fieldName[0]}
                      data={customDataListToMap[key][fieldName[0]]}
                    />
                  </ColumnContainer>
                );
              } else {
                return (
                  <TextField
                    key={key}
                    disabled
                    label={
                      fieldName[0]
                        .toString()
                        .replace(/([A-Z])/g, " $1")
                        .substring(0, 1)
                        .toUpperCase() +
                      fieldName[0]
                        .toString()
                        .replace(/([A-Z])/g, " $1")
                        .substring(1)
                    }
                    value={customDataListToMap[key][fieldName[0]]}
                  />
                );
              }
            })}
        </GridContainerDiv>
        <CustomView setCustomModalOpen={setCustomModalOpen} />
        {/* <SubHeading
          primary
          style={{
            margin: "1rem 0rem 2rem 0rem",
          }}
        >
          Add Document Sections
        </SubHeading>
        <ColumnContainer style={{ gap: "2rem" }}>
          {products.length > 0 && (
            <DocumentContainer productData={products} document={true} />
          )}
          <DocumentStageForm />
        </ColumnContainer> */}
        <ButtonWrapper>
          <CustomButton
            disabled={name === "" || description === ""}
            login
            text="Add Document Data"
            type="submit"
            form="document-form"
          />
        </ButtonWrapper>
      </ColumnContainer>
    </Container>
  );
};

export default DocumentFormContainer;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.noGap ? "0" : "1rem")};
  background-color: #eff2f5;
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  padding: 1.5rem;
`;

export const GridContainer = styled.form`
  display: grid;
  row-gap: 1rem;
  column-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 1368px) {
    grid-template-columns: 1fr;
  }
`;

export const GridContainerDiv = styled.div`
  display: grid;
  row-gap: 1rem;
  column-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 1368px) {
    grid-template-columns: 1fr;
  }
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: ${(props) => (props.span ? "1/3" : "unset")};
`;

export const ButtonWrapper = styled.div`
  padding-top: 1rem;
  display: flex;
  justify-content: flex-end;
`;

export const Column = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  grid-column: ${(props) => (props.span ? "1/3" : "unset")};
  @media (max-width: 768px) {
    grid-column: unset;
  }
`;
