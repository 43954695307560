import React, { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import Select from "react-select";
import ActionConfirm from "../../components/shared/Form/FormComponents/ActionConfirm";
import { SubHeading } from "../../components/shared/Typography/Typo";
import { useStateValue } from "../../context/StateProvider";

import {
  InputContainer,
  Label,
  Container as DropContainer,
  TextField,
} from "../../components/shared/Form/FormComponents/TextField";
import { CustomButton } from "../../components/shared/fields/Button/CustomButton";
import TextEditor from "../../components/shared/TextEditor/TextEditor";
import * as API from "../../api/API";
import { useSnackbar } from "notistack";
import { ADD_USER_DETAILS } from "../../redux/constants/ActionTypes";
import store from "../../redux/reducers";
import { createCVObject, oldCreateCVObject } from "../../utils/cvObject";
import { AddImageToIPFS } from "../../services/IpfsService";
import { jwtDecode } from "../../utils/jwt";
import DropzoneComponent from "../../components/shared/fields/Dropzone/DropzoneComponent";
import CustomView from "../../components/shared/CustomTextfieldModal/CustomView";
import CustomTextFieldModal from "../../components/shared/CustomTextfieldModal/CustomTextFieldModal";
import MultipleImageViewSlider from "../../components/shared/Form/StageForm/MultipleImageViewSlider";
import PrivacyToggle from "../../components/shared/fields/Button/PrivacyToggle";

var phoneNoValidation =
  /^(?:0|94|\+94|0094)?(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|912)(0|2|3|4|5|7|9)|7(0|1|2|4|5|6|7|8)\d)\d{6}$/;

var emailValidation =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// country select options
const countryOptions = [
  { value: "LK", label: "Sri Lanka" },
  { value: "IN", label: "India" },
  { value: "USA", label: "United States" },
  { value: "UK", label: "United Kingdom" },
  { value: "SING", label: "Singapore" },
  { value: "MALAY", label: "Malaysia" },
];

export const ProviderFormContainer = ({ setView }) => {
  const currentUser = useSelector((state) => state.currentUser);
  const [authUser, setAuthUser] = useState(() => API.getUserSession());
  const [authCreator, setAuthCreator] = useState(() => API.getCreatorSession());
  const [showAction, setShowAction] = useState(false);
  const [openCustomModal, setCustomModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [formDataState, setFormDataState] = useState([]);
  const [{ customData, privacyData }, dispatch] = useStateValue();
  const matches = useMediaQuery("(max-width:1368px)");
  const [editorState, onEditorStateChange] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState("");
  const [country, setCountry] = useState(countryOptions[0]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  var dataListfieldKeys =
    customData.length > 0 &&
    customData.map((data) => {
      const keyData = Object.keys(data);
      return keyData;
    });

  const editedByData = {
    editorPublicKey: localStorage.getItem("providerToken")
      ? authCreator.publicKey
      : currentUser.publicKey,
    editorAlias: localStorage.getItem("providerToken")
      ? authCreator.alias
      : currentUser.alias,
    ipfsHash: "",
    createdAt: Date.now(),
  };

  var customDataListToMap =
    customData.length > 0 &&
    customData.map((data) => {
      return data;
    });

  function handleProductSubmit(e) {
    e.preventDefault();
    setFormDataState(e.currentTarget);
    setShowAction(true);
  }

  //FormData
  const getFormData = async (target) => {
    //add image to ipfs
    const ipfsData = await AddImageToIPFS(files && files[0]);
    var previewImageUrl = `https://niftron.infura-ipfs.io/ipfs/${ipfsData.ipfsHash}`;
    var productObject = {};
    const formData = new FormData(target);
    if (files[0]?.preview) {
      const ipfsData = await AddImageToIPFS(files && files[0]);
      var previewImageUrl = `https://niftron.infura-ipfs.io/ipfs/${ipfsData.ipfsHash}`;
    }
    var profileObject = {};
    profileObject.country = country.value;
    profileObject.phoneNumber = phoneNumber;
    profileObject.profileImage = previewImageUrl;
    profileObject.description = description;
    profileObject.customData = customData;

    for (let [key, value] of formData.entries()) {
      profileObject[key] = value;
    }

    dispatch({
      type: "CLEAR_FIELDS",
    });

    return profileObject;
  };

  const dispatchCompanyProfile = async (secretKey) => {
    setLoading(true);
    try {
      let providerData = await getFormData(formDataState);
      // Get logged in user
      const user = jwtDecode();
      // Format into CV Object
      const formattedObject = createCVObject(
        providerData,
        user.publicKey,
        user.publicKey,
        secretKey,
        privacyData
      );

      Object.keys(formattedObject).forEach((key, value) => {
        if (formattedObject[key]?.data) {
          formattedObject[key].editedBy.push(editedByData);
        } else if (formattedObject[key].length > 0) {
          formattedObject[key].forEach((i) => {
            i.editedBy.push(editedByData);
          });
        }
      });

      formattedObject.previousIpfsHash = "";

      //add product to ipfs
      const productIpfsHash = await AddImageToIPFS(
        JSON.stringify(formattedObject)
      );
      const response = await API.updateCredentialProvider({
        path: { id: user.publicKey },
        token: localStorage.niftoken,
        body: {
          latestIpfsUrl: {
            ipfsUrl: `https://niftron.infura-ipfs.io/ipfs/${productIpfsHash.ipfsHash}`,
            timestamp: Date.now(),
          },
          image: providerData.profileImage,
          description: providerData.description,
          phoneNumber: providerData.phoneNumber,
          country: providerData.country,
          name: providerData.name,
          city: providerData.city,
        },
      });
      if (response?.data?.code === 202) {
        const userData = await API.me({
          token: localStorage.niftoken,
        });
        if (userData?.data?.code === 200) {
          store.dispatch({
            type: ADD_USER_DETAILS,
            payload: userData?.data?.data[0],
          });
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      document.getElementById("mainProvider-form").reset();
      setFiles([]);
      enqueueSnackbar(`Profile details added`, {
        variant: "success",
      });
      setLoading(false);
      setShowAction((value) => !value);
    }
    setView(2);
  };

  return (
    <Container>
      <CustomTextFieldModal
        title="Add Additional Details"
        formId="custom-data-form"
        setCustomModalOpen={setCustomModalOpen}
        openCustomModal={openCustomModal}
      />
      {showAction && (
        <ActionConfirm
          successMessage={{
            message: "Adding Profile details into IPFS",
            width: "16rem",
          }}
          warningMessage={"Are you sure you want to submit?"}
          loading={loading}
          setShowAction={setShowAction}
          authTypeInput={authUser.authType}
          publicKey={authUser.publicKey}
          encryptedSecret={authUser.encryptedSecret}
          onSecretKey={dispatchCompanyProfile}
        />
      )}
      <GridContainer id="mainProvider-form" onSubmit={handleProductSubmit}>
        <SubHeading
          primary
          style={{
            gridColumn: `${!matches ? "1/3" : "unset"}`,
            margin: "1rem 0rem",
          }}
        >
          Basic Details
        </SubHeading>
        <TextField
          span
          label="Creator  Name *"
          required
          placeholder="Creator Name"
          id="name"
          name="name"
          type="text"
          maxLength="50"
          icon={<PrivacyToggle fieldName="name" _id="1523" />}
        />
        <TextEditor
          placeholder="About *"
          fieldName="About the Creator"
          editorState={editorState}
          setDescription={setDescription}
          setEditorState={onEditorStateChange}
          icon={<PrivacyToggle fieldName="description" _id="619" />}
        />
        <DropContainer style={{ gridRow: "span 2" }}>
          <PrivacyToggle
            fieldName="profileImage"
            _id="218784"
            select
            top="25%"
            right="1.9%"
          />
          <InputContainer>
            <Label>Institution Logo *</Label>
          </InputContainer>
          <DropzoneComponent
            files={files}
            id="dropzone"
            amount="1"
            form="none"
            multiple={false}
            errors={errors}
            setErrors={setErrors}
            maxSize="1000000"
            setFiles={setFiles}
            type="image/jpeg, image/png"
            description="Drop or select your Logo (Max file size 10MB)"
          />
        </DropContainer>
        <TextField
          label="Address Line-1 *"
          placeholder="Address Line One"
          id="addressLineOne"
          required
          name="addressLineOne"
          type="text"
          maxLength="100"
          icon={<PrivacyToggle fieldName="addressLineOne" _id="53658" />}
        />
        <TextField
          label="Address Line-2"
          placeholder="Address Line Two"
          id="addressLineTwo"
          name="addressLineTwo"
          maxLength="100"
          icon={<PrivacyToggle fieldName="addressLineTwo" _id="21763" />}
        />
        <TextField
          label="City *"
          placeholder="City"
          id="city"
          required
          name="city"
          type="text"
          maxLength="20"
          icon={<PrivacyToggle fieldName="city" _id="92282" />}
        />
        <Column>
          <Label>Country *</Label>
          <PrivacyToggle
            fieldName="country"
            _id="921727"
            select
            top={matches ? "50%" : "40%"}
            right="3%"
          />
          <Select
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                outline: "none",
                text: "orangered",
                primary25: "#2f3a6039",
                primary: " #2f3a60a7",
                neutral0: "#c3d0e1",
                boxShadow: "none",
              },
            })}
            name="country"
            defaultValue={countryOptions[0]}
            options={countryOptions}
            value={country}
            onChange={setCountry}
          />
        </Column>
        <TextField
          label="Phone Number *"
          placeholder="0/ 94/ +94/ 0094"
          id="phoneNumber"
          required
          name="phoneNumber"
          type="text"
          maxLength="13"
          value={phoneNumber}
          onChange={(e) => {
            if (!phoneNoValidation.test(e)) {
              setPhoneNumberError(true);
            } else {
              setPhoneNumberError(false);
            }
            setPhoneNumber(e);
          }}
          color="#FF613F"
          helper={phoneNumberError && "Invalid Mobile Number"}
          icon={<PrivacyToggle fieldName="phoneNumber" _id="27310" />}
        />
        <SubHeading
          primary
          style={{ gridColumn: `${!matches ? "1/3" : "unset"}` }}
        >
          Additional Creator Details
        </SubHeading>{" "}
        {dataListfieldKeys &&
          dataListfieldKeys.length > 0 &&
          dataListfieldKeys.map((fieldName, key) => {
            if (Array.isArray(customDataListToMap[key][fieldName[0]])) {
              return (
                <ColumnContainer span={"true"}>
                  <MultipleImageViewSlider
                    fieldName={fieldName[0]}
                    data={customDataListToMap[key][fieldName[0]]}
                  />
                </ColumnContainer>
              );
            } else {
              return (
                <TextField
                  key={key}
                  disabled
                  label={
                    fieldName[0]
                      .toString()
                      .replace(/([A-Z])/g, " $1")
                      .substring(0, 1)
                      .toUpperCase() +
                    fieldName[0]
                      .toString()
                      .replace(/([A-Z])/g, " $1")
                      .substring(1)
                  }
                  value={customDataListToMap[key][fieldName[0]]}
                />
              );
            }
          })}
        <CustomView
          style={{ gridColumn: `${!matches ? "1/3" : "unset"}` }}
          setCustomModalOpen={setCustomModalOpen}
        />
      </GridContainer>
      <ColumnContainer>
        <ButtonWrapper>
          <CustomButton
            login
            text="Submit"
            type="submit"
            form="mainProvider-form"
          />
        </ButtonWrapper>
      </ColumnContainer>
    </Container>
  );
};

export default ProviderFormContainer;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.noGap ? "0" : "1rem")};
  background-color: #eff2f5;
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  padding: 1.5rem;
`;

export const GridContainer = styled.form`
  display: grid;
  row-gap: 1rem;
  column-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 1368px) {
    grid-template-columns: 1fr;
  }
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: ${(props) => (props.span ? "1/3" : "unset")};
`;

export const ButtonWrapper = styled.div`
  padding-top: 1rem;
  display: flex;
  justify-content: flex-end;
`;

export const Column = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  grid-column: ${(props) => (props.span ? "1/3" : "unset")};
  @media (max-width: 768px) {
    grid-column: unset;
  }
`;
