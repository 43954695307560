import React, { useState } from "react";
import { useSelector } from "react-redux";
import { OverLayContainer } from "../EditForm";
import { ButtonWrapper, ColumnContainer } from "../../Form/Web3ResumeContainer";
import { FlexSpaceBetweenContainer } from "../../Profile/UserProfile";
import { SubHeading } from "../../Typography/Typo";
import { CustomButton } from "../../fields/Button/CustomButton";
import { IconWrapper } from "../../Profile/UserProfileHeader";
import EditIcon from "@mui/icons-material/Edit";
import { useStateValue } from "../../../../context/StateProvider";
import { IconButton } from "@mui/material";
import AddCustomDataForm from "./AddCustomDataForm";
import { Container, OverFlowContainer } from "./MultiCustomDataEditComponent";
import { CustomTextFieldForm } from "../../Form/FormComponents/CustomTextFieldForm";
import { getFormDataById } from "../../../../utils/getFormData";
import { useSnackbar } from "notistack";

function AddCustomDataEditComponent({ setOverLay, setStatus, showEdit }) {
  const currentUser = useSelector((state) => state.currentUser);
  const [customImageArr, setCustomImageArr] = useState([]);

  let formId = "add-custom-data-form";
  const [{ fields }, dispatch] = useStateValue();
  const { enqueueSnackbar } = useSnackbar();

  const closeEdit = () => {
    dispatch({
      type: "CLEAR_CUSTOM_DATA_FIELDS",
    });
    setOverLay(false);
  };

  const validateImage = (arr) => {
    let tempImageArr = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].select.value === "image") {
        tempImageArr.push(arr[i]);
      }
    }
    if (customImageArr.length != tempImageArr.length) {
      return true;
    } else {
      return false;
    }
  };

  const isEmpty = (object) =>
    Object.values(object).every((x) => x === null || x === "");

  function handleAddNewCustomData(e) {
    e.preventDefault();
    const customDataArr = getFormDataById(formId);

    if (!isEmpty(customDataArr) || customImageArr.length > 0) {
      const imageArr = Object.assign({}, ...customImageArr);

      let customDataRefined = {
        ...customDataArr,
        ...imageArr,
      };

      const keys = Object.keys(customDataRefined);

      keys.forEach((key, index) => {
        const customItem = {};
        customItem[keys[index]] = customDataRefined[key];
        customItem.isPublic = fields[index]?.isPublic;

        console.log(customItem);

        dispatch({
          type: "ADD_CUSTOM_DATA_FIELDS",
          item: customItem,
        });
      });
      dispatch({
        type: "CLEAR_FIELDS",
      });
      setCustomImageArr([]);

      setStatus(3);
      document.getElementById(formId).reset();
    } else {
      if (customDataArr) {
        if (isEmpty(customDataArr))
          return enqueueSnackbar("Please fill in the form fields", {
            variant: "error",
          });
      } else {
        if (validateImage(fields))
          return enqueueSnackbar("Please upload images", {
            variant: "error",
          });
      }
    }
  }

  return (
    <>
      <OverLayContainer onClick={() => closeEdit()} />
      <Container>
        <OverFlowContainer style={{ width: "90%" }}>
          <FlexSpaceBetweenContainer
            style={{
              width: "95%",
              position: "absolute",
              left: "0",
              top: "0",
              backgroundColor: "#fff",
              borderRadius: "10px",
              zIndex: "1",
            }}
          >
            <SubHeading style={{ padding: "2rem 3rem" }} primary>
              {currentUser.profileType === 4
                ? "Add Document Data"
                : "Add Additional Data"}
            </SubHeading>
            {!showEdit && (
              <IconWrapper style={{ backgroundColor: "#7271713b" }}>
                <IconButton onClick={() => setStatus(2)}>
                  <EditIcon
                    style={{ color: "#8a8a8a" }}
                    sx={{ fontSize: 20 }}
                  />
                </IconButton>
              </IconWrapper>
            )}
          </FlexSpaceBetweenContainer>
          {fields?.length > 0 && (
            <AddCustomDataForm
              formId={formId}
              setCustomImageArr={setCustomImageArr}
              customImageArr={customImageArr}
            />
          )}
          <ColumnContainer style={{ width: "100%", margin: "4.5rem 0rem" }}>
            <CustomTextFieldForm />
          </ColumnContainer>

          <ColumnContainer
            style={{
              width: "100%",
              position: "absolute",
              right: "0",
              bottom: "0",
              backgroundColor: "#fff",
              borderRadius: "10px",
            }}
          >
            <ButtonWrapper style={{ gap: "1rem", padding: "2rem 3rem" }}>
              <CustomButton
                login
                text="Cancel"
                rejectClass
                type="button"
                onclick={() => setOverLay(false)}
              />
              <CustomButton
                onclick={handleAddNewCustomData}
                login
                text="Add New Data"
                disabled={fields.length <= 0}
              />
            </ButtonWrapper>
          </ColumnContainer>
        </OverFlowContainer>
      </Container>
    </>
  );
}

export default AddCustomDataEditComponent;
