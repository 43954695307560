import { useState } from "react";
import styled from "styled-components";
import * as yup from "yup";
import {
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { makeStyles, withStyles } from "@mui/styles";
import { Text } from "../../components/shared/Typography/Typo";
import { CustomButton } from "../../components/shared/fields/Button/CustomButton";
import { NIFTRON } from "niftron-client-sdk";
import { Link } from "react-router-dom";
import { Column } from "../../components/shared/Form/Web3ResumeContainer";

//Progress button styles
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 4,
    borderRadius: 4,
    width: "100%",
  },
  colorPrimary: {
    backgroundColor: "transparent",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#80B7FF",
    animationDuration: "550ms",
  },
}))(LinearProgress);

const useStyles = makeStyles(() => ({
  TextField: {
    borderRadius: 5,
    color: "#ffffff !important",
  },
}));

//Validating form
const validationSchema = yup.object({
  alias: yup.string("Enter Alias/Email/PK").required("Required"),
  password: yup.string("Enter your Password").required("Required"),
});

export const LoginForm = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  // SetEmail and Alias
  const [passwordValues, setPasswordValues] = useState({
    password: "",
    showPassword: false,
  });

  // Form status
  const Login = async (data, actions) => {
    try {
      actions.setSubmitting(true);
      //the jwt is already set as niftoken in local storage
      await NIFTRON.user.login(`${data.alias.toLowerCase()}`, data.password);
      window.location.assign("/");
      enqueueSnackbar("Logged in Successfully", {
        variant: "success",
      });
      //get user object if need to display from server
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    } finally {
      actions.setSubmitting(false);
    }
  };

  //Functions
  const handleClickShowPassword = () => {
    setPasswordValues({
      ...passwordValues,
      showPassword: !passwordValues.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Formik
      initialValues={{
        alias: "",
        password: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        await Login(values, actions);
      }}
    >
      {/* Formik Props */}
      {({
        values,
        touched,
        errors,
        isValid,
        dirty,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <ContainerForm style={{ rowGap: "2rem" }}>
          {/* Alias */}
          <TextField
            fullWidth
            size="small"
            variant="standard"
            name="alias"
            id="alias"
            label="Alias/PK"
            classes={{ root: classes.TextField }}
            disabled={isSubmitting}
            value={values.alias}
            type="alias"
            helperText={errors.alias && touched.alias ? errors.alias : null}
            error={errors.alias && touched.alias ? true : false}
            onChange={handleChange}
            onBlur={handleBlur}
            required
            InputLabelProps={{
              style: {
                color: "#fff",
                fontFamily: "Poppins",
              },
            }}
            InputProps={{
              style: { color: "#fff", padding: ".2rem" },
            }}
          />
          {/* Password */}
          <TextField
            fullWidth
            size="small"
            variant="standard"
            disabled={isSubmitting}
            name="password"
            label="Password"
            classes={{ root: classes.TextField }}
            id="password"
            value={values.password}
            required
            helperText={
              errors.password && touched.password ? errors.password : null
            }
            error={errors.password && touched.password ? true : false}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            InputLabelProps={{
              style: {
                color: "#fff",
                fontFamily: "Poppins",
              },
            }}
            InputProps={{
              style: { color: "#fff", padding: ".2rem" },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    disabled={isSubmitting}
                  >
                    {passwordValues.showPassword ? (
                      <VisibilityIcon style={{ fill: "#ffffff" }} />
                    ) : (
                      <VisibilityOffIcon style={{ fill: "#ffffff" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
              type: passwordValues.showPassword ? "text" : "password",
              autoComplete: "new-password",
            }}
          />

          <Column style={{ gap: "0.3rem" }}>
            <Text lighter small style={{ width: "100%" }}>
              <Link
                to="/password-reset"
                style={{ color: "#fff", textDecoration: "none" }}
              >
                Forgot Password?
              </Link>
            </Text>{" "}
            <Text lighter small style={{ width: "100%" }}>
              <Link
                to="login-with-secret-key"
                style={{ color: "#34e78d", textDecoration: "none" }}
              >
                Login with SecretKey
              </Link>
            </Text>
          </Column>

          {isSubmitting ? (
            <LoadingContainer>
              <BorderLinearProgress />
              <Text small lighter style={{ width: "80px" }}>
                Verifying <span className="loading"></span>
              </Text>
            </LoadingContainer>
          ) : (
            <CustomButton
              style={{ marginTop: "2rem" }}
              disabled={!isValid || (isSubmitting && dirty)}
              onclick={handleSubmit}
              text="Sign In"
              login
              loginIcon
              loginClass
            />
          )}
        </ContainerForm>
      )}
    </Formik>
  );
};

export const DroppableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: center;
  justify-content: space-evenly;
`;

export const InnerContainer = styled.div`
  height: 10rem;
  text-align: center;
  word-wrap: break-word;
  border: dashed 0.75px #dddddda4;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  background-color: #dddddd37;
`;

export const ContainerForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1.7rem;
  align-items: center;
  margin-bottom: 8rem;
  justify-content: space-evenly;
`;

export const LoadingContainer = styled.div`
  height: 48px;
  width: 100%;
  margin-top: 2rem;
  gap: 0.3rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;
