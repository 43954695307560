import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import moment from "moment";

import { SubHeading, Text } from "../../components/shared/Typography/Typo";
import { assignQr, getObject, retrieveAssignedProducts } from "../../api/API";
import AdditionalDetails from "../../components/shared/Profile/AdditionalDetails";
import { urlToHash } from "../../utils/ipfs";
import { GetIPFSData } from "./../../services/IpfsService";
import InnerLoadingView from "../../components/shared/loading/InnerLoadingView";
import { Error, ErrorContainer } from "../Claim/ClaimView";
import ErrorBG from "../../assets/images/not-found.gif";
import ViewPagination from "../../components/shared/Pagination/Pagination";
import { SearchComponent } from "./../JobListings/SearchComponent";
import ClaimCard from "./../Claim/ClaimCard";
import * as API from "../../api/API";

const ScannedList = () => {
  const { id } = useParams();
  const location = useLocation();

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [date, onDateChange] = useState();
  const currentUser = useSelector((state) => state.currentUser);
  const [mainPageLoading, setMainPageLoading] = useState(false);
  const [tokensData, setTokenData] = useState([]);

  const itemsPerPage = 6;

  const paginate = (event, value) => {
    setCurrentPage(value);
    window.scroll(0, 0);
  };

  const fetchLatestIpfsData = async (data) => {
    let arr = [];

    for (let i = 0; i < data?.length; i++) {
      try {
        const response = await API.getObject({
          aId: data[i].uniqueId,
        });
        if (response !== undefined) {
          arr.push(response.data.data[0]);
        }
      } catch (e) {
        console.log(e);
      }
    }

    setTokenData(arr);
    setMainPageLoading(false);
  };

  const fetchScannedItems = async () => {
    setMainPageLoading(true);
    try {
      const response = await API.retrieveScannedItems({
        path: {
          pId: currentUser.publicKey,
        },
      });
      if (response?.data?.code === 200) {
        fetchLatestIpfsData(response.data.data);
      } else {
        setMainPageLoading(false);
      }
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentTokenList =
    tokensData &&
    tokensData.length > 0 &&
    tokensData.slice(indexOfFirstPost, indexOfLastPost);

  useEffect(() => {
    fetchScannedItems();
  }, []);

  return (
    <Container>
      <SearchComponent
        searchValue={search}
        onSearchChange={setSearch}
        dateValue={date}
        placeholder="Search items"
        onDateChange={(e) => {
          onDateChange(e);
          setSearch(moment(e).format("L"));
        }}
      />

      {(() => {
        if (mainPageLoading) {
          return <InnerLoadingView style={{ marginTop: "1rem" }} />;
        } else if (tokensData <= 0) {
          return (
            <ErrorContainer>
              <Error bg={ErrorBG} width="470px" />
              <Text style={{ marginBottom: "1rem" }} primary>
                No Scanned Items!
              </Text>
            </ErrorContainer>
          );
        } else {
          return (
            <Fragment>
              <GridContainer>
                {currentTokenList &&
                  currentTokenList
                    .filter((item) => {
                      if (search === "" && date === undefined) {
                        return item;
                      } else if (
                        item.itemName &&
                        item.itemName
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      ) {
                        return item;
                      } else if (
                        item.date &&
                        moment(item.date).format("L").includes(search)
                      ) {
                        return item;
                      }
                      return item;
                    })
                    ?.map((item, key) => {
                      return <ClaimCard data={item} key={key} token />;
                    })}
              </GridContainer>
              {search === "" && (
                <ViewPagination
                  itemsPerPage={itemsPerPage}
                  totalItems={tokensData && tokensData.length}
                  paginate={paginate}
                />
              )}
            </Fragment>
          );
        }
      })()}
    </Container>
  );
};

export default ScannedList;

const Container = styled.div`
  display: flex;
  border-radius: 10px;
  border: 0.75px solid #d3d3d3b0;
  gap: 1rem;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 1rem;
  min-height: 55vh;
`;

// const GridContainer = styled.div`
//   gap: 1.5rem;
//   margin: 1.5rem 0rem;
//   display: flex;
//   flex-wrap: wrap;
//   flex-direction: row;
//   justify-content: flex-start;

//   @media (max-width: 768px) {
//     width: 100%;
//     flex-direction: column;
//   }
// `;
const GridContainer = styled.div`
  width: 100%;
  gap: 1rem;
  margin: 1rem 0rem;
  display: flex;
  flex-direction: column;
`;

