import React, { Fragment } from "react";
import styled from "styled-components";
import { Text } from "../Typography/Typo";
import { LoginIcon } from "../fields/Button/LoginIcon";
import DecryptGif from "../../../assets/images/decrypt.gif";
import { LoadingContainer } from "../../../pages/Login/LoginForm";
import { CustomButton } from "../fields/Button/CustomButton";
import Search from "../fields/Search/Search";
import image from "../../../assets/images/text logo.png";
import moment from "moment";
import { withStyles } from "@mui/styles";
import { LinearProgress } from "@mui/material";
import {
  Image,
  ImageContainer,
  ImageWrapper,
} from "../fields/Search/SearchRow";
import Divider from "@mui/material/Divider";
import { Gif } from "../ProductProfile/RequestVerification";
import { ProfileImage, SmallImageWrapper } from "./VerificationCard";
import { ColumnLoadContainer } from "../Profile/ShareConfirm";
import LoadingGif from "../../../assets/images/loading.gif";
import PDFUploaderDropzone from "./PDFUploaderDropzone";

//Progress button styles
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 4,
    borderRadius: 4,
    width: "100%",
  },
  colorPrimary: {
    backgroundColor: "transparent",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#80B7FF",
    animationDuration: "550ms",
  },
}))(LinearProgress);

const SubmitVerification = ({
  verification,
  handleSubmit,
  searchData,
  setFiles,
  files,
  data,
  searchInput,
  searchError,
  setShowAction,
  handleInputChange,
  searchLoading,
  successMessage,
  loading,
}) => {
  const maxlimit = 70;

  return (
    <Container>
      {(() => {
        if (loading) {
          return (
            <ColumnLoadContainer>
              <Gif src={LoadingGif} style={{ width: "23rem" }} />
              <LoadingContainer>
                <BorderLinearProgress />
                <Text
                  primary
                  small
                  lighter
                  style={{ width: successMessage.width }}
                >
                  {successMessage.message}
                  <span className="loading"></span>
                </Text>
              </LoadingContainer>
            </ColumnLoadContainer>
          );
        } else {
          return (
            <Fragment>
              {verification?.isPublic === false ? (
                <>
                  <FlexContainer>
                    <Text primary>{`Type : ${verification.type}`}</Text>
                    <Text primary>Unverified</Text>
                  </FlexContainer>
                  <Divider
                    style={{
                      background: "#36353511",
                      width: "100%",
                      margin: "0.5rem 0rem",
                    }}
                  />
                  <FlexContainer addPadding>
                    <ColumnContainer>
                      <Text primary lighter small>
                        Please Decrypt the Data to continue...
                      </Text>
                    </ColumnContainer>
                    <LoginIcon large />
                  </FlexContainer>
                  <Divider
                    style={{
                      width: "100%",
                    }}
                  />
                  <ColumnContainer
                    style={{
                      padding: "0.5rem 0rem 0rem 2.4rem",
                      marginTop: "-5.2rem",
                    }}
                  >
                    <Gif src={DecryptGif} style={{ width: "18rem" }} />
                  </ColumnContainer>
                  <CustomButton
                    wide
                    login
                    onclick={() => setShowAction(false)}
                    text="Cancel"
                  />
                </>
              ) : (
                <>
                  <FlexContainer>
                    <Text primary>{`Type : ${verification.type}`}</Text>
                    <Text primary>Unverified</Text>
                  </FlexContainer>
                  <Divider
                    style={{
                      background: "#36353511",
                      width: "100%",
                      margin: "0.5rem 0rem",
                    }}
                  />
                  <FlexContainer addPadding>
                    <ColumnContainer>
                      <Text primary lighter small>
                        Please Select an authority
                      </Text>
                    </ColumnContainer>
                    <LoginIcon large />
                  </FlexContainer>
                  <Divider
                    style={{
                      width: "100%",
                      marginBottom: "0.5rem",
                    }}
                  />
                  <Search
                    loading={searchLoading}
                    error={searchError}
                    reducer="AUTHORITY"
                    style={{ width: "100%" }}
                    placeholder="Search for an Authority"
                    searchValue={searchInput}
                    onSearchChange={handleInputChange}
                    data={data}
                    withSelect
                  />
                  <FlexContainer
                    addPadding
                    style={{
                      marginTop: "0.5rem",
                    }}
                  >
                    <ColumnContainer>
                      <Text primary>{verification.type}</Text>
                    </ColumnContainer>
                  </FlexContainer>
                  <FlexContainer>
                    <ColumnContainer
                      style={{ marginTop: "3rem", width: "100%" }}
                    >
                      <PDFUploaderDropzone
                        files={files}
                        id="dropzone"
                        amount="2"
                        multiple={true}
                        maxSize="3000000"
                        setFiles={setFiles}
                        type="application/pdf ,image/jpeg, image/png"
                        description="Drop or select File (Max file size 5MB)"
                      />
                    </ColumnContainer>
                  </FlexContainer>
                  <FlexContainer
                    style={{
                      width: "100%",
                      marginTop: "1rem",
                      marginBottom: "2rem",
                    }}
                  >
                    {searchData?.alias && (
                      <FlexContainerSearch>
                        <ImageContainer>
                          <ImageWrapper>
                            <Image src={image} alt="logo" />
                          </ImageWrapper>
                          <Text lighter small color="#394673">
                            {searchData?.alias}
                          </Text>
                        </ImageContainer>
                      </FlexContainerSearch>
                    )}
                  </FlexContainer>
                  <CustomButton
                    style={{ width: "100%" }}
                    login
                    wide
                    disabled={Object.keys(searchData).length === 0}
                    onclick={handleSubmit}
                    text="Request Verification"
                  />
                </>
              )}
            </Fragment>
          );
        }
      })()}
    </Container>
  );
};

export default SubmitVerification;

const FlexContainerSearch = styled.div`
  text-align: center;
  width: 100%;
  background: #eaeaf3;
  align-items: center;
  border-radius: 5px;
  display: flex;
  cursor: pointer;
  padding: 0.5rem 1rem;
  gap: 1rem;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const FlexContainer = styled.div`
  width: 100%;
  padding: ${(props) => (props.addPadding ? "0.5rem 0rem" : "0rem")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem, 0rem;
`;

const Container = styled.div`
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  width: 40%;
  z-index: 999;
  height: auto;
  min-height: 30rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  @media (max-width: 768px) {
    width: 80%;
    padding: 1.5rem;
  }
  @media (max-width: 1368px) {
    width: 80%;
  }
`;
