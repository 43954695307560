import React, { useState } from "react";
import styled from "styled-components";
import { ButtonContainer } from "../../components/shared/DashboardComponents/ButtonContainer";
import { SubHeading, Text } from "../../components/shared/Typography/Typo";
import { CustomButton } from "../../components/shared/fields/Button/CustomButton";
import { useHistory } from "react-router-dom";
import { NIFTRON } from "niftron-client-sdk";

const Settings = () => {
  const history = useHistory();
  return (
    <Container>
      <ButtonContainer
        heading="Settings"
        description={"Access all the settings here"}
        icon={"settings"}
      />
      <InnerContainer>
        <Field>
          <ContentWithPadding>
            <SubHeading primary>View Credentials</SubHeading>
            <Text primary small lighter>
              You will find all your project related keys here.
            </Text>
          </ContentWithPadding>
          <CustomButton
            rejectClass
            login
            text="View Credentials"
            type="button"
            onclick={() => history.push("/dashboard/settings/credentials")}
          />
        </Field>
        <Field>
          <ContentWithPadding>
            <SubHeading primary>Change Password</SubHeading>
            <Text primary small lighter>
              Request a credential change
            </Text>
          </ContentWithPadding>
          <CustomButton
            rejectClass
            login
            text="Reset Password"
            type="button"
            onclick={() => history.push("/password-reset")}
          />
        </Field>
        <Field>
          <ContentWithPadding>
            <SubHeading primary>Sign Out</SubHeading>
            <Text primary small lighter>
              Sign Out from DePro
            </Text>
          </ContentWithPadding>
          <CustomButton
            rejectClass
            login
            text="Log Out"
            type="button"
            onclick={() => {
              NIFTRON.user.logout();
              localStorage.removeItem("secretKey");
              localStorage.clear();
            }}
          />
        </Field>
      </InnerContainer>
    </Container>
  );
};

export default Settings;

export const InnerContainer = styled.div`
  display: flex;
  border-radius: 10px;
  border: 0.75px solid #d3d3d3b0;
  gap: 1rem;
  flex-direction: column;
  padding: 1.5rem;
  margin-bottom: 1rem;
  min-height: 55vh;
`;

const Container = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
`;

export const Field = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 0.5px solid #9b9b9b46;
  margin: 0rem 1rem;
  justify-content: space-between;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentWithPadding = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 0rem;
`;
