import React from "react";
import { ButtonContainer } from "../../components/shared/DashboardComponents/ButtonContainer";
import styled from "styled-components";
import { Error, ErrorContainer } from "../../pages/Claim/ClaimView";
import { SubHeading, Text } from "../../components/shared/Typography/Typo";
import ErrorBG from "../../assets/images/not-found.gif";
import { useLocation } from "react-router-dom";
import { UniqueNFTView } from "./UniqueNFTView";
import { LimitedSFTView } from "./LimitedSFTView";
import { NonMetaDataSFT } from "./NonMetaDataSFT";

const ViewToken = () => {
  const { state } = useLocation();

  if (state?.item) {
    if (state?.item?.tokenType === "UNIQUENFTS") {
      return <UniqueNFTView data={state?.item} />;
    } else if (state?.item?.tokenType === "LIMITEDSFTS") {
      return <LimitedSFTView data={state?.item} />;
    } else if (state?.item?.tokenType === "NONMETADATASFTS") {
      return <NonMetaDataSFT data={state?.item} />;
    }
  } else {
    return (
      <ErrorContainer>
        <Error bg={ErrorBG} width="500px">
          <SubHeading primary>Token Not Found!</SubHeading>
        </Error>
        <Text primary>Looks like you're lost</Text>
      </ErrorContainer>
    );
  }
};

export default ViewToken;
