import { Button, CircularProgress, IconButton, Modal } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import React, { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import moment from "moment";
import { useSnackbar } from "notistack";
import CancelIcon from "@mui/icons-material/Cancel";
import CircleIcon from "@mui/icons-material/Circle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Web3 from "web3";

import { ImageFlexContainer, LargeImage } from "../../../pages/ViewCV/Field";
import { reviewVerification } from "../../../api/API";
import { FlexIconContainer } from "../VerificationViews/VerificationEntityCard";
import { urlToHash } from "../../../utils/ipfs";
import * as API from "../../../api/API";
import * as encryption from "../../../utils/encryption";
import { getSignature } from "../../../utils/getSingature";

function VerificationTableRow({ data, id, onChange }) {
  const [authUser, setAuthUser] = useState(() => API.getUserSession());
  const currentUser = useSelector((state) => state.currentUser);
  const [openImage, setImageOpen] = useState(false);
  const handleImageOpen = () => setImageOpen(true);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const handleImageClose = () => {
    setImageOpen(false);
  };

  const handleReviewVerification = async (data) => {
    setLoading(true);
    try {
      const { blockchain, signatureObj } = await getSignature(
        currentUser,
        authUser,
        localStorage.secretKey
      );

      const response = await reviewVerification({
        path: {
          vId: data?.data?.senderPublicKey,
        },
        query: {
          status: data?.verificationStatus,
        },
        body: {
          dataType: data?.data?.dataType,
          id: data?.data?._id,
          reviewerPublicKey: currentUser.publicKey,
          network: blockchain,
          signature: signatureObj,
        },
      });
      if (response?.data?.code === 201) {
        onChange();
        let message =
          data.verificationStatus === "approve"
            ? "Effectively Checked!"
            : "Effectively unconfirmed!";
        enqueueSnackbar(message, {
          variant: "success",
        });
      }
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Tr>
      <Td>{id + 1}</Td>
      <Td>
        <Modal
          open={openImage}
          onClose={handleImageClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ImageFlexContainer>
            <div style={{ position: "relative" }}>
              <LargeImage
                src={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(
                  data?.senderImage
                )}`}
                alt="image"
              />
            </div>
          </ImageFlexContainer>
        </Modal>
        <FlexContainer>
          <ImageWrapper
            image={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(
              data?.senderImage
            )}`}
            onClick={() => {
              handleImageOpen();
            }}
          />
          {data?.senderAlias}
        </FlexContainer>
      </Td>
      <Td>
        {" "}
        <FlexContainer>
          {data?.verificationStatus === 0 ? (
            <FlexIconContainer>
              <CircleIcon
                style={{
                  color: "#df642b",
                }}
              />
              Pending
            </FlexIconContainer>
          ) : data?.verificationStatus === 1 ? (
            <FlexIconContainer>
              <CheckCircleIcon
                style={{
                  color: "#1ccc54",
                }}
              />
              Approved
            </FlexIconContainer>
          ) : (
            <FlexIconContainer>
              <CircleIcon
                style={{
                  color: "#cf2020",
                }}
              />
              Rejected
            </FlexIconContainer>
          )}
        </FlexContainer>
      </Td>
      <Td minWidth="10rem">{moment(data.createdAt).format("LLL")}</Td>
      <Td>
        {data?.dataType?.name ? data?.dataType?.name : data?.dataType?.id}
      </Td>
      <Td>
        <FlexContainer>
          <IconButton
            disabled={loading}
            onClick={() => {
              handleReviewVerification({
                verificationStatus: "reject",
                data: data,
              });
            }}
          >
            {loading ? (
              <CircularProgress size={20} />
            ) : (
              <CancelIcon style={{ color: "#ff623f" }} />
            )}
          </IconButton>
          <Button
            style={{
              background: "#1fc36157",
              color: "#080036",
              textTransform: "inherit",
              fontWeight: "500",
              fontFamily: "Poppins",
              padding: ".3rem 1rem",
            }}
            disabled={loading}
            onClick={() =>
              history.push({
                pathname: `/dashboard/verifications/${data?._id}`,
                state: { item: data },
              })
            }
            // disabled={loading}
            size="small"
          >
            Review
          </Button>
        </FlexContainer>
      </Td>
    </Tr>
  );
}

export default VerificationTableRow;

const Tr = styled.tr`
  &:nth-child(odd) {
    background: rgba(57, 70, 115, 0.048);
  }
  &:hover {
    background: rgba(57, 70, 115, 0.226);
  }
`;

const Td = styled.td`
  min-width: ${(props) => props.minWidth};
  padding: 0.6rem 1rem;
  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

const ImageWrapper = styled.div`
  background-color: #fff;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 2rem;
  cursor: pointer;
  width: 2rem;
  border-radius: 50px;
`;

export const FlexContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;
