import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import AddTokenModal from "../../components/shared/Form/TokensForm/AddTokenModal";
import * as API from "../../api/API";
import {
  TableOuterWrapper,
  TableWrapper,
} from "../CertificatesView/TokenCredentialsTableView";
import Table from "../../components/shared/Table/Table";
import InnerLoadingView from "../../components/shared/loading/InnerLoadingView";
import { AccessButton } from "../CredentialProviderProduct/ProfileListCard";
import { TokenPDFFile } from "../../utils/TokenPDFFile";

const dataHead = [
  "Token Name",
  "Token Type",
  "Description",
  "Image",
  "Symbol",
  "Decimal",
  "Created At",
  "",
];

const ViewTokenList = () => {
  const currentUser = useSelector((state) => state.currentUser);
  const { enqueueSnackbar } = useSnackbar();
  const [showAddToken, setShowAddToken] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addTokenLoading, setAddTokenLoading] = useState(false);
  const [tokensList, setTokensList] = useState([]);

  const handleAddToken = async (data) => {
    setAddTokenLoading(true);
    try {
      data["profilePublicKey"] = currentUser.publicKey;
      const addTokenResponse = await API.createToken({
        body: data,
      });

      if (addTokenResponse?.data?.code === 201) {
        setShowAddToken(false);
        fetchTokens();
      }

      if (addTokenResponse?.data?.code === 201) {
        enqueueSnackbar(`Token Added`, {
          variant: "success",
        });
        fetchTokens();
      } else {
        enqueueSnackbar(`Token Added Failed`, {
          variant: "error",
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setAddTokenLoading(false);
    }
  };

  const fetchTokens = async () => {
    setLoading(true);
    try {
      const fetchTokenResponse = await API.retrieveTokens({
        path: {
          tId: currentUser.publicKey,
        },
      });

      if (fetchTokenResponse) {
        setTokensList(fetchTokenResponse?.data?.data.reverse());
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTokens();
  }, []);
  return (
    <Container>
      {showAddToken && (
        <AddTokenModal
          show={showAddToken}
          handleClose={setShowAddToken}
          handleAddToken={handleAddToken}
          addTokenLoading={addTokenLoading}
        />
      )}

      <IconContainer>
        <AccessButton
          onClick={() => {
            setShowAddToken(true);
          }}
        >
          {" "}
          Create
        </AccessButton>{" "}
        {tokensList.length > 0 && (
          <AccessButton
            onClick={() => {
              TokenPDFFile(tokensList);
            }}
          >
            {" "}
            Export
          </AccessButton>
        )}
      </IconContainer>

      {loading ? (
        <InnerLoadingView style={{ marginTop: "-2rem" }} />
      ) : (
        <>
          <ColumnContainer>
            <TableOuterWrapper>
              <TableWrapper>
                <Table theadData={dataHead} tbodyData={tokensList} tokenRow />
              </TableWrapper>
            </TableOuterWrapper>
          </ColumnContainer>
        </>
      )}
    </Container>
  );
};

export default ViewTokenList;

const Container = styled.div`
  display: flex;
  border-radius: 10px;
  border: 0.75px solid #d3d3d3b0;
  gap: 1rem;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 1rem;
  min-height: 55vh;
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 10px 0px 15px 0px;
  gap: 1rem;
`;
const GridContainer = styled.div`
  width: 100%;
  gap: 1rem;
  margin: 1rem 0rem;
  display: flex;
  flex-direction: column;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
