import React, { useState, Fragment, useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import moment from "moment";

import { Heading, Text } from "../../components/shared/Typography/Typo";
import { urlToHash } from "../../utils/ipfs";
import { CustomButton } from "./../../components/shared/fields/Button/CustomButton";
import * as API from "../../api/API";
import TableRow from "./../../components/shared/Table/TableRow";
import InnerLoadingView from "../../components/shared/loading/InnerLoadingView";
import {
  TableOuterWrapper,
  TableWrapper,
} from "../CertificatesView/TokenCredentialsTableView";
import Table from "../../components/shared/Table/Table";
import ActionConfirm from "../../components/shared/Form/FormComponents/ActionConfirm";
import Web3 from "web3";
import { symmetricDecryption } from "../../services/encryptionService";
import { Modal } from "@mui/material";
import { IssueTokenModal } from "./IssueTokenModal";

const dataHead = [
  "Transaction Type",
  "Transaction Hash",
  "Network",
  "Transaction Time",
];

export const NonMetaDataSFT = ({ data }) => {
  const [authUser, setAuthUser] = useState(() => API.getUserSession());
  const currentUser = useSelector((state) => state.currentUser);
  // const [transactionLoading, setTransactionLoading] = useState(false);
  const [transactionList, setTransactionList] = useState([]);
  const [fetchTransactionLoading, setFetchTransactionLoading] = useState(false);
  const [showAction, setShowAction] = useState(false);
  const [transactionType, setTransactionType] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentBalance, setCurrentBalance] = useState(null);
  const [showIssueModel, setShowIssueModel] = useState(false);
  const [evmAddress, setEvmAddress] = useState("");
  const [tokenLimit, setTokenLimit] = useState(null);

  const setBalance = async (mintedDetails) => {
    if (mintedDetails[0].isMinted === true) {
      let provider = "";
      if (data.network === "MATIC") {
        provider = new Web3.providers.HttpProvider("https://polygon-rpc.com/");
      } else if (data.network === "MUMBAI") {
        provider = new Web3.providers.HttpProvider(
          // "https://rpc-mumbai.matic.today"
          "https://matic-mumbai.chainstacklabs.com/"
        );
      } else if (data.network === "BSCTESTNET") {
        provider = new Web3.providers.HttpProvider(
          "https://data-seed-prebsc-1-s1.binance.org:8545/"
        );
      } else if (data.network === "ALFAJORES") {
        provider = new Web3.providers.HttpProvider(
          "https://alfajores-forno.celo-testnet.org"
        );
      }
      const web3 = new Web3(provider);
      const abi = mintedDetails[0].contractABI;
      const contractAddress = mintedDetails[0].contractId;
      const contract = new web3.eth.Contract(abi, contractAddress);
      console.log("contract : ", contract);
      const balance = await contract.methods
        .balanceOf(mintedDetails[0].profileEVMAddress)
        .call();

      setCurrentBalance(web3.utils.fromWei(balance, "ether"));
    }
  };

  const handleTransaction = async (secretKey) => {
    setLoading(true);
    try {
      const privateKey = symmetricDecryption(
        authUser.accounts[2].encryptedSecret,
        secretKey
      );
      let provider = "";
      if (data.network === "MATIC") {
        provider = new Web3.providers.HttpProvider("https://polygon-rpc.com/");
      } else if (data.network === "MUMBAI") {
        provider = new Web3.providers.HttpProvider(
          // "https://rpc-mumbai.matic.today"
          "https://matic-mumbai.chainstacklabs.com/"
        );
      } else if (data.network === "BSCTESTNET") {
        provider = new Web3.providers.HttpProvider(
          "https://data-seed-prebsc-1-s1.binance.org:8545/"
        );
      } else if (data.network === "ALFAJORES") {
        provider = new Web3.providers.HttpProvider(
          "https://alfajores-forno.celo-testnet.org"
        );
      }
      const web3 = new Web3(provider);
      let userEvmAccount = web3.eth.accounts.privateKeyToAccount(privateKey);
      if (transactionType === "MINT") {
        const mintTokenResponse = await API.createTransaction({
          body: {
            refId: data._id,
            transactionType: transactionType,
            tokenType: data.tokenType,
            contractId: data.contractId,
            contractABI: data.contractABI,
            network: data.network,
            profileEVMAddress: userEvmAccount.address,
            tokenLimit: data.tokenLimit,
          },
        });
        if (mintTokenResponse.data.code === 202) {
          //await signForMint(secretKey);
          fetchTransactions();
        }
      } else if (transactionType === "CREATECLAIM") {
        console.log("Create CLaims");
        let signature = await getSignature(web3, userEvmAccount);
        const createClaimResponse = await API.createTransaction({
          body: {
            refId: data._id,
            transactionType: transactionType,
            tokenType: data.tokenType,
            contractId: data.contractId,
            contractABI: data.contractABI,
            network: data.network,
            creatorSignature: signature,
          },
        });
        if (createClaimResponse.data.code === 202) {
          fetchTransactions();
        }
      } else if (transactionType === "ISSUE") {
        console.log("Issue");
        // console.log("network : ", data.network);

        let signature = await getSignature(web3, userEvmAccount);
        const createIssueResponse = await API.createTransaction({
          body: {
            refId: data._id,
            transactionType: transactionType,
            tokenType: data.tokenType,
            contractId: data.contractId,
            contractABI: data.contractABI,
            network: data.network,
            creatorSignature: signature,
            tokenId: transactionList[0].tokenId,
            receiverEvmAddress: evmAddress,
            tokenLimit: tokenLimit,
          },
        });

        if (createIssueResponse.data.code === 202) {
          fetchTransactions();
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
      setShowAction(false);
    }
  };

  const fetchTransactions = async () => {
    setFetchTransactionLoading(true);
    try {
      const fetchTransactionResponse = await API.retrieveTransactions({
        path: {
          tId: data._id,
        },
      });

      if (fetchTransactionResponse) {
        setTransactionList(fetchTransactionResponse?.data?.data);
        await setBalance(fetchTransactionResponse?.data?.data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setFetchTransactionLoading(false);
    }
  };

  const getSignature = async (web3, signerAccount) => {
    let contractAddress = data.contractId;

    let ABI = data.contractABI; //require("../../utils/abi/DEPROERC721.json");
    const chainId = await web3.eth.getChainId();
    let domainData = {
      name: "MetaTransaction",
      version: "1",
      chainId: chainId, // Matic Testnet
      verifyingContract: contractAddress,
    };
    const domainType = [
      { name: "name", type: "string" },
      { name: "version", type: "string" },
      { name: "chainId", type: "uint256" },
      { name: "verifyingContract", type: "address" },
    ];
    const metaTransactionType = [
      { name: "nonce", type: "uint256" },
      { name: "from", type: "address" },
    ];
    let NFTContract = new web3.eth.Contract(ABI, contractAddress);
    const nonce = await NFTContract.methods
      .nonces(signerAccount.address)
      .call();
    let message = {};
    message.nonce = parseInt(nonce);
    message.from = signerAccount.address;
    const dataToSign = JSON.stringify({
      types: {
        EIP712Domain: domainType,
        MetaTransaction: metaTransactionType,
      },
      domain: domainData,
      primaryType: "MetaTransaction",
      message: message,
    });
    const hashedData = web3.utils.sha3(dataToSign);
    const signature = web3.eth.accounts.sign(
      hashedData,
      signerAccount.privateKey
    );
    let signatureObj = {
      signR: signature.r,
      signS: signature.s,
      signV: signature.v,
      messageHash: signature.message,
      evmAddress: signerAccount.address,
    };

    console.log("Signature: ", signatureObj);

    return signatureObj;
  };

  const isMinted = transactionList.find((element) => {
    return element.tokenType === "NONMETADATASFTS" && element.isMinted === true;
  });

  const isIssuedOrClaimed = transactionList.find((element) => {
    return element.tokenType === "NONMETADATASFTS" && currentBalance === 0;
  });

  useEffect(() => {
    fetchTransactions();
  }, []);
  return (
    <Fragment>
      <Container>
        {showAction && (
          <ActionConfirm
            successMessage={{
              message: "Requesting relayer to mint token",
              width: "18rem",
            }}
            warningMessage={"Are you sure you want to submit?"}
            loading={loading}
            setShowAction={setShowAction}
            authTypeInput={authUser.authType}
            publicKey={authUser.publicKey}
            encryptedSecret={authUser.encryptedSecret}
            onSecretKey={handleTransaction}
          />
        )}
        <Modal
          open={showIssueModel}
          onClose={() => {
            setShowIssueModel(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <IssueTokenModal
            tokenData={data}
            setShowIssueModel={setShowIssueModel}
            setShowAction={setShowAction}
            setStateEvmAddress={setEvmAddress}
            setStateTokenLimit={setTokenLimit}
            currentBalance={currentBalance}
          />
        </Modal>
        <InnerContainer>
          <ColumnContainer>
            <Heading large primary>
              {data.tokenName}
            </Heading>
            <Text lighter primary>
              {data.description}
            </Text>
            <RowContainer style={{ paddingTop: "20px" }}>
              <Text lighter primary small>
                Token Type :
              </Text>
              <Text lighter primary small>
                {data.tokenType === "UNIQUENFTS"
                  ? "Unique NFTs"
                  : data.tokenType === "LIMITEDSFTS"
                  ? "Limited SFTs"
                  : data.tokenType === "NONMETADATASFTS"
                  ? "Non-Metadata SFTs"
                  : ""}
              </Text>
            </RowContainer>
            <RowContainer style={{ paddingTop: "20px" }}>
              <Text lighter primary small>
                Contract ID :
              </Text>
              <Text lighter primary small>
                {data.contractId}
              </Text>
            </RowContainer>
            <RowContainer style={{ paddingTop: "20px" }}>
              <Text lighter primary small>
                Token Limit :
              </Text>
              <Text lighter primary small>
                {data.tokenLimit}
              </Text>
            </RowContainer>
            {currentBalance && (
              <RowContainer style={{ paddingTop: "20px" }}>
                <Text lighter primary small>
                  Current Balance :
                </Text>
                <Text lighter primary small>
                  {currentBalance}
                </Text>
              </RowContainer>
            )}
            <RowContainer style={{ paddingTop: "20px" }}>
              <Text lighter primary small>
                Create At :
              </Text>
              <Text lighter primary small>
                {moment(Number(data?.createdAt)).format("MM/DD/YYYY")}
              </Text>
            </RowContainer>
          </ColumnContainer>
        </InnerContainer>
        <ButtonContainer>
          <ButtonWrapper>
            {!isMinted && !fetchTransactionLoading && (
              <CustomButton
                login
                text="Mint Token"
                type="submit"
                // onclick={handleTransaction}
                onclick={() => {
                  setTransactionType("MINT");
                  setShowAction(true);
                }}
              />
            )}
            {isMinted && currentBalance > 0 && (
              <>
                <CustomButton
                  login
                  text={"Create Claim"}
                  type="submit"
                  onclick={handleTransaction}
                />
                <CustomButton
                  login
                  text={"Issue Token"}
                  type="submit"
                  onclick={() => {
                    setTransactionType("ISSUE");
                    // setShowAction(true);
                    setShowIssueModel(true);
                  }}
                />
              </>
            )}
          </ButtonWrapper>
        </ButtonContainer>{" "}
      </Container>

      {fetchTransactionLoading ? (
        <InnerLoadingView style={{ marginTop: "-2rem" }} />
      ) : (
        <Container style={{ marginTop: "20px" }}>
          <ColumnContainer>
            <TableOuterWrapper>
              <TableWrapper>
                <Table
                  theadData={dataHead}
                  tbodyData={transactionList}
                  uniqueNFTRow
                />
              </TableWrapper>
            </TableOuterWrapper>
          </ColumnContainer>
        </Container>
      )}
    </Fragment>
  );
};

const Container = styled.div`
  display: flex;
  border-radius: 10px;
  border: 0.75px solid #d3d3d3b0;
  gap: 1rem;
  flex-direction: column;
  padding: 1rem;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const ButtonWrapper = styled.div`
  gap: 1rem;
  display: flex;
  justify-content: flex-end;
`;

const TableContainer = styled.table`
  width: 100%;
  color: #2f3a60;
  height: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

export const Td = styled.td`
  padding: 0.6rem 1rem;

  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

const TableHead = styled.thead`
  width: 100%;
  position: sticky;
  // z-index: 99;
  top: 0;
  background: #eff2f5;
  left: 0;
  text-align: center;
`;

const Th = styled.th`
  text-transform: capitalize;
  padding: 1rem;
  color: #1e1b3b;
  min-width: 6rem;
  text-align: left;
`;

const Tr = styled.tr`
  &:last-child {
    border-right: 0;
  }
`;

const InnerContainer = styled.div`
  text-decoration: none;
  grid-column: ${(props) => (props.span ? "1/3" : "unset")};
  display: flex;
  background-color: #eff2f5;
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  min-height: 15rem;
  justify-content: space-between;
  //   padding: 1.5rem;
  @media (max-width: 1368px) {
    flex-direction: column;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  overflow: hidden;
  border-radius: 5px;
`;

const Image = styled.img`
  width: 100%;
  height: 200px;
  justify-self: end;
  border-radius: 5px;
  text-align: center;
  display: block;
`;

export const Icon = styled.img`
  width: 6rem;
  height: auto;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
