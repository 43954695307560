import React, { useState } from "react";
import styled from "styled-components";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Accordion, AccordionSummary } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import moment from "moment";

import VerificationCard from "../VerificationViews/VerificationCard";
import { SmallText } from "../Profile/ShareConfirm";
import { Text } from "../Typography/Typo";
import VerificationEntityCard from "../VerificationViews/VerificationEntityCard";
import CorrectGif from "../../../assets/images/correct-tick.gif";
import PendingGif from "../../../assets/images/pending-status.gif";
import RejectedGif from "../../../assets/images/rejected.gif";
import IpfsEntityCard from "./IpfsEntityCard";

const IpfsDataStepper = ({
  item,
  setCvHistoryIpfsUrl,
  index,
  setShowAction,
  itemName,
}) => {
  console.log(index);
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <FlexColumnContainer>
      <Accordion
        style={{ boxShadow: "none" }}
        expanded={expanded === `panel${index}`}
        onChange={handleChange(`panel${index}`)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${index}bh-content`}
          id={`panel${index}bh-header`}
        >
          <FlexContainer>
            <Text lighter primary>
              {index === 0
                ? "Created Details"
                : item?.verificationStatus === 0
                ? "Verification Sent "
                : item?.verificationStatus === 1
                ? "Verification Approved"
                : item?.verificationStatus === 2
                ? "Verification Rejected"
                : "Edited Details"}
            </Text>
            <SmallText lighter>
              {moment(item?.createdAt).format("LLL")}
            </SmallText>
          </FlexContainer>
        </AccordionSummary>
        <AccordionDetails>
          <IpfsEntityCard
            item={item}
            index={index}
            setCvHistoryIpfsUrl={setCvHistoryIpfsUrl}
            setShowAction={setShowAction}
          />
        </AccordionDetails>
      </Accordion>
    </FlexColumnContainer>
  );
};

export default IpfsDataStepper;

const FlexContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const FlexIconContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;
const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  // margin-top: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  &:first-child {
    margin-top: unset;
  }
`;

const FlexColumnCenterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-direction: column;
`;

export const Gif = styled.img`
  width: 8rem;
  height: auto;
`;
