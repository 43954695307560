import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router";
import Login from "./pages/Login/Login";
import styled from "styled-components";
import SignUp from "./pages/SignUp/SignUp";
import GuardedRoute from "react-guarded-route";
import { NIFTRON } from "niftron-client-sdk";
import jwt from "jsonwebtoken";
import Dashboard from "./pages/Home/Dashboard";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useSnackbar } from "notistack";
import axios from "axios";
import store from "./redux/reducers";
import {
  ADD_IPFS_COPY_DATA,
  ADD_IPFS_DATA,
  ADD_USER_DETAILS,
} from "./redux/constants/ActionTypes";
import LoadingView from "./components/shared/loading/LoadingView";
import ClaimCertificate from "./ClaimCertificate";
import { GetIPFSData } from "./services/IpfsService";
import { useSelector } from "react-redux";
import { urlToHash } from "./utils/ipfs";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import NewPassword from "./pages/NewPassword/NewPassword";
import SecretLogin from "./pages/SecretLogin/SecretLogin";
import { AUTH_URL } from "./api/API";

TimeAgo.addDefaultLocale(en);
const rax = require("retry-axios");

require("dotenv").config();

const niftronConfig = {
  projectKey: process.env.REACT_APP_PROJECT_KEY,
  secretKey: process.env.REACT_APP_DEV_SECRET_KEY,
};
const Niftron = new NIFTRON(niftronConfig);

rax.attach();
Niftron.initialize();

export default function App() {
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useSelector((state) => state.currentUser);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setError] = useState("Loading");

  async function decodeUser(data) {
    try {
      // Me api
      const response = await axios.get(AUTH_URL + "me", {
        headers: {
          "x-auth-token": data.token,
        },
        raxConfig: data.config,
        timeout: data.timeout,
      });
      return response;
    } catch (error) {
      if (error) {
        enqueueSnackbar("Session timed out", {
          variant: "error",
        });
        setTimeout(() => {
          NIFTRON.user.logout();
          localStorage.removeItem("secretKey");
          localStorage.clear();
        }, 1000);
        console.error(error);
      }
    }
  }

  const retrieveUser = async () => {
    const token = localStorage.getItem("niftoken");
    console.log(token);
    const retryConfig = {
      retry: 5, // number of retry when facing 4xx or 5xx
      noResponseRetries: 5, // number of retry when facing connection error
      onRetryAttempt: (err) => {
        const cfg = rax.getConfig(err);
        setError(
          `Couldn't retrieve user, Retry attempt #${cfg.currentRetryAttempt}`
        );
        console.log(
          `Couldn't retrieve user, Retry attempt #${cfg.currentRetryAttempt}`
        ); // track current trial
      },
    };

    setLoading(true);
    try {
      const retrieveUserDetails = await decodeUser({
        token: token,
        config: retryConfig,
        timeout: 5000,
      });

      if (retrieveUserDetails && retrieveUserDetails.data) {
        store.dispatch({
          type: ADD_USER_DETAILS,
          payload: retrieveUserDetails.data.data[0],
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchLatestProfileData = async () => {
    if (currentUser?.latestIpfsUrl?.ipfsUrl) {
      const hash = urlToHash(currentUser?.latestIpfsUrl?.ipfsUrl);
      try {
        const data = await GetIPFSData(hash);
        if (data) {
          console.log(data);
          store.dispatch({
            type: ADD_IPFS_DATA,
            payload: data,
          });
          store.dispatch({
            type: ADD_IPFS_COPY_DATA,
            payload: data,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const authValidator = () => {
    if (localStorage.getItem("niftoken")) {
      const token = localStorage.getItem("niftoken");
      if (token) {
        const decodedToken = jwt.decode(token);
        if (decodedToken == null) {
          localStorage.removeItem("secretKey");
          localStorage.removeItem("niftoken");
          return false;
        } else if (
          new Date(decodedToken.exp).toISOString() > new Date().toISOString()
        ) {
          localStorage.removeItem("secretKey");
          localStorage.removeItem("niftoken");
          return false;
        } else {
          return true;
        }
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("niftoken")) {
      retrieveUser();
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchLatestProfileData();
    //eslint-disable-next-line
  }, [currentUser]);

  if (loading) {
    return <LoadingView message={errorMessage} />;
  } else {
    return (
      <Container>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/sign-up" component={SignUp} />
          <Route path="/password-reset" component={ResetPassword} />
          <Route path="/login-with-secret-key" component={SecretLogin} />
          <Route path="/new-password/:id" component={NewPassword} />
          <Route path="/claim-certificate/:id" component={ClaimCertificate} />
          <GuardedRoute
            path="/"
            component={Dashboard}
            redirectTo="/login" //(Optional) Redirect to '/login' if validatorFunction returns false. Will redirect to '/' if not provided.
            validatorFunction={authValidator()}
          />
        </Switch>
      </Container>
    );
  }
}

const Container = styled.div`
  margin: 1.5rem;
  @media (max-width: 1368px) {
    margin: 0.6rem;
  }
`;

export const IconWrapper = styled.div`
  display: inline-block;
  background: -moz-linear-gradient(top, #00f260 0%, #0575e6 100%);
  background: -webkit-linear-gradient(top, #00f260 0%, #0575e6 100%);
  background: linear-gradient(to bottom, #00f260 0%, #0575e6 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#00F260, endColorstr=#0575E6, GradientType=0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
