import React, { useRef, useEffect, useState } from "react";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Keyboard,
  Pagination,
  Navigation,
  Scrollbar,
  Mousewheel,
} from "swiper";
import LinkIcon from "@mui/icons-material/Link";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Zoom from "@mui/material/Zoom";

import styled from "styled-components";
import PDFViewer from "../../../../PDFViewer";
import { CircularProgress, useMediaQuery } from "@mui/material";
import { Text } from "../../Typography/Typo";

// install Swiper modules
SwiperCore.use([Keyboard, Pagination, Navigation, Scrollbar, Mousewheel]);

const MultipleDocumentSlider = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const [fileType, setFileType] = useState("");
  const matchesMobile = useMediaQuery("(max-width:640px)");
  const matchesTab = useMediaQuery("(max-width:1578px)");

  const getFileType = async (fileURL) => {
    if (fileURL) {
      setLoading(true);
      let file = fileURL;
      var req = await fetch(file, { method: "HEAD" });
      setLoading(false);
      return setFileType(req.headers.get("content-type"));
    }
  };

  useEffect(() => {
    if (data && data.length > 0) getFileType(data[0]);
  }, []);

  const getSlidesPerview = () => {
    if (fileType === "application/pdf") return 1;
    else {
      if (matchesMobile) return 1;
      else if (matchesTab) return 2;
      else {
        return 3;
      }
    }
  };

  return (
    <div>
      <Swiper
        keyboard={{
          enabled: true,
        }}
        slidesPerView={getSlidesPerview()}
        spaceBetween={20}
        direction="horizontal"
        navigation={{
          prevEl: ".prev",
          nextEl: ".next",
        }}
        className="mySwiper"
      >
        {data &&
          data.length > 0 &&
          data.map((data, key) => {
            return (
              <SwiperSlide key={key}>
                <Container>
                  <FieldWrapper>
                    {loading ? (
                      <CircularProgress size={20} />
                    ) : fileType === "application/pdf" ? (
                      <PDFViewer
                        style={{
                          overflow: "scroll",
                          height: 200,
                          width: "100%",
                        }}
                        url={data}
                        isShowHeader="true"
                        isShowFooter="true"
                      />
                    ) : (
                      <OverFlowWrap src={data} />
                    )}
                  </FieldWrapper>
                  <Tooltip
                    TransitionComponent={Zoom}
                    arrow
                    title="View Document"
                  >
                    <LinkIcon
                      onClick={() => window.open(data)}
                      style={{ color: "#394673", cursor: "pointer" }}
                      sx={{ fontSize: 23 }}
                    />
                  </Tooltip>
                </Container>
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
};

export default MultipleDocumentSlider;

const Container = styled.div`
  flex-direction: row;
  height: 100%;
  display: flex;
  z-index: 30;
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  gap: 0.5rem;
`;

const OverFlowWrap = styled.img`
  max-width: 350px;
  max-height: 350px;
  min-width: 20px;
  min-height: 20px;
  border-radius: 10px;
  object-fit: contain;
  object-position: center;
  &:before {
    content: "";
    background: url("https://c.tenor.com/I6kN-6X7nhAAAAAi/loading-buffering.gif");
    min-width: 350px;
    min-height: 350px;
    object-fit: contain;
    object-position: center;
    display: block;
    background: #ffffff;
  }
  @media (max-width: 768px) {
    max-width: 250px;
    max-height: 250px;
    min-width: 250px;
    min-height: 250px;
  }
`;

export const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
`;
