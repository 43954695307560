import React from "react";
import styled from "styled-components";

// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Keyboard, Pagination, Navigation } from "swiper";
import { CustomSliderComponent } from "./CustomSliderComponent";

// install Swiper modules
SwiperCore.use([Pagination]);
SwiperCore.use([Keyboard, Pagination, Navigation]);

export const CustomSlider = ({
  productData,
  isPublic,
  setShowAction,
  setVerification,
  setViewVerifiedData,
  setShowDecryption,
  setCurrentField,
  setShowEncryption,
  setEditedDetails,
  setOpenEditedDetails,
}) => {
  return (
    <div style={{ marginBottom: "1rem" }}>
      <Swiper
        slidesPerView={1}
        keyboard={{
          enabled: true,
        }}
        direction="horizontal"
        navigation={{
          prevEl: ".prev",
          nextEl: ".next",
        }}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1578: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1724: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
        className="mySwiper "
      >
        {productData &&
          productData?.length > 0 &&
          productData.map((data, key) => {
            if (data) {
              return (
                <SwiperSlide key={key}>
                  <CustomSliderComponent
                    item={data?.data ? data?.data : data}
                    data={data}
                    addPadding
                    fieldName="stageData"
                    fieldId={{ id: key, name: "stageData" }}
                    setShowAction={setShowAction}
                    setShowDecryption={setShowDecryption}
                    setVerification={setVerification}
                    setViewVerifiedData={setViewVerifiedData}
                    setCurrentField={setCurrentField}
                    isPublic={isPublic}
                    isDecrypted={data?.isPublic}
                    encryptedType={data?.data?.addedDate?.encryptionType}
                    setShowEncryption={setShowEncryption}
                    setOpenEditedDetails={setOpenEditedDetails}
                    setEditedDetails={setEditedDetails}
                  />
                </SwiperSlide>
              );
            }
          })}
      </Swiper>
    </div>
  );
};

export const ArrowsContainer = styled.div`
  display: grid;
  place-items: center;
  grid-template-columns: 1fr 1fr;
  align-items: center;
`;

export const ArrowBody = styled.div`
  cursor: pointer;
  display: grid;
  place-items: center;
  padding: 0.4rem;
  border: 1px solid #d3d3d3;
  border-radius: 50%;
`;
