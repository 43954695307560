import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Heading, Text } from "../../components/shared/Typography/Typo";
import { NewPasswordForm } from "./NewPassForm";

export const NewPassComponent = () => {
  return (
    <Container>
      <HeadingWrapper>
        <Heading style={{ color: "#ffffff" }}>Welcome</Heading>
        <Text lighter small>
          Enter your New password
        </Text>
      </HeadingWrapper>
      <NewPasswordForm />
      <Text lighter small>
        Already have an account?{" "}
        <Link to="/login" style={{  color: "#34e78d"  }}>
          Login
        </Link>
      </Text>
    </Container>
  );
};

const Container = styled.div`
  color: #fff;
  background: #2c2858;
  flex-direction: column;
  border-radius: 10px;
  padding: 2rem 2rem;
  display: flex;
  gap: 3rem;
  align-items: center;
  margin: 1.5rem;
  @media (max-width: 1368px) {
    margin: 2rem;
  }
`;

const HeadingWrapper = styled.div`
  text-align: center;
`;
