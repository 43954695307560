import React from "react";
import styled from "styled-components";
import { Text } from "../../components/shared/Typography/Typo";

export const ClaimCardHeader = (props) => {
  return (
    <Container>
      <HeaderWrapper
        addPadding={props.addPadding}
        removeBackground={props.removeBackground}
      >
        <HeaderTop>
          <Text
            primary
            style={{
              justifySelf: "start",
              marginTop: "0.5rem",
              marginLeft: "1rem",
              marginRight: "1rem",
              textTransform: "capitalize",
              wordWrap: "break-word",
            }}
          >
            {props.certificateName}
          </Text>
          <Text
            primary
            lighter
            small
            style={{
              justifySelf: "end",
              marginRight: "1rem",
              textTransform: "capitalize",
              wordWrap: "break-word",
            }}
          >
            {props.id}
          </Text>
        </HeaderTop>

        <Text
          primary
          lighter
          small
          style={{
            marginBottom: "0.5rem",
            marginLeft: "1rem",
            textTransform: "capitalize",
            wordWrap: "break-word",
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: props.description }} />
          {/* {props.description} */}
        </Text>
      </HeaderWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`;

const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: ${(props) => props.addPadding && "1rem 0rem"};
  flex-direction: column;
  border-radius: 5px;
  background-color: ${(props) => (props.removeBackground ? "" : "#c3d0e1")};
  /* padding: 0.5rem 1rem; */
  @media (max-width: 768px) {
    /* width: 89%; */
  }
`;

const HeaderTop = styled.div`
  display: grid;
  width: 100%;
  align-items: center;
  grid-template-columns: 1fr 1fr;
`;
