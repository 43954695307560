import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { format } from "timeago.js";

import { Td, Tr } from "./TableRow";

const UniqueNFTRow = ({ item }) => {
  const history = useHistory();
  const [openImage, setImageOpen] = useState(false);
  const handleImageOpen = () => setImageOpen(true);
  const [urlName, setUrlName] = useState("polygonscan");

  const handleImageClose = () => {
    setImageOpen(false);
  };

  React.useEffect(() => {
    switch (item?.network) {
      case "MUMBAI":
        setUrlName("mumbai.polygonscan.com");
        break;
      case "ETHEREUM":
        setUrlName("etherscan.com");
        break;
      case "RINKEBY":
        setUrlName("rinkeby.etherscan.com");
        break;
      case "BSC":
        setUrlName("bscscan.com");
        break;
      case "BSCTESTNET":
        setUrlName("testnet.bscscan.com");
        break;
      case "MATIC":
        setUrlName("polygonscan.com");
        break;
      case "ALFAJORES":
        setUrlName("alfajores.celoscan.io");
        break;
      case "CELO":
        setUrlName("celoscan.io");
        break;
      default:
        setUrlName("polygonscan.com");
    }
  }, []);
  return (
    <Tr credentialRow>
      <Td>{item?.transactionType}</Td>
      <Td>
        <a
          target={"_blank"}
          href={`https://${urlName}/tx/${item?.transactionHash}`}
        >
          {item?.transactionHash &&
            item?.transactionHash?.toString().substring(0, 30 - 3) + "..."}
        </a>
      </Td>
      <Td>{item?.network}</Td>
      <Td>{format(item?.createdAt)}</Td>
    </Tr>
  );
};

export default UniqueNFTRow;
