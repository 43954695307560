import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getCreatorSession, getUserSession } from "../../../../api/API";
import { useStateValue } from "../../../../context/StateProvider";
import { dispatchEditObject, getArrayData } from "../../../../utils/editForm";
import { useHistory } from "react-router-dom";
import ActionConfirm from "../../Form/FormComponents/ActionConfirm";
import AddStageDataEditComponent from "./AddStageDataEditComponent";
import MultiStageEditComponent from "./MultiStageEditComponent";

function StageEditSwitch({ setOverLay, authType }) {
  const [authCreator, setAuthCreator] = useState(() => getCreatorSession());
  const latestIpfsData = useSelector((state) => state.latestIpfsData);
  const ipfsDataCopy = useSelector((state) => state.ipfsDataCopy);
  const [stageData, setStageData] = useState(
    getArrayData(latestIpfsData?.stageData)
  );
  const [status, setStatus] = useState(stageData?.length <= 0 ? 1 : 2);

  const [loading, setLoading] = useState(false);
  const [authUser, setAuthUser] = useState(() => getUserSession());
  const [{ products }, dispatch] = useStateValue();
  const [deleteData, setDeleteData] = useState();

  const currentUser = useSelector((state) => state.currentUser);

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const handleEditStage = (secretkey) => {
    let productObject = {};
    try {
      if (products?.length > 0) {
        let updatedData = latestIpfsData?.stageData.map(
          (obj) =>
            products.find((o) => o.data.id.data === obj.data.id.data) || obj
        );

        productObject.stageData = updatedData;
      }
      if (deleteData) {
        latestIpfsData.stageData = deleteData;
        productObject.stageData = deleteData;
      }

      if (productObject.stageData) {
        dispatchEditObject(
          secretkey,
          setLoading,
          productObject,
          [],
          currentUser,
          enqueueSnackbar,
          "stageData-edit",
          // latestIpfsData,
          ipfsDataCopy.length !== 0 ? ipfsDataCopy : latestIpfsData,
          setOverLay,
          history,
          authType,
          authUser,
          localStorage.getItem("providerToken") ? authCreator : currentUser
        );
      } else {
        dispatch({
          type: "CLEAR_PRODUCTS",
        });
        dispatch({
          type: "CLEAR_STAGE_FIELDS",
        });
        dispatch({
          type: "CLEAR_STAGE_CUSTOM_DATA_FIELDS",
        });

        setOverLay(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: "CLEAR_PRODUCTS",
      });
      dispatch({
        type: "CLEAR_STAGE_FIELDS",
      });
      dispatch({
        type: "CLEAR_STAGE_CUSTOM_DATA_FIELDS",
      });
    }
  };

  const handleAddStage = (secretkey) => {
    let productObject = {};
    try {
      productObject.stageData = products;

      dispatchEditObject(
        secretkey,
        setLoading,
        productObject,
        [],
        currentUser,
        enqueueSnackbar,
        "stageData-add",
        // latestIpfsData,
        ipfsDataCopy.length !== 0 ? ipfsDataCopy : latestIpfsData,
        setOverLay,
        history,
        authType,
        authUser,
        localStorage.getItem("providerToken") ? authCreator : currentUser
      );
    } catch (err) {
      console.log(err);
    } finally {
      dispatch({
        type: "CLEAR_PRODUCTS",
      });
      dispatch({
        type: "CLEAR_STAGE_FIELDS",
      });
      dispatch({
        type: "CLEAR_STAGE_CUSTOM_DATA_FIELDS",
      });
    }
  };

  return (
    <>
      {(() => {
        switch (status) {
          case 1:
            return (
              <AddStageDataEditComponent
                setOverLay={setOverLay}
                setStatus={setStatus}
                showEdit={stageData?.length <= 0}
              />
            );
          case 2:
            return (
              <MultiStageEditComponent
                setOverLay={setOverLay}
                setStatus={setStatus}
                stageData={stageData}
                datas={latestIpfsData.stageData}
                setDeleteData={setDeleteData}
              />
            );
          case 3:
            return (
              <ActionConfirm
                successMessage={{
                  message: "Making changes in IPFS",
                  width: "12rem",
                }}
                warningMessage={"Are you sure you want to Add data?"}
                loading={loading}
                setShowAction={setOverLay}
                authTypeInput={authUser.authType}
                publicKey={authUser.publicKey}
                encryptedSecret={authUser.encryptedSecret}
                onSecretKey={handleAddStage}
              />
            );
          case 4:
            return (
              <ActionConfirm
                successMessage={{
                  message: "Making changes in IPFS",
                  width: "12rem",
                }}
                warningMessage={"Are you sure you want to edit?"}
                loading={loading}
                setShowAction={setOverLay}
                authTypeInput={authUser.authType}
                publicKey={authUser.publicKey}
                encryptedSecret={authUser.encryptedSecret}
                onSecretKey={handleEditStage}
              />
            );
          default:
            return null;
        }
      })()}
    </>
  );
}

export default StageEditSwitch;
