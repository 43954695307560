import { Button, Modal } from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { format } from "timeago.js";

import { ImageWrapper, Td, Tr } from "./TableRow";
import { ImageFlexContainer, LargeImage } from "./../../../pages/ViewCV/Field";
import { urlToHash } from "../../../utils/ipfs";

const TokenRow = ({ item }) => {
  const history = useHistory();
  const [openImage, setImageOpen] = useState(false);
  const handleImageOpen = () => setImageOpen(true);

  const handleImageClose = () => {
    setImageOpen(false);
  };

  return (
    <Tr credentialRow>
      <Td>{item?.tokenName}</Td>
      <Td>{item?.tokenType}</Td>
      <Td>{item?.description}</Td>
      <Td>
        <Modal
          open={openImage}
          onClose={handleImageClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ImageFlexContainer>
            <div style={{ position: "relative" }}>
              <LargeImage
                src={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(
                  item?.image
                )}`}
                alt="image"
              />
            </div>
          </ImageFlexContainer>
        </Modal>
        <ImageWrapper
          image={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(
            item?.image
          )}`}
          onClick={() => {
            handleImageOpen();
          }}
        />
      </Td>
      <Td>{item?.symbol}</Td>
      <Td>{item?.decimal}</Td>
      <Td>{format(item?.createdAt)}</Td>
      <Td>
        <Button
          style={{
            background: "#1fc36157",
            color: "#080036",
            textTransform: "inherit",
            fontWeight: "500",
            fontFamily: "Poppins",
          }}
          // disabled
          size="small"
          onClick={() => {
            history.push({
              pathname: `/dashboard/view-tokens/${item._id}`,
              state: { item: item },
            });
          }}
        >
          View Token
        </Button>
      </Td>
    </Tr>
  );
};

export default TokenRow;
