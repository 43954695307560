import React from "react";
import { PDFReader } from "reactjs-pdf-reader";

const PDFViewer = ({ url, style }) => {
  return (
    <div style={style ? style : { overflow: "scroll", height: 600 }}>
      <PDFReader showAllPage="true" url={url} />
    </div>
  );
};

export default PDFViewer;
