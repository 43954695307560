import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import jwt from "jsonwebtoken";
// import { CustomButton } from "../Body/Button/CustomButton";
import MenuIcon from "@mui/icons-material/Menu";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { Menu, NavTagContainer } from "./HeaderStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useLocation } from "react-router";
import { NavTag } from "../Header/NavComponent/NavTag";
import { Text } from "../Typography/Typo";
import { useHistory } from "react-router-dom";
import { IconButton } from "@mui/material";
import Logo from "../../../assets/images/logo.png";
import { CustomButton } from "../fields/Button/CustomButton";
import AccountPopUp from "../navigation/AccountPopUp";
import CameraDialog from "../Camera/CameraDialogNew";

export const Header = (props) => {
  const history = useHistory();
  const token = localStorage.getItem("niftoken");
  const currentUser = useSelector((state) => state.currentUser);
  const location = useLocation();
  const [locationArr, setLocationArr] = useState([]);
  const [showScanner, setShowScanner] = useState(false);

  const matches = useMediaQuery("(max-width:1368px)");

  const providerToken = jwt.decode(localStorage.getItem("providerToken"));

  useEffect(() => {
    const currentID = () => {
      var locationParams = location.pathname.split("/");
      locationParams.shift();
      setLocationArr(locationParams);
    };
    currentID();
  }, [location]);

  const showSidebar = () => {
    props.setSidebar((value) => !value);
  };

  const handleChipClick = () => {
    const credentialProviderToken = localStorage.getItem("providerToken");
    window.localStorage.setItem("niftoken", credentialProviderToken);
    window.localStorage.removeItem("providerToken");
    window.localStorage.removeItem("secretKey");
    window.localStorage.removeItem("providerSecretKey");
    window.location.assign("/profiles");
  };

  return (
    <FlexContainer primary hide={props.shouldHideHeader}>
      {location.pathname === "/" && (
        <Icon style={{ width: "5.5rem" }} src={Logo} alt="Logo" />
      )}
      {matches && location.pathname !== "/" && (
        <NavTagContainer>
          <Text lighter>DePro</Text>
        </NavTagContainer>
      )}
      <NavTagContainer>
        {locationArr.map((item, index) => {
          if (locationArr.length <= 1) {
            return <NavTag param={item} key={index} />;
          }
          if (index === locationArr.length - 1) {
            return <NavTag param={item} key={index} />;
          } else {
            return <NavTag renderIcon param={item} key={index} />;
          }
        })}
      </NavTagContainer>

      <Menu>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {currentUser?.type === "PROFILE" && localStorage.providerToken && (
            <Chip
              style={{
                background: "linear-gradient(45deg, #0575e6 1%, #00f260 100%)",
              }}
              avatar={
                <Avatar>{providerToken?.alias.charAt(0).toUpperCase()}</Avatar>
              }
              onClick={handleChipClick}
              label={`Accessing via ${providerToken?.alias}`}
            />
          )}
          {currentUser.profileType === 1 && <CameraDialog />}

          {token ? (
            <AccountPopUp />
          ) : (
            <CustomButton
              onclick={() => {
                history.push("/login");
              }}
              text="Sign In"
              login
              loginIcon
              loginClass
            />
          )}
          {matches && (
            <IconButton onClick={showSidebar}>
              <MenuIcon style={{ color: "#fff" }} />
            </IconButton>
          )}
        </div>
      </Menu>
    </FlexContainer>
  );
};

export const Icon = styled.img`
  width: 100%;
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
  height: auto;
`;

const FlexContainer = styled.header`
  padding: 0.3rem 2rem;
  position: sticky;
  -webkit-box-shadow: -1px 5px 18px -1px rgba(0, 0, 0, 0.57);
  box-shadow: -1px 5px 18px -1px rgba(0, 0, 0, 0.57);
  transform: ${(props) => (props.hide ? "translateY(-110%)" : "translateY(0)")};
  min-height: 50px;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, #293149 0%, #1b2236 100%);
  color: ${(props) => (props.primary ? "#fff" : "#1E1B3B")};
  margin-bottom: ${(props) => (props.primary ? "1rem" : "none")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  z-index: 50;
  @media (max-width: 1368px) {
    padding: 0.3rem 1rem;
  }
`;
