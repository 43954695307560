import React, { Fragment } from "react";
import styled from "styled-components";
import { Text } from "../../Typography/Typo";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSnackbar } from "notistack";
import { useStateValue } from "../../../../context/StateProvider";
import { styled as MuiStyled } from "@mui/material/styles";
import { Switch } from "@mui/material";

export const ToggleField = MuiStyled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

export const TextField = (props) => {
  //eslint-disable-next-line
  const [{ fields }, dispatch] = useStateValue();
  const { enqueueSnackbar } = useSnackbar();

  const handleRemoveField = (name) => {
    if (
      window.confirm(
        `Are you sure you want to delete the field named ${props.label} ? `
      )
    ) {
      enqueueSnackbar(`Field named ${props.label} Removed!`, {
        variant: "warning",
      });
      dispatch({
        type: `${props.stageType ? "REMOVE_STAGE_FIELD" : "REMOVE_FIELD"}`,
        id: name,
      });
    }
  };

  return (
    <Container span={Boolean(props.span)} style={props.style}>
      <InputContainer custom={props.custom}>
        <Label style={{ paddingBottom: "5px" }} id={props.id}>
          {props.label}
        </Label>
        {props.custom && (
          <CancelIcon
            onClick={() => {
              handleRemoveField(props.id);
            }}
            size="small"
            style={{ color: "#6e6e6eca", cursor: "pointer" }}
          />
        )}
      </InputContainer>
      <TextInputContainer>
        <Fragment>{props.startIcon}</Fragment>
        <Input
          defaultValue={props.defaultValue}
          form={props.form}
          disabled={props.disabled}
          type={props.type}
          placeholder={props.placeholder}
          required={props.required}
          onChange={
            props.onChange && (({ target: { value } }) => props.onChange(value))
          }
          value={props.value}
          name={props.name}
          id={props.id}
          error={props.error}
          control={props.control}
          onBlur={props.onBlur}
          onKeyPress={props.onKeyPress}
          maxLength={props.maxLength}
          min={props.min}
        />
        <Fragment>{props.icon}</Fragment>
      </TextInputContainer>
      <FlexContainer>
        <Text lighter small color={props.color}>
          {props.helper}
        </Text>
      </FlexContainer>
    </Container>
  );
};

const TextInputContainer = styled.div`
  display: flex;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  background-color: #c3d0e1;
  border-radius: 5px;
  align-items: center;
`;

const Input = styled.input`
  border: none;
  width: 100%;
  padding: 0rem 0.5rem;
  outline: none;
  color: #080036;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  background-color: transparent;
  @media (max-width: 1368px) {
    font-size: 14px;
  }
`;

export const Label = styled.label`
  color: #080036;
  font-weight: 500;
  @media (max-width: 400px) {
    font-size: 0.875rem;
  }
`;

export const Container = styled.div`
  gap: 0.3rem;
  outline: none;
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  grid-column: ${(props) => (props.span ? "1/3" : "unset")};
  @media (max-width: 768px) {
    grid-column: unset;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  align-items: center;
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${(props) => (props.custom ? "0.2rem" : "0")}; ;
`;
