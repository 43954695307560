import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import moment from "moment";

import Table from "../../components/shared/Table/Table";
import { SubHeading, Text } from "../../components/shared/Typography/Typo";
import {
  TableOuterWrapper,
  TableWrapper,
} from "../../pages/CertificatesView/TokenCredentialsTableView";
import { SearchComponent } from "../../pages/JobListings/SearchComponent";
import { ColumnContainer } from "../../university-pages/Certificates/IssueCertificateForm";
import * as API from "../../api/API";
import InnerLoadingView from "../../components/shared/loading/InnerLoadingView";
import { TextField } from "../../components/shared/Form/FormComponents/TextField";
import Select from "react-select";
import { Button } from "@mui/material";
import { CSVLink, CSVDownload } from "react-csv";


const ViewCustomers = () => {
  const [printContent, setPrintContent] = useState(null);

  const currentUser = useSelector((state) => state.currentUser);
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [date, onDateChange] = useState();
  const [search, setSearch] = useState("");
  const [productSelectOptions, setProductSelectOptions] = useState([]);
  const [products, setProducts] = useState({});
  const [blockchain, setBlockchain] = useState();

  let arr = [];

  const handlePrint = () => {

  };

  const fetchProducts = async (data) => {
    setLoading(true);
    for (let i = 0; i < data?.length; i++) {
      try {
        const response = await API.retrieveAssignedProducts({
          path: {
            vId: data[i].publicKey,
          },
          query: {
            type: "assigned",
          },
        });
        if (response?.data?.code === 200) {
          console.log(response)
          arr=response?.data?.data;
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }

    // const unique = [...new Set(arr.map((item) => item.userPublicKey))];
    setCustomers(arr.reverse());

  };

  useEffect(() => {
    if (currentUser?.profiles && currentUser?.profiles.length > 0) {
      const productsSelectOptionsArray = []
      const productsObject = {};
      for (let i = 0; i < currentUser.profiles.length; i++) {
        productsSelectOptionsArray.push({ value: currentUser.profiles[i].publicKey, label: currentUser.profiles[i].alias })
        productsObject[currentUser.profiles[i].publicKey] = { label: currentUser.profiles[i].alias }
      }
      setProductSelectOptions(productsSelectOptionsArray)
      setProducts(productsObject)
      currentUser?.profiles && fetchProducts(currentUser?.profiles);
    }

  }, [currentUser?.profiles]);

  const dataHead = ["Alias", "Country", "Product", "Scanned"];

  return (
    <Container gap>
      <ColumnContainer>
        <SubHeading primary>View Customers</SubHeading>
        <Text small lighter color="#6b6b6b">
          View all Assigned customers and Details
        </Text>
      </ColumnContainer>

      {loading ? (
        <InnerLoadingView style={{ marginTop: "-2rem" }} />
      ) : (
        <>
          <SearchComponent
            placeholder="Search for Customers"
            searchValue={search}
            onSearchChange={setSearch}
            dateValue={date}
            onDateChange={(e) => {
              onDateChange(e);
              setSearch(moment(e).format("L"));
            }}
          />
          <Select
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                outline: "none",
                text: "orangered",
                primary25: "#2f3a6039",
                primary: " #2f3a60a7",
                neutral0: "#c3d0e1",
                boxShadow: "none",
              },
            })}
            placeholder={"Filter by profile"}
            // defaultValue={productSelectOptions[0]}
            options={productSelectOptions}
            value={blockchain}
            onChange={(e) => {
              setBlockchain(e)
              setSearch(e.value)
            }}
          />
          <ColumnContainer>


            <TableOuterWrapper>
              <TableWrapper>
                <Table
                  productsList={products}
                  theadData={dataHead}
                  tbodyData={customers?.filter((item) => {
                    if (search === "" && date === undefined) {
                      return item;
                    } else if (
                      item?.name
                        ?.toLowerCase()
                        .includes(search?.toLowerCase())
                    ) {
                      return item;
                    }
                    else if (
                      item?.productPublicKey
                        ?.toLowerCase()
                        .includes(search?.toLowerCase())
                    ) {
                      return item;
                    } else if (moment(item?.createdAt).format("L").includes(search))
                      return item;
                  })}
                  customerRow
                />
              </TableWrapper> 
               <Button
                style={{
                  background: "#1fc36157",
                  color: "#080036",
                  textTransform: "inherit",
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  float: "right",
                  left:"10px"
          
                }}
                size="small"
              >
                Send Message All
              </Button>
              <CSVLink data={customers} >
              <Button
                style={{
                  background: "#1fc36157",
                  color: "#080036",
                  textTransform: "inherit",
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  float: "right"
                }}
                size="small"
              >
                Export to CSV
              </Button>

            </CSVLink>
            </TableOuterWrapper>

            
          </ColumnContainer>



        </>

      )}
    </Container>
  );
};

export default ViewCustomers;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  min-height: 20rem;
`;

