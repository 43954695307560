import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import EditIcon from "@mui/icons-material/Edit";
import LockIcon from "@mui/icons-material/Lock";
import Avatar from "@mui/material/Avatar";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ShareIcon from "@mui/icons-material/Share";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { IconButton, Modal } from "@mui/material";
import { Text } from "../../../pages/JobListings/JobComponent";
import { Heading } from "../Typography/Typo";
import VerifiedStatusContainer from "../Profile/VerifiedStatusContainer";
import { FieldWrapper, FlexSpaceBetweenContainer } from "./CustomProfile";
import { ImageFlexContainer, LargeImage } from "../../../pages/ViewCV/Field";
import { urlToHash } from "../../../utils/ipfs";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";

const CustomProfileHeader = ({
  profileData,
  setShowAction,
  setVerification,
  setShowDecryption,
  setShowEncryption,
  isPublic,
  setIsPublic,
  setCurrentField,
  decryptType,
  isDecrypted,
  setEditOpen,
  setEditType,
  setShareOpen,
  setContactOpen,
  isViewAssignedItem,
  workflowJson,
  setLoading,
  ownerShipDetails,
  uniqueNFTsTotalToken,
  limitedSFTsTotalToken,
}) => {
  // console.log("workflowJson : ", workflowJson);
  const currentUser = useSelector((state) => state.currentUser);
  const history = useHistory();
  const [openImage, setImageOpen] = useState(false);
  const handleImageOpen = () => setImageOpen(true);
  const [profileMenuAnchor, setProfileMenuAnchor] = useState(null);
  const openProfileMenu = Boolean(profileMenuAnchor);
  const [header1, setHeader1] = useState("");
  const [header2, setHeader2] = useState("");
  const [header3, setHeader3] = useState("");
  const [headerImage, setHeaderImage] = useState("");

  const setHeaders = () => {
    if (workflowJson) {
      setLoading(true);
      workflowJson.form.sections.filter((arr) => {
        if (arr.sectionType === "header") {
          arr.sections[0].fields.filter((item) => {
            if (item.type === "header1") {
              const header1 = profileData?.basicData[item.label];
              setHeader1(header1?.data?.data);
            } else if (item.type === "header2") {
              const header2 = profileData?.basicData[item.label];
              setHeader2(header2?.data?.data);
            } else if (item.type === "header3") {
              const header3 = profileData?.basicData[item.label];
              setHeader3(header3?.data?.data);
            } else if (item.type === "headerImage") {
              let headerImage;

              if (profileData?.basicData[item.label]) {
                headerImage = profileData?.basicData[item.label];
              } else if (profileData?.basicData["Image"]) {
                headerImage = profileData?.basicData["Image"];
              }

              setHeaderImage(headerImage?.data?.data);
            }
          });
        }
      });

      setLoading(false);
    }
  };

  useEffect(() => {
    setHeaders();
  }, [workflowJson]);

  return (
    <Fragment>
      <SectionContainer>
        <AbsoluteContainer>
          <FlexSpaceBetweenContainer style={{ gap: "0.5rem" }}>
            <DesktopViewIconWrapper style={{ gap: "0.5rem" }}>
              <IconWrapper>
                <IconButton
                  onClick={() => {
                    setShareOpen((value) => !value);
                  }}
                >
                  <ShareIcon
                    style={{ color: "#8a8a8a" }}
                    sx={{ fontSize: 20 }}
                  />
                </IconButton>
              </IconWrapper>
            </DesktopViewIconWrapper>
          </FlexSpaceBetweenContainer>
        </AbsoluteContainer>
        {headerImage ? (
          <>
            <Banner>
              <ImageWrapper>
                <ProfileImage
                  alt="Profile Picture"
                  cursor
                  src={headerImage}
                  onClick={() => {
                    handleImageOpen();
                  }}
                />
              </ImageWrapper>
            </Banner>
            <SectionInnerContainer>
              <ContentContainer>
                <NameContainer>
                  <FieldWrapper>
                    <Heading primary>{header1 && header1}</Heading>
                  </FieldWrapper>
                </NameContainer>

                <FieldWrapper>
                  <NameContainer>
                    <Text lighter>{header2 && header2}</Text>
                  </NameContainer>
                </FieldWrapper>
              </ContentContainer>
              <ContentContainer>
                <NameContainer>
                  <Text lighter color="#6d6d6d">
                    {header3 && header3}
                  </Text>
                </NameContainer>
              </ContentContainer>
            </SectionInnerContainer>
          </>
        ) : (
          <Banner>
            <SectionInnerContainer>
              <ContentContainer>
                <NameContainer>
                  <FieldWrapper>
                    <Heading primary>{header1 && header1}</Heading>
                  </FieldWrapper>
                </NameContainer>

                <FieldWrapper>
                  <NameContainer>
                    <Text lighter>{header2 && header2}</Text>
                  </NameContainer>
                </FieldWrapper>
              </ContentContainer>
              <ContentContainer style={{ gap: "0.8rem", marginTop: "4rem" }}>
                <NameContainer>
                  <Text lighter color="#6d6d6d">
                    {header3 && header3}
                  </Text>
                </NameContainer>
              </ContentContainer>
            </SectionInnerContainer>
          </Banner>
        )}
      </SectionContainer>
    </Fragment>
  );
};

export default CustomProfileHeader;

export const SectionContainer = styled.section`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: ${(props) => (props.noGap ? "0" : "1rem")};
  background-color: #eff2f5;
  border-radius: 10px;
`;

const SectionInnerContainer = styled.div`
  display: flex;
  position: relative;
  margin-top: 2.5rem;
  justify-content: space-between;
  gap: 1rem;
  padding: 1.5rem;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const SpanLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
  color: #1696ff;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const TokenContainer = styled.div`
  margin-top: 40%;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  @media (max-width: 1368px) {
    margin-top: 30%;
  }
`;
const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

const ImageWrapper = styled.div`
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  background-color: #fff;
  margin-top: 3rem;
  margin-left: 2rem;
  @media (max-width: 1368px) {
    margin-top: 3rem;
    margin-left: 1rem;
    width: 8rem;
    height: 8rem;
  }
`;

const ProfileImage = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  cursor: ${(props) => (props.cursor ? "pointer" : "unset")};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
`;

const Banner = styled.div`
  position: relative;
  background: linear-gradient(45deg, #0575e6 1%, #00f260 100%);
  height: 10rem;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: 1368px) {
    height: 8rem;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const SmallImageWrapper = styled.div`
  overflow: hidden;
  width: 2rem;
  height: 2rem;
  background-color: #fff;
  position: relative;
  border-radius: 2px;
`;

const Hai = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

export const AbsoluteContainer = styled.div`
  position: absolute;
  top: 4%;
  right: 1.5%;
  z-index: 2;
`;

export const AbsoluteTokenContainer = styled.div`
  position: absolute;
  top: 4%;
  right: 1.5%;
  z-index: 1;
`;

export const MobileViewIconWrapper = styled.div`
  background-color: #fff;
  border-radius: 50%;
  display: none;
  @media (max-width: 768px) {
    display: flex;
  }
`;

export const DesktopViewIconWrapper = styled.div`
  // display: flex;
  // @media (max-width: 768px) {
  //   display: none;
  // }

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const IconWrapper = styled.div`
  background-color: #fff;
  border-radius: 50%;
`;
