import React, { Fragment, useEffect, useState } from "react";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Keyboard,
  Pagination,
  Navigation,
  Scrollbar,
  Mousewheel,
} from "swiper";
import { Modal } from "@mui/material";

import styled from "styled-components";
import PDFViewer from "../../../../PDFViewer";
import { CircularProgress, useMediaQuery } from "@mui/material";
import { Text } from "../../Typography/Typo";

// install Swiper modules
SwiperCore.use([Keyboard, Pagination, Navigation, Scrollbar, Mousewheel]);

const MultipleImageSlider = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const [fileType, setFileType] = useState("");
  const matchesMobile = useMediaQuery("(max-width:640px)");
  const matchesTab = useMediaQuery("(max-width:1578px)");

  const [openImage, setImageOpen] = useState(false);
  const handleImageOpen = () => setImageOpen(true);
  const handleImageClose = () => setImageOpen(false);
  const [imageData, setImageData] = useState({});

  const getFileType = async (fileURL) => {
    if (fileURL) {
      setLoading(true);
      let file = fileURL;
      var req = await fetch(file, { method: "HEAD" });
      setLoading(false);
      return setFileType(req.headers.get("content-type"));
    }
  };

  useEffect(() => {
    if (data && data.length > 0) getFileType(data[0]);
  }, []);

  const getSlidesPerview = () => {
    if (fileType === "application/pdf") return 1;
    else {
      if (matchesMobile) return 1;
      else if (matchesTab) return 2;
      else {
        return 3;
      }
    }
  };

  return (
    <div>
      <Modal
        open={openImage}
        onClose={handleImageClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ImageFlexContainer>
          <div style={{ position: "relative" }}>
            <LargeImage src={imageData.url} alt="image" />
          </div>
        </ImageFlexContainer>
      </Modal>
      <Swiper
        keyboard={{
          enabled: true,
        }}
        slidesPerView={getSlidesPerview()}
        spaceBetween={20}
        direction="horizontal"
        navigation={{
          prevEl: ".prev",
          nextEl: ".next",
        }}
        className="mySwiper"
      >
        {data &&
          data.length > 0 &&
          data.map((data, key) => {
            return (
              <SwiperSlide key={key}>
                <Container fileType={fileType}>
                  <FieldWrapper>
                    {/* {loading ? (
                      <CircularProgress size={20} />
                    ) : fileType === "application/pdf" ? (
                      <PDFViewer
                        style={{
                          overflow: "scroll",
                          height: 200,
                          width: "100%",
                        }}
                        url={data}
                        isShowHeader="true"
                        isShowFooter="true"
                      />
                    ) : (
                      <OverFlowWrap
                        src={data}
                        onClick={() => {
                          // handleImageOpen();
                          // console.log("hai..");
                          console.log("data : ", data);
                        }}
                      />
                    )} */}
                    {loading ? (
                      <CircularProgress size={20} />
                    ) : fileType === "application/pdf" ? (
                      <PdfViewContainer onClick={() => window.open(data)}>
                        <PDFViewer
                          style={{
                            overflow: "scroll",
                            // height: "350px",
                            width: "300px",
                          }}
                          url={data}
                          isShowHeader="true"
                          isShowFooter="true"
                        />
                      </PdfViewContainer>
                    ) : (
                      <OverFlowWrap
                        src={data}
                        onClick={() => {
                          setImageData &&
                            setImageData({
                              url: data,
                              type: "image",
                            });
                          handleImageOpen && handleImageOpen();
                        }}
                      />
                    )}
                  </FieldWrapper>
                </Container>
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
};

export default MultipleImageSlider;

const Container = styled.div`
  padding: 1rem;
  flex-direction: column;
  height: 100%;
  width: ${(props) => (props.fileType === "application/pdf" ? "470px" : "")};
  display: flex;
  z-index: 30;
  justify-content: space-between;
  background-color: #eff2f5;
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  gap: 0.5rem;
`;

const PdfViewContainer = styled.div`
  // max-width: 350px;
  // max-height: 350px;
  // min-width: 20px;
  // min-height: 20px;
  height: 300px;
  width: 300px;
  border-radius: 10px;
  object-fit: contain;
  object-position: center;
  &:before {
    // content: "";
    // background: url("https://c.tenor.com/I6kN-6X7nhAAAAAi/loading-buffering.gif");
    // min-width: 350px;
    // min-height: 350px;
    height: 300px;
    width: 300px;
    object-fit: contain;
    object-position: center;
    display: block;
    background: #ffffff;
  }
  @media (max-width: 768px) {
    // max-width: 250px;
    // max-height: 250px;
    // min-width: 250px;
    // min-height: 250px;
    height: 300px;
    width: 300px;
  }
  cursor: pointer;
`;
const OverFlowWrap = styled.img`
  // max-width: 350px;
  // max-height: 350px;
  // min-width: 20px;
  // min-height: 20px;
  height: 300px;
  width: 300px;
  border-radius: 10px;
  object-fit: contain;
  object-position: center;
  &:before {
    content: "";
    background: url("https://c.tenor.com/I6kN-6X7nhAAAAAi/loading-buffering.gif");
    // min-width: 350px;
    // min-height: 350px;
    height: 300px;
    width: 300px;
    object-fit: contain;
    object-position: center;
    display: block;
    background: #ffffff;
  }
  @media (max-width: 768px) {
    // max-width: 250px;
    // max-height: 250px;
    // min-width: 250px;
    // min-height: 250px;
    height: 300px;
    width: 300px;
  }
  cursor: pointer;
`;

export const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
`;

const ImageFlexContainer = styled.div`
  display: grid;
  width: 40%;
  height: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  background: #ffffff;
  transform: translate(-50%, -50%);
  place-items: center;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  @media (max-width: 1368px) {
    width: 60%;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const LargeImage = styled.img`
  text-align: center;
  width: 100%;
  height: auto;
`;
