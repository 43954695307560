import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { RequestVerification } from "../../components/shared/Profile/RequestVerification";
import { SubHeading, Text } from "../../components/shared/Typography/Typo";
import WorkSection from "../../components/shared/Profile/WorkSection";
import UserProfileHeader, {
  SectionContainer,
} from "../../components/shared/Profile/UserProfileHeader";
import VerifiedStatusContainer from "../../components/shared/Profile/VerifiedStatusContainer";
import EducationSection from "../../components/shared/Profile/EducationSection";
import AchievementSection from "../../components/shared/Profile/AchievementSection";
import AdditionalDetails from "../../components/shared/Profile/AdditionalDetails";
import EncryptedData from "../../components/shared/Profile/EncryptedData";
import { ViewVerifiedDetails } from "../../components/shared/ProductProfile/ViewVerifiedDetails";

const ProviderViewEntity = ({ latestIpfsData, ownerShipDetails }) => {
  const currentUser = useSelector((state) => state.currentUser);
  const [isPublic, setIsPublic] = useState(false);
  const [isDecrypted, setIsDecrypted] = useState(false);
  const [showDecryption, setShowDecryption] = useState(false);
  const [showAction, setShowAction] = useState(false);
  const [verification, setVerification] = useState({
    type: null,
    fieldData: null,
  });
  const [currentField, setCurrentField] = useState({});
  const [viewVerifiedData, setViewVerifiedData] = useState(false);

  return (
    <Fragment>
      {showAction && (
        <RequestVerification
          verification={verification}
          currentField={currentField}
          show={showAction}
          setShowAction={setShowAction}
        />
      )}
      {viewVerifiedData && (
        <ViewVerifiedDetails
          verifiedData={currentField}
          setShowAction={setViewVerifiedData}
        />
      )}
      <Container>
        <UserProfileHeader
          profileData={latestIpfsData}
          setVerification={setVerification}
          setShowAction={setShowAction}
          isPublic={isPublic}
          setIsPublic={setIsPublic}
          setShowDecryption={setShowDecryption}
          setCurrentField={setCurrentField}
          isDecrypted={isDecrypted}
          isViewAssignedItem={true}
          ownerShipDetails={ownerShipDetails}
        />
        <SectionContainer>
          <SectionInnerContainer>
            <FlexSpaceBetweenContainer>
              <SubHeading primary>About</SubHeading>{" "}
              {latestIpfsData?.bio?.isPublic && (
                <VerifiedStatusContainer
                  isPublic={isPublic}
                  fieldData={latestIpfsData?.bio}
                  fieldName="Bio"
                  fieldId={{ id: "bio" }}
                  setShowAction={setShowAction}
                  setVerification={setVerification}
                  setShowDecryption={setShowDecryption}
                  isDecrypted={latestIpfsData?.bio?.isPublic}
                  encryptedType={latestIpfsData?.bio?.data?.encryptionType}
                  setCurrentField={setCurrentField}
                />
              )}
            </FlexSpaceBetweenContainer>
            {latestIpfsData?.bio?.isPublic ? (
              <Text primary lighter>
                {latestIpfsData?.bio?.data?.data}
              </Text>
            ) : (
              <EncryptedData
                fieldData={latestIpfsData?.bio?.data?.data}
                setShowDecryption={setShowDecryption}
              />
            )}
          </SectionInnerContainer>
        </SectionContainer>

        {/*AchievementSection */}
        <AchievementSection
          isDecrypted={isDecrypted}
          isPublic={isPublic}
          profileData={latestIpfsData}
          setShowAction={setShowAction}
          setVerification={setVerification}
          setShowDecryption={setShowDecryption}
          setCurrentField={setCurrentField}
        />
        <AdditionalDetails
          isDecrypted={isDecrypted}
          isPublic={isPublic}
          profileData={latestIpfsData}
          setShowAction={setShowAction}
          setVerification={setVerification}
          setShowDecryption={setShowDecryption}
          setCurrentField={setCurrentField}
        />
      </Container>
    </Fragment>
  );
};

export default ProviderViewEntity;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.noGap ? "0" : "1rem")};
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  padding: 1rem;
  @media (max-width: 1368px) {
    padding: 0.4rem;
  }
`;

export const SectionInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
`;

export const FlexSpaceBetweenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Box = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 2px;
  background-color: ${(props) => props.color};
`;
export const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
