import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Web3 from "web3";
import {
  InputContainer,
  Label,
  TextField,
} from "../Form/FormComponents/TextField";
import {
  Button,
  IconButton,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import CreatableSelect from "react-select/creatable";
import { useHistory } from "react-router-dom";
import { getUserSession } from "../../../api/API";
import { useSnackbar } from "notistack";
import { SubHeading } from "../Typography/Typo";
import { FlexSpaceBetweenContainer } from "../DocumentProfile/DocumentProfile";
import CustomTextFieldModal from "../CustomTextfieldModal/CustomTextFieldModal";
import { CustomButton } from "../fields/Button/CustomButton";
import DropzoneComponent from "../fields/Dropzone/DropzoneComponent";
import InnerLoadingView from "../loading/InnerLoadingView";
import CreateWorkflowModal from "./CreateWorkflowModal";
export var phoneNoValidation =
  /^(?:0|94|\+94|0094)?(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|912)(0|2|3|4|5|7|9)|7(0|1|2|4|5|6|7|8)\d)\d{6}$/;

export const CreateTemplate = ({ templateData }) => {
  const [openCustomModal, setCustomModalOpen] = useState(false);
  const [dataJson, setDataJson] = useState(JSON.parse(templateData.file));
  const [showMainAction, setShowMainAction] = useState(false);
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState("");
  let [multiSelect, setMultiSelect] = useState([]);
  const [isFinalComplete, setIsFinalComplete] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const matches = useMediaQuery("(max-width:1368px)");
  const [showAction, setShowAction] = useState(false);

  return (
    <Container>
      {showAction && (
        <CreateWorkflowModal
          show={showAction}
          handleClose={setShowAction}
          dataJson={dataJson}
          templateId={templateData?.id}
        />
      )}
      {!dataJson ? (
        <InnerLoadingView />
      ) : (
        <>
          <CustomTextFieldModal
            title="Add Additional Stage Details"
            formId="custom-stage-data-form"
            setCustomModalOpen={setCustomModalOpen}
            openCustomModal={openCustomModal}
            stageType
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            {dataJson &&
              dataJson.form.sections.map((item, itemIndex) => {
                return (
                  <GridContainer id="main-form">
                    <FlexSpaceBetweenContainer
                      style={{
                        gridColumn: `${!matches ? "1/3" : "unset"}`,
                        margin: "1rem 0rem",
                      }}
                    >
                      <SubHeading primary>{item.sectionTitle}</SubHeading>
                    </FlexSpaceBetweenContainer>
                    {item.sections.map((section, key) => {
                      return (
                        <FieldContainer>
                          {section.sectionTitle && (
                            <FlexSpaceBetweenContainer
                              style={{
                                gridColumn: `${!matches ? "1/3" : "unset"}`,
                                margin: "1rem 0rem",
                              }}
                            >
                              <SubHeading primary>
                                {`${section.sectionTitle}  ${
                                  section?.required === false ? "" : "*"
                                }`}
                              </SubHeading>
                            </FlexSpaceBetweenContainer>
                          )}
                          {section.fields.map((field) => {
                            if (field.inputType === "text") {
                              return (
                                <TextField
                                  label={`${field.label}  ${
                                    field?.required === false ? "" : "*"
                                  }`}
                                  // required={field.required}
                                  placeholder={field.placeHolder}
                                  id={field.label}
                                  name={field.label}
                                  disabled={true}
                                />
                              );
                            } else if (field.inputType === "selection") {
                              return (
                                <DropContainer style={{ gridRow: "span 2" }}>
                                  <InputContainer>
                                    <Label>
                                      {" "}
                                      {`${field.label}  ${
                                        field?.required === false ? "" : "*"
                                      }`}
                                    </Label>
                                  </InputContainer>
                                  <DropzoneComponent
                                    id="dropzoneTwo"
                                    amount="1"
                                    form="none"
                                    multiple={false}
                                    errors={errors}
                                    setErrors={setErrors}
                                    maxSize="1000000"
                                    setFiles={setFiles}
                                    type="image/jpeg, image/png"
                                    description="Drop or select image (Max file size 10MB)"
                                    disabled={true}
                                  />
                                </DropContainer>
                              );
                            } else if (field.inputType === "multiSelection") {
                              return (
                                <Column span={!matches ? true : false}>
                                  <Label>{`${field.label}  ${
                                    field?.required === false ? "" : "*"
                                  }`}</Label>
                                  <CreatableSelect
                                    theme={(theme) => ({
                                      ...theme,
                                      colors: {
                                        ...theme.colors,
                                        outline: "none",
                                        text: "orangered",
                                        primary25: "#2f3a6039",
                                        primary: " #2f3a60a7",
                                        neutral0: "#c3d0e1",
                                        boxShadow: "none",
                                      },
                                    })}
                                    styles={{
                                      menu: (provided) => ({
                                        ...provided,
                                        zIndex: 3,
                                      }),
                                    }}
                                    isMulti
                                    name={field.label}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    defaultValue={field.options[0]}
                                    options={field.options}
                                    value={multiSelect}
                                  />
                                </Column>
                              );
                            }
                          })}
                        </FieldContainer>
                      );
                    })}
                  </GridContainer>
                );
              })}

            <ButtonContainer
              style={{
                gridColumn: `${!matches ? "1/3" : "unset"}`,
              }}
            >
              <CustomButton
                login
                text="Create Workflow"
                onclick={() => {
                  setShowAction(true);
                }}
              />
            </ButtonContainer>
          </div>
        </>
      )}
    </Container>
  );
};

export default CreateTemplate;

const Container = styled.div`
  // display: flex;
  // flex-direction: column;
  gap: ${(props) => (props.noGap ? "0" : "1rem")};
`;

const DropContainer = styled.div`
  gap: 0.3rem;
  outline: none;
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  grid-column: ${(props) => (props.span ? "1/3" : "unset")};
  @media (max-width: 768px) {
    grid-column: unset;
  }
`;

const ButtonContainer = styled.div`
  margin: 1rem 0rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`;

const FieldContainer = styled.div`
  display: grid;
  row-gap: 1rem;
  column-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  border: 0.75px solid #d3d3d36e;
  margin-top: 1rem;
  background-color: #c3d0e181;
  border-radius: 10px;
  padding: 1.5rem;
  @media (max-width: 1368px) {
    grid-template-columns: 1fr;
  }
`;

const GridContainer = styled.div`
  background-color: #eff2f5;
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  padding: 1.5rem;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: ${(props) => (props.span ? "1/3" : "unset")};
`;

const ButtonWrapper = styled.div`
  padding-top: 1rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`;

const Column = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  grid-column: ${(props) => (props.span ? "1/3" : "unset")};
  @media (max-width: 768px) {
    grid-column: unset;
  }
`;
