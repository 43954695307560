import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Heading, Text } from "../../components/shared/Typography/Typo";
import { LoginForm } from "./LoginForm";

export const LoginComponent = () => {
  return (
    <Container>
      <HeadingWrapper>
        <Heading style={{ color: "#ffffff" }}>Welcome</Heading>
        <Text lighter small>
          Login in as a Profile or a Creator
        </Text>
      </HeadingWrapper>
      <LoginForm />
      <Text lighter small>
        Don't have an account?{" "}
        <Link to="/sign-up" style={{  color: "#34e78d"  }}>
                   Register
        </Link>
      </Text>
    </Container>
  );
};

const Container = styled.div`
  color: #fff;
  background: linear-gradient(180deg, #293149 0%, #1b2236 100%);
  flex-direction: column;
  border-radius: 10px;
  padding: 2rem 2rem;
  display: flex;
  gap: 1.5rem;
  align-items: center;
  margin: 1.5rem;
  @media (max-width: 1368px) {
    margin: 2rem;
  }
`;

const HeadingWrapper = styled.div`
  text-align: center;
`;
