import React, { useRef, Fragment, useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import styled from "styled-components";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import ArticleTwoToneIcon from "@mui/icons-material/ArticleTwoTone";

const VerifiedStatusContainer = ({
  fieldId,
  status,
  setVerification,
  fieldData,
  isDecrypted,
  setShowDecryption,
  setShowEncryption,
  isPublic,
  fieldName,
  setShowAction,
  style,
  setCurrentField,
  encryptedType,
  type,
  item,
  setViewVerifiedData,
  setOpenEditedDetails,
  setEditedDetails,
}) => {
  const [urlName, setUrlName] = useState("polygonscan");

  useEffect(() => {
    switch (item?.data?.blockchain?.data) {
      case "MUMBAI":
        setUrlName("mumbai.polygonscan");
        break;
      case "ETHEREUM":
        setUrlName("etherscan");
        break;
      case "RINKEBY":
        setUrlName("rinkeby.etherscan");
        break;
      case "BSC":
        setUrlName("bscscan");
        break;
      case "BSCTESTNET":
        setUrlName("testnet.bscscan");
        break;
      case "MATIC":
        setUrlName("polygonscan");
        break;
      default:
        setUrlName("polygonscan");
    }
  }, [item?.data?.blockchain]);

  const positionRef = useRef({
    x: 0,
    y: 0,
  });
  const handleActionOpen = () => {
    setShowAction(true);
  };

  const popperRef = useRef(null);
  const areaRef = useRef(null);

  const handleMouseMove = (event) => {
    positionRef.current = { x: event.clientX, y: event.clientY };

    if (popperRef.current != null) {
      popperRef.current.update();
    }
  };

  return (
    <Container style={style}>
      <InnerContainer>
        {(() => {
          if (!isDecrypted) {
            return (
              <Tooltip
                arrow
                placement="top"
                title="Click to Decrypt"
                PopperProps={{
                  popperRef,
                  anchorEl: {
                    getBoundingClientRect: () => {
                      return new DOMRect(
                        positionRef.current.x,
                        areaRef?.current?.getBoundingClientRect().y,
                        0,
                        0
                      );
                    },
                  },
                }}
              >
                <IconContainer
                  onClick={() => {
                    var foo = setCurrentField;
                    if (typeof foo === "function") {
                      setCurrentField && setCurrentField(fieldId);
                      setShowDecryption && setShowDecryption(true);
                    }
                  }}
                  ref={areaRef}
                  onMouseMove={handleMouseMove}
                >
                  <LockIcon
                    sx={{ fontSize: 18 }}
                    style={{ color: "#646464" }}
                  />
                </IconContainer>
              </Tooltip>
            );
          } else if (isDecrypted && encryptedType === "SYMMETRIC") {
            return (
              <Tooltip
                arrow
                placement="top"
                title="Click to Encrypt"
                PopperProps={{
                  popperRef,
                  anchorEl: {
                    getBoundingClientRect: () => {
                      return new DOMRect(
                        positionRef?.current.x,
                        areaRef?.current?.getBoundingClientRect().y,
                        0,
                        0
                      );
                    },
                  },
                }}
              >
                <IconContainer
                  onClick={() => {
                    var foo = setCurrentField;
                    if (typeof foo === "function") {
                      setCurrentField && setCurrentField(fieldId);
                      setShowEncryption && setShowEncryption(fieldId);
                    }
                  }}
                  ref={areaRef}
                  onMouseMove={handleMouseMove}
                >
                  <LockOpenIcon
                    sx={{ fontSize: 18 }}
                    style={{ color: "#646464" }}
                  />
                </IconContainer>
              </Tooltip>
            );
          }
        })()}{" "}
      </InnerContainer>

      <InnerContainer>
        {(() => {
          if (
            fieldData?.verifiedBy &&
            fieldData?.verifiedBy.find(
              (item) => item?.verificationStatus === 0
            ) &&
            isPublic
          ) {
            return (
              <Tooltip
                placement="top"
                arrow
                title="Verification Pending"
                PopperProps={{
                  popperRef,
                }}
              >
                <StatusContainer
                  style={style}
                  status={0}
                  ref={areaRef}
                  onClick={() => {
                    handleActionOpen();
                    if (setVerification) {
                      setCurrentField && setCurrentField(fieldId);
                      setVerification({
                        type: fieldName,
                        fieldData: fieldData,
                        isPublic: isDecrypted,
                      });
                    }
                  }}
                >
                  <Box color="#fa7d25fc" />
                </StatusContainer>
              </Tooltip>
            );
          } else if (isPublic && fieldData?.verifiedBy?.length === 0) {
            return (
              <Tooltip
                arrow
                placement="top"
                title="Unverified"
                PopperProps={{
                  popperRef,
                }}
              >
                <StatusContainer
                  onClick={() => {
                    handleActionOpen();
                    if (setVerification) {
                      setCurrentField && setCurrentField(fieldId);
                      setVerification({
                        type: fieldName,
                        fieldData: fieldData,
                        isPublic: isDecrypted,
                      });
                    }
                  }}
                  status={status}
                  ref={areaRef}
                  onMouseMove={handleMouseMove}
                >
                  <Box color="#9b9b9b" />
                </StatusContainer>
              </Tooltip>
            );
          } else if (
            fieldData?.verifiedBy &&
            fieldData?.verifiedBy.find(
              (item) => item?.verificationStatus === 1
            ) &&
            isPublic
          ) {
            return (
              <Tooltip
                arrow
                placement="top"
                title="Verified"
                PopperProps={{
                  popperRef,
                }}
              >
                <StatusContainer
                  style={style}
                  onClick={() => {
                    handleActionOpen();
                    if (setVerification) {
                      setCurrentField && setCurrentField(fieldId);
                      setVerification({
                        type: fieldName,
                        fieldData: fieldData,
                        isPublic: isDecrypted,
                      });
                    }
                  }}
                  status={status}
                  ref={areaRef}
                  onMouseMove={handleMouseMove}
                >
                  <Box color="#59D88C" />
                </StatusContainer>
              </Tooltip>
            );
          } else if (
            fieldData?.verifiedBy &&
            fieldData?.verifiedBy.find(
              (item) => item?.verificationStatus === 2
            ) &&
            isPublic
          ) {
            return (
              <Tooltip
                arrow
                placement="top"
                title="Unverified"
                PopperProps={{
                  popperRef,
                }}
              >
                <StatusContainer
                  style={style}
                  onClick={() => {
                    handleActionOpen();
                    if (setVerification) {
                      setCurrentField && setCurrentField(fieldId);
                      setVerification({
                        type: fieldName,
                        fieldData: fieldData,
                        isPublic: isDecrypted,
                      });
                    }
                  }}
                  status={status}
                  ref={areaRef}
                  onMouseMove={handleMouseMove}
                >
                  <Box color="#9b9b9b" />
                </StatusContainer>
              </Tooltip>
            );
          }

          if (isPublic && fieldData.editedBy.length > 0) {
            return (
              <Tooltip
                arrow
                placement="top"
                title="Unverified"
                PopperProps={{
                  popperRef,
                }}
              >
                <StatusContainer
                  style={style}
                  onClick={() => {
                    handleActionOpen();
                    if (setVerification) {
                      setCurrentField && setCurrentField(fieldId);
                      setVerification({
                        type: fieldName,
                        fieldData: fieldData,
                        isPublic: isDecrypted,
                      });
                    }
                  }}
                  status={status}
                  ref={areaRef}
                  onMouseMove={handleMouseMove}
                >
                  <Box color="#9b9b9b" />
                </StatusContainer>
              </Tooltip>
            );
          }
        })()}
        &nbsp;
        {/* View edited details */}
        {isPublic && fieldData.editedBy.length > 0 && (
          <Tooltip
            arrow
            placement="top"
            title="View edited details"
            PopperProps={{
              popperRef,
            }}
          >
            <StatusContainer
              style={style}
              onClick={() => {
                setOpenEditedDetails(true);
                setEditedDetails && setEditedDetails(fieldData.editedBy[0]);
              }}
              status={status}
              ref={areaRef}
              onMouseMove={handleMouseMove}
            >
              <Box color="#0575e6" />
            </StatusContainer>
          </Tooltip>
        )}
        {(() => {
          if (type && type === "stage") {
            return (
              <>
                <Tooltip
                  arrow
                  placement="top"
                  title="View Verified Details"
                  PopperProps={{
                    popperRef,
                    anchorEl: {
                      getBoundingClientRect: () => {
                        return new DOMRect(
                          positionRef?.current.x,
                          areaRef?.current?.getBoundingClientRect().y,
                          0,
                          0
                        );
                      },
                    },
                  }}
                >
                  <StatusContainer
                    style={style}
                    onClick={() => {
                      setViewVerifiedData && setViewVerifiedData(true);
                      setCurrentField && setCurrentField(item);
                    }}
                    status={status}
                    ref={areaRef}
                    onMouseMove={handleMouseMove}
                  >
                    <Box color="#59D88C" />
                  </StatusContainer>
                </Tooltip>
                <Tooltip
                  placement="top"
                  arrow
                  title="See Blockchain Transaction"
                  PopperProps={{
                    popperRef,
                    anchorEl: {
                      getBoundingClientRect: () => {
                        return new DOMRect(
                          positionRef.current.x,
                          areaRef?.current?.getBoundingClientRect().y,
                          0,
                          0
                        );
                      },
                    },
                  }}
                >
                  <StatusContainer
                    style={style}
                    status={0}
                    ref={areaRef}
                    onMouseMove={handleMouseMove}
                  >
                    <Link
                      target="_blank"
                      href={`https://${urlName}.com/tx/${item?.data?.transactionHash?.data}`}
                    >
                      <ArticleTwoToneIcon
                        sx={{ fontSize: 30 }}
                        style={{ color: "#646464" }}
                      />
                    </Link>
                  </StatusContainer>
                </Tooltip>
              </>
            );
          }
        })()}
      </InnerContainer>
    </Container>
  );
};

export default VerifiedStatusContainer;

const Container = styled.div`
  display: flex;
  // gap: 0.5rem;
`;

const InnerContainer = styled.div`
  position: relative;
  display: flex;
  // gap: 0.7rem;
  justify-content: center;
  align-items: center;
`;

const Box = styled.div`
  width: 0.9rem;
  height: 0.9rem;
  border-radius: 2px;
  background-color: ${(props) => props.color};
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.2rem;
  cursor: ${(props) => props.status !== 1 && "pointer"};
`;

const IconContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  // gap: 0.2rem;
  cursor: pointer;
`;

export const Link = styled.a`
  color: #1e1b3b;
  max-width: 600px;
  word-break: break-word;
  font-weight: 500;
  @media (max-width: 1368px) {
    max-width: 500px;
  }
  @media (max-width: 768px) {
    max-width: 200px;
  }
`;
