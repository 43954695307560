import { Avatar, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { useHistory } from "react-router-dom";
import { NIFTRON } from "niftron-client-sdk";
import { useState } from "react";
import { getUserSession } from "../../../services/UserManagement";
import { useSelector } from "react-redux";
import { urlToHash } from "../../../utils/ipfs";

const AccountPopUp = () => {
  //eslint-disable-next-line
  const [user, setUser] = useState(getUserSession());
  const latestIpfsData = useSelector((state) => state.latestIpfsData);
  const currentUser = useSelector((state) => state.currentUser);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title={user && user?.alias}>
          <IconButton onClick={handleClick} size="small">
            <Avatar
              src={
                latestIpfsData?.profileImage?.data?.data
                  ? `https://niftron.infura-ipfs.io/ipfs/${urlToHash(
                      latestIpfsData?.profileImage?.data?.data
                    )}`
                  : `https://niftron.infura-ipfs.io/ipfs/${urlToHash(
                      latestIpfsData?.productImage?.data?.data
                    )}`
              }
              sx={{ width: 32, height: 32 }}
            >
              {latestIpfsData?.profileImage?.data?.data
                ? null
                : user && user?.alias?.charAt(0).toUpperCase()}
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => {
            currentUser.type === "PROFILE"
              ? history.push("/dashboard/my-profile")
              : history.push("/dashboard/profile");
          }}
        >
          <Avatar
            src={
              latestIpfsData?.profileImage?.data?.data &&
              `https://niftron.infura-ipfs.io/ipfs/${urlToHash(
                latestIpfsData?.profileImage?.data?.data
              )}`
            }
            sx={{ width: 32, height: 32 }}
          ></Avatar>
          {user && user?.alias}
        </MenuItem>

        <Divider />
        <MenuItem
          onClick={() => {
            history.push("/dashboard/settings");
          }}
        >
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem
          onClick={() => {
            NIFTRON.user.logout();
            localStorage.removeItem("secretKey");
            localStorage.clear();
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
};

export default AccountPopUp;
