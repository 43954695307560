import * as API from "../api/API";
export const fetchTokensData = async (
  currentUser,
  setUniqueNFTsTotalToken,
  setLimitedSFTsTotalToken
) => {
  try {
    const fetchTokenResponse = await API.retrieveTokens({
      path: {
        tId: currentUser.publicKey,
      },
    });

    if (fetchTokenResponse) {
      const tokenArray = fetchTokenResponse?.data?.data;

      const totalUniqueNFTToken = tokenArray
        .filter(({ tokenType }) => tokenType === "UNIQUENFTS")
        .reduce(
          (previousScore, currentScore, index) =>
            previousScore + currentScore.tokenLimit,
          0
        );
      setUniqueNFTsTotalToken(totalUniqueNFTToken);

      const totalLimitedSFTToken = tokenArray
        .filter(({ tokenType }) => tokenType === "LIMITEDSFTS")
        .reduce(
          (previousScore, currentScore, index) =>
            previousScore + currentScore.tokenLimit,
          0
        );

      setLimitedSFTsTotalToken(totalLimitedSFTToken);
    }
  } catch (e) {
    console.log(e);
  } finally {
  }
};
