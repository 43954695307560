import Web3 from "web3";
import * as API from "../api/API";
import { symmetricDecryption } from "./encryption";

export async function getSignature(currentUser, authUser, secretKey) {
  let blockchain = "MUMBAI";

  if (currentUser?.creatorPublicKey) {
    try {
      const creatorResponse = await API.retrieveCredentialProvider({
        path: {
          id: currentUser.creatorPublicKey,
        },
      });

      if (creatorResponse?.data?.code === 200) {
        if (creatorResponse?.data?.data[0].traceNetwork === "MATIC") {
          blockchain = "MATIC";
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  // get signature
  const evmPrivateKey = authUser.accounts[2].encryptedSecret;
  const privateKey = symmetricDecryption(evmPrivateKey, secretKey);
  let provider = "";
  if (blockchain === "MATIC") {
    provider = new Web3.providers.HttpProvider("https://polygon-rpc.com/");
  } else if (blockchain === "MUMBAI") {
    provider = new Web3.providers.HttpProvider(
      "https://matic-mumbai.chainstacklabs.com/"
    );
  }
  const web3 = new Web3(provider);

  let signerAccount = web3.eth.accounts.privateKeyToAccount(privateKey);
  let contractAddress = "";
  if (blockchain === "MATIC") {
    contractAddress = "0x7bbb46a6Df9144272cEc505b05BCD82Fefe123B0";
  } else {
    contractAddress = "0x20268E954616591e357eA04faE3B1e9F67703faC";
  }

  let DeproTraceABI = require("./abi/DeproTrace.json");

  const chainId = await web3.eth.getChainId();

  let domainData = {
    name: "MetaTransaction",
    version: "1",
    chainId: chainId, // Matic Testnet
    verifyingContract: contractAddress,
  };

  const domainType = [
    { name: "name", type: "string" },
    { name: "version", type: "string" },
    { name: "chainId", type: "uint256" },
    { name: "verifyingContract", type: "address" },
  ];

  const metaTransactionType = [
    { name: "nonce", type: "uint256" },
    { name: "from", type: "address" },
  ];

  let DeproTrace = new web3.eth.Contract(DeproTraceABI, contractAddress);
  let nonce = await DeproTrace.methods.nonces(signerAccount.address).call();

  let message = {};

  message.nonce = parseInt(nonce);
  message.from = signerAccount.address;

  const dataToSign = JSON.stringify({
    types: {
      EIP712Domain: domainType,
      MetaTransaction: metaTransactionType,
    },
    domain: domainData,
    primaryType: "MetaTransaction",
    message: message,
  });

  const hashedData = web3.utils.sha3(dataToSign);
  const signature = web3.eth.accounts.sign(
    hashedData,
    signerAccount.privateKey
  );

  let signatureObj = {
    signR: signature.r,
    signS: signature.s,
    signV: signature.v,
    messageHash: signature.message,
    profileAddress: signerAccount.address,
  };

  return { blockchain, signatureObj };
}
